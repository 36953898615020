import React from "react";
import cls from "./style.module.scss";
import useStyles from "isomorphic-style-loader/useStyles";
import { useApp } from "../../hooks/common";
import { useHistory } from "react-router-dom";
import { useCallback } from "react";
import { Iconfont } from "../icon/iconfont";
import Affix from "../affix";

// 正常通用header
export const Header = props =>{
    useStyles(cls);
    const history = useHistory();

    const handleClose = useCallback(() =>{
        if(props?.onClose){
            props.onClose?.();
        }else{
            history.goBack();
        }
    }, []);

    return <div className={`${cls.NavBar}`} style={{...props?.style}}>
        <span className={cls.Left} onClick={()=>handleClose()}>
            <Iconfont className={cls.Icon} style={{...props?.textStyle}}>&#xe7c7;</Iconfont>
        </span>

        <div className={cls.Content}>
            {props?.centerSlot}
        </div>

        <div className={cls.Right}>
            {props?.rightSlot}
        </div>

        {props?.children}
    </div>
}

export default props =>{
    useStyles(cls);

    const isApp = useApp();

    return <React.Fragment>
        {
            !isApp && <Affix offsetTop={0}>
                <Header {...props} />
            </Affix>
        }
    </React.Fragment>
    
}