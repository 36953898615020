import React, { useCallback, useEffect, useMemo, useState } from 'react'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import { TOGGLE_UI_LOTTERY } from '../../../../store/actions/ui'
import { useDispatch, useSelector } from 'react-redux'
import { Iconfont } from '../../../components/icon/iconfont'
import Cache from '../../../../utils/cache'
import withSourceClick from '../../../hocs/source-only-click'
import CountDown from '../../../components/count-down/index'
import LotteryAlertModal from '../index-lottery/modal/modal2/index';
import deeplink from '../../../../utils/deeplink'
import { FormattedMessage } from 'react-intl'
import { action_fetch_floating_mark_data } from '../../../../store/actions/global'
import { IMAGE_GEEKO_LTD } from '../../../../constants'
import { setOPLoading } from '../../../../store/actions/ui'
import { useLocation } from 'react-router-dom'
import { useBot } from '../../../hooks/common'


const IndexAlert = withSourceClick(props => {
    useStyles(classes);
    const {  innerRef, show, onClose, floatingMarkData, setShowFixIcon } = props
    const { icon, deepLink, isLottery, prize, serverTime } = floatingMarkData;
    const [showModal, setShowModal] = useState(false);
    const [lotteryResult, setLotteryResult] = useState(null);

    const dispatch = useDispatch()
    const global = useSelector(state=>state.global);
    const { config } = global || {};
    const { indexContactService = false } = config;
    const pageHome = useSelector((state) => state.pageHome);
    const { toogleService } = pageHome || {};

    const fixedEvent = (evt) =>{
        if(prize){
            evt.preventDefault()
            let offset = 0;
            if(prize.expiredDate && serverTime){
                offset = prize.expiredDate - serverTime;
            }

            setLotteryResult({ ...prize, offset });
            setShowModal(true);
            setShowFixIcon(false);
        } else if(isLottery){
            evt.preventDefault()
            dispatch({
                type: TOGGLE_UI_LOTTERY,
                payload: true
            })
        }
    }

    const touchEvent = evt =>{
        evt.stopPropagation()
    }

    const onCloseEvent = () =>{
        onClose();
        Cache.set('fixed-icon', 1)
    }

    const offset = useMemo(() =>{
        if(floatingMarkData?.expiredDate && floatingMarkData?.serverTime){
            return floatingMarkData.expiredDate - floatingMarkData.serverTime
        }

        return 0;
    }, [floatingMarkData]);

    const imageUrl = useMemo(() =>{
        if(icon?.indexOf("http") != -1){
            return icon;
        }else{
            return `${IMAGE_GEEKO_LTD}${icon}`;
        }
    }, [icon]);

    const refreshPrize = useCallback(async () =>{
        setShowModal(false);
        dispatch(setOPLoading(true));
        await dispatch(action_fetch_floating_mark_data());
        dispatch(setOPLoading(false));
        setShowFixIcon(true);
    }, []);

    const actualFont = useCallback((size) =>{
        return `${2 * size / 75 * 1}rem`;
    }, []);

    const slicingName = useMemo(() =>{
        if(prize?.name){
            let arrName = prize.name.split(" ");
            if(arrName?.length > 1){
                let [name1, ...name2] = arrName;
                let name1Str = name1?.length > 4 ? `<span style="font-size:${actualFont(14)};">${name1}</span>` : name1;
                return {
                    name1: name1Str,
                    name2: name2.join(" ")
                }
            }else{
                return prize.name;
            }
        }

        return null;
    }, [prize]);


    const serciceActive = useMemo(() =>{
        return indexContactService && toogleService;
    }, [indexContactService, toogleService])

    return <React.Fragment>
        <div className={`${classes.IndexAlert} ${show? classes.Show: ''} ${indexContactService?classes.ContactService:""} ${serciceActive?classes.ContactServiceActive:""}`}>
            <span className={classes.CloseIcon} onClick={onCloseEvent}>
                <Iconfont>&#xe69a;</Iconfont>
            </span>
            <a 
                className={classes.FixedLink}
                onClick={fixedEvent} 
                onTouchStart={touchEvent} 
                ref={innerRef} 
                data-title="icon"
                data-position="1"
                data-type="12" 
                data-content={floatingMarkData?.ref || prize?.name || deeplink(deepLink)}
                href={deeplink(deepLink)}
            >
                <img src={imageUrl} />

                {
                    prize && <React.Fragment>
                        {
                            slicingName?.name1 ? <div className={classes.slicingName}>
                                <p className={classes.PrizeName1} dangerouslySetInnerHTML={{ __html: slicingName.name1 }}></p>
                                <p className={classes.PrizeName2}>{slicingName.name2}</p>
                            </div> : <div className={`${classes.slicingName} ${classes.LineTwo}`}>{slicingName}</div>
                        }
                        

                        <div className={classes.PrizeMessage} style={{ backgroundColor: floatingMarkData?.backgroundColor || "#222" }}>
                            {
                                offset > 0 ? <React.Fragment>
                                    <CountDown 
                                        callback={refreshPrize} 
                                        offset={offset} 
                                        showDay 
                                        showHour 
                                    />
                                </React.Fragment> : <span><FormattedMessage id="view" defaultMessage="view" /></span>
                            }
                        </div>
                    </React.Fragment>
                }
            </a>
            
        </div>

        <LotteryAlertModal 
            isNotLottery
            showMaskBg
            showModal={showModal} 
            lotteryResult={lotteryResult} 
            refreshPrize={refreshPrize} 
            onClose={() => {
                setShowModal(false);
                setShowFixIcon(true);
            }} 
        />
    </React.Fragment>
})

export default props => {
    const dispatch = useDispatch();
    const location = useLocation();
    const ui = useSelector(state => state.ui);
    const global = useSelector(state=>state.global);
    const { floatingMarkData, country, hideFixedIcon } = global;
    const [showIcon, setShowIcon] = useState(true);
    const [showFixIcon, setShowFixIcon] = useState(true);
    const isBot = useBot()

    useEffect(async () => {
        const fixedIcon = Cache.get('fixed-icon');
        if(!fixedIcon){
            await dispatch(action_fetch_floating_mark_data());
        }
    }, []);

    useEffect(() => {
        if(location.pathname?.indexOf('order-confirm') != -1 && floatingMarkData?.isLottery){
            setShowIcon(false)
        } else {
            setShowIcon(true)
        }
    }, [floatingMarkData, location])

    return <React.Fragment>
        {
            !isBot && showIcon && floatingMarkData && !hideFixedIcon && <React.Fragment>
                <IndexAlert 
                    show={(ui.direction === 'down' || !ui.direction) && !ui.showLottery && showFixIcon} 
                    floatingMarkData={floatingMarkData} 
                    onClose={()=>setShowIcon(false)} 
                    setShowFixIcon={setShowFixIcon} 
                    shouldMini={country == 'MX'}
                />
            </React.Fragment>
        }
    </React.Fragment>
}