import React, { createRef, useEffect, useCallback } from 'react'
import Header from './fragments/header'
// import Footer from './fragments/footer'
import Menu from './fragments/menu'
import Search from './fragments/search'
import LeftToRight from '../components/transitions/left-to-right'
import {
	useSelector,
	useDispatch
} from 'react-redux'
import { TOGGLE_UI_MENU, TOOGLE_UI_DIRECTION, TOOGLE_UI_POPUP_SLIDE_IMAGES, TOOGLE_UI_REQUEST_PRIVACY, TOOGLE_UI_ALERT, TOOGLE_UI_CONFIRM } from '../../store/actions/ui'
import { BlackMask } from '../components/mask'
import ScrollToTop from '../components/pageicons/back-top'
import { useApp, useHeader, useOnlyList, useQuery } from '../hooks/common'
import LoginPage from "../pages/login2/components/alert-login/index"
import {WebPush} from './fragments/web-push'
import AddToHome from './fragments/add-to-home'
import { useScroll } from '../hooks/scroll'
import Notification from './fragments/notification'
import IndexFixedIcon from "../pages/components/index-fixicon/index";
import IndexLottery from '../pages/components/index-lottery/index'
import { useLocation } from 'react-router-dom'
import PopupSlideImages from './fragments/popup-slide-images'
import SizeChartWrapped from './fragments/size-chart2'
import Alert from '../components/alters/alert'
import Confirm from '../components/alters/confirm'
import OPLoading from '../components/loading/op-loading'
// import { setShowSizeChart } from '../../store/actions/global'
import { setGiftsModalData } from '../../store/actions/pages/collection'
import { GiftsToolsModal } from '../pages/listing/fragments/gifts'
import ContactService from '../pages/moduleable/home/fragments/contact-service'
import ConsentBanner from '../components/consent-banner'

export default props => {
	const dispatch = useDispatch()
	const ui = useSelector((state) => state.ui)
	const pageCollection = useSelector(state =>state.pageCollection);
	const global = useSelector(state=>state.global);
	const { imageSearch = {}, isBot } = global;
	const touchRef = createRef()

	const isApp = useApp()
	const isOnlyList = useOnlyList()
	const direction = useScroll()
	const hasHeader = useHeader()

	const location = useLocation();

	const query = useQuery();

	useEffect(() => {
		dispatch({
			type: TOOGLE_UI_DIRECTION,
			payload: direction
		})
	}, [direction])

	const closeAlertHandle = useCallback(() => {
        dispatch({
            type: TOOGLE_UI_ALERT,
            payload: null
        })
    }, [])


	const closeConfirmHandle = useCallback(() => {
        dispatch({
            type: TOOGLE_UI_CONFIRM,
            payload: null
        })
    }, [])

	return <React.Fragment>
		{
			imageSearch.show && <Search/>
		}

		{
			<div style={{display: !imageSearch.show ? 'block':'none'}}>
				{
					!isApp && !isOnlyList && hasHeader && <Header/>
				}
				<div ref={touchRef}>
					{
						props.children
					}
				</div>
				<LeftToRight in={ui.showMenu}>
					<div>
						<Menu 
							onClose={() =>{
								dispatch({
									type:TOGGLE_UI_MENU,
									payload: false
								})
							}}
						/>
					</div>
				</LeftToRight>
				{ui.showMenu && <BlackMask onClick={ () => {
					dispatch({
						type:TOGGLE_UI_MENU,
						payload: false
					})
				}}/>}
			</div>
		}

		{
			!isApp && !isBot && location.pathname !== '/landing-page/chicme-7th-anniv-sale' && location.pathname !== '/landing-page/chicme-7th-anniv-sale/' && <React.Fragment>
				<IndexFixedIcon />
				<IndexLottery />

				<ContactService />
			</React.Fragment>
		}

		<ScrollToTop/>
		<LoginPage />
		<WebPush/>
		<Notification/>
		<AddToHome/>
		
		<SizeChartWrapped/>

		<PopupSlideImages />

		<ConsentBanner />

		<Alert show={ui.alert?.show} options={ui.alert?.options} onClose={closeAlertHandle}/>

		<Confirm show={ui.confirm?.show} options={ui.confirm?.options} onClose={closeConfirmHandle}/>
		{
			ui.opLoading && <OPLoading />
		}

		<GiftsToolsModal 
			showModal={pageCollection?.giftsModalData?.show} 
			onClose={() =>{
				dispatch(setGiftsModalData({
					show: false,
					options: null
				}))
			}} 
			giftData={pageCollection?.giftsModalData?.options} 
			isDrawGift
		/>
	</React.Fragment>
}