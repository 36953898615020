import React, { useEffect, useState, useCallback, useRef,useLayoutEffect, useMemo } from 'react'
import {
	useDispatch, useSelector
} from 'react-redux'

import _ from 'lodash'

import {
	searchSimple,
	fetchSearchImage,
	fetchSearchImageSort, 
} from '../../../../api'

import classes from './style.module.scss'
import { action_fetch_hot_words } from "../../../../store/actions/global";

import { LazyLoadImage } from 'react-lazy-load-image-component'
import Affix from '../../../components/affix'
import { getProductUrl } from '../../../../utils/product'
import useStyles from 'isomorphic-style-loader/useStyles'

import { FormattedMessage } from 'react-intl'

import Cache from '../../../../utils/cache'
import { Iconfont } from '../../../components/icon/iconfont'
import { BlackMask } from '../../../components/mask'
import UploadModal from './components/upload-modal'
import Alert from '../../../components/alert'
import { Products as SearchImageProducts } from '../../../pages/listing/components/list'
import { Swiper, SwiperSlide } from 'swiper/react'
import { SET_SEARCH_IMAGE_RESULT } from '../../../../store/actions/global'
import { isIOS, isMacOs } from 'react-device-detect'
import withSource from '../../../hocs/source-scoll'
import { getSensorsUrl } from '../../../utils/sensor'
import withSourceClick from '../../../hocs/source-only-click'
import SimilarRecommend from './components/similar-recommend'
import RecentlyViewedProducts from './components/recently-viewd'



const { IMAGE_GEEKO_LTD } = require('../../../../constants')

const addWordToCache = word => {
	try {
		const recentlyWords = Cache.get('recently-words') || []
		const currentWords = recentlyWords.filter(r => r.value !== word?.value)

		if (currentWords?.length >= 10) {
			currentWords.length = 9
		}
		currentWords.unshift(word)
		Cache.set('recently-words', currentWords, 30 * 24 * 60 * 60)
	} catch (e) {
		console.error(e)
	}
}

const RecentWords = props => {
	useStyles(classes)
	const { hotWords } = props
	return <div className={classes.HotWordContainer}>
		<div className={classes.Hd}>
			<FormattedMessage id="recently_search" defaultMessage="Recently Search" />
			<Iconfont onClick={props.handleRemove} className={classes.Remove}>&#xe777;</Iconfont>
		</div>
		<div className={classes.Bd}>
			<div className={classes.HotWords}>
				{
					hotWords?.map(hotWord => <HotWord isRecently hot={hotWord} key={hotWord.value} sensors={{type:"18"}} />)
				}
			</div>
		</div>
	</div>
}

const HotWord = withSourceClick(props => {

	useStyles(classes)
	const { hot, isRecently, innerRef, sensors } = props

	const clickHandle = event => {
		event.preventDefault()
		let href;

		try {
			if (!isRecently) {
				addWordToCache(hot)
			}
		} catch (e) {
			console.error(e)
		}

		if (hot.href) {
			href = hot.href
		} else {
			href = `/w-site/anon/search?searchValue=${encodeURIComponent(hot.value)}`
		}
		
		window.location.href = getSensorsUrl(href, {
			resourcepage_title: sensors?.title || "search",
			resource_type: sensors?.type,
			resource_content: hot.label,
			resource_position: sensors?.position || "1"
		})
	}

	return <a 
		className={classes.HotWord} 
		onClick={clickHandle}
		ref={innerRef}
		data-title={sensors?.title || "search"}
        data-position={sensors?.position || "1"}
        data-type={sensors?.type}
        data-content={hot.label}
	>
		{
			hot.hotIcon && <img className={classes.Icon} src={`${IMAGE_GEEKO_LTD}${hot.hotIcon}`} />
		}

		<span className={classes.Text}>
			{hot.label}
		</span>
	</a>
})

const HotWords = React.memo(props => {
	useStyles(classes)
	const { hotWords } = props
	const hotWordContainerRef = useRef();
	const hotWordRef = useRef();
	const [showViewMore,setShowViewMore] = useState(false);
	const [fullHeight,setFullHeight] = useState(false);

	useEffect(() =>{
		if(hotWordContainerRef.current?.offsetHeight > 0){
			let sumHeight = hotWordContainerRef.current.offsetHeight;
			let minuteHeight = hotWordRef.current.offsetHeight;

			if(minuteHeight > sumHeight){
				setShowViewMore(true);
			}
		}
		
	},[hotWords]);

	const viewMoreEvent = () =>{
		setShowViewMore(false);
		setFullHeight(true);
	}

	return <div className={classes.HotWordContainer}>
		<div className={classes.Hd}>
			<FormattedMessage id="hot_search" defaultMessage="Hot Search" />
		</div>
		<div className={classes.Bd}>
			<div className={!fullHeight?classes.HotWordsCon:classes.HotWordsConFull} ref={hotWordContainerRef}>
				<div className={classes.HotWords} ref={hotWordRef}>
					{
						hotWords?.map(hotWord => <React.Fragment>
							<HotWord hot={hotWord} key={hotWord.value} sensors={{type:"13"}} />
						</React.Fragment>)
					}
				</div>
			</div>
			
			{
				showViewMore && <p className={classes.ViewMore}>
					<span onClick={viewMoreEvent}><FormattedMessage id="view_more" defaultMessage="View More" /> {'>'}</span>
				</p>
			}
		</div>
	</div>
})


const Words = props => {
	useStyles(classes)
	const { words, searchValue } = props
	const reg = new RegExp(searchValue, 'ig')

	const getHighLightWord = useCallback((word) => {
		return word?.replace(reg, str => `<b>${str}</b>`)
	}, [])

	const handleSearch = useCallback(word => {
		if(window.GeekoSensors){
			window.GeekoSensors.Track("PitPositionClick", {
				resourcepage_title: 'search',
				resource_position: '1',
				resource_type: '15',
				resource_content: word
			})

			// window.GeekoSensors.Track("PitPositionExposure", {
			// 	resourcepage_title: 'search',
			// 	resource_position: '1',
			// 	resource_type: '15',
			// 	resource_content: word
			// })
		}


		window.location.href = getSensorsUrl(`/w-site/anon/search?searchValue=${encodeURIComponent(word)}`, {
			resourcepage_title: 'search',
			resource_position: '1',
			resource_type: '15',
			resource_content: word
		})
	}, [])



	return <ul className={classes.Words}>
		{
			words?.map(word => <li onClick={() => { handleSearch(word) }} className={classes.Word} key={word}>
				<span dangerouslySetInnerHTML={{ __html: getHighLightWord(word) }} />
			</li>)
		}
	</ul>
}

const Products = props => {
	useStyles(classes)
	const { productResult, onViewMore } = props
	const handleProduct = useCallback(product => {
		let href = getProductUrl(product?.id, product?.name);
		if(window.GeekoSensors){
			window.GeekoSensors.Track("PitPositionClick", {
				resourcepage_title: 'search',
				resource_position: '1',
				resource_type: '16',
				resource_content: product?.id
			})

			// window.GeekoSensors.Track("PitPositionExposure", {
			// 	resourcepage_title: 'search',
			// 	resource_position: '1',
			// 	resource_type: '16',
			// 	resource_content: product?.id
			// })
		}

		window.location.href = getSensorsUrl(href, {
			resourcepage_title: 'search',
			resource_position: '1',
			resource_type: '16',
			resource_content: product?.id
		})
	}, [])
	return <div className={classes.ProductArea}>
		<div className={classes.Hd}>{`Items(${productResult?.count})`}</div>
		<div>
			<ul className={classes.Products}>
				{
					productResult?.result?.map(product => <li onClick={() => { handleProduct(product) }} className={classes.Product} key={product.id}>
						<div className={classes.SmallImage}>
							<LazyLoadImage src={`${IMAGE_GEEKO_LTD}/small/${product.image || product.id}`} />
						</div>
						<div>
							{product.name}
						</div>

					</li>)
				}
				<li className={`${classes.Product} ${classes.ViewMore}`} onClick={onViewMore}>
					<a>
						<FormattedMessage id="view_more" defaultMessage="View More" />
					</a>
				</li>
			</ul>
		</div>
	</div>
}


const SearchResult = props => {
	useStyles(classes)
	const { searchResult, searchValue, onViewMore } = props
	return <div className={classes.Boxes}>
		<Words words={searchResult?.words?.result} searchValue={searchValue} />
		<Products productResult={searchResult?.product} onViewMore={onViewMore}/>
	</div>
}

const UploadImageErrorModal = (props) => {
	useStyles(classes);
	const { onClose, tryAgainEvent } = props;

	const closeEvent = () =>{
		onClose();
	}

	const tryAgain = () =>{
		tryAgainEvent();
	}

	return <>
		<div className={classes.UploadImageErrorModal}>
			<div className={classes.Container}>
				<p className={classes.Title}><FormattedMessage id="not_match_fund" defaultMessage="Not Match found" /></p>
				<p className={classes.Content}><FormattedMessage id="please_try_clearer_image" defaultMessage="Please try a clearer image, or put the product you want to search for in the middle of the image" /></p>
				<p><FormattedMessage id="or" defaultMessage="or" /></p>
				<p><FormattedMessage id="try_text_to_search" defaultMessage="Try text to search for the item you want" /></p>

				<button className={classes.FirstBtn} onClick={closeEvent}><FormattedMessage id="try_text_search" defaultMessage="Try Text Search" /></button>
				<button className={classes.SecondBtn} onClick={tryAgain}><FormattedMessage id="try_again_with_image" defaultMessage="Try Again With Image" /></button>
			</div>
		</div>

		<BlackMask style={{zIndex:2}}/>
	</>
}

const UploadImagePage = props => {
	useStyles(classes);

	const { searchBack, onClose, onSearchImage, handleSensors } = props
	const [loading, setLoading] = useState(false)
	const imageSrcRef = useRef(null);
	const [errorModal, setErrorModal] = useState(false);
	const cancelLoading = useRef(true);

	const searchByImage = async (event,type) => {
		let file = event.target.files[0];
		if(!!!file) return false;
		let maxSize = 10485760;
		if (file?.size > maxSize) {
			Alert(`A single image should not exceed 10M!`);
			return false;
		}

		const formData = new FormData();
		formData.append("image", file);
		// formData.append("limit", 30);
		const src = file && window.navigator.userAgent.indexOf("Chrome") >= 1 || window.navigator.userAgent.indexOf("Safari") >= 1 ? window.webkitURL.createObjectURL(file) : window.URL.createObjectURL(file);
		setLoading(true)
		onSearchImage(src);
		cancelLoading.current = true;
		let response = await fetchSearchImage(formData);
		if (response?.code === 200) {
			if(response?.result?.length > 0){
				cancelLoading.current && searchBack(response?.result);
			}else{
				setErrorModal(true);
			}
		} else {
			// setErrorModal(true);
			response?.result && Alert(response.result, {
				onClose: tryAgainEvent
			});
		}
		setLoading(false);

		let sensorsData;

		if(type){
			sensorsData = {
				resourcepage_title: 'search',
				resource_position: '1',
				resource_type: '19',
				resource_content: "Photo"
			};

			if(window.GeekoSensors){
				window.GeekoSensors.Track("PitPositionClick", sensorsData)
			}
		}else{
			sensorsData = {
				resourcepage_title: 'search',
				resource_position: '1',
				resource_type: '19',
				resource_content: "Image"
			};

			if(window.GeekoSensors){
				window.GeekoSensors.Track("PitPositionClick", sensorsData)
			}
		}

		handleSensors(sensorsData);
	}

	const closeEvent = async () =>{
		setLoading(false);
		onSearchImage(null);
		cancelLoading.current = false;
	}

	const tryAgainEvent = () =>{
		setErrorModal(false);
		onSearchImage(null);
	}


	return <div className={classes.UploadImagePage}>
		<div className={classes.Hd}>
			<Iconfont className={`${classes.Icon}`} onClick={onClose}>&#xe693;</Iconfont>
		</div>
		<div className={classes.Bd}>
			<p><FormattedMessage id="visual_search" defaultMessage="Visual Search" /></p>
			<p><FormattedMessage id="photo_upload_image" defaultMessage="You can now search for the item you want by take a photo or upload an image" /></p>
		</div>

		<div className={classes.Fd}>
			<div className={classes.Left}>
				<span>
					<Iconfont>&#xe706;</Iconfont>
					<input type="file" accept="image/*" name="image" onChange={(event) =>searchByImage(event,true)} />
				</span>
				<p><FormattedMessage id="take_a_photo" defaultMessage="Take a Photo" /></p>
			</div>

			<div className={classes.Right}>
				<span>
					<Iconfont>&#xe705;</Iconfont>
					<input type="file" accept="image/*" name="image" onChange={(event) =>searchByImage(event,false)} />
				</span>
				<p><FormattedMessage id="upload_a_image" defaultMessage="Upload a Image" /></p>
			</div>
		</div>

		{
			loading && <UploadModal imageSrc={imageSrcRef} onClose={closeEvent} />
		}

		{
			errorModal && <UploadImageErrorModal
				onClose={() => {
					onClose();
					onSearchImage(null);
				}}
				tryAgainEvent={tryAgainEvent}
			/>
		}
	</div>
}

const SearchImageResultPage = props => {
	useStyles(classes);
	const { imageResult, onClose, sensors, productId } = props
	const [activeIndex, setActiveIndex] = useState(0);
	const [sortNum, setSortNum] = useState(0);
	const [products, setProducts] = useState(imageResult?.[activeIndex]?.products);
	const global = useSelector(state =>state.global);

	useEffect(async () =>{
		if(global?.abTestForImageSearch == "B" && !(isIOS || isMacOs)){
			await sortProductsEvent(2);
		}
	},[]);

	const sortProductsEvent = async (num) => {
		setSortNum(num);
		setProducts(null);
		let data = {
			ids: imageResult[activeIndex]?.productIds.join(","),
			sort: num
		};
		let response = await fetchSearchImageSort(data);
		if (response.code === 200) {
			setProducts(response?.result);
		} else {
			console.log("response", response.result);
			response?.result && Alert(response.result);
		}
	}

	const changeImageEvent = (index) => {
		setActiveIndex(index);
		setSortNum(0);
		setProducts(imageResult[index]?.products);
	}

	const isSizeSelector = useMemo(() =>{
		return productId && !imageResult?.length;
	}, [productId, imageResult]);

	return <div>
		<div className={classes.SearchImageResultPage}>
			<div className={classes.Hd}>
				<div className={classes.Close}>
					<Iconfont className={`${classes.Icon}`} onClick={onClose}>&#xe693;</Iconfont>
				</div>

				{
					isSizeSelector ? <div className={classes.SimilarFont}><FormattedMessage id="similar_item" defaultMessage="Similar Items" /></div> : <div className={classes.SearchImageContainer}>
						<Swiper
							spaceBetween={10}
							slidesPerView='auto'
							onSlideChange={(swiper) => console.log()}
							onSwiper={(swiper) => console.log()}
							className={classes.SwiperContainer}
						>
							{
								imageResult?.map((item, index) =><SwiperSlide key={index} className={`${classes.SearchImageitem} ${index === activeIndex ? classes.Active : ""}`}  onClick={() => changeImageEvent(index)}>
										<span>{item?.title}</span>
									</SwiperSlide>
								)
							}
						</Swiper>
					</div>
				}
				
			</div>
			
			{
				!isSizeSelector && <div className={classes.Bd}>
					<div className={sortNum === 0 ? classes.Active : ""} onClick={() => sortProductsEvent(0)}><FormattedMessage id="recommend" defaultMessage="Recommend" /></div>
					<div className={sortNum === 1 ? classes.Active : ""} onClick={() => sortProductsEvent(1)}><FormattedMessage id="new_arrivals" defaultMessage="New Arrivals" /></div>
					<div
						className={`${sortNum === 2 ? classes.LowPrice : ""} ${sortNum === 3 ? classes.Highprice : ""} ${classes.Price} ${sortNum === 2 || sortNum === 3 ? classes.Active : ""}`}
						onClick={() => sortProductsEvent(sortNum === 2 ? 3 : 2)}
					>
						{
							sortNum === 2 || sortNum === 3 ?
								(sortNum === 2 ? <FormattedMessage id="low_price" defaultMessage="Low Price" /> : <FormattedMessage id="high_price" defaultMessage="High Price" />) :
								(<FormattedMessage id="price" defaultMessage="Price" />)
						}
					</div>
				</div>
			}
			
			{
				isSizeSelector ? <div className={classes.ScrollContainer}>
					<SimilarRecommend 
						productId={productId} 
						sensors={sensors} 
					/>
				</div> : <React.Fragment>
					<div className={classes.ProductsContainer}>
						{
							products?.length > 0 ? (
								<SearchImageProducts 
									products={products} 
									column="virtualsearch" 
									product-type="virtualsearch" 
									sensors={sensors}
								/>
							) : 
							(<div className={classes.Loading}>
								<FormattedMessage id="loading" defaultMessage="Loading" />
							</div>)
						}
					</div>
				</React.Fragment>
			}
		</div>

		<BlackMask onClick={onClose} style={{zIndex:2}}/>
	</div>
}

export default props => {

	useStyles(classes)
	const global = useSelector((state) => state.global)
	const { hotWords, imageSearch } = global
	const [showSuggestion, setShowSuggestion] = useState(false)
	const [searchValue, setSearchValue] = useState('')
	const [searchResult, setSearchResult] = useState(null)

	const [recentlyWords, setRecentlyWords] = useState([])
	const [searchUI, setSearchUI] = useState(imageSearch?.searchType)
	const [imageResult, setImageResult] = useState(imageSearch?.result);
	const [imageUrl, setImageUrl] = useState(imageSearch?.url);
	const [messageShow, setMessageShow] = useState(true);

	const [sensors, setSensors] = useState(imageSearch?.sensors);

	const search = useCallback(_.debounce(async key => {
		if (key && key.length >= 2) {
			const response = await searchSimple(key)
			if (response.code === 200) {
				setSearchResult(response.result?.groupResult)
			}
		}
		setShowSuggestion(key && key.length >= 2)
	}, 400), [])

	const dispatch = useDispatch()

	useEffect(() => {
		if (!hotWords || hotWords.length < 1) {
			dispatch(action_fetch_hot_words())
		}

		setRecentlyWords(Cache.get('recently-words'))
		
		let virtualSearch = Cache.get("virtual-search");
		if(global?.imageSearch?.result?.length > 0 || !!virtualSearch){
			setMessageShow(false);
		}else{
			setMessageShow(true);
			Cache.set("virtual-search",1);

			setTimeout(() => {
				setMessageShow(false);
			}, 3000);
		}

		// if(global.currentSearch || hotWords?.[0]?.value){
		// 	window.GeekoSensors.Track("PitPositionExposure", {
		// 		resourcepage_title: 'search',
		// 		resource_position: '1',
		// 		resource_type: '17',
		// 		resource_content: global.currentSearch || hotWords?.[0]?.value
		// 	})
		// }
	}, [])

	useEffect(() => {
		search(searchValue)
	}, [searchValue])

	const handleSearch = e => {
		e?.preventDefault()
		let sensorsData;

		const finalSearchVaule = searchValue||global.currentSearch || hotWords?.[0]?.value

		if(!finalSearchVaule) return

		try {
			addWordToCache({ value: finalSearchVaule, label: finalSearchVaule })
		} catch (e) {
			console.error(e)
		}

		if(searchValue){
			sensorsData = {
				resourcepage_title: 'search',
				resource_position: '1',
				resource_type: '14',
				resource_content: searchValue
			};

			if(window.GeekoSensors){
				window.GeekoSensors.Track("PitPositionClick", sensorsData)

				// window.GeekoSensors.Track("PitPositionExposure", sensorsData)
			}
		}else if(global.currentSearch || hotWords?.[0]?.value){
			sensorsData = {
				resourcepage_title: 'search',
				resource_position: '1',
				resource_type: '17',
				resource_content: global.currentSearch || hotWords?.[0]?.value
			};

			window.GeekoSensors.Track("PitPositionClick", sensorsData)
		}

		window.location.href = getSensorsUrl(`/w-site/anon/search?searchValue=${encodeURIComponent(finalSearchVaule)}`,sensorsData);
	}

	const handleRemove = useCallback(evt => {
		setRecentlyWords([])
		Cache.remove('recently-words')
	}, []);

	const uploadImageEvent = useCallback(() =>{
		setSearchUI(1);
	}, []);

	const onHandleClose = useCallback(() =>{
		dispatch({
			type:SET_SEARCH_IMAGE_RESULT,
			payload:{
				url:null,
				result:[],
				show:false,
				searchType:null,
				sensors:null,
				productId: null
			}
		});
	}, []);

	const onImageResultClose = useCallback(async () =>{
		// 隔绝出来直接类似于商品详情页直接传入数据的情况
		if(imageSearch?.productId || imageSearch?.result?.length > 0){
			onHandleClose();
		}else{
			setSearchUI(1);
			setImageUrl(null);
		}
	}, [imageSearch]);

	return <div className={classes.SearchContainer}>

		{
			searchUI === 0 && <div>
				<Affix offsetTop={0}>
					<div className={classes.SearchArea}>
						<Iconfont 
							className={classes.ReturnPage}
							onClick={onHandleClose}
						>&#xe7c7;</Iconfont>

						<form onSubmit={handleSearch} action={'/search'}>
							<input type='search' autoComplete={false} value={searchValue} onChange={e => { setSearchValue(e.target.value) }} className={classes.SearchInput} autoFocus={!!!(global?.imageSearch?.result?.length > 0)} placeholder={global.currentSearch || hotWords?.[0]?.value} />

							{/* {
								searchValue === "" && <div className={classes.CameraContainer}>
									<span className={classes.Icon} onClick={uploadImageEvent}></span>

									{
										messageShow && <div className={classes.MessageContainer}>
											<div>
												<Iconfont onClick={() => setMessageShow(false)}>&#xe69a;</Iconfont>
											</div>
											<p className={classes.Title}><FormattedMessage id="visual_search" defaultMessage="Visual Search" /></p>
											<p className={classes.Content}><FormattedMessage id="upload_image_search_photo" defaultMessage="Take a photo or upload an image to find similar items." /></p>
										</div>
									}
								</div>
							} */}
						</form>
						
						<div className={classes.CancelRight}>
							<span className={classes.CancelBtn} onClick={handleSearch}></span>
						</div>
						
					</div>
				</Affix>



				<div>
					{
						showSuggestion && <SearchResult searchResult={searchResult} searchValue={searchValue} onViewMore={handleSearch}/>
					}

					{
						!showSuggestion && <div>

							{
								recentlyWords && recentlyWords.length > 0 && <RecentWords hotWords={recentlyWords} handleRemove={handleRemove} />
							}

							<HotWords hotWords={hotWords} />

							<RecentlyViewedProducts />
						</div>
					}

				</div>
			</div>
		}
		

		{
			searchUI === 1 && 
				<UploadImagePage 
					onSearchImage={(src) => setImageUrl(src)} 
					searchBack={result => {
						setImageResult(result)
						setSearchUI(2)
					}} 
					onClose={() => {
						setSearchUI(0)
					}} 
					handleSensors={(data)=>{
						setSensors(data);
					}}
			/>
		}


		{
			searchUI === 2 && 
				<SearchImageResultPage 
					imageResult={imageResult} 
					imageUrl={imageUrl} 
					searchUI={searchUI} 
					sensors={sensors} 
					productId={imageSearch?.productId} 
					onClose={onImageResultClose} 
				/>
		}

		{
			(searchUI === 1 || searchUI === 2) && imageUrl && <div
				className={classes.ImageModal}
				style={{ background: `url(${imageUrl}) center center/cover no-repeat` }}>
			</div>
		}
	</div>
}