import React from "react";
import classes from "./style.module.scss";
import useStyles from "isomorphic-style-loader/useStyles";
import { useField } from "formik";
import { FormattedMessage } from "react-intl";

export default props =>{
    useStyles(classes);
    const { InputProps, codeComponent, refreshCode } = props;
    const [field, meta, helpers] = useField(InputProps)

    return <div className={classes.AreaCodeContainer}>
        <label><FormattedMessage id="code" defaultMessage="Code" /></label>
        <div className={classes.AreaCode}>
            <input 
                type="text" 
                {...field} 
                {...InputProps} 
                autoComplete={"off"} 
            />

            <span onClick={() => refreshCode()} className={classes.AreaImage} style={{cursor: 'pointer'}}>
                {codeComponent}
            </span>

            <span className={classes.RefreshIcon} onClick={() => refreshCode()}></span>
        </div>
        <div className={classes.ErrorMsg}>{meta.touched && meta.error ? meta.error : null}</div>
    </div> 
}
