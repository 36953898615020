import React, { useEffect, useCallback, useState } from 'react'
import useStyles from 'isomorphic-style-loader/useStyles'
import classes from './style.module.scss'
import { useTouch } from '../../../hooks/touch'
import { useBot, useApp, useTouched } from '../../../hooks/common'
import { useDispatch, useSelector } from 'react-redux'
import { action_fetch_notification, setNotificationEventData } from '../../../../store/actions/global'
import { TOOGLE_SUCCESS_WINDOW } from '../../../../store/actions/ui'
import moment from 'moment'
import deeplink from '../../../../utils/deeplink'
import { IMAGE_GEEKO_LTD } from '../../../../constants'
import { getByUrl } from '../../../../api'
import Alert from '../../../components/alert'
import { Iconfont } from '../../../components/icon/iconfont'
import Cache from '../../../../utils/cache'
import { BlackMask } from '../../../components/mask'
import { FormattedMessage } from 'react-intl'
import { BigButton } from '../../../components/button'
import CountDown from '../../../components/count-down'

const getImage = image => {
    if (image?.startsWith('/')) {
        return `${IMAGE_GEEKO_LTD}${image}`
    } else if (image?.startsWith('http')) {
        return image
    } else {
        return `${IMAGE_GEEKO_LTD}/${image}`
    }
}

const getFromNow = t => {
    try {
        if (t)
            return moment(t).fromNow()
        return ''
    } catch (e) {
        return ''
    }
}

const CouponButton = props => {
    useStyles(classes)
    const dispatch = useDispatch()
    const { button } = props
    const [loading, setLoading] = useState(false)

    const buttonHandle = async e => {
        e.preventDefault()
        setLoading(true)
        const { deepLink, successMsg } = button
        if (deepLink) {
            if (deepLink.type === 35) {
                const response = await getByUrl(deepLink?.params?.[0])
                if (response?.code === 200) {
                    dispatch(setNotificationEventData(null))
                    dispatch({
                        type: TOOGLE_SUCCESS_WINDOW,
                        payload: successMsg
                    })
                } else {
                    Alert(response.result)
                }
                props?.onClose()
            } else {
                const url = deeplink(deepLink,"msite")
                window.location.href = url
            }
        }

        setLoading(false)

        if (window.GeekoSensors) {
            window.GeekoSensors.Track('StationMsg', {
                type: '站内信',
                action: 'click',
                message_id: deepLink?.utmTerm
            })
        }
    }


    return <React.Fragment>
        <button className={classes.CouponButton} onClick={buttonHandle}>
            {
                loading && <Iconfont className={classes.LoadIcon}>&#xe62d;</Iconfont>
            }
            {button?.text}
        </button>
    </React.Fragment>
}

const CouponSuccess = props => {
    useStyles(classes)

    const dispatch = useDispatch()
    const { coupon } = props

    const closeHandle = () => {
        dispatch({
            type: TOOGLE_SUCCESS_WINDOW,
            payload: false
        })
    }

    return <React.Fragment>
        <BlackMask onClick={closeHandle} />
        <div className={classes.CouponSuccess}>
            <img src={`${IMAGE_GEEKO_LTD}/chicme/20220613/jbbj.svg`} />
            <div className={classes.Title}>
                {coupon}
            </div>
            <div>
                <a className={classes.Dsc} href="/user/coupons">
                    <FormattedMessage id="check_it_in_coupons" defaultMessage="Check it in [My Coupons]" />
                </a>
            </div>
            <div className={classes.Timmer}>
                <CountDown offset={24 * 60 * 60 * 1000} showLabel showHour labelClass={classes.Label} numberClass={classes.Number} dotClass={classes.Dotted} />
            </div>
            <div className={classes.ButtonA}>
                <BigButton onClick={closeHandle}>
                    <FormattedMessage id="got_it" defaultMessage="Got It" />
                </BigButton>
            </div>
        </div>
    </React.Fragment>
}

const Type2 = props => {
    useStyles(classes)
    const global = useSelector(state => state.global)
    const { notification, handleClick, notiRef } = props
    return <div className={classes.NotificationContainer}>
        <div onClick={handleClick} ref={notiRef} className={classes.Notification}>
            <div className={classes.Hd}>
                <div className={classes.LogoContainer}>
                    <img className={classes.Logo1} src={notification?.bodycon || global?.config?.icon} />
                    {
                        notification?.title ? <span className={classes.Title}>{notification?.title}</span> : <img className={classes.Logo2} src={global?.config?.logo} />
                    }
                </div>
                <span className={classes.Time}>{getFromNow(notification?.sendTime)}</span>
            </div>
            <div className={classes.Bd}>
                <div className={classes.Block}>
                    <div className={classes.Content}>
                        <span dangerouslySetInnerHTML={{ __html: notification?.body }} />
                    </div>
                    {
                        notification?.image && <div className={classes.Icon} style={{ backgroundImage: `url(${getImage(notification?.image)})` }} />
                    }
                </div>
            </div>
        </div>
    </div>
}


const Type3 = props => {
    useStyles(classes)
    const global = useSelector(state => state.global)
    const { notification, notiRef } = props
    return <div className={classes.NotificationContainer}>
        <div ref={notiRef} className={classes.Notification}>
            <div className={classes.Hd}>
                <div className={classes.LogoContainer}>
                    <img className={classes.Logo1} src={notification?.bodycon || global?.config?.icon} />
                    {
                        notification?.title ? <span className={classes.Title}>{notification?.title}</span> : <img className={classes.Logo2} src={global?.config?.logo} />
                    }
                </div>
                <span className={classes.Time}>{getFromNow(notification?.sendTime)}</span>
            </div>
            <div className={classes.CouponBd}>
                <div className={classes.Coupon}>
                    <div className={classes.Content}>
                        <span dangerouslySetInnerHTML={{ __html: notification?.body }} />
                    </div>
                    <CouponButton onClose={props?.onClose} button={notification?.buttons?.[0]} />
                </div>
            </div>
        </div>
    </div>
}


const Notification = props => {
    useStyles(classes)
    const notiRef = React.createRef()
    const { notification, onClose } = props


    const { changing, changed } = useTouch(notiRef, 30, true)

    const timeRef = React.useRef()

    const handleClick = e => {
        if (notification?.deepLink) {
            const url = deeplink(notification?.deepLink, "msite")
            window.location.href = url
        }

        if (window.GeekoSensors) {

            window.GeekoSensors.Track('StationMsg', {
                type: '站内信',
                action: 'click',
                message_id: notification?.deepLink?.utmTerm
            })

        }

    }

    const timeoutHandle = useCallback(dom => {
        clearTimeout(timeRef.current)
        timeRef.current = setTimeout(() => {
            dom.classList.add(classes.Removed)
            onClose()
        }, 10000)
    }, [])

    useEffect(() => {
        notiRef.current.style.top = `${changing}px`
        clearTimeout(timeRef.current)
    }, [changing])

    useEffect(() => {
        if (changed <= -30) {
            notiRef.current.classList.add(classes.Removed)
            onClose()
        } else {
            notiRef.current.style.top = 0
        }
        timeoutHandle(notiRef.current)
    }, [changed])





    let NotificationDOM

    switch (notification?.type) {
        case 2:
            NotificationDOM = Type2
            break
        case 3:
            NotificationDOM = Type3
            break
        default:
            NotificationDOM = Type2
            break
    }


    return <NotificationDOM {...props} notiRef={notiRef} handleClick={handleClick} />
}

export default props => {
    useStyles(classes)
    const isBot = useBot()
    const isApp = useApp()
    const touched = useTouched()
    const dispatch = useDispatch()
    const global = useSelector(state => state.global)
    const ui = useSelector(state => state.ui)
    const { notificationData } = global

    useEffect(async () => {
        if (!isBot && !isApp && touched) {
            if (global?.user) {
                const data = await dispatch(action_fetch_notification('notification'))
                if (data?.body) {
                    if (window.GeekoSensors) {
                        window.GeekoSensors.Track('StationMsg', {
                            type: '站内信',
                            action: 'expose',
                            message_id: data?.deepLink?.utmTerm
                        })
                    }
                }
            }

            const shouldShow = !!!Cache.get('notification-coupon-show')
            if (shouldShow) {
                setTimeout(async () => {
                    const data = await dispatch(action_fetch_notification('coupon'))
                    if (data?.body) {
                        Cache.set('notification-coupon-show', 1)
                        if (window.GeekoSensors) {

                            window.GeekoSensors.Track('StationMsg', {
                                type: '站内信',
                                action: 'expose',
                                message_id: data?.buttons?.[0]?.deepLink?.utmTerm
                            })

                        }
                    }
                }, 10000)
            }

        }
    }, [touched])

    return <React.Fragment>
        {
            notificationData && <Notification onClose={() => {
                setTimeout(() => {
                    dispatch(setNotificationEventData(null))
                }, 400)
            }} notification={notificationData} />
        }
        {
            
            ui.showSuccessWindow && <CouponSuccess {...props} coupon={ui.showSuccessWindow} onClose={() => {
                dispatch({
                    type: TOOGLE_SUCCESS_WINDOW,
                    payload: false
                })
            }}/>
        }
    </React.Fragment>

}