import React,{useState,useEffect,useRef} from "react";
import Loading from "../loading";
import { BlackMask } from "../../../../../components/mask";
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import { FormattedMessage } from "react-intl";

const UploadModal = props =>{
    useStyles(classes);
    const [progress,setProgress] = useState(1);
    const progressRef = useRef(null);

    useEffect(() =>{
        progressRef.current = setInterval(() => {
            setProgress(preValue =>preValue > 97 ? (98) : (preValue + 2));
        }, 100);

        if(window.GeekoSensors){
			window.GeekoSensors.Track("ProductListExposure", {
				page_sort:"virtualsearch",
				button_pv:"cancel"
			})
		}

        return () =>clearInterval(progressRef.current);
    },[]);

    const cancelEvent = () =>{
        props?.onClose();

        if(window.GeekoSensors){
			window.GeekoSensors.Track("ProductListClick", {
				page_sort:"virtualsearch",
				button_click:"cancel"
			})
		}
    }

    return <div className={classes.UploadModal}>
        <div className={classes.ProgressContainer}>
            {
                !!progress &&  <span className={classes.Progress}>{progress}%</span>
            }
            <Loading className={classes.LoadingModal}/>

            <button className={classes.Cancel} onClick={cancelEvent}><FormattedMessage id="cancel" defaultMessage="CANCEL"/></button>
        </div>
        
        <BlackMask style={{zIndex:2}}/>
    </div>
}

export default UploadModal;