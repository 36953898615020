import React, { useMemo, useState } from "react";
import classes from "./style.module.scss";
import useStyles from "isomorphic-style-loader/useStyles";
import ReactDOM from 'react-dom'
import { IMAGE_GEEKO_LTD } from "../../../../constants";
import AutoWidthSwiper from "../../../components/swiper/autowidth";
import { useDispatch, useSelector } from "react-redux";
import { TOOGLE_UI_POPUP_SLIDE_IMAGES } from "../../../../store/actions/ui";
import ProductShopSimlar from "../../../pages/details/module/search-image/shop-simlar";
import { Swiper, SwiperSlide } from 'swiper/react';

const ImageDisplayer = props =>{
    useStyles(classes);
    const { options } = props;
    const { images, selctedIndex = 0, shopSimilar } = options;
	const [initialSlide, setInitialSlide] = useState(selctedIndex+1);
    const dispatch = useDispatch();

    const initialOpts = useMemo(() =>{
        return {
            initialSlide: selctedIndex
        }
    }, [selctedIndex]);

    const onClose = () =>{
        dispatch({
            type: TOOGLE_UI_POPUP_SLIDE_IMAGES,
            payload: {
                show: false,
                options: null
            }
        });
    }
	return <div className={classes.AlertImageModal}>
            <div onClick={onClose} className={classes.AlertImage}>
                {/* <AutoWidthSwiper 
                    items={images} 
                    options={{
                        onSlideChange: (swiper) => {
                            setInitialSlide(swiper.realIndex + 1)
                        },
                        onSwiper: () =>{console.log()},
                        ...initialOpts
                    }} 
                    render={(image) =>(
                        <div style={{ width: "100%" }}>
                            <img src={`${IMAGE_GEEKO_LTD}/original/${image}`}/>
                        </div>
                    )}
                /> */}

                <span className={classes.ImageCount}>
                    <span>{initialSlide}</span>/<span>{images?.length}</span>
                </span>

                <Swiper 
                    slidesPerView='auto'
                    onSlideChange={(swiper) => {
                        setInitialSlide(swiper.realIndex + 1)
                    }}
                    onSwiper={(swiper) => console.log()}
                    {...initialOpts}
                >
                    {
                        images?.map((image, index) => <SwiperSlide key={index}>
                            <img src={`${IMAGE_GEEKO_LTD}/original/${image}`}/>
                        </SwiperSlide>)
                    }
                </Swiper>
                
                {/* {
                    shopSimilar && <ProductShopSimlar 
                        { ...shopSimilar }
                        image={images?.[initialSlide-1]} 
                        className={classes.ShopSimlar} 
                        onClose={onClose} 
                    />
                } */}
                
        </div>
    </div>
}

export default props =>{
    const { popupImages } = useSelector(state=>state.ui);

    if (typeof window === 'undefined')
    return null

    return ReactDOM.createPortal(
        <React.Fragment>
            {
                popupImages?.show && <ImageDisplayer {...props} popupImages={popupImages} options={popupImages.options} />
            }
        </React.Fragment>,
        document.getElementById('root')
    )
}

/**
 * images 图片数组
 * shopSimilar: product shop similar
 * selctedIndex:下标 
 * 
 * dispatch({
        type: TOOGLE_UI_POPUP_SLIDE_IMAGES, 
        payload: {
            show: true,
            options: {
                images: images,
                selctedIndex: 0, 
                shopSimilar: {
                    productId:"c705d0dd-41b1-48a8-b3c6-23852644f72e"
                }
            }
        }
  });
 * 
 */