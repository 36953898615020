import React from "react";
import classes from "./style.module.scss";
import useStyles from "isomorphic-style-loader/useStyles";
import Swiper from "../../../../../components/swiper/autowidth";
import { useCallback } from "react";
import { setCurrentPage } from "../../../../../../store/actions/pages/home";
import { setWebsite, setWebsiteInfo, action_fetch_menu } from "../../../../../../store/actions/global";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import { setOPLoading } from "../../../../../../store/actions/ui";
import { useHistory } from "react-router-dom";


export default props =>{
    useStyles(classes);
    const { topWebsites, isMenu } = props;
    const dispatch = useDispatch();
    const hasHot = pageHome?.pages?.some(i => i.icon)
    const pageHome = useSelector((state) => state.pageHome)
    const pageGlobal = useSelector((state) => state.global)
    const { websites, website } = pageGlobal
    const history = useHistory()

    const currentWebsite = website || pageHome?.currentPage || '1'

    const tabClick = useCallback((s, e) => {
        if (s.clickedIndex === s.activeIndex || s.clickedIndex === s.activeIndex + 1) {
            s.slidePrev(300)
        } else {
            s.slideToClosest(300)
        }
    }, []);

    const changeModule = useCallback(async id => {
        dispatch(setCurrentPage(id))
        dispatch(setWebsite(id))
        dispatch(setWebsiteInfo(websites?.find?.(w => w.id === id)))
        Cookies.set('website', id, { expires: 1, path: '/' })

        if(isMenu){
            dispatch(setOPLoading(true));
            await dispatch(action_fetch_menu({website: id}))
            dispatch(setOPLoading(false));
        }else{
            dispatch(action_fetch_menu({website: id}))
        }

        history.replace(`/?wbs=${id}`)

    }, [websites, isMenu]);

    return <div className={`${classes.TabContainer} ${!isMenu && hasHot ? classes.HasHot : ''} ${props?.className?props.className:""}`}>
        <Swiper options={{
            slidesPerView: 'auto',
            spaceBetween: 18,
            slideToClickedSlide: true,
            onClick: tabClick
        }} items={topWebsites} render={(item, index) => {
            return <a href={`/?wbs=${item.id}`} className={`${classes.TopTab}  ${item.id === currentWebsite ? classes.Selected : ''}`} onClick={e => {
                e.preventDefault()
                changeModule(item.id)
            }}>
                <span>{item.name}</span>
                {
                    !isMenu &&item.icon && <span className={classes.Hot} style={{
                        color: item.icon.color,
                        backgroundColor: item.icon.backgroundColor
                    }}>
                        {item.icon.text}
                    </span>
                }
            </a>
        }} />
    </div>
}