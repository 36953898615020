import React, { useState, useEffect, useCallback } from 'react'
import ReactDOM from 'react-dom'
import _ from 'lodash'
import { Swiper, SwiperSlide } from 'swiper/react'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import BottomToTop from '../../../../components/transitions/bottom-to-top'
import { BlackMask } from '../../../../components/mask'
import { Iconfont } from '../../../../components/icon/iconfont'
import { FormattedMessage } from 'react-intl'
import { useRef } from 'react'

import { fetchStatesByCountry, fetchCitiesByCAA } from '../../../../utils/global-fetchs'
import { SensorsTrack } from '../../../../utils/sensor'
import { IMAGE_GEEKO_LTD } from '../../../../../constants'

const SearchInput = props => {
    useStyles(classes)
    const { onFilter, value } = props
    const [filter, setFilter] = useState(null)

    useEffect(() => {
        setFilter(value)
    }, [value])

    const debouncedFilter = useCallback(_.debounce(f => {
        onFilter(f)
    }, 300), [])

    const handleChange = useCallback(e => {
        setFilter(e.target?.value)
        debouncedFilter(e.target?.value)
    }, [])


    return <div className={classes.SearchInput}>
        <Iconfont className={classes.Icon}>&#xe772;</Iconfont>
        <input value={filter} onChange={handleChange} />
    </div>
}

const Option = props => {
    useStyles(classes)
    const { option, onOption, selected, isCountry } = props
    const ref = useRef()

    useEffect(() => {
        if (selected) {
            ref?.current.scrollIntoView({
                block: 'start',
            })
        }
    }, [])


    return <div ref={ref} className={`${classes.Option} ${selected ? classes.Selected : ''}`} onClick={e => onOption(option)}>
        {
            isCountry && option?.value && option?.value != "OTHER" && <img src={`${IMAGE_GEEKO_LTD}/country/flag/${option.value}.png`} alt="" />
        }
        <span>{option?.label}</span>
    </div>
}

const OptionGroup = props => {
    useStyles(classes)
    const { title, options, option, onOption, innerRef, isCountry } = props
    return <div ref={innerRef} className={classes.OptionGroup}>
        <div className={classes.Hd}>{title}</div>
        <div className={classes.Bd}>
            {
                options?.map((o, index) => <Option onOption={onOption} isCountry={isCountry} option={o} selected={o.value === option} key={`${o.value}-${index}`} />)
            }
        </div>
    </div>
}

const OptionList = props => {
    useStyles(classes)
    const { options, filter, option, onOption, isCountry } = props
    const [selectedLetter, setSelectedLetter] = useState(null)
    const groupedOptions = _.groupBy((options || []).filter(o => o.label.toUpperCase().startsWith((filter || '').toUpperCase())), c => c.label.substr(0, 1).toUpperCase())
    const keys = Object.keys(groupedOptions).sort()

    const refs = keys.reduce((acc, value) => {
        acc[value] = React.createRef()
        return acc
    }, {})

    const handleLetter = letter => {
        refs?.[letter]?.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        })
        setSelectedLetter(letter)
    }

    return <div className={classes.OptionList}>
        <div className={classes.Bd}>
            {
                keys?.map?.(k => <OptionGroup isCountry={isCountry} innerRef={refs?.[k]} key={k} title={k} options={groupedOptions?.[k]} option={option} onOption={onOption} />)
            }
        </div>
        <div className={classes.Aside}>
            <Swiper className={classes.SwiperContainer} direction='vertical' slidesPerView='auto'>
                {
                    keys?.map?.(k => <SwiperSlide key={k} style={{ height: 'auto', width: 'auto' }}>
                        <span onClick={e => handleLetter(k)} className={`${classes.Letter} ${selectedLetter === k ? classes.Active : ''}`}>{k}</span>
                    </SwiperSlide>)
                }
            </Swiper>
        </div>
    </div>
}

const CountrySelect = props => {
    useStyles(classes)
    const { countries, country, state, city, onSelect, onlyCountry, onClose, currentStep } = props
    const [step, setStep] = useState(currentStep || (city ? 3 : (state ? 2 : 1)))
    const [states, setStates] = useState(null)
    const [cities, setCities] = useState(null)

    const [selectedCountry, setSelectedCountry] = useState(country)
    const [selectedState, setSelectedState] = useState(state)
    const [selectedCity, setSelectedCity] = useState(city)

    const [filter, setFilter] = useState(null)

    useEffect(async () => {
        if (step >= 1) {
            setStates(await fetchStatesByCountry(country))
        }
        if (step >= 2 && state) {
            setCities(await fetchCitiesByCAA(country, state))
        }
    }, [country, state])


    useEffect(() => {
        setSelectedCountry(country)
        setSelectedState(state)
        setSelectedCity(city)
    }, [country, state, city])

    const handleCountrySelect = async c => {
        setSelectedCountry(c.value)
        setFilter('')

        if (c.value !== selectedCountry) {
            setStates(null)
            setCities(null)
            setSelectedState(null)
            setSelectedCity(null)
            const sts = await fetchStatesByCountry(c.value)
            if (!onlyCountry) {
                if (sts?.length > 0) {
                    setStates(sts)
                    setStep(2)
                } else {
                    setStep(1)
                    onSelect({ country: c.value })
                    onClose()
                }
            } else {
                setStep(1)
                onSelect({ country: c.value, state: sts?.[0]?.value })
                onClose()
            }

            SensorsTrack('address_country_change', {
                country: c.value
            })
        }

    }

    const handleStateSelect = async s => {
        setSelectedState(s.value)
        setFilter('')

        if (s.value !== selectedState) {
            setCities(null)
            setSelectedCity(null)
            const cts = await fetchCitiesByCAA(selectedCountry, s.value)
            if (cts?.length > 0) {
                setCities(cts)
                setStep(3)
            } else {
                onSelect({ country: selectedCountry, state: s.value })
                onClose()
            }

            SensorsTrack('address_country_change', {
                country: selectedCountry,
                state: s.value
            })
        }

    }


    const handleCitySelect = c => {
        setSelectedCity(c?.name)
        setFilter('')
        onSelect({ country: selectedCountry, state: selectedState, city: c?.name, zipCodes: c?.zipCodes })
        onClose()
        SensorsTrack('address_country_change', {
            country: selectedCountry, 
            state: selectedState, 
            city: c?.name
        })
    }

    const handleStep = s => {
        setStep(s)
        setFilter('')
    }


    const countryName = selectedCountry ? countries?.find?.(c => c.value === selectedCountry)?.label : null
    const stateName = selectedState ? states?.find?.(s => s.value === selectedState)?.label : null


    return <div className={classes.Container}>
        <div className={classes.Hd}>
            <span><FormattedMessage id="country" defaultMessage={'Country / Region'} /></span>
            <Iconfont className={classes.Close} onClick={e => onClose()}>&#xe6af;</Iconfont>
        </div>
        <div className={classes.SearchArea}>
            <SearchInput onFilter={setFilter} value={filter} />
        </div>
        <div className={classes.NavigationArea}>
            <span onClick={e => handleStep(1)} className={`${classes.Nav} ${step === 1 && !onlyCountry ? classes.Active : ''}`}>
                {countryName || <FormattedMessage id="country" defaultMessage={'Country / Region'} />}
            </span>
            {
                !onlyCountry && <>

                    {
                        states?.length > 0 && <>
                            <Iconfont className={classes.Icon}>&#xe690;</Iconfont>
                            <span onClick={e => handleStep(2)} className={`${classes.Nav} ${step === 2 ? classes.Active : ''}`}>
                                {stateName || <FormattedMessage id="state" defaultMessage={'State / Province'} />}
                            </span>
                        </>
                    }

                    {
                        cities?.length > 0 && <>
                            <Iconfont className={classes.Icon}>&#xe690;</Iconfont>
                            <span onClick={e => handleStep(3)} className={`${classes.Nav} ${step === 3 ? classes.Active : ''}`}>
                                {selectedCity || <FormattedMessage id="city" defaultMessage={'City'} />}
                            </span>
                        </>
                    }
                </>
            }
        </div>
        <div className={classes.Bd}>
            {
                step === 1 && <OptionList filter={filter} options={countries} option={selectedCountry} isCountry onOption={handleCountrySelect} />
            }

            {
                step === 2 && <OptionList filter={filter} options={states} option={selectedState} onOption={handleStateSelect} />
            }

            {
                step === 3 && <OptionList filter={filter} options={cities} option={selectedCity} onOption={handleCitySelect} />
            }
        </div>
    </div>
}


const WrappedSelect = props => {
    useStyles(classes)
    return <React.Fragment>
        <BottomToTop in={props.show} className={classes.EditorContainer}>
            <div>
                <CountrySelect {...props} />
            </div>
        </BottomToTop>
        {props.show && <BlackMask onClick={props.onClose} style={{ zIndex: 19 }} />}
    </React.Fragment>
}


export default props => {

    if (typeof window === 'undefined')
        return null

    return ReactDOM.createPortal(
        <WrappedSelect {...props} />,
        document.getElementById('root')
    )
}