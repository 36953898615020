import React, { useCallback, useEffect } from "react";
import classes from "./style.module.scss";
import useStyles from "isomorphic-style-loader/useStyles";
// import Lottery from "./anniversary-draw/index";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "../../../hooks/common";
import { TOGGLE_UI_LOTTERY } from "../../../../store/actions/ui";
// import  Lottery  from './black-friday-draw/index'
import BlackFridayLuckyWheel from './black-friday-luckywheel-23/index'
import BlackFridayLottery from './summer-luckygrid-0614/index'

export default (props) => {
    useStyles(classes);
    const dispatch = useDispatch()
    const ui = useSelector(state => state.ui)
    const global = useSelector(state=>state.global);
    
    const query = useQuery()

    useEffect(() => {
        if(!!query.show_lottery){
            dispatch({
                type: TOGGLE_UI_LOTTERY,
                payload: true
            })
        }
    }, [])

    const showLotterHandler = useCallback(event => {
        event.preventDefault()
        dispatch({ type: TOGGLE_UI_LOTTERY, payload: true })
    }, [])

    useEffect(async () => {
        const aE = document.querySelector("a[href='/i/points-mall']")
        aE?.addEventListener("click", showLotterHandler)
        return () => {
            aE?.removeEventListener("click", showLotterHandler)
            aE = null
        }
    }, []);




    const closeEvent = () => {

        dispatch({ type: TOGGLE_UI_LOTTERY, payload: false })

        if (window.GeekoSensors) {
            window.GeekoSensors.Track('PopUp', {
                type: "转盘抽奖",
                popupclose: true
            })
        }
    }

    return <React.Fragment>
        {
            ui.showLottery && <React.Fragment>
                {
                    global?.config?.showLuckyWheel ? <BlackFridayLuckyWheel onClose={closeEvent} /> : <BlackFridayLottery onClose={closeEvent} />
                }
            </React.Fragment>
        }
    </React.Fragment>
}