import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import { useDispatch, useSelector } from 'react-redux'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import { BlackMask } from '../../../components/mask'
import BottomToTop from '../../../components/transitions/bottom-to-top'
import { setSizeDefaultCountry } from '../../../../store/actions/global'
import { useCallback } from 'react'
import Cookies from 'js-cookie'
import { Iconfont } from '../../../components/icon/iconfont'
import { BigButton } from '../../../components/button'
import { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'


const CountryOption = props => {
    useStyles(classes)
    const { selected, onSelected, country } = props
    return <div onClick={e => onSelected(country)} className={`${classes.CountryOption} ${selected ? classes.Selected : ''}`}>
        <span className={classes.Label}><FormattedMessage id={'country_size'} defaultMessage={`${country} Size`} values={{country: country}}/></span>
        <span className={classes.Cycle}></span>
    </div>
}

const CountrySelector = props => {
    useStyles(classes)
    const { countries, onChange, value } = props

    const [selectedValue, setSelectedValue] = useState(value)

    useEffect(() => {
        setSelectedValue(value)
    }, value)

    const changeHandle = country => {
        setSelectedValue(country)
    }

    const applyHandle = e => {
        e.preventDefault()
        onChange(selectedValue)
    }

    return <div className={classes.CountrySelector}>
        <div className={classes.Hd}>
            <span><FormattedMessage id={'size_conversion'} defaultMessage="Size Conversion"/></span>
            <Iconfont className={classes.Close} onClick={props.onClose}>&#xe7c9;</Iconfont>
        </div>
        <div className={classes.Content}>
            <FormattedMessage id={'international_size_reference'} defaultMessage="The international size comparison is for reference only, the specific size is still based on the actual size the clothes."/>
        </div>
        <div className={classes.Bd}>
            {
                countries?.map(country => <CountryOption key={country} selected={country === selectedValue} onSelected={changeHandle} country={country} />)
            }
        </div>
        <div className={classes.Fd}>
            <BigButton onClick={applyHandle} className={classes.Button}><FormattedMessage id="apply" defaultMessage={'Apply'}/></BigButton>
        </div>
    </div>
}


const CountrySelectorAlert = props => {
    useStyles(classes)

    if (typeof window === 'undefined')
        return null
    return ReactDOM.createPortal(
        <React.Fragment>
            <BottomToTop in={props.show} className={classes.CountrySelectorContainer}>
                <div>
                    <CountrySelector {...props} />
                </div>
            </BottomToTop>
            {props.show && <BlackMask style={{zIndex: 12}} onClick={props.onClose} />}
        </React.Fragment>,
        document.getElementById('root')
    )
}


export default props => {
    useStyles(classes)
    const { sizeChart2 } = props
    const global = useSelector(state => state.global)
    const dispatch = useDispatch()
    const [showSelector, setShowSelector] = useState(false)

    const countries = sizeChart2?.countryAndSizesList?.[0]?.map(c => c.country)
    const selectedSizeCountry = countries?.find(c => c === global.sizeDefaultCountry) || sizeChart2?.defaultCountry

    // useEffect(() => {
    //     if(global.sizeDefaultCountry != selectedSizeCountry){
    //         Cookies.set('sizeDefaultCountry', selectedSizeCountry, { path: '/', expires: 30 })
    //         dispatch(setSizeDefaultCountry(selectedSizeCountry))
    //     }
    // }, [])

    const changeHandle = useCallback(country => {
        Cookies.set('sizeDefaultCountry', country, { path: '/', expires: 30 })
        dispatch(setSizeDefaultCountry(country))
        setShowSelector(false)
    }, [])

    return <>
        <span className={`${classes.SizeCountry} ${props?.className?props.className:''}`} onClick={e => { setShowSelector(true) }}>
            <span className={classes.Label}><FormattedMessage id={'country_size'} defaultMessage={`${selectedSizeCountry} Size`} values={{country: selectedSizeCountry}}/></span>
            <Iconfont className={classes.Sword}>&#xe78c;</Iconfont>
        </span>
        <CountrySelectorAlert
            show={showSelector}
            onClose={() => setShowSelector(false)}
            value={selectedSizeCountry}
            countries={countries}
            onChange={changeHandle}
        />
    </>
}