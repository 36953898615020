import React, { useState, useCallback, useEffect } from 'react'
import useStyles from 'isomorphic-style-loader/useStyles'
import classes from '../style.module.scss'
import PlaceHolder from '../placeholder'
import Label from '../label'
import { useSelector, useDispatch } from 'react-redux'
import { action_fect_countries } from '../../../../../../store/actions/global'
import { Iconfont } from '../../../../../components/icon/iconfont'
import { SensorsTrack } from '../../../../../utils/sensor'


export default props => {
    useStyles(classes)
    const dispatch = useDispatch()
    const [focused, setFocused] = useState(false)
    const [country, setCountry] = useState(null)
    const { placeholder, title, required, onChange, value, error } = props
    const { countries } = useSelector(state => state.global)

    const handleClick = useCallback(e => {
        setFocused(true)
    }, [])

    const handleBlur = useCallback(e => {
        setFocused(false)
    }, [])

    useEffect(async () => {
        if(countries?.length > 0){
            setCountry(countries?.find(c => c.value === value))
        }else{
            const cs = await dispatch(action_fect_countries())
            setCountry(cs?.find(c => c.value === value))
        }
    }, [])

    useEffect(() => {
        setCountry(countries?.find(c => c.value === value))
    }, [value])

    const changeHandler = useCallback(e => {
        e.preventDefault()
        const v = e.target.value
        onChange(v)
    }, [])

    

    return <div className={classes.Select} onClick={handleClick}>
        <div>
            <Label title={title} required={required} />
        </div>

        <select value={value} onChange={changeHandler} focused={focused} onBlur={handleBlur}>
            <option value="">Select Country</option>
            {
                countries?.map((country, index) => {
                    return <option key={index} value={country.value}>{country.label}</option>
                })
            }
        </select>

        <Iconfont className={classes.Icon}>&#xe692;</Iconfont>

        {
            value ? <div>
                {country?.label}
            </div>: <div>
                {
                    error ? <div className={classes.ErrorInfo}>{error}</div> : <div className={classes.Text}>
                        <PlaceHolder title={placeholder} />
                    </div>
                }
        </div>
        }
    </div>
}