// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3E582lzaFWqIlQFlY3dCiD{position:fixed;z-index:30;width:100%;height:100%;background-color:#efefef}\n", "",{"version":3,"sources":["webpack://src/client/layout/fragments/privacy-request/style.module.scss"],"names":[],"mappings":"AAEA,yBACI,cAAe,CACf,UAAW,CACX,UAAW,CACX,WAAY,CACZ,wBAAyB","sourcesContent":["@import '../../../base.scss';\n\n.FixedContainer{\n    position: fixed;\n    z-index: 30;\n    width: 100%;\n    height: 100%;\n    background-color: #efefef;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FixedContainer": "_3E582lzaFWqIlQFlY3dCiD"
};
module.exports = ___CSS_LOADER_EXPORT___;
