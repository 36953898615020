// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._6WeHxDoRhi_KOP0oc51l-{padding-left:.32rem;padding-right:.32rem}._6WeHxDoRhi_KOP0oc51l- ._1Q2SAw7X6D9oVy0JuS_PAr{height:.37333rem;background-color:#e6e6e6;border-radius:.37333rem}._6WeHxDoRhi_KOP0oc51l->div{margin-top:.16rem}._3W5Y2HLQpbQaIeJ9XHLQVE{padding-left:.32rem;padding-right:.32rem;padding-top:.53333rem;padding-bottom:1.06667rem}._3W5Y2HLQpbQaIeJ9XHLQVE ._1_QRzG4H4sURXxpSV_kYHi{line-height:.48rem}._3W5Y2HLQpbQaIeJ9XHLQVE ._2Fhd9vfHl1P-EsdRtVROAz{margin-top:2.13333rem}\n", "",{"version":3,"sources":["webpack://src/client/layout/fragments/privacy-request/pages/policy/style.module.scss","webpack://src/client/base.scss"],"names":[],"mappings":"AAEA,wBAOI,mBCLoC,CDMpC,oBCNoC,CDFxC,iDAEQ,gBCAgC,CDChC,wBAAyB,CACzB,uBCFgC,CDFxC,4BAWQ,iBCTgC,CDUnC,yBAID,mBCdoC,CDepC,oBCfoC,CDgBpC,qBChBoC,CDiBpC,yBCjBoC,CDaxC,kDAOQ,kBCpBgC,CDaxC,kDAUQ,qBCvBgC","sourcesContent":["@import \"../../../../../base.scss\";\n\n.Empty{\n    .Element{\n        height: actual(14);\n        background-color: #e6e6e6;\n        border-radius: actual(14);\n    }\n\n    padding-left: actual(12);\n    padding-right: actual(12);\n\n    & > div{\n        margin-top: actual(6);\n    }\n}\n\n.Container{\n    padding-left: actual(12);\n    padding-right: actual(12);\n    padding-top: actual(20);\n    padding-bottom: actual(40);\n\n    .Text{\n        line-height: actual(18);\n    }\n    .Button{\n        margin-top: actual(80);\n    }\n}","$baseFontSize: 75; //基于视觉稿横屏尺寸/10得出的基准font-size\n$IMAGE_GEEKO_LTD: 'https://image.geeko.ltd';\n\n@function actual($px) {\n  @return 2 * $px / $baseFontSize * 1rem;\n}\n\n@function actualPc($px){\n  @return $px / 1920 * 100vw;\n}\n\n@function imageGeekoLtd($url){\n\t@return $IMAGE_GEEKO_LTD + $url;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Empty": "_6WeHxDoRhi_KOP0oc51l-",
	"Element": "_1Q2SAw7X6D9oVy0JuS_PAr",
	"Container": "_3W5Y2HLQpbQaIeJ9XHLQVE",
	"Text": "_1_QRzG4H4sURXxpSV_kYHi",
	"Button": "_2Fhd9vfHl1P-EsdRtVROAz"
};
module.exports = ___CSS_LOADER_EXPORT___;
