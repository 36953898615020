import React from "react";
import useStyles from 'isomorphic-style-loader/useStyles'
import classes from "./style.module.scss"
import { FormattedMessage } from "react-intl";
import { useRef } from "react";
import { useDispatch } from "react-redux";
import { getProductSearchImageResult } from "../../../../../../api";
import { SET_SEARCH_IMAGE_RESULT } from "../../../../../../store/actions/global";
import { Iconfont } from "../../../../../components/icon/iconfont";
import { useState } from "react";
import { IMAGE_GEEKO_LTD } from "../../../../../../constants";
import withSource from '../../../../../hocs/source-scoll'
import withSourceClick from '../../../../../hocs/source-only-click'
import Alert from "../../../../../components/alert";

const ShopTheLookSpan = withSource(props => {
    const { innerRef} = props

    return <span 
        ref={innerRef}
        data-title="product"
        data-position={1}
        data-type={20}
        data-content={'shop the look'}
        className={`${classes.ProductShopTheLook} ${props?.className?props.className:""}`} 
        onClick={() => props?.openShopTheLookMask()}>
        <img className={classes.coatHangerIcon} src="https://image.geeko.ltd/chicme/20220719/CoatHangerWhite.svg" alt="shop the look" />
        
        <span className={classes.Font}>
            <FormattedMessage id="shop_the_look" defaultMessage="Shop The Look" />
        </span>
    </span>
})

const ShopSimilarSpan = withSourceClick(props => {
    const { innerRef, image , productId, sensors } = props

    const dispatch = useDispatch();
    const loadingRef = useRef(false);
    const [loading,setLoading] = useState(false);

    const searchByImage = async (evt) =>{
        evt.stopPropagation();
        if(!loadingRef.current){
            loadingRef.current = true;
            setLoading(true);
            let imageUrl;
            if(image.indexOf('/') >= 0){
                imageUrl = `${IMAGE_GEEKO_LTD}${image}`;
            }else{
                imageUrl = `${IMAGE_GEEKO_LTD}/large/${image}`;
            }
            let response = await getProductSearchImageResult({productId:productId,imageUrl:imageUrl});
            if(response?.code === 200 && response?.result){
                await dispatch({
                    type:SET_SEARCH_IMAGE_RESULT,
                    payload:{
                        show: true,
                        searchType: 2,
                        url:imageUrl,
                        result:response?.result,
                        sensors:sensors
                    }
                });
            }else{
                response?.result && Alert(response.result);
            }
            loadingRef.current = false;
            setLoading(false);
            props?.onClose?.();
        }
    }

    return <span 
        ref={innerRef}
        data-title={sensors?.resourcepage_title}
        data-position={sensors?.resource_position}
        data-type={sensors?.resource_type}
        data-content={sensors?.resource_content}
        className={`${classes.ProductShopSimilar} ${props?.className?props.className:""}`} 
        onClick={searchByImage}
        >
            <Iconfont className={classes.CreameIcon}>&#xe706;</Iconfont>

            <span className={classes.Font}>
                <FormattedMessage id="shop_similar" defaultMessage="Shop Similar" />
            </span>

            {
                loading && <Iconfont className={classes.LoadIcon}>&#xe62d;</Iconfont>
            }
    </span>
    

})

const ShopSimlar = props =>{
    useStyles(classes);
    const { showShopTheLook } = props;

    return <>
        {
            !showShopTheLook ?
            <ShopSimilarSpan {...props} sensors={{
                resourcepage_title: "virtual-search",
                resource_type: 19,
                resource_position: 1,
                resource_content: "shop_similar"
            }} />
            :
            <ShopTheLookSpan {...props}></ShopTheLookSpan>
            
        }
    </>
}

export default ShopSimlar