// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._8747eQSadWA78yRXtwNhG{width:100%;height:100vh;position:fixed;z-index:12;top:0}._8747eQSadWA78yRXtwNhG .vMuw5C5t5Pziv_W3Eu9s3{height:calc(100% - 1.2rem);background-color:#fff}._8747eQSadWA78yRXtwNhG .hf0f6z1dY_pCSlowkGZdp{height:100%;background-color:#fff}\n", "",{"version":3,"sources":["webpack://src/client/layout/fragments/size-chart2/style.module.scss"],"names":[],"mappings":"AAEA,wBACI,UAAW,CACX,YAAa,CACb,cAAe,CAEf,UAAW,CACX,KAAM,CANV,+CASQ,0BAAkC,CAClC,qBAAsB,CAV9B,+CAcQ,WAAY,CACZ,qBAAsB","sourcesContent":["@import '../../../base.scss';\n\n.SizeChartContainer{\n    width: 100%;\n    height: 100vh;\n    position: fixed;\n    // left: 0;\n    z-index: 12;\n    top: 0;\n\n    .Bd{\n        height: calc(100% - #{actual(45)});\n        background-color: #fff;\n    }\n\n    .BdApp{\n        height: 100%;\n        background-color: #fff;\n    }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SizeChartContainer": "_8747eQSadWA78yRXtwNhG",
	"Bd": "vMuw5C5t5Pziv_W3Eu9s3",
	"BdApp": "hf0f6z1dY_pCSlowkGZdp"
};
module.exports = ___CSS_LOADER_EXPORT___;
