import React, { useState, useCallback } from "react";
import classes from "./style.module.scss";
import useStyles from "isomorphic-style-loader/useStyles";
import { v4 } from "uuid";
import { useMemo } from "react";
import { useField, useFormikContext } from "formik";
import { FormattedMessage } from "react-intl";
import { useEffect } from "react";

export default props =>{
    useStyles(classes);
    const { InputProps, phoneAreaCode, codeValue, noPadding, globalPhoneNumber } = props;
    const [field, meta, helpers] = useField(InputProps)
    const [labelActive, setLabelActive] = useState(false);
    const { setFieldValue } = useFormikContext()
    const { setValue } = helpers;
    const { value } = meta;

    useEffect(() =>{
        if(globalPhoneNumber){
            setValue(globalPhoneNumber);
        }
    },[globalPhoneNumber]);

    const phoneId = useMemo(() =>{
        return v4();
    },[])

    const blurHandle = useCallback((e, value) => {
        field.onBlur(e)
        
        if(value?.length <= 0){
            setLabelActive(false)
        }
    }, [])

    return <div className={`${classes.NormalCon} ${classes.PhoneNumber}`}>
        <label htmlFor={phoneId}><FormattedMessage id="telephone_number" defaultMessage="Telephone Number" /></label>
        <div id={phoneId} className={`${classes.NormalItem} ${classes.PhoneCon} ${meta.touched && meta.error?classes.ErrorCon:""}`}>
            <div>
                <span className={classes.FontBold}>{codeValue} {phoneAreaCode}</span>
            </div>
            {/* <div className={`${classes.InputCon} ${noPadding?classes.NoPading:""}`}> */}
                <input 
                    className={`${classes.InputItem} ${noPadding?classes.NoPading:""}`}
                    {...field} 
                    {...InputProps} 
                    onBlur={(e)=>blurHandle(e, value)}
                    onFocus={() => {
                        setLabelActive(true);
                    }}
                    autoComplete={"off"}
                />
            {/* </div> */}

            {
                labelActive && <span
                    className={classes.clearIcon}
                    onClick={() => {
                        setValue('')
                        setLabelActive(false);
                    }}>
                </span>
            }
        </div>

        <div className={classes.ErrorMsg}>{meta.touched && meta.error ? meta.error : null}</div>
    </div>
}