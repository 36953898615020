import React, { useRef, useState, useEffect } from 'react'
import Cache from '../../../../utils/cache'
import useStyles from 'isomorphic-style-loader/useStyles'
import classes from './style.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { useCallback } from 'react'
import {registerServiceWorker} from '../web-push'
import { useApp } from '../../../hooks/common'
import { setDeferredPromptInform } from '../../../../store/actions/global'

export default props => {

    useStyles(classes)

    const [show, setShow] = useState(false)
    const [showCache, setShowCache] = useState(false);
    const [touched, setTouched] = useState(false)

    const global = useSelector(state => state.global)

    const dispatch = useDispatch();

    const isApp = useApp()

    const deferredPrompt = useRef()

    const handlePrompt = useCallback(async e => {
        e.preventDefault()
        deferredPrompt.current = e

        window.deferredPrompt = e;

        await dispatch(setDeferredPromptInform(true));

        setShow(true)
    }, [])


    const handleTouch = useCallback(e => {
        setTouched(true)
    }, [])


    useEffect(() => {
        const hasAlertedWebPush = Cache.get('notificationalert')
        const hasAlertedAddToHome = Cache.get('addtohomealert')

        try {
            registerServiceWorker()
        } catch (error) {
            
        }

        window.addEventListener('beforeinstallprompt', handlePrompt)
        
        if (!!hasAlertedWebPush && !!!hasAlertedAddToHome) {
            window.addEventListener('touchstart', handleTouch)

            setShowCache(true);
        }

        return () => {
            window.removeEventListener('beforeinstallprompt', handlePrompt)
            window.removeEventListener('touchstart', handleTouch)
        }

    }, [])

    

    const handleClose = useCallback(e => {
        Cache.set('addtohomealert', 1, 7 * 24 * 60 * 60)
        setShow(false)
    }, [])

    const handleSubscribe = useCallback(async e => {

        Cache.set('addtohomealert', 1, 700 * 24 * 60 * 60)
        setShow(false)

        deferredPrompt.current.prompt()

        const { outcome } = await deferredPrompt.current.userChoice
    
        console.log(`User response to the install prompt: ${outcome}`);
        
        deferredPrompt.current = null
    }, [])

    return !global?.showBottomBanner && showCache && show && touched && !global.isFacebook && !isApp ? <div className={classes.Container}>
        <div className={classes.Logo}>
            <img src={global.config?.icon} />
        </div>
        <div className={classes.BD}>
            <div className={classes.Text}>
                <FormattedMessage id="addtohometext" values={{
                    site: global?.config?.name
                }}  defaultMessage="Add {site} to Home Page" />
            </div>
            <div className={classes.Buttons}>
                <button className={`${classes.Button} ${classes.No}`} onClick={handleClose}>
                    <FormattedMessage id="no_thanks" defaultMessage={'No Thanks'} />
                </button>
                <button className={`${classes.Button} ${classes.Yes}`} onClick={handleSubscribe}>
                    <FormattedMessage id="ok" defaultMessage={'Ok'} />
                </button>
            </div>
        </div>
    </div> : <></>
}