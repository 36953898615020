import React, { useCallback, useEffect, useState } from 'react'
import classes from './style.module.scss'
import {
	useSelector,
	useDispatch
} from 'react-redux'

import { action_fetch_menu, action_fetch_currencies, action_set_currency, setLanguage, action_fetch_websites } from '../../../../store/actions/global'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import RightToLeft from '../../../components/transitions/right-to-left'
import { Iconfont } from "../../../components/icon/iconfont";
import Swiper from '../../../components/swiper/normal'
import {MenuImages as ImageCards} from '../../../components/card/images/gourp-images'
import Select from './components/select'
import MLink from './components/link'
import _ from 'lodash'
import { FormattedMessage } from 'react-intl'
import withSourceScroll from '../../../hocs/source-scoll'

import { getDeepLinkType } from '../../../../utils/deeplink'
import Cookies from 'js-cookie'

import useStyles from 'isomorphic-style-loader/useStyles'

import { isIOS, isMacOs } from 'react-device-detect'
import { getSensorsUrl } from '../../../utils/sensor'
import NavSwiper from '../../../pages/moduleable/home/fragments/nav-swiper'
import { useMemo } from 'react'
import { IMAGE_GEEKO_LTD } from '../../../../constants'
import { useQuery } from '../../../hooks/common'

const handleAClick = (e, sensors) => {
	e.preventDefault()
    const href = e.currentTarget?.href
    window.location.href = getSensorsUrl(href, sensors)
}


const FirstLevelItem = withSourceScroll(props => {
	useStyles(classes)
	const { item, innerRef, position, sensors } = props
	return <div
		ref={innerRef}
		data-title={sensors?.resourcepage_title}
		data-type={sensors?.resource_type}
		data-content={sensors?.resource_content}
		data-position={position}
		onClick={props?.onClick}
		className={classes.FirstLevelItem}>
		<div className={classes.FirstLevelImage}>
			<LazyLoadImage src={item.imageUrl} alt={item.title}/>
		</div>
		<div>
			<span dangerouslySetInnerHTML={{ __html: item.styledTitle }} />
		</div>
	</div>
})

const FirstLevelItems = props => {
	useStyles(classes)
	const { items, sensors } = props


	const loadingItems = (!items || items.length < 1) ? Array.from([1, 1, 1, 1, 1, 1]).map((s, index) => {
		return {
			imageUrl: 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==',
			styledTitle: '',
			id: index
		}
	}) : items


	return <ul className={classes.FirstLevelItems}>
		{
			loadingItems?.map((item, index) => <li key={item.id}>
				<FirstLevelItem position={index + 1} sensors={{ ...sensors, resource_content: item?.enTitle || item?.title }} item={item} onClick={e => {
					props?.onSelect(item, index + 1)
				}} />
			</li>)
		}
	</ul>
}

const SwiperItem = withSourceScroll(props => {
	const { item, innerRef, sensors, position } = props

	const { deepLink, width, height } = item

	const aspectRatio = width && height ? `${width} / ${height}` : undefined

	const deepType = getDeepLinkType(deepLink)

	let percent
	if (width && height) {
		percent = `${Math.floor(height * 100 / width)}%`
	}



	return <a
		ref={innerRef}
		href={item.href}
		data-title={sensors?.resourcepage_title}
		data-position={position}
		data-type={sensors?.resource_type}
		data-content={sensors?.resource_content}
		onClick={e => handleAClick(e, {...sensors, resource_position: position})}
		style={{
			display: 'block',
			position: 'relative',
			paddingTop: percent
		}}
	>
		<img src={item.url} style={{ position: percent ? 'absolute' : 'static', top: 0, left: 0, width: '100%' }} />
	</a>
})

const TypeImages1 = props => {
	const { images, menuTitle, position, sensors } = props
	return <Swiper items={images} render={(item, index) => {
		return <SwiperItem
			sensors={{...sensors, resource_content: `${sensors?.resource_content} - ${item?.enTitle || item?.title}`}}
			position={`${position}-${index+1}`}
			item={item} />
	}} />
}


// const ImageItem = withSourceScroll(props => {
// 	const { image, innerRef, resourcepage_title, resource_position, resource_type } = props
// 	const { deepLink } = image
// 	const deepType = getDeepLinkType(deepLink)

// 	return <a ref={innerRef} href={image.href}
// 		data-title={resourcepage_title}
// 		data-position={resource_position}
// 		data-type={resource_type}
// 		data-content={`${deepType}@${deepLink?.params?.[0]}`}
// 	>
// 		<img src={image.url} />
// 	</a>
// })

const TypeImages2 = props => {
	const { images, menuTitle, position, sensors } = props
	return <ImageCards images={images} position={position} sensors={sensors} render={(image, index) => {
		return <LazyLoadImage key={index} src={image.url} alt={image.title} />
	}} />
}

const ImageGroups = props => {
	const { imageGroups, menuTitle, sensors, position, parentPostion } = props

	return <React.Fragment>
		{
			imageGroups?.map((imageGroup, index) => {
				switch (imageGroup.type) {
					case '1':
						return <div style={{ marginTop: `${index > 0 ? '10px' : ""}` }}>
							<TypeImages1 position={`${parentPostion}-${position + index}`} sensors={sensors} menuTitle={menuTitle} key={index} images={imageGroup.images} />
						</div>
					default:
						return <div style={{ marginTop: `${index > 0 ? '10px' : ""}` }}>
							<TypeImages2 position={`${parentPostion}-${position + index}`} sensors={sensors} menuTitle={menuTitle} key={index} images={imageGroup.images} />
						</div>
				}
			})
		}
	</React.Fragment>
}


const MenuCard = withSourceScroll(props => {
	useStyles(classes)
	const { item, innerRef, sensors, position } = props

	return <div className={classes.Card}>
		<a href={item.href} ref={innerRef}
			data-title={sensors?.resourcepage_title}
			data-position={position}
			data-type={sensors?.resource_type}
			data-content={sensors?.resource_content}
			onClick={e => handleAClick(e, {...sensors, resource_position: position})}
		>
			<div className={classes.Image}>
				<LazyLoadImage src={item.imageUrl} />
			</div>
			<div className={classes.Title}>
				{
					props.title || <span dangerouslySetInnerHTML={{ __html: item.styledTitle }} />
				}
			</div>
		</a>
	</div>
})


export const SecondaryMenu = withSourceScroll(props => {
	useStyles(classes)
	const { item, position, menuTitle, sensors, innerRef } = props
	return <div className={classes.SecondaryMenu}>
		<div className={classes.Hd} ref={innerRef} 
		data-title={sensors?.resourcepage_title}
		data-type={sensors?.resource_type}
		data-content={sensors?.resource_content}
		data-position={position}
		>
			<span dangerouslySetInnerHTML={{ __html: item.styledTitle }} />
		</div>
		<div className={classes.Bd}>
			<div>
				<MenuCard sensors={{
					...sensors,
					resource_content: `${sensors?.resource_content} - View All`
				}} position={`${position}-1`} item={item} title={<FormattedMessage id="view_all" defaultMessage={"View All"}/>} />
			</div>

			{
				item?.children?.map((c, index) => <div key={c.id}>
					<MenuCard item={c}
						sensors={{
							...sensors,
							resource_content: `${sensors?.resource_content} - ${c?.enTitle || c?.title}`
						}}
						position={`${position}-${index + 2}`}
					/>
				</div>)
			}
		</div>
	</div>
})


const ChildMenu = props => {
	useStyles(classes)
	const { item, sensors, position } = props
	return <div {...props} className={classes.ChildMenu}>
		<div className={classes.Hd}>
			<span className={classes.Title}><span dangerouslySetInnerHTML={{ __html: item?.styledTitle }} /></span>
			<Iconfont onClick={props?.onClose} className={classes.Close}>&#xe693;</Iconfont>
		</div>
		<div className={classes.Bd}>
			<ChildMenuBody item={item} sensors={sensors} position={position} />
		</div>
	</div>
}

export const ChildMenuBody = props => {
	const { item, sensors, position } = props
	const {
		imageGroups,
		children,
		title
	} = item

	const splitImageGroups = useCallback((groups, sort) => {
		return _.reduce(groups, function (result, group, index) {
			if (group.sort <= sort) {
				result[0].push(group)
			} else {
				result[1].push(group)
			}
			return result
		}, [[], []]);
	}, [])

	const splitedGroups = splitImageGroups(imageGroups, children?.[0]?.sort || 0)

	return <div>
		<ImageGroups sensors={sensors} parentPostion={position} position={1} menuTitle={title} imageGroups={splitedGroups[0]} />
		{
			children && <div className={classes.SecondaryMenus}>
				{
					children.map((c, index) => <SecondaryMenu sensors={{
						...sensors,
						resource_content: `${sensors?.resource_content} - ${c?.enTitle || c.title}`
					}} position={splitedGroups[0]?.length > 0 ? `${position}-${index + 2}` : `${position}-${index + 1}`} menuTitle={title} key={c.id} item={c} />)
				}
			</div>
		}

		<ImageGroups sensors={sensors} parentPostion={position} position={(children?.length || 0) + (splitedGroups[0]?.length || 0) + 1} menuTitle={title} imageGroups={splitedGroups[1]} />
	</div>
}

const DownLoad = props => {
	useStyles(classes)

	const global = useSelector((state) => state.global)

	const { config } = global

	const handleClick = useCallback(() => {
		if (isIOS || isMacOs) {
			window.location.href = `https://itunes.apple.com/us/app/chic-me-shopping-sharing-saving/id${config?.app.ios.id}?l=zh&ls=1&mt=8`;
		} else {
			window.location.href = `https://play.google.com/store/apps/details?id=${config?.app.android.id}`;
		}
	}, [])


	return <div className={classes.Download}>
		<div className={classes.Icon}>
			<LazyLoadImage src={config?.icon} />
		</div>
		<div className={classes.Text}>
			<div>{config?.name}</div>
			<div><FormattedMessage id="get_app" values={{ app: config?.name }} defaultMessage="Get The {app} App" /></div>
		</div>
		<Iconfont onClick={handleClick} className={classes.DIcon}>&#xe7cf;</Iconfont>
	</div>
}

const AddToHome = props =>{
	useStyles(classes)
	const global = useSelector((state) => state.global)
	const { config, deferredPromptInform } = global;
	const [show, setShow] = useState(false);

	useEffect(() =>{
		if(window.deferredPrompt){
			setShow(true);
		}
	}, [deferredPromptInform]);

	const onSubmit = useCallback(async () =>{
		if(window.deferredPrompt){
			window.deferredPrompt.prompt()

			const { outcome } = await window.deferredPrompt.userChoice
		
			console.log(`User response to the install prompt: ${outcome}`);
			
			window.deferredPrompt = null;
			
			setShow(false);
		}else{
			console.log('window.deferredPrompt is inexistence')
		}

		if(window.GeekoSensors){
			window.GeekoSensors.Track("AddHomeScreen")
		}
	}, []);

	return <React.Fragment>
		{
			show && <div className={classes.AddToHome} onClick={onSubmit}>
				<span><FormattedMessage id="add_to_home_screen" values={{ name: config?.name }} defaultMessage="Add {name} To Home Screen" /></span>
				<img src={`${IMAGE_GEEKO_LTD}/chicme/20230516/add_to_home_screen.svg`} alt="" />
			</div>
		}
	</React.Fragment>
}


export default props => {
	useStyles(classes)
	const { onClose } = props;
	const global = useSelector((state) => state.global)
	const pageHome = useSelector((state) => state.pageHome)
	const { menus, currencies, currency, languages, language, websites, config } = global
	const dispatch = useDispatch()
	const [showChild, setShowChild] = useState(false)
	const [currentChild, setCurrentChild] = useState(undefined)
	const [position, setPosition] = useState(1)

	const query = useQuery();

	const topWebsites = websites || pageHome?.pages

	const sensors = {
		resourcepage_title: `sidecat-${global?.websiteInfo?.refId}`,
		resource_type: '10'
	}

	const childSelect = (item, p) => {
		if(!item.children || item.children.length < 1){
            if(item.href){
				window.location.href = getSensorsUrl(item.href, {...sensors, resource_position: p, resource_content: item?.enTitle || item?.title})
            }
        }else{
            setCurrentChild(item)
            setShowChild(true)
            setPosition(p)
        }
		// setCurrentChild(item)
		// setShowChild(true)
		// setPosition(p)
	}



	useEffect(async () => {
		if (!menus || menus.length < 1) {
			if (!websites || websites.length < 1) {
				await dispatch(action_fetch_websites({ website: Cookies.get('website') }))
			}
			dispatch(action_fetch_menu({ website: Cookies.get('website') }))
		}

		if (!currencies || currencies.length < 1) {
			dispatch(action_fetch_currencies())
		}

	}, [])

	const handleCurrency = async evt => {
		const { value } = evt.target
		await dispatch(action_set_currency(value))
		Cookies.set('currency', value, { path: '/', expires: 30 })
		window.location.reload()
	}

	const handleLanguage = evt => {
		const { value } = evt.target
		dispatch(setLanguage(value))
		Cookies.set('lang', value, { path: '/', expires: 30 })
		const originUrl = window.location.href
		const directUrl = originUrl.replace(/\/(en|es|de|fr|pt|is|fi|sv|nb|da)\//, `/${value}/`)
		window.location.replace(directUrl)
	}

	const supportHandle = evt => {
		if (window.zE) {
			window.zE('webWidget', 'show');
			window.zE('webWidget', 'open');
		}
	}

	const isShowNavSwiper = useMemo(() =>{
		return topWebsites?.length > 1;
	}, [topWebsites]);

	return <React.Fragment>
		<span className={classes.SlideMenuClose} onClick={onClose}>
			<Iconfont>&#xe69a;</Iconfont>
		</span>

		<div className={classes.Menu}>
			{
				isShowNavSwiper && <NavSwiper topWebsites={topWebsites} className={classes.NavSwiper} isMenu />
			}

			<div className={`${classes.Container} ${isShowNavSwiper?classes.HasNav:""}`}>
				<FirstLevelItems sensors={sensors} items={menus} onSelect={childSelect} />
				<div className={classes.ToolContainer}>
					<Select onChange={handleCurrency} data={currencies} icon={'&#xe76e;'} label={
						<FormattedMessage id="currency" defaultMessage="Currency" />
					} value={currency} />
					<Select onChange={handleLanguage} data={languages} icon={'&#xe76f;'} label={
						<FormattedMessage id="language" defaultMessage="Language" />
					} value={language} />
				</div>
				<div className={classes.GrayBack}>
					<MLink href="/policy/order-tracking"><FormattedMessage id="order_tracking" defaultMessage="Order Tracking" /></MLink>
					<MLink href="/policy/shipping-policy"><FormattedMessage id="shipping_info" defaultMessage="Shipping Info" /></MLink>
					<MLink href="/policy/return-policy"><FormattedMessage id="return_policy" defaultMessage="Return Policy" /></MLink>
					<MLink href="/policy/privacy-security-policy"><FormattedMessage id="privarcy_policy" defaultMessage="Privacy & Security Policy Terms" /></MLink>
					<DownLoad />
					
					<AddToHome />
				</div>
			</div>
			<div className={classes.Fd}>
				<div className={classes.FootTools}>
					<a href="/" alt="Home">
						<Iconfont className={classes.Tool}>&#xe712;</Iconfont>
					</a>
					<a href="/user" alt="Home">
						<Iconfont className={classes.Tool}>&#xe6a3;</Iconfont>
					</a>
					<a href="/user/wishlist">
						<Iconfont className={`${classes.Tool} ${classes.Special}`}>&#xe6a2;</Iconfont>
					</a>
					<a href="/user/support">
						<Iconfont className={classes.Tool}>&#xe770;</Iconfont>
						{/* onClick={supportHandle} */}
					</a>

				</div>
			</div>

			<RightToLeft in={showChild}>
				<ChildMenu item={currentChild} position={position} sensors={{
					...sensors,
					resource_content: currentChild?.enTitle || currentChild?.title
				}} onClose={() => {
					setShowChild(false)
				}} />
			</RightToLeft>
				
		</div>
	</React.Fragment>
}