// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3xF0faQ6JxBkLbTH5N8Yx0{height:100%}._3xF0faQ6JxBkLbTH5N8Yx0>._2yrFOV2YO03CwskntJ6suK{background-color:#fff;height:1.17333rem;line-height:1.17333rem;text-align:center;position:relative}._3xF0faQ6JxBkLbTH5N8Yx0>._2yrFOV2YO03CwskntJ6suK ._2alHvSukjx3ZvH1xd34jpk{font-family:Roboto-Bold;font-size:.42667rem}._3xF0faQ6JxBkLbTH5N8Yx0>._2yrFOV2YO03CwskntJ6suK ._2KJxnI8o5tcoho2N90Sltk{position:absolute;left:.32rem;top:0;cursor:pointer}._3xF0faQ6JxBkLbTH5N8Yx0>._1ocA-plWoJ6iahEWTfLBKC{background-color:#efefef;height:calc(100% - acutal(44));overflow:auto}\n", "",{"version":3,"sources":["webpack://src/client/layout/fragments/privacy-request/pages/style.module.scss","webpack://src/client/base.scss"],"names":[],"mappings":"AAGA,yBACI,WAAY,CADhB,kDAGQ,qBAAsB,CACtB,iBCHgC,CDIhC,sBCJgC,CDKhC,iBAAkB,CAClB,iBAAkB,CAP1B,2EASY,uBAAwB,CACxB,mBCT4B,CDDxC,2EAaY,iBAAkB,CAClB,WCb4B,CDc5B,KAAM,CACN,cAAe,CAhB3B,kDAqBQ,wBAAyB,CACzB,8BAAkC,CAClC,aAAc","sourcesContent":["@import \"../../../../base.scss\";\n\n\n.Container{\n    height: 100%;\n    & > .Hd{\n        background-color: #fff;\n        height: actual(44);\n        line-height: actual(44);\n        text-align: center;\n        position: relative;\n        .Title{\n            font-family: Roboto-Bold;\n            font-size: actual(16);\n        }\n        .Close{\n            position: absolute;\n            left: actual(12);\n            top: 0;\n            cursor: pointer;\n        }\n    }\n\n    & > .Bd{\n        background-color: #efefef;\n        height: calc(100% - #{acutal(44)});\n        overflow: auto;\n    }\n}","$baseFontSize: 75; //基于视觉稿横屏尺寸/10得出的基准font-size\n$IMAGE_GEEKO_LTD: 'https://image.geeko.ltd';\n\n@function actual($px) {\n  @return 2 * $px / $baseFontSize * 1rem;\n}\n\n@function actualPc($px){\n  @return $px / 1920 * 100vw;\n}\n\n@function imageGeekoLtd($url){\n\t@return $IMAGE_GEEKO_LTD + $url;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Container": "_3xF0faQ6JxBkLbTH5N8Yx0",
	"Hd": "_2yrFOV2YO03CwskntJ6suK",
	"Title": "_2alHvSukjx3ZvH1xd34jpk",
	"Close": "_2KJxnI8o5tcoho2N90Sltk",
	"Bd": "_1ocA-plWoJ6iahEWTfLBKC"
};
module.exports = ___CSS_LOADER_EXPORT___;
