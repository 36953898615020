// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2ollIsPMM5mVLotdoVzXB3{position:fixed;width:1.06667rem;height:1.06667rem;z-index:8;background-color:rgba(0,0,0,0.4);right:.32rem;bottom:.53333rem;color:#fff;font-size:.32rem;text-align:center;line-height:1.06667rem;border-radius:.10667rem}._2ollIsPMM5mVLotdoVzXB3._2pXDbxgFG0y_ziyllLlWAE{bottom:1.6rem}\n", "",{"version":3,"sources":["webpack://src/client/components/pageicons/style.module.scss","webpack://src/client/base.scss"],"names":[],"mappings":"AAEA,yBACI,cAAe,CACf,gBCAoC,CDCpC,iBCDoC,CDEpC,SAAU,CACV,gCAAiC,CACjC,YCJoC,CDKpC,gBCLoC,CDMpC,UAAW,CACX,gBCPoC,CDQpC,iBAAkB,CAClB,sBCToC,CDUpC,uBCVoC,CDFxC,iDAeQ,aCbgC","sourcesContent":["@import \"../../base\";\n\n.BackToTop{\n    position: fixed;\n    width: actual(40);\n    height: actual(40);\n    z-index: 8;\n    background-color: rgba(0,0,0,0.4);\n    right: actual(12);\n    bottom: actual(20);\n    color: #fff;\n    font-size: actual(12);\n    text-align: center;\n    line-height: actual(40);\n    border-radius: actual(4);\n\n    &.Style1{\n        bottom: actual(60);\n    }\n}","$baseFontSize: 75; //基于视觉稿横屏尺寸/10得出的基准font-size\n$IMAGE_GEEKO_LTD: 'https://image.geeko.ltd';\n\n@function actual($px) {\n  @return 2 * $px / $baseFontSize * 1rem;\n}\n\n@function actualPc($px){\n  @return $px / 1920 * 100vw;\n}\n\n@function imageGeekoLtd($url){\n\t@return $IMAGE_GEEKO_LTD + $url;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"BackToTop": "_2ollIsPMM5mVLotdoVzXB3",
	"Style1": "_2pXDbxgFG0y_ziyllLlWAE"
};
module.exports = ___CSS_LOADER_EXPORT___;
