import React, { useEffect, useRef, useState } from "react";
import useStyles from 'isomorphic-style-loader/useStyles'
import classes from "./style.module.scss"
import PasswordInput from "../input/password-input";
import { BigButton } from "../../../../components/button";
import { useQuery } from "../../../../hooks/common";
import { useIntl } from "react-intl";
import { FormattedMessage } from "react-intl";
import { Formik, Form } from "formik";
import { fetchUserDistinguish } from "../../../../../api";
import VerifyPopover from "../verify-popover/email-pop/index";
import Toast from "../../../../components/toast";
import EmailInputFiled from "../input/email-input-filed";


const EmailValidatorFrom = props =>{
    const {
        loading, 
        showVerifyCodeModal, 
        setLocalEmail, 
        email, 
        setCurrentLoginType
    } = props;

    const Intl = useIntl();

    const submitHandle = async (values, formikBag) =>{
        // register
        let response = await fetchUserDistinguish({ loginData: values.email, countryCode: '' });
        if(response?.code === 200 && response?.result){
            setCurrentLoginType(response.result?.register == "1");
            setLocalEmail(values.email);
            showVerifyCodeModal();
        }else{
            response?.result && Toast(response?.result);
        }
    }

    return <Formik 
        onSubmit={submitHandle}
        initialValues={{
            email: email,
        }}
        validate={values => {
            const errors = {}
            let errorFlag = false;
            const emailReg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

            if(!emailReg.test(values.email)){
                errorFlag = true;
                errors.email = Intl.formatMessage({ id: "incorrect_email_address", defaultMessage: "Incorrect email address" });
            }
            
            return errors
        }}
        enableReinitialize={true}
    >
        {
            ({ isSubmitting, values }) =>(
                <Form className={classes.PhoneRegisterFrom} >
                    <EmailInputFiled 
                        InputProps={{
                            type:"text",
                            name:"email"
                        }}
                    />

                    <BigButton type="submit" loading={isSubmitting || loading} className={classes.SubmitButton}><FormattedMessage id="continue" defaultMessage="Continue" /></BigButton>
                </Form>
            )
        }
    </Formik>
}

export default props => {
    useStyles(classes);
    const { popValue, setPopValue } = props;
    const [show, setShow] = useState(false);
    const [currentLoginType, setCurrentLoginType] = useState(false);

    return <div className={classes.LoginContainer}>
        <EmailValidatorFrom 
            {...props} 
            showVerifyCodeModal={()=>setShow(true)} 
            setLocalEmail={(value)=>setPopValue(value)} 
            setCurrentLoginType={setCurrentLoginType} 
        />

        <VerifyPopover 
            {...props}
            show={show && popValue} 
            currentLoginType={currentLoginType} 
            onClose={()=>setShow(false)} 
            localEmail={popValue}  
        />
    </div>
}