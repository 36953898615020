import React, {useEffect, createRef} from 'react'

export default Wrapped => {

    const handleClick = event => {
        const {
            currentTarget
        } = event


        const resourcepage_title = currentTarget.getAttribute("data-title")
        const resource_position = currentTarget.getAttribute("data-position")
        const resource_type = currentTarget.getAttribute("data-type")
        const resource_content = currentTarget.getAttribute("data-content")
       
        window.GeekoSensors.Track("PitPositionClick", {
            resourcepage_title,
            resource_position,
            resource_type,
            resource_content,
            pdetai_product_id: window.pdetai_product_id
        })

    }


    return props => {
        const dom = createRef()
        useEffect(() => {
            window?.sourceObserver?.observe(dom.current)

            // dom?.current?.addEventListener?.('click', handleClick)

            return () => {
                window?.sourceObserver?.unobserve(dom.current)
            }
        }, [])
        return <Wrapped innerRef={dom} {...props}/>
    }
}