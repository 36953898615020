import useStyles from "isomorphic-style-loader/useStyles"
import React, { useEffect, useRef } from "react"
import { useState } from "react"
import { Iconfont } from "../../../../../components/icon/iconfont"
import classes from './style.module.scss'

const Dropdown = props => {
	useStyles(classes)
	const { data, value } = props
	const [showOption, setShowOption] = useState(false)

	const selectRef = useRef()

    useEffect(() => {
        document.addEventListener("click", checkClick, false)
        return () => {
            document.removeEventListener("click", checkClick, false)
        }
    }, [])

	const checkClick = (e) => {
        // 焦点移除关闭下拉框
        if(!selectRef.current?.contains(e?.target)){
            setShowOption(false)
        }
    }

	return <div className={`${classes.SelectM} ${props.className}`}>
		<div className={classes.SelectBox} onClick={() => setShowOption(!showOption)} ref={selectRef}>
			<span className={classes.SelectValue}>{value || data?.[0]['value']}</span>
			<Iconfont className={`${classes.SelectIcon}`} style={{transform: `rotate(${showOption ? '90deg': '-90deg'})`}}>&#xe78c;</Iconfont>
			{
				showOption &&
				<div className={classes.SelectOptionBox}>
					{
						data?.map((item, index) => <div key={index} className={`${classes.SelectOption} ${item?.value == value && classes.SelectedOption}`} onClick={() => props?.onChange({target:{value:item.value}})}>{ item.label }</div>)
					}
				</div>
			}
		</div>
	</div>
}

export default Dropdown