import { fetchStates, fetchCites, fetchConfig } from '../../api'

const stateMap = {}
const cityMap = {}
let phoneCodes = null

export const fetchStatesByCountry = async country => {
    if (stateMap[country]) {
        return Promise.resolve(stateMap[country])
    }
    const response = await fetchStates(country)
    if (response?.code === 200) {
        stateMap[country] = response.result
    }
    return stateMap[country]
}

export const fetchCitiesByCAA = async (country, state) => {
    const key = `${country}-${state}`
    if (cityMap[key]) {
        return Promise.resolve(cityMap[key])
    }
    const response = await fetchCites(country, state)
    if (response?.code === 200) {
        cityMap[key] = response.result?.map?.(c => ({...c, label: c.name, value: c.name}))
    }
    return cityMap[key]
}

export const fetchPhoneCodes = async () => {
    if(phoneCodes) return Promise.resolve(phoneCodes)
    const response = await fetchConfig('0482-W')
    if(response?.code === 200){
        phoneCodes = response?.result
    }
    return phoneCodes
}