import React, { useEffect, useRef, useState, useCallback, useMemo } from "react";
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import { IMAGE_GEEKO_LTD } from "../../../../../constants";
import { 
    fetchLuckDraw, 
    fetchGetUserLastTimes, 
    fetchLuckLastesthistory, 
    fetchLastestPrize
} from "../../../../../api";
import { isIOS, isMacOs } from 'react-device-detect'
import { useSelector } from "react-redux";
import { v4 } from 'uuid'
import LotteryAlertModal from "../modal/modal2/index";
import Cache from "../../../../../utils/cache";
import { useIntl, FormattedMessage } from "react-intl";
import { useDispatch } from 'react-redux'
import LotteryRulesPrizes from "../rotary-draw/lottery-rules";
import LotteryDrawHistory from "../draw-history/index";
import { useApp, useQuery } from "../../../../hooks/common";
import { ContainerMask } from "../../../../components/mask";
import ReactDOM from 'react-dom'
import { fetchGetLotteryPrizes } from "../../../../../api";
import { LuckyGrid } from '@lucky-canvas/react'
import { action_fetch_floating_mark_data } from "../../../../../store/actions/global";
import { setOPLoading } from "../../../../../store/actions/ui";
import { debounce } from 'lodash';
import { BigButton } from "../../../../components/button";

const concatImageDomain = (url) => {
    if(url?.indexOf("http") != -1){
        return url
    } else {
        return IMAGE_GEEKO_LTD + url
    }
}

const LotteryContainer = props => {
    useStyles(classes);
    const { allPrizes, letteryCount } = props;
   
    const lotteryInit = useMemo(() =>{
        return {
            prizes: [
                { 
                    x: 0, 
                    y: 0,
                    fonts: [{ text: '1', top: '55%' }], 
                    imgs: [{
                        src: 'https://image.geeko.ltd/chicme/2021111101/black-friday22/lottery-thks.png',
                        top: '0%',
                        width: '60%'
                    }],
                    background: '#FFFFDF' 
                },
                { 
                    x: 1, 
                    y: 0,
                    fonts: [{ text: '2', top: '55%' }], 
                    imgs: [{
                        src: 'https://image.geeko.ltd/chicme/2021111101/black-friday22/lottery-thks.png',
                        top: '0%',
                        width: '60%'
                    }],
                    background: '#FFFFDF' 
                },
                { 
                    x: 0, 
                    y: 1,
                    fonts: [{ text: '3', top: '55%' }], 
                    imgs: [{
                        src: 'https://image.geeko.ltd/chicme/2021111101/black-friday22/lottery-thks.png',
                        top: '0%',
                        width: '60%'
                    }],
                    background: '#FFFFDF' 
                },
                { 
                    x: 1, 
                    y: 1,
                    fonts: [{ text: '4', top: '55%' }], 
                    imgs: [{
                        src: 'https://image.geeko.ltd/chicme/2021111101/black-friday22/lottery-thks.png',
                        top: '0%',
                        width: '60%'
                    }],
                    background: '#FFFFDF' 
                },
            ],
            defaultStyle:{fontColor:'#989310',fontSize:'0.48rem',wordWrap:true,fontStyle:'Roboto-Black',lineClamp:2, lengthLimit:'80%', borderRadius: 15,},
            defaultConfig:{}
        }
    }, []);

    const [prizes, setPrizzes] = useState(() => {
        const newArr = lotteryInit.prizes.map((item, index) => {
            let newItem = allPrizes?.[index];
            // ?.replace(/\s+/g,'\n')
            let name = newItem?.name;
            item.fonts[0].text = name;
            item.imgs[0].src = concatImageDomain(newItem?.pcImageUrl);
            item.data = newItem;
            return item;
        });

        return newArr;
    });

    const [prizesButton, setPrizesButton] = useState(lotteryInit.buttons);

    const dispatch = useDispatch();
    const myLucky = useRef();
    const isApp = useApp();
    const intl = useIntl();
    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState(null);
    
    const global = useSelector(state => state.global);
    const lotteryCount = useRef(letteryCount);
    const offsetRef = useRef(0);
    lotteryCount.current = letteryCount;
    

    useEffect(async () =>{
        if(isApp){
            // await promise
            // handlePlayEnd { data:data } 数据结构
            let response2 = await fetchGetUserLastTimes();
            if(response2?.code === 200 && response2?.result <= 0){
                let response = await fetchLastestPrize();
                if(response?.code === 200 && response?.result){
                    if(response.result?.expiredDate){
                        offsetRef.current = response.result.expiredDate - (response.serverTime || 0)
                    }
                    handlePlayEnd({ data: response.result });
                }
            }
        }
    }, []);

    const handlePlayEnd = useCallback((prize) => {
        let lotteryResult = prize?.data || {};
        setModalData({...lotteryResult, offset: offsetRef.current });
        setShowModal(true);
        props?.onDisplayClose(false);
        props?.getDrawHistory?.();

        // 登录未登录都需要刷新一次悬浮标
        if(!isApp){
            dispatch(action_fetch_floating_mark_data());
        }
        return;
    }, []);

    const refreshPrize = async (prize) =>{
        // setModalData(prize);
        // setShowModal(true);
        // props?.onDisplayClose(false);

        // 登录完之后刷新一次悬浮标 
        props?.onDisplayClose(true);
        setShowModal(false);
        dispatch(setOPLoading(true));
        await dispatch(action_fetch_floating_mark_data());
        dispatch(setOPLoading(false));
        props?.getUserLastTimes();
        props?.getDrawHistory?.();
    }

    const handlePlayStart = async () => {
        // 注释测试区
        // let prize = Math.floor(Math.random() * 8);
        // console.log('prize', prize);
        // if(prize >= 0){
        //     myLucky.current.play()
        //     // 模拟调用接口异步抽奖
        //     setTimeout(() => {
        //         myLucky.current.stop(prize)
        //     }, 2500)
        // }

        // return;
        
        const randomid = v4();
        let response = await fetchLuckDraw(randomid);
        if (response?.code === 200) {
            let result = response?.result;

            let prize = allPrizes?.findIndex(item => item?.id === result?.id);
            if (prize >= 0) {
                myLucky.current.play()
                if(result?.expiredDate){
                    offsetRef.current = result.expiredDate - (response.serverTime || 0)
                }

                // 模拟调用接口异步抽奖
                setTimeout(() => {
                    myLucky.current.stop(prize)
                }, 2500)
            }

            props?.getUserLastTimes();


            if (window.GeekoSensors) {
                let lottery_result = response?.result?.name || '接口返回为空'
                if(response?.result?.physicalInfo){
                    lottery_result += '_' + response?.result?.physicalInfo
                }
                window.GeekoSensors.Track('PopUp', {
                    type: "转盘抽奖",
                    lottery_result: lottery_result,
                })
            }
        } else {
            alert(response?.result);
            props?.getUserLastTimes();
        }
    }

    const handleBegin = () => {
        let luckShowed = Cache.get("__luckshowed");
        // 注释测试区
        // handlePlayStart();
        // return;

        //无抽奖机会提示
        if (lotteryCount.current == 0 || lotteryCount.current == '?') {
            setShowModal(true);

            setModalData({
                btn2: {
                    label: `${intl.formatMessage({ id: "ok", defaultMessage: "OK" })}`,
                    callback: () => {
                        setShowModal(false);
                        props?.onDisplayClose(true);
                    }
                },
                font1: {
                    label: `${intl.formatMessage({ id: "today_no_change", defaultMessage: "You've run out of chances today, go shopping" })}!`,
                    style: {
                        fontSize: "14px",
                        marginTop:"15px"
                    }
                },
                imageUrl: "/chicme/2022052401/no_times_mask.png",
            });


            props?.onDisplayClose(false);
            return;
        }else{
            if (lotteryCount.current > 0) {
                handlePlayStart();
    
                if (!luckShowed) {
                    Cache.set("__luckshowed", 1, 24 * 60 * 60);
                }
            }
        }

        if (window.GeekoSensors) {
            window.GeekoSensors.Track('PopUp', {
                type: "转盘抽奖",
                page_content: "popup",
                is_join: true
            })
        }
    };

    const onStart = debounce(() => {
        // if (!global?.user || !global?.user?.id) {
        //     // handleSub()
        //     if(isApp){
        //         window.location.href = "chic-me://chic.me/loginRoot"
        //     }else{
            //     dispatch({
            //         type: TOGGLE_UI_LOGIN,
            //         payload: {
            //             showLogin:true,
            //             eventType:"抽奖",
            //             redirectUrl:'/?show_lottery=1',
            //             callBack:()=>{
            //                 props?.getUserLastTimes?.();
            //             }
            //         }
            //     });
            // }
        //     return;
        // } else {
            handleBegin()
        // }
    }, 200);

    return <div className={classes.LotteryBox}>
        <div className={classes.LotteryBoxItem}>
            <span className={classes.Font}><FormattedMessage id="you_have" defaultMessage="You Have" /> {letteryCount} <FormattedMessage id="changes" defaultMessage="Chances" /></span>
        </div>

        <div className={classes.LotteryDraw}>
            <LuckyGrid
                key={global?.user?.id}
                ref={myLucky}
                width={'6.88rem'}
                height={'6.88rem'}
                rows={2} 
                cols={2} 
                blocks={lotteryInit.blocks}
                prizes={prizes}
                buttons={prizesButton}
                defaultConfig={{}}
                defaultStyle={lotteryInit.defaultStyle} 
                activeStyle={{ background: '#FFF68E' }}
                onEnd={(prize) => handlePlayEnd(prize)}
            ></LuckyGrid>
        </div>

        <div className={classes.StartLuck}>
            <BigButton onClick={onStart}><FormattedMessage id="click_to_draw" defaultMessage="Click to draw" /></BigButton>
        </div>

        <LotteryAlertModal 
            showMaskBg
            showModal={showModal} 
            lotteryResult={modalData} 
            refreshPrize={refreshPrize} 
            onClose={() => {
                props?.onDisplayClose(true);
                setShowModal(false)
            }} 
        />
    </div>
}

export const BlackFridayLotteryGrid = props => {
    useStyles(classes);
    const { displayAlert, onClose, forApp } = props;
    const [letteryCount, setLotteryCount] = useState('?');
    const [showRules, setShowRules] = useState(false);
    const [drawHistory, setDrawHistory] = useState(null);
    const global = useSelector(state => state.global);
    const { config, language } = global;

    const getUserLastTimes = async () => {
        let response = await fetchGetUserLastTimes();
        if (response?.code === 200) {
            setLotteryCount(response?.result);
        }
    }

    const getDrawHistory = async () => {
        let response = await fetchLuckLastesthistory();
        if (response?.code === 200) {
            setDrawHistory(response?.result);
        }
    }

    useEffect(() => {
        // if (!global?.user || !global?.user.id) {
        //     setLotteryCount('?');
        // } else {
        //     getUserLastTimes();
        // }

        getUserLastTimes();

        getDrawHistory();
    }, []);

    const handleClick = useCallback(() => {
        if (isIOS || isMacOs) {
            setTimeout(() => {
                window.location.href = `https://itunes.apple.com/us/app/chic-me-shopping-sharing-saving/id${config?.app.ios.id}?l=zh&ls=1&mt=8`;
            }, 200);
            window.location.href = `chic-me://chic.me/`;
        } else {
            setTimeout(() => {
                window.location.href = `https://play.google.com/store/apps/details?id=${config?.app.android.id}`;
            }, 200);
            window.location.href = `chic-me://chic.me/`;
        }

    }, []);

    const imageTitle = useMemo(() =>{
        let supportLanguages = ["es", "de", "fr", "pt"];
        let jointStr = forApp ? 'app-' : '';
        if(!!language && supportLanguages.includes(language)){
            return `${IMAGE_GEEKO_LTD}/chicme/2023061401/title-${jointStr}${language.toLowerCase()}.png`
        }

        return `${IMAGE_GEEKO_LTD}/chicme/2023061401/title-${jointStr}en.png`
    }, [language, forApp]);

    return <div className={classes.LotteryContainer} 
        onClick={e=> {
            e.stopPropagation()
        }}
        style={{ display: !displayAlert?'none':undefined }}
    >   
        {/* style={{background: `url(${IMAGE_GEEKO_LTD}/chicme/2021111101/black-friday/decorate_draw.png) center/cover no-repeat`}} */}
        {
            !forApp && <a onClick={onClose} className={classes.CloseLottery}></a>
        }

        <div className={classes.LotteryDecorate}>
            <div className={`${classes.LotteryHd}`} style={{backgroundImage: `url(${imageTitle})`}}></div>

            <div className={`${classes.LotteryTitle}`}>
                <span className={`${forApp ? classes.ForAppBlack : ""}`}><FormattedMessage id="mystery_gifts_giveaway" defaultMessage="mystery gifts & big giveaway" /></span>
            </div>
            
            <LotteryContainer
                {...props}
                getUserLastTimes={getUserLastTimes}
                letteryCount={letteryCount}
                getDrawHistory={getDrawHistory}
                handleClick={handleClick} 
            />
        </div>
        
        <LotteryDrawHistory 
            drawHistory={drawHistory} 
            style={{backgroundColor: 'rgba(255, 255, 255, .8)'}} 
            fontColor={'#000000'}
        />

        <div className={classes.MoreRules}>
            <a onClick={() => setShowRules(true)} className={`${classes.RulesButton} ${forApp ? classes.ForAppBlack : ""}`}> <FormattedMessage id="my_prizes_rules" defaultMessage="My Prizes & Rules" /></a>
        </div>

        <LotteryRulesPrizes 
            showRules={showRules}
            handleClick={handleClick}
            onClose={() => setShowRules(false)}
            getUserLastTimes={getUserLastTimes}
        />
    </div>
}


const Lottery = props =>{
    useStyles(classes);
    const { onClose } = props;
    const [message, setMessage] = useState(null)
    const [displayAlert, setDisplayAlert] = useState(true);

    useEffect(async () =>{
        const message = await fetchGetLotteryPrizes().then(data => data.result).catch(e => {
            console.error(e)
            return null
        })
        setMessage(message)
    },[]);

    return <React.Fragment>
        {
            message?.length > 0 && ReactDOM.createPortal(<React.Fragment>
                <ContainerMask onClick={onClose} style={{backgroundColor:'rgba(0,0,0,0.8)', zIndex:5}}>
                    <BlackFridayLotteryGrid
                        {...props}
                        allPrizes={message.slice(0,4)}
                        onClose={onClose}
                        displayAlert={displayAlert}
                        onDisplayClose={(flag) => setDisplayAlert(flag)}
                    />
                </ContainerMask>
            </React.Fragment>, typeof document !== 'undefined' ? document.getElementById('root') : null)
        }
    </React.Fragment>
}

export default Lottery