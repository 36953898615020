import React, { useMemo, useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { action_add_modal_gift_list, setGiftsModalData } from "../../../../../store/actions/pages/collection";
import { useApp } from "../../../../hooks/common";
import { TOGGLE_UI_LOGIN } from "../../../../../store/actions/ui";
import OPLoading from "../../../../components/loading/op-loading";
import { getSensorsUrl } from "../../../../utils/sensor";


export default (Wrapped) =>{
    return props =>{
        const { lotteryResult, onClose, refreshPrize, isNotLottery } = props;
        const intl = useIntl();
        const global = useSelector(state=>state.global);
        const pageCollection = useSelector(state =>state.pageCollection);
        const { countCart, user = {} } = global;
        const dispatch = useDispatch();
        const isApp = useApp();
        const [loading, setLoading] = useState(false);


        const getGiftData = async (collectionId) => {
            // await dispatch(action_fetch_gifts_info_message());
            if(collectionId){
                setLoading(true);
                if(pageCollection?.modalGifts?.products?.length <= 0){
                    await dispatch(action_add_modal_gift_list(collectionId, {skip:0}));
                }
                
                await dispatch(setGiftsModalData({
                    show: true,
                    options: {
                        giftCollectionId: collectionId, 
                        showMsg: true
                    }
                }));
                setLoading(false);
            }
        }
    
        const collectCart = (webHref, appHref) =>{
            if(isApp){
                window.location.href = appHref;
            }else{
                window.location.href = webHref;
            }
        }

        const actualFont = useCallback((size) =>{
            return `${2 * size / 75 * 1}rem`;
        }, []);

        const handleButtonCopier = useCallback((prizeData) =>{
            // isLoginRef.current
            if(!!user?.id){
                let disposeBtn
                if(countCart > 0){
                    disposeBtn = {
                        label: intl.formatMessage({ id: "check_out", defaultMessage: "CHECKOUT" }),
                        isUppercase:true,
                        callback: () => {
                            if (window.GeekoSensors) {
                                window.GeekoSensors.Track('ClickLottery', {
                                    button: "checkout"
                                })
                            }
    
                            onClose();
        
                            collectCart("/cart", "chic-me://chic.me/shoppingcart");
                        }
                    }
                }else{
                    let sensors = {
                        resourcepage_title: 'lottery',
                        resource_position: '1',
                        resource_type: '27',
                        resource_content: "goshopping"
                    };
                    if(window.GeekoSensors){
                        window.GeekoSensors.Track("PitPositionExposure", sensors);
                    }
                    
                    disposeBtn = {
                        label: intl.formatMessage({ id: "go_shopping", defaultMessage: "Go Shopping" }),
                        isUppercase:true,
                        callback: () => {
                            if(window.GeekoSensors){
                                window.GeekoSensors.Track("PitPositionClick", sensors);
                            }
    
                            onClose();
        
                            collectCart(getSensorsUrl("/i/trending-now", sensors), getSensorsUrl("chic-me://chic.me/trendingselling", sensors));
                        }
                    };
                }

                return {
                    btn1: {
                        label: intl.formatMessage({ id: "got_it", defaultMessage: "Got it" }),
                        isUppercase:true,
                        callback: () => {
                            if (window.GeekoSensors) {
                                window.GeekoSensors.Track('ClickLottery', {
                                    button: "got"
                                })
                            }

                            onClose();

                            if(isNotLottery){
                                if(prizeData.type == 1){
                                    collectCart("/user/credits", "chic-me://chic.me/credits");
                                } else if(prizeData.type == 2){
                                    collectCart("/user/coupons", "chic-me://chic.me/coupon");
                                } else if(prizeData.type == 5 || prizeData.type == 7){
                                    collectCart("/user/wallet", "chic-me://chic.me/wallet");
                                }
                            }
                        }
                    },
                    btn2: disposeBtn,
                }
            }else{
                return {
                    btn2: {
                        label: intl.formatMessage({ id: "login_to_receive", defaultMessage: "Login To Receive" }),
                        isUppercase:true,
                        callback: () => {
                            // setShowModal(false);
                            // props?.onDisplayClose(true);

                            if (window.GeekoSensors) {
                                window.GeekoSensors.Track('ClickLottery', {
                                    button: "login"
                                })
                            }

                            if(isApp){
                                window.location.href = "chic-me://chic.me/loginRoot"
                            }else{
                                dispatch({
                                    type: TOGGLE_UI_LOGIN,
                                    payload: {
                                        showLogin:true,
                                        eventType:"抽奖",
                                        redirectUrl:'/?show_lottery=1',
                                        callBack:()=>{
                                            refreshPrize(prizeData);
                                            props?.getUserLastTimes?.();
                                        }
                                    }
                                });
                            }
                        }
                    }
                }
            }
        }, [countCart, user, isNotLottery]);

        const modalData = useMemo(() =>{
             // 积分 type:1
            // 优惠券 type  2
            // 现金券 type 5
            // thanks  type 4
            // 大奖 type 3 (iphone13 || 200$)
            // 美妆实物  type 6
            // 送到钱包里面的现金券 type 7
            if(!!lotteryResult){
                let modalItem;
                if(lotteryResult.type){
                    if (lotteryResult.type == 1) {
                        modalItem = {
                            ...handleButtonCopier(lotteryResult),
                            font1: {
                                label: `${intl.formatMessage({ id: "apply_before_expires", defaultMessage: "Apply it before expires!" })}`,
                                style: {
                                    fontSize: actualFont(12)
                                }
                            },
                            font2: {
                                label: lotteryResult.name,
                                style: {
                                    fontSize: actualFont(24),
                                    marginTop: actualFont(5),
                                    textTransform: "uppercase"
                                }
                            },
                            showTimes: lotteryResult.offset,
                            imageUrl: lotteryResult.imageUrl
                        };
                    } else if (lotteryResult.type ==  2) {
                        // 这一次的现金券就是这样判断
                        if(lotteryResult.winningNumber == 6){
                            modalItem = {
                                ...handleButtonCopier(lotteryResult),
                                font3: {
                                    label: `${intl.formatMessage({ id: "congratulations", defaultMessage: "Congratulations" })}!`,
                                    style: {
                                        fontSize: actualFont(12),
                                        marginBottom: actualFont(10)
                                    } 
                                },
                                showTimes: lotteryResult.offset,
                                imageUrl: lotteryResult.imageUrl
                            };
                        }else{
                            modalItem = {
                                ...handleButtonCopier(lotteryResult),
                                font1: {
                                    label: `${intl.formatMessage({ id: "apply_before_expires", defaultMessage: "Apply it before expires!" })}`, 
                                    style: {
                                        fontSize: actualFont(12),
                                        marginTop: actualFont(10)
                                    }
                                },
                                font2: {
                                    label: lotteryResult.name,
                                    style: {
                                        fontSize: actualFont(24),
                                        marginTop: actualFont(5),
                                        textTransform: "uppercase"
                                    }
                                },
                                showTimes: lotteryResult.offset,
                                imageUrl: lotteryResult?.imageUrl 
                            };
                        }
                    } else if (lotteryResult.type == 3) {
                        modalItem = {
                            ...handleButtonCopier(lotteryResult),
                            font1: {
                                label: `${intl.formatMessage({ id: "apply_before_expires", defaultMessage: "Apply it before expires!" })}`, 
                                style: {
                                    fontSize: actualFont(12),
                                    marginTop: actualFont(10)
                                }
                            },
                            font2: {
                                label: lotteryResult.name,
                                style: {
                                    fontSize: actualFont(22),
                                    marginTop: actualFont(5),
                                    textTransform: "capitalize"
                                }
                            },
                            font4: {
                                label: intl.formatMessage({id: 'gift_to_your_bag'}),
                                style: {
                                    // todo
                                }
                            },
                            imageUrl: lotteryResult.imageUrl,
                            imageWidth:"35%",
                            showTimes: lotteryResult.offset,
                        }
                    } else if(lotteryResult.type == 5){
                        modalItem = {
                            ...handleButtonCopier(lotteryResult),
                            font1: {
                                label: `${intl.formatMessage({ id: "apply_before_expires", defaultMessage: "Apply it before expires!" })}`,
                                style: {
                                    fontSize: actualFont(12)
                                }
                            },
                            font2: {
                                label: lotteryResult.name,
                                style: {
                                    fontSize: actualFont(24),
                                    marginTop: actualFont(5),
                                    textTransform: "uppercase"
                                }
                            },
                            imageUrl: lotteryResult.imageUrl,
                            // imageWidth:"35%",
                            showTimes: lotteryResult.offset,
                        }
                    }else if (lotteryResult.type == 6) {
                        modalItem = {
                            btn1: {
                                label: intl.formatMessage({ id: "got_it", defaultMessage: "Got it" }),
                                callback: () => {
                                    if (window.GeekoSensors) {
                                        window.GeekoSensors.Track('ClickLottery', {
                                            button: "got"
                                        })
                                    }

                                    onClose();
                                }
                            },
                            btn2: {
                                label: intl.formatMessage({ id: "pick_my_gift", defaultMessage: "Pick My Gift" }),
                                callback: async () => {
                                    if (window.GeekoSensors) {
                                        window.GeekoSensors.Track('ClickLottery', {
                                            button: "pickgift"
                                        })
                                    }

                                    await getGiftData(lotteryResult?.data);
                                    onClose();
                                }
                            },
                            font1: {
                                label: `${intl.formatMessage({ id: "congratulations", defaultMessage: "Congratulations" })}!`,
                                style: {
                                    fontSize: actualFont(12),
                                    marginTop: actualFont(10)
                                }
                            },
                            font2: {
                                label: lotteryResult.name,
                                style: {
                                    fontSize: actualFont(22),
                                    marginTop: actualFont(5),
                                    textTransform: "capitalize"
                                }
                            },
                            font4: {
                                label: `<p style="color: #222222;">${intl.formatMessage({ id: "choose_add_your_cart", defaultMessage: "Please choose your favorite gift to add to your cart." })}</p><p style="display: inline-block;transform: scale(0.83);">*${intl.formatMessage({ id: "reselect_gift_your_bag", defaultMessage: "You can reselect the gift in your bag." })}</p>`,
                                style: {}
                            },
                            imageUrl: lotteryResult.imageUrl,
                            imageWidth:"35%",
                            showTimes: lotteryResult.offset,
                        }
                    } else if(lotteryResult.type == 7){
                        modalItem = {
                            ...handleButtonCopier(lotteryResult),
                            font3: {
                                label: `${intl.formatMessage({ id: "congratulations", defaultMessage: "Congratulations" })}!`,
                                style: {
                                    fontSize: actualFont(12),
                                    marginBottom: actualFont(10)
                                }
                            },
                            imageUrl: lotteryResult.imageUrl,
                            // imageWidth:"35%",
                            showTimes: lotteryResult.offset,
                        }
                    }else {
                        modalItem = {
                            btn2: {
                                label: intl.formatMessage({ id: "player_again", defaultMessage: "play again" }),
                                callback: () => {
                                    onClose();
                                }
                            },
                            font1: {
                                label: `${intl.formatMessage({ id: "thanks", defaultMessage: "Thanks" })}!`,
                                style: {
                                    fontSize: actualFont(12),
                                    fontFamily: 'Roboto-Medium'
                                }
                            },
                            font2: {
                                label: `${intl.formatMessage({ id: "please_try_again", defaultMessage: "Oops, please try again" })}!`,
                                style: {
                                    fontSize: actualFont(16)
                                }
                            },
                            imageUrl: lotteryResult.imageUrl
                        };
                    }
                }else{
                    modalItem = lotteryResult;
                }
                return modalItem;
            }
            return null;
        }, [lotteryResult, user, countCart]);

        return <React.Fragment>
            <Wrapped {...props} modalData={modalData} />

            {
                loading && <OPLoading />
            }
        </React.Fragment>
    }
}