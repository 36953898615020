import React, {useCallback} from 'react'
import ReactDOM from 'react-dom'
import BottomToTop from '../../../components/transitions/bottom-to-top'
import RequestPrivacy from './pages/index'
import useStyles from 'isomorphic-style-loader/useStyles'
import classes from './style.module.scss'
import { useSelector, useDispatch } from 'react-redux'
import {TOOGLE_UI_REQUEST_PRIVACY} from '../../../../store/actions/ui'

const WrappedEditor = props => {
    useStyles(classes);
    const ui = useSelector(state => state.ui);

	const dispatch = useDispatch()

	const onClose = useCallback(e => {
		dispatch({
			type: TOOGLE_UI_REQUEST_PRIVACY,
			payload: false
		})
	}, [])


	return <React.Fragment>
		<BottomToTop in={ui?.requestPrivacy} className={classes.FixedContainer}>
			<div>
                <RequestPrivacy onClose={onClose}/>
			</div>
		</BottomToTop>
	</React.Fragment>
}

export default props =>{

    if (typeof window === 'undefined')
		return null

	return ReactDOM.createPortal(
		<WrappedEditor {...props} />,
		document.getElementById('root')
	)
}