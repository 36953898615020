import React from 'react'

const Affix = class extends React.Component{

	constructor(props) {
		super(props)
		this.state={
			fixed: false,
			offsetTop: props.offsetTop
		}
		this.scrollHandle = this.scrollHandle.bind(this)
	}

	componentDidMount() {
		const { offsetTop } = this.props
		const rem = 2 * offsetTop / 75
		const [topSize] = window.document.documentElement.style.fontSize.split('px')
		this.setState({
			offsetTop: rem * topSize
		})
		window.addEventListener('scroll', this.scrollHandle)

		this.observerResize()

	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.scrollHandle)
		try{
			if(this.resizeObserver){
				this.resizeObserver.disconnect()
			}
		}catch(e){}
	}

	scrollHandle(event){

		const { callBack, offsetStart } = this.props

		if(this.affix){
			const { offsetTop } = this.state
			const rect = this.affix.getBoundingClientRect()
			if(rect.top + (offsetStart || 0) < offsetTop){
				this.setState({
					fixed: true
				})
				if(callBack){
					callBack(true)
				}
				
			}
		}

		if(this.hiddenAffix){
			const { offsetTop } = this.state
			const rect = this.hiddenAffix.getBoundingClientRect()
			if(rect.top + (offsetStart || 0) >= offsetTop){
				this.setState({
					fixed: false
				})
				if(callBack){
					callBack(false)
				}
				
			}
		}
	}

	getStyles(){
		if(this.state.fixed){
			return {
				position: 'fixed',
				width: '100%',
				top: this.state.offsetTop,
				zIndex: this.props.zIndex || 5,
				...this.props.style
			}
		}
		return
	}

	observerResize(){
		try{
			const self = this
			this.resizeObserver = new ResizeObserver(entries => {
				self.scrollHandle()
			})
			this.resizeObserver.observe(this.affix)
		}catch(e){}
	}

	render(){

		return <React.Fragment>
			{
				this.state.fixed && <div ref={c => this.hiddenAffix = c} style={{
					height: this.affix.offsetHeight
				}} aria-hidden={true}></div>
			}

			<div ref={c => this.affix = c} style={this.getStyles()}>
				{this.props.children}
			</div>
		</React.Fragment>
	}
}

export default Affix