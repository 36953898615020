import React, { useEffect, useState } from "react";
import useStyles from 'isomorphic-style-loader/useStyles'
import classes from "./style.module.scss"
import { Iconfont } from "../../../../components/icon/iconfont";
import { BigButton } from "../../../../components/button";
import { BlackMask as BlackMaskCopy } from "../../../../components/mask";
import BottomToTop from "../../../../components/transitions/bottom-to-top";
import { FormattedMessage, useIntl } from "react-intl";
import EmailInput from "../input/email-input";
import { fetchRestPassword, fetchValidateSms, fetchPhoneNumberChangePassword, fetchChangePassword } from "../../../../../api";
import Alert from "../../../../components/alert";
import { useQuery } from "../../../../hooks/common";
import { useSelector } from "react-redux";
import { Formik, Form } from "formik";
import VerificationCodeInput from "../input/verification-code-input"
import PhoneNumberField from "../input/phone-number-input";
import PhonePasswordInput from "../input/phone-password-input";
import { isEmojiCharacter } from "../../../../../utils/common";
import Toast from "../../../../components/toast";
import { useRef } from "react";
import ReactDOM from 'react-dom'

const BlackMask = props =>{
    return <BlackMaskCopy {...props} style={{ zIndex: 21 }} />
}

const withCreatePortal = Wrapped => {
    return props => {
        if (typeof window === 'undefined')
        return null

        return ReactDOM.createPortal(
            <Wrapped {...props} />,
            typeof document !== 'undefined' ? document.getElementById('root') : null
        )
    }
}

export const RegisterSuccessModal = withCreatePortal(props => {
    useStyles(classes);
    const { showModal, registerSuccessMsg1, registerSuccessMsg2, registerSuccessMsg3, onClose, email, onRedirectHandle, popValue } = props;
    const intl = useIntl();

    const phoneNumberReg = /^[0-9]+$/;

    const registerSuccessI18n = !!!phoneNumberReg.test(popValue) ? intl.formatMessage(
        { id: 'register_success_message' },
        {
            coupon: `<span style="color: #773F00;">${intl.formatMessage({id:"coupon",defaultMessage:"coupon"})}</span>`,
            point: `<span style="color: #773F00;">100 ${intl.formatMessage({id:"points",defaultMessage:"points"})}</span>`,
            email: email
        }
    ) : intl.formatMessage(
        { id: 'register_success_message_phone' },
        {
            coupon: `<span style="color: #773F00;">${intl.formatMessage({id:"coupon",defaultMessage:"coupon"})}</span>`,
            point: `<span style="color: #773F00;">100 ${intl.formatMessage({id:"points",defaultMessage:"points"})}</span>`
        }
    );

    const htmlStr = "<a href='/policy/bonus-point' style='display: inline-block;position: relative;top: 2px;text-decoration: none;'> <img src='https://image.geeko.ltd/chicme/2021111101/question.png' alt='Question' style='width: 14px;height: 14px;'> </a>";

    const confirmButtonEvent = () =>{
        if(props?.alertLoginEvent){
            props?.alertLoginEvent?.();

            onClose();
            return;
        }

        onRedirectHandle();
    }

    return <>
        <BottomToTop in={showModal} className={classes.RegisterSuccessModal}>
            <div>
                {/* <Iconfont className={classes.SuccessIcon}>&#xe6b7;</Iconfont> */}
                {/* <p className={classes.Title1}><FormattedMessage id="welcome_to" defaultMessage="Welcome to" />{config?.name}</p> */}
                <p className={classes.Title2}><FormattedMessage id="registration_success" defaultMessage="Registration Successful" /></p>

                {
                    registerSuccessMsg1 && <div className={classes.DiscountMessage}>
                        <Iconfont className={classes.DiscountIcon}>&#xe6d9;</Iconfont>

                        <span className={classes.DiscountFont} dangerouslySetInnerHTML={{ __html: registerSuccessMsg1 }}></span>
                    </div>
                }

                {
                    registerSuccessMsg3 && <div className={classes.DiscountMessage}>
                        <Iconfont className={classes.DiscountIcon}>&#xe698;</Iconfont>

                        <span className={classes.DiscountFont} dangerouslySetInnerHTML={{ __html: registerSuccessMsg3 }}></span>
                    </div>
                }

                {
                    registerSuccessMsg2 && <div className={classes.DiscountMessage}>
                        <Iconfont className={classes.DiscountIcon} style={{ fontSize: "20px" }}>&#xe6a9;</Iconfont>

                        <span className={classes.DiscountFont} dangerouslySetInnerHTML={{ __html: `${registerSuccessMsg2}${htmlStr}` }}></span>
                    </div>
                }

                <p className={classes.Message} dangerouslySetInnerHTML={{ __html: registerSuccessI18n }}></p>

                <BigButton
                    className={classes.ConfirmButton}
                    onClick={confirmButtonEvent}
                ><FormattedMessage id="confirm" defaultMessage="Confirm" /></BigButton>
            </div>
        </BottomToTop>

        {
            showModal && <BlackMask />
        }
    </>
});

const LoginForgetPassworModalItem = props =>{
    useStyles(classes);
    const { onClose , mainEmail, onToogle } = props;
    const [email, setEmail] = useState(mainEmail || '');
    const [restSuccess, setRestSuccess] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() =>{
        if(!restSuccess && mainEmail){
            setEmail(mainEmail);
        }
    }, [mainEmail, restSuccess]);

    const SubmitEvent = async (evt) => {
        evt.preventDefault();
        try {
            setLoading(true);
            let response = await fetchRestPassword(email);
            if (response?.code === 200) {
                setRestSuccess(true);
                if (restSuccess) {
                    Alert(response?.result);
                }
            } else {
                Alert(response?.result);
            }

            setLoading(false);
        } catch (error) {
            Alert(error);
            setLoading(false);
        }
    }


    return <>
        <p className={classes.CloseHeader}>
            <Iconfont onClick={onClose}>&#xe69a;</Iconfont>
        </p>

        {
            !restSuccess ?
                <>
                    <p className={classes.Title1}><FormattedMessage id="reset_password" defaultMessage="Reset password" /></p>
                    <p className={classes.Title2}><FormattedMessage id="please_send_email_auto_matically" defaultMessage="Please enter the email address that you registered with us.A password recovery email will be send to your automatically." /></p>

                    <form action="#" onSubmit={(e) => SubmitEvent(e)}>
                        <EmailInput className={classes.InputMargin} email={email} changeEmail={(email) => setEmail(email)} />

                        <BigButton loading={loading} className={classes.SubmitButton}><FormattedMessage id="send_email" defaultMessage="Send Email" /></BigButton>
                    </form>

                    {/* <div className={classes.ToogleResetType} onClick={onToogle}>
                        <span className={classes.IconPhone}></span>
                        <span className={classes.Message}><FormattedMessage id="reset_password_via_phone" defaultMessage="Reset password via phone number" /></span>
                    </div> */}
                </>
                :
                <>
                    <Iconfont className={classes.SuccessIcon}>&#xe6b7;</Iconfont>
                    <p className={classes.Title3}><FormattedMessage id="link_rest_password_to" defaultMessage="The link to reset password has been sent to" /> {email}</p>
                    <div className={classes.MessageExplain}>
                        <p><FormattedMessage id="create_new_password_link_easy" defaultMessage="To create your new password, just click the link in the email we sent you - easy" /></p>
                        <p><FormattedMessage id="check_agin_enter_email_send" defaultMessage="Didn't receive it? Check your junk mail or click the link below and we'll send you a new one." /></p>
                        <p><FormattedMessage id="still_online_help" defaultMessage={`If you still can't find it, click "Online Help" for help`} /></p>
                        <p><FormattedMessage id="three_hours_expires" defaultMessage="Warning: link expires in three hours!" /></p>
                    </div>
                    <BigButton
                        loading={loading}
                        className={classes.SubmitButton2}
                        onClick={(e) => SubmitEvent(e)}
                    ><FormattedMessage id="resend_email" defaultMessage="Resend Email" /></BigButton>

                    <a href="#" className={classes.OnLineHelp}></a>
                </>
        }
    </>
}

export const LoginForgetPassworModal = withCreatePortal(props => {
    useStyles(classes);
    const { showModal , onClose, onToogle } = props;

    return <>
        <BottomToTop in={showModal} className={classes.LoginForgetPassworModal}>
            <div>
                <LoginForgetPassworModalItem {...props} />
            </div>
        </BottomToTop>

        {
            showModal && <BlackMask onClick={onClose} />
        }
    </>
});


export const EmailOrPasswordErrorModal = withCreatePortal(props => {
    useStyles(classes);
    const { errorModal } = props;
    const { message, button1, button2, callback1, callback2 } = errorModal;

    return <>
        <div className={classes.EmailOrPasswordErrorModal}>
            <p className={classes.Message1}>{message}</p>
            {
                button1 && <button className={classes.Button1} onClick={callback1}>{button1}</button>
            }
            
            {
                button2 && <button className={classes.Button2} onClick={callback2}>{button2}</button>
            }
        </div>

        <BlackMaskCopy onClick={callback2} style={{zIndex:24}}  />
    </>
});

export const LoginSuccessModal = withCreatePortal(props => {
    useStyles(classes);
    const { fetchLoginResult , onLoginClose, onRedirectHandle } = props;
    const query = useQuery();
    const global = useSelector((state) => state.global);
    const { config } = global;
    const { appId, pageId, messengerAppId } = config?.facebook;
    const uniqueUserRefId = useRef(`${fetchLoginResult?.customer?.id}_${new Date().getTime()}`);


    useEffect(() => {
        window?.FB.init({
            appId: messengerAppId,
            xfbml: true,
            autoLogAppEvents: true,
            version: "v6.0"
        })

        window?.FB.Event.subscribe('messenger_checkbox', function (e) {
            if (e.event == 'rendered') {
                console.log('Plugin was rendered')
            } else if (e.event == 'checkbox') {
                var checkboxState = e.state
                // click checked status : checked 
                console.log('Checkbox state: ' + checkboxState)

                window?.FB.AppEvents.logEvent('MessengerCheckboxUserConfirmation', null, {
                    'app_id': messengerAppId,
                    'page_id': pageId,
                    'ref': 'facebook',
                    'user_ref': uniqueUserRefId.current
                })
            } else if (e.event == 'not_you') {
                console.log('User clicked \'not you\'')
            } else if (e.event == 'hidden') {
                console.log('Plugin was hidden')
            }

        })
    }, []);

    const onClose = () => {
        if(props?.alertLoginEvent){
            props?.alertLoginEvent?.();
            onLoginClose();
            return;
        }

        onRedirectHandle();
    }

    const markup = {
        __html: `<div class=fb-messenger-checkbox origin="${global?.config?.root}" messenger_app_id="${messengerAppId}" page_id="${pageId}" user_ref="${uniqueUserRefId.current}" size="small" allow_login="true" center_align="true"></div>`
    };

    return <>
        <div className={classes.LoginSuccessModal}>
            <Iconfont className={classes.CloseIcon} onClick={onClose}>&#xe69a;</Iconfont>

            <div className={classes.Bd}>
                <p className={classes.Title}><strong><FormattedMessage id="advanced_messenger" defaultMessage="Advanced messenger service" /></strong></p>

                <ol className={classes.Description}>
                    <li><FormattedMessage id="discount_deals_weekly" defaultMessage="You will receive discount codes and special deals weekly" /></li>
                    <li><FormattedMessage id="response_order_infomation" defaultMessage="You can get auto response about order information" /></li>
                    <li><FormattedMessage id="online_custoer_service" defaultMessage="You can get online customer service" /></li>
                </ol>

                <div>
                    <div dangerouslySetInnerHTML={markup} />
                    {/* <div
                        class="fb-messenger-checkbox"
                        origin={global?.config?.root}
                        page_id={pageId}
                        messenger_app_id={messengerAppId}
                        user_ref={`${fetchLoginResult?.customer?.id}_${new Date().getTime()}`}
                        size="small"
                        skin="light"
                        center_align="true">
                    </div> */}
                </div>

                <BigButton className={classes.ContinueButton} onClick={onClose}><FormattedMessage id="continue" defaultMessage="Continue" /></BigButton>
            </div>
        </div>

        <BlackMask />
    </>
});

export const VerifySpecialEmailModal = withCreatePortal(props =>{
    const {onClose,onSuccess,message} = props;

    return <>
        <div className={classes.verifySpecialEmailModal}>
            <p className={classes.message} dangerouslySetInnerHTML={{__html:message}}></p>

            <div className={classes.ButtonContainer}>
                <button onClick={onSuccess}><FormattedMessage id="confirm" defaultMessage="CONFIRM" /></button>
                <button onClick={onClose}><FormattedMessage id="cancel" defaultMessage="CANCEL" /></button>
            </div>
        </div>

        <BlackMask onClick={onClose} />
    </>
});

const PhoneFrom1 = props =>{
    const { phoneAreaCode, codeValue, loading, setMessage, setLoading, setErrorModal, phoneNumber } = props;
    const Intl = useIntl();

    const submitHandle = async (data) =>{
        setLoading(true)
        let params = {phoneAreaCode: phoneAreaCode, phoneNumber: data.phoneNumber, randcode: data.codeNumber};
        let response = await fetchValidateSms(params);
        if(response?.code === 200){
            setMessage(params);
        }else{
            setErrorModal({
                message: Intl.formatMessage({ id: "verification_code_error_message", defaultMessage: "Sorry, the verification code entered is incorrect or invalid." }),
                button1: Intl.formatMessage({ id: "ok", defaultMessage: "Ok" }),
                callback1: () => {
                    setErrorModal(null);
                }
            });
        }
        setLoading(false)
    }

    return <Formik 
        onSubmit={submitHandle}
        initialValues={{
            phoneNumber: phoneNumber,
            codeNumber: '',
        }}
        validate={values => {
            const errors = {}
            let __reg_phone_normal__ = /^\d{5,20}$/
            const __code_reg__ = /^[0-9]{4}$/;
            if(codeValue === "BR"){
                __reg_phone_normal__ = /^\d{10,11}$/;
            }

            if (!values.phoneNumber) {
                errors.phoneNumber = Intl.formatMessage({id:"enter_phone_number", defaultMessage:"Enter your phone number"});
            }else if(!__reg_phone_normal__.test(values.phoneNumber)){
                errors.phoneNumber = Intl.formatMessage({id:"incorrect_phone_number", defaultMessage:"Incorrect phone number"});
            }
            
            if (!values.codeNumber) {
                errors.codeNumber = Intl.formatMessage({id:"enter_the_code_number", defaultMessage:"Enter the code number"});
            }else if(!__code_reg__.test(values.codeNumber)){
                errors.codeNumber = Intl.formatMessage({ id: "verification_code_error_message", defaultMessage: "Sorry, the verification code entered is incorrect or invalid." });
            }
            return errors
        }}
        enableReinitialize={true} 
    >
        {
            ({isSubmitting}) =>(
                <Form className={classes.PhoneRegisterFrom} >
                    <PhoneNumberField 
                        InputProps={{
                            placeholder:Intl.formatMessage({id:"enter_phone_number", defaultMessage:"Enter your phone number"}),
                            type:"text",
                            name:"phoneNumber"
                        }}
                        codeValue={codeValue}
                        phoneAreaCode={phoneAreaCode}
                    />

                    <VerificationCodeInput
                        InputProps={{
                            placeholder:Intl.formatMessage({id:"enter_the_code_number", defaultMessage:"Enter the code number"}),
                            type:"text",
                            name:"codeNumber"
                        }}
                        mode="3"
                        phoneAreaCode={phoneAreaCode}
                    />
                    
                    <BigButton loading={loading} className={classes.SubmitButton}><FormattedMessage id="confirm" defaultMessage="confirm" /></BigButton>
                </Form>
            )
        }
    </Formik>
}

const PhoneFrom2 = props =>{
    const { onClose, loading, setLoading, setMessage } = props;
    const Intl = useIntl();

    const submitHandle = async (data) =>{
        setLoading(true);
        let response = await fetchChangePassword({password:data.password2});
        if(response?.code === 200){
            onClose();
            setMessage(null);
            Toast(Intl.formatMessage({id:"Password changed successfully", defaultMessage:"Password changed successfully"}));
        }else{
            Alert(response?.result);
        }
        setLoading(false);
    }

    return <Formik 
        onSubmit={submitHandle}
        initialValues={{
            password1: '',
            password2: '',
        }}
        validate={values => {
            const errors = {}
            if(values.password1.length < 6){
                errors.password1 = "6 characters minimum";
            }else if(values.password1.length > 20){
                errors.password1 = "20 characters maximum";
            }else if(isEmojiCharacter(values.password1)){
                errors.password1 = "No emojis";
            }
            
            if (!(values.password1 === values.password2)) {
                errors.password2 = Intl.formatMessage({id:"inconststent_password", defaultMessage:"Inconsistent passwords"});
            }
            return errors
        }}
    >
        {
            ({isSubmitting}) =>(
                <Form className={classes.PhoneRegisterFrom} >
                    <PhonePasswordInput 
                        InputProps={{
                            placeholder:Intl.formatMessage({id:"password", defaultMessage:"Password"}),
                            type:"password",
                            name:"password1"
                        }}
                        showRules
                    />

                    <PhonePasswordInput 
                        InputProps={{
                            placeholder:Intl.formatMessage({id:"confirm_password", defaultMessage:"Confirm Password"}),
                            type:"password",
                            name:"password2"
                        }}
                        label={Intl.formatMessage({id:"confirm_password", defaultMessage:"Confirm Password"})}
                    />

                    <BigButton loading={loading} className={classes.SubmitButton}><FormattedMessage id="confirm" defaultMessage="Confirm" /></BigButton>
                </Form>
            )
        }
    </Formik>
}

const PhoneLoginForgetPassworModalItem = props =>{
    useStyles(classes);
    const { onClose, onToogle, phoneNumber } = props;
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(null);

    return <React.Fragment>
        <p className={classes.CloseHeader}>
            <Iconfont onClick={onClose}>&#xe69a;</Iconfont>
        </p>

        <p className={classes.Title1}><FormattedMessage id="reset_password" defaultMessage="Reset password" /></p>
        <p className={classes.Title2}><FormattedMessage id="confirm_your_phone_number_change" defaultMessage="Confirm your phone number and input correct code to change password." /></p>

        {
            !message ? <React.Fragment>
                <PhoneFrom1 
                    {...props} 
                    loading={loading} 
                    setMessage={(value)=>setMessage(value)} 
                    setLoading={(flag)=>setLoading(flag)}
                />

                {/* <div className={classes.ToogleResetType} onClick={onToogle}>
                    <span className={classes.IconEmail}></span>
                    <span className={classes.Message}><FormattedMessage id="reset_password_via_email" defaultMessage="Reset password via email address" /></span>
                </div> */}
            </React.Fragment> : <React.Fragment>
                <PhoneFrom2 
                    {...props} 
                    loading={loading} 
                    message={message} 
                    setMessage={(value)=>setMessage(value)} 
                    setLoading={(flag)=>setLoading(flag)}
                />
            </React.Fragment>
        }
    </React.Fragment>
}

export const PhoneLoginForgetPassworModal = withCreatePortal(props => {
    useStyles(classes);
    const { showModal , onClose, setErrorModal, onToogle } = props;

    return <React.Fragment>
        <BottomToTop in={showModal} className={classes.LoginForgetPassworModal}>
            <div>
                <PhoneLoginForgetPassworModalItem {...props} />
            </div>
        </BottomToTop>

        {
            showModal && <BlackMask onClick={onClose} />
        }
    </React.Fragment>
})