import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Affix from '../../components/affix'
import { useDispatch, useSelector } from 'react-redux'
import classes from './style.module.scss'
import { Iconfont } from "../../components/icon/iconfont";
import { TOGGLE_UI_LOGIN, TOGGLE_UI_MENU } from "../../../store/actions/ui";
import { action_fetch_hot_words, setCurrentSearch, setListType } from "../../../store/actions/global"
import Badge from '../../components/badge'
import useStyles from 'isomorphic-style-loader/useStyles'
import { FormattedMessage } from 'react-intl'
import Cookies from 'js-cookie'
import { useHistory, useLocation } from 'react-router-dom'

import CountDown from '../../components/count-down'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay } from 'swiper'
import withSource from '../../hocs/source-scoll'
import { SensorsTrack, getSensorsUrl } from '../../utils/sensor';
import PaypalMessage from '../../components/paypal-message'
import withSourceClick from '../../hocs/source-only-click';
import { SET_SEARCH_IMAGE_RESULT } from '../../../store/actions/global';
import { Link } from 'react-router-dom'
import Cache from '../../../utils/cache';


const HeaderCartDiscount = props => {

}

const HeadTip = withSourceClick(props => {
	const { tip, innerRef, extraHeaderTip } = props
	useStyles(classes)
	const global = useSelector(state => state.global)
	const plateform_type = global.isiPhone ? '__IOS__' : (global.isAndroid ? '__Android__' : 'msite')

	const _tip = extraHeaderTip?.[plateform_type] || tip


	const clickHandle = useCallback(e => {
		e.preventDefault()
		const href = e.currentTarget?.href
		window.location.href = getSensorsUrl(href, {
			resourcepage_title: "topbanner",
			resource_type: '7',
			resource_content: "topbanner",
			resource_position: '0',
		})
	}, [])

	const aspectRatio = _tip?.width && _tip?.height ? `${_tip?.width} / ${_tip?.height}` : undefined

	let ratio
	if (_tip?.width && _tip?.height) {
		ratio = `${Math.floor(_tip?.height * 100 / _tip?.width)}%`
	}

	const leftTime = useMemo(() => {
		let offsetTime = 0
		try {
			if(typeof window === 'undefined'){
				return 0
			}
			const circle = _tip.circle
			if (circle) {
				const header_tip_circle_time = Cache.get('header_tip_circle_time')

				if (!header_tip_circle_time || header_tip_circle_time - new Date().getTime() <= 0) {
					if (circle.endsWith('d')) {
						offsetTime = parseInt(circle.split('d')?.[0]) * 24 * 60 * 60 * 1000
					} else if (circle.endsWith('h')) {
						offsetTime = parseInt(circle.split('h')?.[0]) * 60 * 60 * 1000
					} else if (circle.endsWith('m')) {
						offsetTime = parseInt(circle.split('m')?.[0]) * 60 * 1000
					} else {
						offsetTime = parseInt(circle) * 1000
					}
					Cache.set('header_tip_circle_time', new Date().getTime() + (offsetTime || 0), 365 * 24 * 60 * 60)
				} else {
					return header_tip_circle_time - new Date().getTime()
				}

			} else {
				offsetTime = Number(_tip?.endTime) - new Date().getTime()
			}
		} catch (error) {
			console.error(error)
		}


		return offsetTime
	}, [_tip])



	return <a
		ref={innerRef}
		data-position={0}
		data-type="7"
		data-content="topbanner"
		data-title="topbanner"
		onClick={clickHandle}
		href={_tip?.href} className={classes.HeaderTip} style={{ backgroundColor: _tip?.bgcolor, aspectRatio, paddingTop: ratio, }}>
		<img src={_tip?.imageUrl} alt={_tip?.title || 'Header Tips'}/>
		{
			_tip?.endTime && <CountDown numberStyle={{
				color: _tip?.numberColor
			}} labelStyle={{
				color: _tip?.labelColor
			}} offset={leftTime} showDay showHour showLabel className={classes.CountDown} />
		}

	</a>
})

const PayPalMessageTip = withSource(props => {
	const { currency, country, clientId, innerRef, messageFailure } = props
	return <PaypalMessage messageFailure={messageFailure} sensors={{
		ref: innerRef,
		'data-type': '1',
		'data-content': 'PayPal Message',
		'data-title': 'nav-banner',
		position: `1-2`
	}} styleAlign="center" placement={'home'} money={{ currency }} country={country} clientId={clientId} />
})

const SwiperHeadTip = props => {
	useStyles(classes)
	const { tip, extraHeaderTip } = props
	const global = useSelector(state => state.global)
	const [failure, setFailure] = useState(false)
	const [swiper, setSwiper] = useState(null)


	const { currency, country, config } = global

	const failureHandle = () => {
		setFailure(true)
		swiper?.update()
		console.log(swiper)
	}

	const showMessage = config?.paypal?.showMessage && country === 'US' && currency === 'USD'

	return (failure || !showMessage || !global.isFirstOrder) ? <HeadTip extraHeaderTip={extraHeaderTip} tip={tip} /> : <Swiper onSwiper={setSwiper} className={classes.SwiperHeadTip} loop autoplay>
		<SwiperSlide data-swiper-autoplay="5000">
			<HeadTip extraHeaderTip={extraHeaderTip} tip={tip} />
		</SwiperSlide>
		{
			showMessage && global.isFirstOrder && <SwiperSlide key={global?.config?.paypal?.clientId} data-swiper-autoplay="1000">
				<div className={classes.PaypalMessage}>
					<PayPalMessageTip messageFailure={failureHandle} styleAlign="center" placement={'home'} currency={global.currency} country={global.country} clientId={global?.config?.paypal?.clientId} />
				</div>
			</SwiperSlide>
		}
	</Swiper>
}


const DownLoadTip = props => {
	useStyles(classes)
	const { download, global } = props;
	const { config } = global;

	const handleClick = () => {
		// 安卓的facebook无法正确跳转应用商店特殊判断 主因:setTimeout
		if(global?.isAndroid && global?.isFacebook){
			window.location.href = `https://play.google.com/store/apps/details?id=${config?.app?.android?.id}`;
			return;
		}else{
			const _fbp = Cookies.get('_fbp');
			const _fbc = Cookies.get('_fbc');
			window.location.href = `${window.adjustUrl}${!!_fbp?`&fbp=${_fbp}`:""}${!!_fbc?`&fbc=${_fbc}`:""}`;
		}
	};

	return <div className={classes.DownloadTip}>
		<Iconfont onClick={props?.onClose} className={classes.Cls}>&#xe7bc;</Iconfont>
		<img className={classes.Logo} src={download?.icon} alt="logo"/>
		<div className={classes.Text}>
			<div className={classes.Title}>{download?.headerTitle}</div>
			<div className={classes.Dsc}>{download?.title}</div>
			<div className={classes.Stars}>
				{
					Array.from(new Array(5)).map((_, index) => <Iconfont className={classes.Star} key={index}>&#xe7bb;</Iconfont>)
				}
			</div>
		</div>
		<button onClick={handleClick} className={classes.Get}>
			<FormattedMessage id="get" defaultMessage="GET" />
		</button>
	</div>
}

const HotwordsDisplaySource = (props => {
	const { hot } = props;

	return <span
		data-title={"search"}
		data-position={"1"}
		data-type={"17"}
		data-content={hot.value}
	>{hot.value}</span>
})

const HotwordsDisplay = props => {
	useStyles(classes)
	const { hotWords } = props
	const { onSwiper } = props

	const swiperHandler = (swiper) => {
		const activeIndex = swiper.activeIndex
		onSwiper(hotWords?.[activeIndex - 1] || hotWords[0])
	}

	return <Swiper
		direction="vertical"
		autoplay={{
			delay: 2000,
		}}
		loop={true}
		modules={[Autoplay]}
		onSlideChange={swiperHandler}
		className={classes.SwiperHotWords}
	>
		{
			hotWords?.map((hot, index) => <SwiperSlide className={classes.SwiperHotWordSlide} key={index}>
				<HotwordsDisplaySource hot={hot} />
			</SwiperSlide>)
		}
	</Swiper>
}

export const SearchInput = props => {
	useStyles(classes)
	const global = useSelector(state => state.global)
	const dispatch = useDispatch()
	const hotWords = global?.hotWords

	const [hot, setHot] = useState(null)

	const onSwiper = useCallback(hot => {
		setHot(hot)
	}, [])

	const clickHandle = async e => {
		e.preventDefault()
		await dispatch(setCurrentSearch(hot?.value))
		await dispatch({
			type: SET_SEARCH_IMAGE_RESULT,
			payload: {
				show: true,
				searchType: 0
			}
		})

		if (window.GeekoSensors) {
			window.GeekoSensors.Track("click_search");
			// window.GeekoSensors.Track("PitPositionClick", {
			// 	resourcepage_title: 'search',
			// 	resource_position: '1',
			// 	resource_type: '17',
			// 	resource_content: hot?.value
			// })
		}
	}

	useEffect(() => {
		if (!hotWords || hotWords.length < 1) {
			dispatch(action_fetch_hot_words())
		}
	}, [])


	return <div className={`${classes.HeadSearch} ${props.radius ? classes.Radius : ''}`} onClick={clickHandle}>
		<div className={classes.HotwordsArea}>
			<Iconfont className={classes.Sousuo}>&#xe772;</Iconfont>
			<div>
				{
					hotWords && hotWords.length > 0 && <HotwordsDisplay hotWords={hotWords} onSwiper={onSwiper} />
				}
			</div>
		</div>
		<Iconfont className={classes.Xiangji}>&#xe706;</Iconfont>
	</div>
}

const HeaderTool = props => {
	useStyles(classes)
	const dispatch = useDispatch()
	const ui = useSelector((state) => state.ui)
	const global = useSelector(state => state.global)
	const history = useHistory()
	const location = useLocation()
	const [showHotWords, setShowHotWords] = useState(false)


	const handleScroll = useCallback(e => {
		if (window.scrollY > 200) {
			setShowHotWords(true)
		} else {
			setShowHotWords(false)
		}
	}, [])

	useEffect(() => {
		window.addEventListener('scroll', handleScroll)
		return () => {
			window.removeEventListener('scroll', handleScroll)
		}
	}, [])

	const notificationLoginEvent = () => {
		if (global?.user || global?.user?.id) {
			window.location.href = "/user/notification";
		} else {
			dispatch({
				type: TOGGLE_UI_LOGIN,
				payload: {
					showLogin: true,
					eventType: "首页",
					redirectUrl: '/user/notification',
					callBack: function () {
						window.location.href = "/user/notification";
					}
				}
			})

			// dispatch({
			// 	type:SET_REDIRECT_URL,
			// 	payload: `/me/m/notification`
			// })
		}
	}

	const wishListLoginEvent = () => {
		if (global?.user || global?.user?.id) {
			window.location.href = "/user/wishlist";
		} else {
			dispatch({
				type: TOGGLE_UI_LOGIN,
				payload: {
					showLogin: true,
					eventType: "首页",
					redirectUrl: '/user/wishlist',
					callBack: function () {
						window.location.href = "/user/wishlist";
					}
				}
			})
		}
	}

	const isIndexPath = ['/', '/en/', '/de/', '/fr/', '/pt/', '/es/', '/da/', '/sv/', '/fi/', '/is/', '/nb/', '/i/index'].indexOf(location.pathname) >= 0

	const hotsearchDisplay = showHotWords && (location.pathname?.indexOf('/product/') >= 0 || location.pathname?.indexOf('/simple-list/') >= 0) || location.pathname.indexOf('/i/menu') >= 0
	const showLove = !showHotWords && isIndexPath

	const listTypeHandle = useCallback(type => {
		try {
			Cookies.set('listType', type === '1' ? '0' : '1', { path: '/', expires: 30 })
			dispatch(setListType(type === '1' ? '0' : '1'))
			SensorsTrack('listType', { view_pattern: type === '1' ? 'double' : 'single' })
		} catch (error) {
		}

	}, [])

	const isSmallLogo = props.isSmallLogo

	return <div className={classes.Header}>


		{
			location.pathname === '/landing-page/new' ? <Link to="/" className={classes.LogoTitle}>
				{global.config?.name}&nbsp;New
			</Link> : <React.Fragment>

				{
					!hotsearchDisplay && <a href="/" className={`${classes.Logo} ${isSmallLogo ? classes.Small : ''}`}>
						<img src={props.logo} alt="logo"/>
					</a>
				}

			</React.Fragment>
		}


		<div className={classes.Tools}>
			<div>

				{
					!isIndexPath && <span className={classes.ToolIcon} onClick={() => {
						history?.goBack()
					}}>
						<Iconfont>&#xe760;</Iconfont>
					</span>
				}





				{
					location.pathname.indexOf('/i/menu') < 0 && <span className={`${classes.ToolIcon} ${classes.Menu}`} onClick={
						() => {
							dispatch({
								type: TOGGLE_UI_MENU,
								payload: !ui.showMenu
							})
						}
					}><Iconfont>&#xe771;</Iconfont></span>
				}




				{
					isIndexPath && <span className={classes.ToolIcon}>
						<Badge count={global.countNotification} onClick={notificationLoginEvent}>
							<Iconfont className={`${classes.bigger} ${classes.Notification}`}>&#xe70b;</Iconfont>
						</Badge>
					</span>
				}

			</div>

			{
				hotsearchDisplay && <SearchInput />
			}

			<div>
				{/* {
					/^\/(collection|category)\//.test(location.pathname) && <span className={classes.ToolIcon} onClick={() => listTypeHandle(global.listType)}>
						{
							global.listType === '1' ? <Iconfont className={classes.bigger}>&#xe7cb;</Iconfont> : <Iconfont className={classes.bigger}>&#xe7cc;</Iconfont>
						}
					</span>
				} */}

				{
					!hotsearchDisplay && !showLove && <span className={classes.ToolIcon} onClick={
						() => {
							dispatch({
								type: SET_SEARCH_IMAGE_RESULT,
								payload: {
									show: true,
									searchType: 0,
								}
							})

							if (window.GeekoSensors) {
								window.GeekoSensors.Track("click_search");
							}
						}
					}><Iconfont className={classes.bigger}>&#xe772;</Iconfont></span>
				}

				{
					showLove && <span className={classes.ToolIcon} onClick={() => wishListLoginEvent()}>
						<Iconfont className={classes.bigger}>&#xe6a2;</Iconfont>
					</span>
				}




				<span className={classes.ToolIcon}>
					<Badge count={global.countCart} onClick={() => {
						window.location.href = '/cart'
					}}>
						<Iconfont className={classes.bigger}>&#xe6a4;</Iconfont>
					</Badge>

				</span>
			</div>
		</div>
	</div>
}

const Header = props => {
	useStyles(classes)
	const [showDownload, setShowDownload] = useState(true)
	const global = useSelector((state) => state.global)
	const { tip, download, config, extraHeaderTip } = global
	const location = useLocation()

	const handleCloseDown = evt => {
		setShowDownload(false)
		Cookies.set('h-download', '1', { path: '/', expires: 1 })
	}

	return <div>
		{
			showDownload && !config?.support?.branch && !global?.isShowDownload && !!download && location.pathname !== '/i/menu' && <DownLoadTip onClose={handleCloseDown} download={{ ...download, icon: config.icon, headerTitle: `${config.name}${download?.headerTitle}` }} global={global} />
		}
		<div>
			{/* <HeadTip tip={tip} /> */}
			{/* <SwiperHeadTip tip={tip}/> */}

			{
				location.pathname?.indexOf('/product/') >= 0 ? <HeadTip extraHeaderTip={extraHeaderTip} tip={tip} /> : <SwiperHeadTip extraHeaderTip={extraHeaderTip} tip={tip} />
			}

		</div>
		<Affix offsetTop={0}>
			<HeaderTool logo={config?.logo1 || config?.logo} isSmallLogo={!!config?.logo1}/>
		</Affix>
	</div>
}

export default React.memo(Header)