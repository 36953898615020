import React, { useCallback, useMemo, useState } from "react";
import cls from "./style.module.scss";
import useStyles from "isomorphic-style-loader/useStyles";
import { Iconfont } from "../../../../../components/icon/iconfont";
import { useDispatch, useSelector } from "react-redux";
import ReactDOM from 'react-dom'
import RightToLeft from "../../../../../components/transitions/right-to-left";
import { BlackMask } from "../../../../../components/mask";
import { Header } from "../../../../../components/header";
import { useIntl, FormattedMessage } from "react-intl";
import { setHomeToogleService } from "../../../../../../store/actions/pages/home";

const ContactSeviceDetail = props =>{
    const { config, onClose } = props;
    const { policy = {} } = config;

    return <div className={cls.ContactSeviceDetail}>
        <Header 
            onClose={onClose} 
            centerSlot={"WhatsApp"} 
        />

        <div className={cls.WhatsAppContainer}>
            {
                policy?.whatsAppId1 && <a href={`https://api.whatsapp.com/send?phone=${policy.whatsAppId1}`} className={cls.WhatsAppItem}>
                    <Iconfont className={cls.WhatsIcon}>&#xe7c4;</Iconfont>
        
                    <div className={cls.WhatsFont}>
                        <p><FormattedMessage id="pre_wholesale" defaultMessage="PRE-Wholesale" /></p>
                        <p>+{policy.whatsAppId1}</p>
                    </div>
                </a>
            }
            
            {
                policy?.whatsAppId3 && <a href={`https://api.whatsapp.com/send?phone=${policy.whatsAppId3}`} className={cls.WhatsAppItem}>
                    <Iconfont className={cls.WhatsIcon}>&#xe7c4;</Iconfont>
        
                    <div className={cls.WhatsFont}>
                        <p><FormattedMessage id="after_wholesale" defaultMessage="AFTER-Wholesale" /></p>
                        <p>+{policy.whatsAppId3}</p>
                    </div>
                </a>
            }

            {
                policy?.whatsAppId4 && <a href={`https://api.whatsapp.com/send?phone=${policy.whatsAppId4}`} className={cls.WhatsAppItem}>
                    <Iconfont className={cls.WhatsIcon}>&#xe7c4;</Iconfont>
        
                    <div className={cls.WhatsFont}>
                        <p><FormattedMessage id="drop_shipping" defaultMessage="Drop Shipping" /></p>
                        <p>+{policy.whatsAppId4}</p>
                    </div>
                </a>
            }
        </div>
    </div>
}

const ContactSeviceDetailModal = props =>{
    if (typeof window === 'undefined')
        return null

    return ReactDOM.createPortal(
        <React.Fragment>
            <RightToLeft in={props.show} className={cls.ContactSeviceDetailModal}>
                <div>
                    <ContactSeviceDetail {...props} />
                </div>
            </RightToLeft>
            {props.show && <BlackMask style={{zIndex: 10}} onClick={props.onClose} />}
        </React.Fragment>,
        document.getElementById('root')
    )
}

export default props =>{
    useStyles(cls);
    const [show, setShow] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const global = useSelector(state=>state.global);
    const pageHome = useSelector((state) => state.pageHome)
    const { config } = global;
    const dispatch = useDispatch();
    const ui = useSelector(state => state.ui);

    const whatsAppHandle = useCallback((evt) =>{
        evt.preventDefault();
        setShowModal(true);
    }, []);

    const toogle = useMemo(() =>{
        return pageHome?.toogleService;
    }, [pageHome]);

    return <React.Fragment>
            {
                config?.indexContactService && <React.Fragment>
                    {
                        show && <div className={`${cls.ContactSevice} ${(ui.direction === 'down' || !ui.direction) ? cls.Show : ""}`}>
                            <div className={cls.CloseIcon} onClick={()=>setShow(false)}>
                                <span className="iconfont">&#xe7c8;</span>
                            </div>
            
                            <div className={cls.FoldIconList}>
                                <div className={`${cls.ToogleContainer} ${toogle ? cls.Active: ""}`}>
                                    <span className={`${cls.FlodIcon} ${cls.WhatsIcon}`} onClick={whatsAppHandle}>
                                        <Iconfont>&#xe7c4;</Iconfont>
                                    </span>
            
                                    <a className={`${cls.FlodIcon} ${cls.MessengerIcon}`} href={`https://www.messenger.com/t/${config?.facebook?.pageId}`}>
                                        <Iconfont>&#xe664;</Iconfont>
                                    </a>
                                </div>
            
                                <span className={`${cls.FlodIcon} `} onClick={()=>{
                                    dispatch(setHomeToogleService(!toogle));
                                }}>
                                    <span className={`${cls.NormalIcon} ${toogle ? cls.Active: ""}`}></span>
                                </span>
                            </div>
                        </div>
                    }

                    <ContactSeviceDetailModal 
                        show={showModal} 
                        onClose={()=>setShowModal(false)} 
                        config={config} 
                    />
                </React.Fragment>
            }
            
    </React.Fragment>
}