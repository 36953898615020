import React, { useMemo } from "react";
import classes from "./style.module.scss";
import useStyles from "isomorphic-style-loader/useStyles";
import { v4 } from "uuid";
import { BigButton } from "../../../../components/button";
import { useField, useFormikContext } from "formik";
import { useEffect } from "react";
import { fetchPhoneRandcode } from "../../../../../api";
import { useState } from "react";
import { useRef } from "react";
import Toast from "../../../../components/toast";
import { FormattedMessage, useIntl } from "react-intl";

export default props =>{
    useStyles(classes);
    const { InputProps, errorMsg, mode, phoneAreaCode } = props;
    const [field, meta, helpers] = useField(InputProps)

    const {
        values
    } = useFormikContext()

    const { setError } = helpers;
    const [send, setSend] = useState(false);
    const progressRef = useRef();
    const [time, setTime] = useState(60);
    const [loading, setLoading] = useState(false);
    const [btnDisabled, setBtnDisabled] = useState(false);
    const Intl = useIntl();

    const verificationCodeId = useMemo(() =>{
        return v4();
    },[])

    const handleClick = async (evt) =>{
        if(!values?.phoneNumber) return;
        evt.preventDefault();
        setLoading(true);
        let response = await fetchPhoneRandcode({phoneAreaCode:phoneAreaCode, phoneNumber: values?.phoneNumber, mode});
        if(response?.code === 200){
            setLoading(false);
            setBtnDisabled(true);
            Toast(Intl.formatMessage({id:"verifycation_code_success", defaultMessage:"Verification code send successfully"}));
            progressRef.current = setInterval(() => {
                setTime(preValue =>preValue <= 0 ? 0 : (preValue - 1));
            }, 1000);
        }else{
            Toast(response?.result);
            setLoading(false);
        }
    }

    useEffect(() =>{
        if(time <= 0){
            clearInterval(progressRef.current)
            setSend(true);
            setTime(60);
            setBtnDisabled(false);
        }
    },[time])

    useEffect(() =>{

        return () =>clearInterval(progressRef.current);
    },[])

    useEffect(() =>{
        if(errorMsg){
            setError(errorMsg);
        }
    },[errorMsg]);
    
    return <div className={`${classes.NormalCon} ${classes.VerificationCode}`}>
        <label htmlFor={verificationCodeId}><FormattedMessage id="verification_code" defaultMessage="Verification Code" /></label>
        <div id={verificationCodeId} className={`${classes.NormalItem} ${classes.VerificationCon} ${meta.touched && meta.error?classes.ErrorCon:""}`}>
            <input 
                className={classes.InputItem}
                {...field} 
                {...InputProps} 
                autoComplete={"off"}
            />

            <BigButton type="button" className={classes.SendBtn} loading={loading} disabled={btnDisabled} onClick={handleClick}>{btnDisabled  ? (`${time}S`) : (send?Intl.formatMessage({id:"resend", defaultMessage:"Resend"}):Intl.formatMessage({id:"send", defaultMessage:"Send"}))}</BigButton>
        </div>

        <div className={classes.ErrorMsg}>{meta.touched && meta.error ? meta.error : null}</div>
    </div>
}