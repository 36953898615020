import React, { useEffect, useCallback } from 'react'
import { useState } from 'react'
import useStyles from 'isomorphic-style-loader/useStyles'
import classes from "./style.module.scss"
import { action_fetch_cart_count, action_fetch_current_user, action_fetch_notification_count } from '../../../../../store/actions/global'
import { useDispatch } from 'react-redux'
import EmailPhoneWay from '../email-phone-way'
import EmailWay from "../email-way/index"
import { 
    fetchRegister2, 
    fetchLogin, 
    fetchFacebookLoginHandler,
    fetchRegisterAreaCode, 
    fetchLoginAreaCode, 
    fetchPhoneNumberLogin, 
    fetchPhoneLoginByPassword, 
    fetchRegisterCountryData,
    fetchCountry, 
    fetchGoogleLogin, 
    fetchLoginBonusMessage
} from '../../../../../api'
import { RegisterSuccessModal, LoginForgetPassworModal, EmailOrPasswordErrorModal, LoginSuccessModal } from '../dialog'
import { Iconfont } from '../../../../components/icon/iconfont'
import { useQuery } from '../../../../hooks/common'
import { useSelector } from 'react-redux'
import Cache from '../../../../../utils/cache'
import { FormattedMessage, useIntl } from 'react-intl'
import { TOGGLE_UI_LOGIN, TOOGLE_UI_REQUEST_PRIVACY } from '../../../../../store/actions/ui'
import RequestPrivacy from '../../../../layout/fragments/privacy-request'
import Alert from '../../../../components/alert'
import { V } from '../../../../../api'
import { useRef } from 'react'
import { useMemo } from 'react'
import RegisterCountrySelect from "../../fragments/country/index"
import RegisterMessage from '../../fragments/register-message'
import { PhoneLoginForgetPassworModal } from '../dialog'
import { setCountry } from '../../../../../store/actions/global'
import Cookies from 'js-cookie'
import { setFirstOrder } from '../../../../../store/actions/global'
import OPLoading from "../../../../components/loading/op-loading";
import WhatsAppLoginPage from './whatsapp/index';
import FacebookEvents from '../../../../ads/events/facebook'

// const loadLoginGooleSdk = () =>{
//     let googleJdkScript = document.getElementById('google-sdk');
//     let googleJdk = document.getElementById('google-jssdk');
//     googleJdkScript?.remove();
//     googleJdk?.remove();
//     const head = document.getElementsByTagName('head')[0]
//     const script = document.createElement('script')
//     script.type = 'text/javascript'
//     script.id = 'google-sdk'
//     script.text = `
//         (function (d, s, id) {
//             var js, fjs = d.getElementsByTagName(s)[0];
//             if (d.getElementById(id)) { return; }
//             js = d.createElement(s); js.id = id;
//             js.async = true; js.defer = true;
//             js.src = "https://accounts.google.com/gsi/client";
//             fjs.parentNode.insertBefore(js, fjs);
//         }(document, 'script', 'google-jssdk'));
//     `
//     head.appendChild(script);
// }

const loadLoginSdks = data => {
    const facebookSdk = document.getElementById('facebook-jssdk');
    if(!facebookSdk){
        window.fbAsyncInit = function () {
            window.FB.init({
                appId: `${data.facebookAppId}`,
                autoLogAppEvents: true,
                xfbml: true,
                version: 'v3.1'
            })
        }

        const head = document.getElementsByTagName('head')[0]
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.id = 'facebook-sdk'
        script.text = `
            (function (d, s, id) {
                var js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) { return; }
                js = d.createElement(s); js.id = id;
                js.src = "https://connect.facebook.net/en_US/sdk.js";
                fjs.parentNode.insertBefore(js, fjs);
            }(document, 'script', 'facebook-jssdk'));
        `
        head.appendChild(script)
    }else if(window.FB){
        window.FB.init({
            appId: `${data.facebookAppId}`,
            autoLogAppEvents: true,
            xfbml: true,
            version: 'v3.1'
        })
    }


    let googleJdk = document.getElementById('google-sdk');
    googleJdk?.remove();
    const head = document.getElementsByTagName('head')[0]
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.id = 'google-sdk'
    script.text = `
        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) { return; }
            js = d.createElement(s); js.id = id;
            js.async = true; js.defer = true;
            js.src = "https://accounts.google.com/gsi/client";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'google-jssdk'));
    `
    head.appendChild(script);
}

const eventTypeStr = (str) =>{
    let type = "其他";
    if(str?.indexOf('cart') >= 0){
        type = "购买中";
    }else if(str?.indexOf('share') >= 0){
        type = "首页";
    }else if(str?.indexOf('settings') >= 0){
        type = "设置页";
    }else if(str?.indexOf('wishlist') >= 0){
        type = "收藏页";
    }else if(str?.indexOf('me') >= 0){
        type = "Me页面";
    }else if(str?.indexOf('show_lottery') >= 0){
        type = "抽奖";
    }
    
    return type;
}


/**
 * 记得记录事件
 */

const CodeCon = props =>{
    const { codeImage } = props;
    return <img src={codeImage} alt="AreaCode"  />
}

const NormalLoginPage =  props => {
    useStyles(classes)

    const dispatch = useDispatch()
    // const [selectPage, setSelectPage] = useState('register');
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [resetPasswodModal, setRestPasswordModal] = useState(false);
    const [resetPasswodPhoneModal, setRestPasswordPhoneModal] = useState(false);
    const [codeImage, setCodeImage] = useState('');
    const [showLoginCode, setShowLoginCode] = useState(false);
    const [showRegister, setShowRegisterCode] = useState(false);
    const [countryData, setCountryData] = useState(null);
    const [globalPhoneNumber, setGlobalPhoneNumber] = useState('');
    const googleEventRef = useRef(null);
    const [registerDiscount, setRegisterDiscount] = useState(null);

    const globalCode = null;

    const query = useQuery();

    const global = useSelector(state => state.global);

    const ui = useSelector(state => state.ui);

    

    const {
        onSuccess,
        onFailure,
        isModal, 
        handleLoginin, 
        redirectUrl, 
        setFetchResult, 
        setLoginFetchResult, 
        setOpLoading, 
        setErrorModal, 
        onRedirectHandle, 
        popValue, 
        setPopValue
    } = props
    
    const Intl = useIntl();

    useEffect(async () => {
        try {
            // let cacheIsFirstOrder = window.localStorage.getItem("c_has_order");
            let cacheEmail = Cache.get("customerEmail");
            let cookieUtmEmail= Cookies.get("utm_email");
            // if (!global?.isFirstOrder || cacheEmail || cookieUtmEmail || query?.loginPage == 1) {
            //     // setSelectPage('login');
                
            // }else{
                
            // }

            
            let customerPhone = Cache.get("customerPhone");
            let shippingDetail = Cache.get("shippingDetail");

            if (cacheEmail) {
                setEmail(cacheEmail);
            } else if (shippingDetail?.email) {
                setEmail(shippingDetail?.email);
            } else if(cookieUtmEmail){
                setEmail(cookieUtmEmail);
            }

        if(customerPhone){
            setGlobalPhoneNumber(customerPhone)
        }

        } catch (error) {
            console.log('error', error)
        }

        


        //init sdk

        loadLoginSdks({facebookAppId: global?.config?.facebook?.appId});

        // init 验证码
        acquireLoginCode(true);
        acquireRegisterCode(true);

        let response = await fetchCountry();
        if(response?.code === 200){
            dispatch(setCountry(response.result))
        }
    }, []);

    useEffect(() =>{
        if(isModal){
            ui?.login?.eventType && (googleEventRef.current = ui.login.eventType);
        }else{
            redirectUrl && (googleEventRef.current = redirectUrl);
        }
        
    }, [isModal, ui?.login, redirectUrl]);

    useEffect(() =>{
        window.handleCredentialResponse = handleCredentialResponse;
        window.handleClickListener = handleClickListener;
    }, []);

    useEffect(async () =>{
        let response = await fetchLoginBonusMessage();
        if(response?.code === 200 && response?.result){
            setRegisterDiscount(response.result);
        }
    }, []);

    const codeComponent = useMemo(() =>{
        if(codeImage){
            return <CodeCon codeImage={codeImage} />
        }
        return null;
    },[codeImage])

    useEffect(async () =>{
        let response = await fetchRegisterCountryData();
        if(response?.code === 200){
            setCountryData(response?.result);
        }
    },[]);

    const currentSupportPhone = useMemo(() =>{
        try {
            if(countryData?.length > 0 && global?.country){
                let currentCountry = global?.country;
                let countryItem = countryData.find(item=>item.value === currentCountry);

                if(!!countryItem){
                    countryItem?.areaCode && (countryItem = {...countryItem, areaCode: `+${countryItem.areaCode}`})
                }

                return {
                    supportPhone: countryItem?.supportPhoneNumberRegister,
                    currentCode: countryItem
                }
            }
        } catch (error) {
            console.log('error', error)
        }
    },[countryData, global?.country]);

    const acquireLoginCode = async (flag) =>{
        let response = await fetchLoginAreaCode();
        if (response?.code === 200) {
            if(response?.result){
                refreshCode();
                setShowLoginCode(true);
            }else{
                setShowLoginCode(false);
            }
        }
    }

    const acquireRegisterCode = async (flag) =>{
        let response = await fetchRegisterAreaCode();
        if (response?.code === 200) {
            if(response?.result){
                refreshCode();
                setShowRegisterCode(true);
            }else{
                setShowRegisterCode(false);
            }
        }
    }

    const LoginModal = response => {
        let _me = response?.result;
        if (window.FB && _me && _me.customer && !_me.customer.subscribeToFacebookMessage) {
            setLoginFetchResult(_me);
        } else {
            if (props?.alertLoginEvent) {
                props?.alertLoginEvent?.();

                return;
            }
            onRedirectHandle();
        }
    }

    
    const handleCredentialResponse = useCallback(async (data) =>{
        try {
            setOpLoading(true);
            let response = await fetchGoogleLogin(data.credential);
            if(response?.code === 200){
                // 通用handleLogin处理
                await handleLoginin();
                LoginModal(response);

                const register_scenes = isModal ? (googleEventRef.current || "其他") : eventTypeStr(googleEventRef.current);
                if(response?.result?.registerFrom){
                    if (window.GeekoSensors) {
                        window.GeekoSensors.Track('Register', {
                            register_scenes: register_scenes,
                            register_method: 'googleLogin',
                            is_success: true
                        })
                    }

                    FacebookEvents.register({ registration_method: 'googleLogin', status: true });
                }else{
                    if (window.GeekoSensors) {
                        window.GeekoSensors.Track('Login', {
                            register_scenes: register_scenes,
                            register_method: 'googleLogin',
                            is_success: true
                        })
                    }
                }

                if (response?.result?.customer) {
                    window?.sensors.login(response?.result?.customer.id)
                }
            }else{
                response?.result && Alert(response.result);
            }
            setOpLoading(false);
        } catch (error) {
            console.log('error', error)
            setOpLoading(false);
        }
    }, [redirectUrl, ui?.login]);

    const handleClickListener = () =>{
        if (window.GeekoSensors) {
            window.GeekoSensors.Track('ClickGoogle', {
                register_scenes: isModal ? (googleEventRef.current || "其他") : eventTypeStr(googleEventRef.current),
            })
        }
    }

    const handleLogin = async (data, phoneLogin) => {
        // 让调用这个函数的返回值可以知道是成功还是失败
        let callbackFlag = true;
        try {
            setLoading(true);
            let fetchItem;
            if(phoneLogin){
                if(phoneLogin?.showPassword){
                    fetchItem = fetchPhoneLoginByPassword(data);
                }else{
                    fetchItem = fetchPhoneNumberLogin(data);
                }
            }else{
                fetchItem = fetchLogin(data)
            }
            const response = await fetchItem;

            if (response?.code === 200) {
                await handleLoginin();
                LoginModal(response);
                let customerId = response?.result?.customer?.id;

                

                if(response?.result){
                    let isFirstOrder = response.result.isFirstOrder;
                    dispatch(setFirstOrder(isFirstOrder));
                    window.is_first_order = isFirstOrder;

                    if(!isFirstOrder){
                        window.localStorage && window.localStorage.setItem('c_has_order', 1);
                    }
                }
                
                
                if(isModal){
                    if (window.GeekoSensors) {
                        window.GeekoSensors.Track('Login', {
                            register_scenes: ui?.login?.eventType || "其他",
                            register_method: phoneLogin?'phone':'email',
                            is_success: true
                        })
    
                        customerId && window?.sensors.login(customerId)
                    }
                }else{
                    if (window.GeekoSensors) {
                        window.GeekoSensors.Track('Login', {
                            register_scenes: eventTypeStr(redirectUrl),
                            register_method: phoneLogin?'phone':'email',
                            is_success: true
                        })
    
                        customerId && window?.sensors.login(customerId)
                    }
                }
                // if (window.GeekoSensors) {
                //     window.GeekoSensors.Track('Login', {
                //         register_scenes: redirectUrl && redirectUrl.indexOf('cart') >= 0 ? '购买中登陆' : '正常登陆',
                //         register_method: 'email',
                //         is_success: true
                //     })

                //     customerId && window?.sensors.login(customerId)
                // }

                if (window.gtag) {
                    window?.gtag("event", "login", {
                        method: "email"
                    })
                }

                if (window?.mobileAnalyticsClient) {
                    window?.mobileAnalyticsClient.recordEvent('LOGIN_SUCCESS', {
                        'info1': window?.utm_source,
                        'info2': window?.utm_campaign,
                        'info3': window?.utm_medium,
                        'type': '1',
                        'customerId': customerId,
                        'currentPage': window?.currentPage ? window.currentPage : ""
                    });
                }
                
                if(phoneLogin){
                    Cache.set("customerPhone", data?.phoneNumber, (365 * 24 * 60 * 60) * 1000);
                }else{
                    Cache.set("customerEmail", data?.email || email, (365 * 24 * 60 * 60) * 1000);
                }

                if(response?.result?.couponShareErrorCode){
                    Cache.set("couponShareErrorCode", response?.result?.couponShareErrorCode, (24 * 60 * 60) * 1000);
                }
            } else {
                callbackFlag = false;
                if (response?.code === 401) {
                    //	登录的时候密码错误的状态
                    setErrorModal({
                        message: Intl.formatMessage({ id: "password_incorrect", defaultMessage: "Password incorrect! Forgot your password?" }),
                        button1: Intl.formatMessage({ id: "forget_password", defaultMessage: "Forgot my password" }),
                        button2: Intl.formatMessage({ id: "try_again", defaultMessage: "Try again" }),
                        callback1: () => {
                            if(phoneLogin){
                                setRestPasswordPhoneModal(true);
                            }else{
                                setRestPasswordModal(true);
                            }
                            setErrorModal(null);
                        },
                        callback2: () => {
                            setErrorModal(null);
                        }
                    });
                } else if (response?.code === 402) {
                    let message =  phoneLogin ? Intl.formatMessage({ id: "phone_number_no_register_now", defaultMessage: "This phone number is not registered, do you want to register now?" }) : Intl.formatMessage({ id: "emaul_no_register_now", defaultMessage: "This email account is not registered, do you want to register now?" });
                    setErrorModal({
                        message: message,
                        button1: Intl.formatMessage({ id: "register", defaultMessage: "REGISTER" }),
                        button2: Intl.formatMessage({ id: "no_thanks", defaultMessage: "NO,THANKS" }),
                        callback1: () => {
                            // setSelectPage('register');
                            setErrorModal(null);

                            if(phoneLogin){
                                setGlobalPhoneNumber(data?.phoneNumber);
                                
                                if(!phoneLogin?.showPassword){
                                    const { phoneAreaCode, phoneNumber, randcode } = data;
                                    handleRegister({phoneAreaCode, phoneNumber, smsRandcode:randcode}, phoneLogin);
                                }
                            }
                        },
                        callback2: () => {
                            setErrorModal(null);
                        }
                    });
                }else if(response?.code === 403){
                    if(phoneLogin){
                        setErrorModal({
                            message: Intl.formatMessage({ id: "verification_code_error_message", defaultMessage: "Sorry, the verification code entered is incorrect or invalid." }),
                            button1: Intl.formatMessage({ id: "ok", defaultMessage: "Ok" }),
                            callback1: () => {
                                setErrorModal(null);
                            }
                        });
                    }else{
                        Alert(response?.result,{
                            buttonText:Intl.formatMessage({ id: "ok", defaultMessage: "Ok" })
                        });
                    }
                }else{
                    Alert(response?.result || Intl.formatMessage({ id: "network_error", defaultMessage: "Network Error" }),{
                        buttonText:Intl.formatMessage({ id: "ok", defaultMessage: "Ok" })
                    });
                }

                
                
                if(isModal){
                    if (window.GeekoSensors) {
                        window.GeekoSensors.Track('Login', {
                            register_scenes: ui?.login?.eventType || "其他",
                            register_method: phoneLogin?'phone':'email',
                            is_success: false,
                            fail_reason: response.result
                        })
                    }
                }else{
                    if (window.GeekoSensors) {
                        window.GeekoSensors.Track('Login', {
                            register_scenes: eventTypeStr(redirectUrl),
                            register_method: phoneLogin?'phone':'email',
                            is_success: false,
                            fail_reason: response.result
                        })
                    }
                }

                // if (window.GeekoSensors) {
                //     window.GeekoSensors.Track('Login', {
                //         register_scenes: redirectUrl?.indexOf('cart') >= 0 ? '购买中登陆' : '正常登陆',
                //         register_method: 'email',
                //         is_success: false,
                //         fail_reason: data.result
                //     })
                // }

                //  注册错误重新请求注册验证码  当提示错误信息并且存在验证码的时候 刷新一次验证码 
                acquireLoginCode();
            }
            setLoading(false);

            if (window?.mobileAnalyticsClient) {
                window.mobileAnalyticsClient?.recordEvent('LOGIN', {
                    'info1': window?.utm_source,
                    'info2': window?.utm_campaign,
                    'info3': window?.utm_medium,
                    'type': '1',
                    'currentPage': window?.currentPage ? window.currentPage : ""
                });
            }


            // if (window.GeekoSensors) {
            //     window.GeekoSensors.Track('Login', {
            //         register_scenes: redirectUrl && redirectUrl.indexOf('cart') >= 0 ? '购买中登陆' : '正常登陆',
            //         register_method: 'email'
            //     })
            // }
        } catch (error) {
            callbackFlag = false;
            onFailure(error)
            setLoading(false);
        }

        return new Promise((reslove, rej) =>{
            reslove(callbackFlag);
        });
    }

    const handleRegister = async (data, phoneRegister = false) => {
        // 让调用这个函数的返回值可以知道是成功还是失败
        let callbackFlag = true;
        try {
            setLoading(true);
            const response = await fetchRegister2(data);

            if (response?.code === 200) {
                await handleLoginin();
                setFetchResult(response?.result);
                let customerId = response?.result?.customer?.id;

                if (window?.fbq) {
                    window.fbq('track', "CompleteRegistration");
                }

                if (window.mobileAnalyticsClient) {
                    window.mobileAnalyticsClient.recordEvent('COMPLETED_REGISTRATION', {
                        'info1': window?.utm_source,
                        'info2': window?.utm_campaign,
                        'info3': window?.utm_medium,
                        'type': '1',
                        'customerId': customerId,
                        'currentPage': window?.currentPage ? window.currentPage : ""
                    });
                }
                
                if(isModal){
                    if (window.GeekoSensors) {
                        window.GeekoSensors.Track('Register', {
                            register_scenes: ui?.login?.eventType || "其他",
                            register_method: phoneRegister?'phone':'email',
                            is_success: true
                        })
                        window.sensors.login(customerId)
                    }
                }else{
                    if (window.GeekoSensors) {
                        window.GeekoSensors.Track('Register', {
                            register_scenes: eventTypeStr(redirectUrl),
                            register_method: phoneRegister?'phone':'email',
                            is_success: true
                        })
                        window.sensors.login(customerId)
                    }
                }

                if (window.gtag) {
                    window.gtag("event", "sign_up", {
                        method: "email"
                    })
                }

                if(phoneRegister){
                    Cache.set("customerPhone", data?.phoneNumber, (365 * 24 * 60 * 60) * 1000);
                }else{
                    Cache.set("customerEmail", data?.email || email, (365 * 24 * 60 * 60) * 1000); 
                }

                if(response?.result?.couponShareErrorCode){
                    Cache.set("couponShareErrorCode", response?.result?.couponShareErrorCode, (24 * 60 * 60) * 1000);
                }

                FacebookEvents.register({ registration_method: phoneRegister?'phone':'email', status: true });
            } else {
                callbackFlag = false;
                if (response?.code === 401) {
                    let message = phoneRegister ? Intl.formatMessage({ id: "phone_register_has_already", defaultMessage: "The Mobile Phone Number Has Already Been To An Account, Sign In Now?" }) : Intl.formatMessage({ id: "register_has_login_now", defaultMessage: "This email account has been registered, do you want to log in now?" });
                    setErrorModal({
                        message: message,
                        button1: Intl.formatMessage({ id: "log_in_now", defaultMessage: "Log in now" }),
                        button2: Intl.formatMessage({ id: "cancel", defaultMessage: "Cancel" }),
                        callback1: () => {
                            // setSelectPage('login');
                            setErrorModal(null);
                            if(phoneRegister){
                                const { phoneAreaCode, phoneNumber, smsRandcode } = data;
                                handleLogin({phoneAreaCode, phoneNumber, randcode:smsRandcode}, {showPassword:false});
                                setGlobalPhoneNumber(data?.phoneNumber);
                            }
                        },
                        callback2: () => {
                            setErrorModal(null);
                        }
                    });
                }else if(response?.code === 403){
                    if(phoneRegister){
                        setErrorModal({
                            message: Intl.formatMessage({ id: "verification_code_error_message", defaultMessage: "Sorry, the verification code entered is incorrect or invalid." }),
                            button1: Intl.formatMessage({ id: "ok", defaultMessage: "Ok" }),
                            callback1: () => {
                                setErrorModal(null);
                            }
                        });
                    }else{
                        Alert(response?.result,{
                            buttonText:Intl.formatMessage({ id: "ok", defaultMessage: "Ok" })
                        });
                    }
                }else{
                    Alert(response?.result || Intl.formatMessage({ id: "network_error", defaultMessage: "Network Error" }), {
                        buttonText:Intl.formatMessage({ id: "ok", defaultMessage: "Ok" })
                    });
                }

                if(isModal){
                    if (window.GeekoSensors) {
                        window.GeekoSensors.Track('Register', {
                            register_scenes: ui?.login?.eventType || "其他",
                            register_method: phoneRegister?'phone':'email',
                            is_success: false,
                            fail_reason: response?.result
                        })
                    }
                }else{
                    if (window.GeekoSensors) {
                        window.GeekoSensors.Track('Register', {
                            register_scenes: eventTypeStr(redirectUrl),
                            register_method: phoneRegister?'phone':'email',
                            is_success: false,
                            fail_reason: response?.result
                        })
                    }
                }

                // 注册错误重新请求注册验证码
                acquireRegisterCode();
            }
            setLoading(false);

            if (window.fbq) {
                window.fbq('track', 'Lead');
            }

            if (window.pintrk) {
                window.pintrk('track', 'signup', {
                    lead_type: window?.utm_source ? window.utm_source : ""
                });
            }

            // if (window.GeekoSensors) {
            //     window.GeekoSensors.Track('Register', {
            //         register_scenes: redirectUrl?.indexOf('cart') >= 0 ? '购买中注册' : '正常注册',
            //         register_method: 'email'
            //     })
            // }

            if (window.mobileAnalyticsClient) {
                window.mobileAnalyticsClient.recordEvent('INIT_REGISTRATION', {
                    'info1': window?.utm_source,
                    'info2': window?.utm_campaign,
                    'info3': window?.utm_medium,
                    'type': '1',
                    'currentPage': window?.currentPage ? window.currentPage : ""
                });
            }
        } catch (error) {
            onFailure(error)
            setLoading(false);
            callbackFlag = false;
        }

        return new Promise((reslove, rej) =>{
            reslove(callbackFlag);
        });
    }

    const handleFacebookLogin = async e => {
        try {
            if (!!window?.FB) {
                window?.FB?.login(async function (resp) {
                    if (resp.status == 'connected') {
                        let response = await fetchFacebookLoginHandler({ fbId: resp?.authResponse?.userID, accessToken: resp?.authResponse?.accessToken });
                        if (response?.code === 200) {
                            await handleLoginin();
                            LoginModal(response);

                            if (window.mobileAnalyticsClient) {
                                window.mobileAnalyticsClient.recordEvent('COMPLETED_REGISTRATION', {
                                    'info1': window?.utm_source,
                                    'info2': window?.utm_campaign,
                                    'info3': window?.utm_medium,
                                    'type': '2',
                                    'customerId': resp.authResponse.userID,
                                    'currentPage': "login"
                                });
                            }

                            const register_scenes = isModal ? (ui?.login?.eventType || "其他") : eventTypeStr(redirectUrl);
                            if(response?.result?.registerFrom){
                                if (window.GeekoSensors) {
                                    window.GeekoSensors.Track('Register', {
                                        register_scenes: register_scenes,
                                        register_method: 'facebook',
                                        is_success: true
                                    })
    
                                    if (response?.result?.customer) {
                                        window?.sensors.login(response?.result?.customer.id)
                                    }
                                }

                                FacebookEvents.register({ registration_method: 'facebook', status: true });
                            }else{
                                if (window.GeekoSensors) {
                                    window.GeekoSensors.Track('Login', {
                                        register_scenes: register_scenes,
                                        register_method: 'facebook',
                                        is_success: true
                                    })
    
                                    if (response?.result?.customer) {
                                        window?.sensors.login(response?.result?.customer.id)
                                    }
                                }
                            }

                            

                            if (window.gtag) {
                                window.gtag("event", "login", {
                                    method: "facebook"
                                })
                            }
                        } else {
                            if(isModal){
                                if (window.GeekoSensors) {
                                    window.GeekoSensors.Track('Login', {
                                        register_scenes: ui?.login?.eventType || "其他",
                                        register_method: 'facebook',
                                        is_success: false,
                                        fail_reason: response?.result
                                    })
                                }
                            }else{
                                if (window.GeekoSensors) {
                                    window.GeekoSensors.Track('Login', {
                                        register_scenes: eventTypeStr(redirectUrl),
                                        register_method: 'facebook',
                                        is_success: false,
                                        fail_reason: response?.result
                                    })
                                }
                            }
                        }
                    } else {
                        console.log(resp);
                    }
                }, {
                    scope: 'public_profile,email,user_messenger_contact',
                    messenger_page_id: global?.config?.facebook?.pageId
                });
            }

            if (window.GeekoSensors) {
                window.GeekoSensors.Track('ClickFacebook', {
                    register_scenes: isModal ? (ui?.login?.eventType || "其他") : eventTypeStr(redirectUrl),
                })
            }

            //发起注册
            if (window.mobileAnalyticsClient) {
                window.mobileAnalyticsClient.recordEvent('INIT_REGISTRATION', {
                    'info1': window?.utm_source,
                    'info2': window?.utm_campaign,
                    'info3': window?.utm_medium,
                    'type': '2',
                    'currentPage': window?.currentPage ? window.currentPage : ""
                });
            }
        } catch (error) {
            onFailure(error)
        }
    }

    const handleWhatsappLogin = (data) =>{
        if (window.GeekoSensors) {
            window.GeekoSensors.Track('ClickWhatsapp', {
                register_scenes: isModal ? (ui?.login?.eventType || "其他") : eventTypeStr(redirectUrl)
            })
        }

        window.location.href = `https://api.whatsapp.com/send/?phone=${data.phone}&text=${data.text}&type=phone_number&app_absent=0`;
    };

    const handlePaypalLogin = (config) =>{
        const localRedirectUrl = isModal ? window.location.href : (document.referrer || config?.root);
        Cache.set('paypal-redirecturl', localRedirectUrl);

        if (window.GeekoSensors) {
            window.GeekoSensors.Track('ClickPayPal', {
                register_scenes: isModal ? (ui?.login?.eventType || "其他") : eventTypeStr(redirectUrl)
            })
        }

        window.location.href = `https://www.${config?.env?'sandbox.':''}paypal.com/signin/authorize?flowEntry=static&client_id=${config?.paypal?.clientId}&scope=email%20address%20openid%20profile&redirect_uri=${encodeURIComponent(`${config?.root}/sp/login-by-paypal`)}`;
    };

    const forgetPasswordClose = async () =>{
        setRestPasswordModal(false);
        await dispatch({
            type: TOGGLE_UI_LOGIN,
            payload: {
                alertForgetPassword:false
            }
        });
    }

    const refreshCode = () =>{
        setCodeImage(`${V}/login-customer/anon/get-rand-code?timestamp=${new Date().getTime()}`)
    }

    const notCheckVerifyEvent = (eventName, type) =>{
        if(isModal){
            if (window.GeekoSensors) {
                window.GeekoSensors.Track(eventName, {
                    register_scenes: ui?.login?.eventType || "其他",
                    register_method: type,
                })
            }
        }else{
            if (window.GeekoSensors) {
                window.GeekoSensors.Track(eventName, {
                    register_scenes: eventTypeStr(redirectUrl),
                    register_method: type,
                })
            }
        }
    }

    return <div className={classes.LoginPageContainer}>
        <div className={classes.PaddingLR20}>
            <RegisterMessage registerDiscount={registerDiscount} />

            <RegisterCountrySelect countrys={countryData} />

            {
                currentSupportPhone?.supportPhone ? <React.Fragment>
                    <EmailPhoneWay 
                        registerDiscount={registerDiscount} 
                        country={global?.country} 
                        loading={loading} 
                        email={email} 
                        popValue={popValue} 
                        setOpLoading={setOpLoading} 
                        codeComponent={codeComponent} 
                        showLoginCode={showLoginCode} 
                        showRegister={showRegister} 
                        globalPhoneNumber={globalPhoneNumber}
                        supportPhone={currentSupportPhone?.supportPhone}
                        currentCode={globalCode || currentSupportPhone?.currentCode}
                        setPopValue={setPopValue} 
                        handleRegister={handleRegister} 
                        handleLogin={handleLogin} 
                        setRestPasswordModal={() => setRestPasswordModal(true)} 
                        setRestPasswordPhoneModal={()=> setRestPasswordPhoneModal(true)} 
                        refreshCode={() =>refreshCode()}
                        notCheckVerifyEvent={notCheckVerifyEvent} 
                    />
                </React.Fragment> : <React.Fragment>
                    <EmailWay 
                        registerDiscount={registerDiscount} 
                        email={email} 
                        loading={loading} 
                        popValue={popValue} 
                        codeComponent={codeComponent} 
                        showLoginCode={showLoginCode} 
                        showRegister={showRegister} 
                        globalPhoneNumber={globalPhoneNumber}
                        supportPhone={currentSupportPhone?.supportPhone}
                        currentCode={globalCode || currentSupportPhone?.currentCode}
                        setPopValue={setPopValue} 
                        handleRegister={handleRegister} 
                        handleLogin={handleLogin}
                        setRestPasswordModal={() => setRestPasswordModal(true)}
                        refreshCode={() =>refreshCode()}
                        notCheckVerifyEvent={notCheckVerifyEvent}
                    />
                </React.Fragment>
            }
        </div>
        
        <div className={classes.ReqeustPrivacy}>
            <span onClick={() => { 
                dispatch({
                    type: TOOGLE_UI_REQUEST_PRIVACY,
                    payload: true
                })
            }}>
                <FormattedMessage id="privacy_request_center" defaultMessage="Privacy Request Center"/>
            </span>
        </div>
        
        {
            !(global?.isAndroid && global?.isFacebook) && <React.Fragment>
                <div className={classes.JoinWith}>
                    <span className={classes.Line}></span>
                    <span><FormattedMessage id="or_join_with" defaultMessage="Or Join With" /></span>
                    <span className={classes.Line}></span>
                </div>

                <div className={classes.AuthorizedLogin}>
                    {
                        global?.config?.google?.clientId && <div className={classes.AuthorizedSub}>
                            <span className={classes.GoogleIcon}>
                                <span className={classes.Icon}></span>
                            </span>
                            
                            <span className={classes.AuTitle}>
                                <FormattedMessage id="sign_in_with" defaultMessage="Sign in With {mode}" values={{ mode: 'Google' }} />
                            </span>

                            <div className={classes.HideGoogleLogin}>
                                <div 
                                    id="g_id_onload" 
                                    data-client_id={global.config.google.clientId} 
                                    data-callback="handleCredentialResponse" 
                                    data-auto_prompt="false"
                                >
                                </div>
                                <div
                                    class="g_id_signin"
                                    data-type="standard"
                                    data-size="large"
                                    data-theme="outline"
                                    data-text="sign_in_with"
                                    data-shape="rectangular"
                                    data-logo_alignment="center"
                                    data-click_listener="handleClickListener">
                                </div>
                            </div>
                        </div>
                    }
                    
                    <div className={classes.AuthorizedSub} onClick={() => handleFacebookLogin()}>
                        <Iconfont className={classes.FaceBookIcon}>&#xe6b5;</Iconfont>
                        <span className={classes.AuTitle}>
                            <FormattedMessage id="sign_in_with" defaultMessage="Sign in With {mode}" values={{ mode: 'Facebook' }} />
                        </span>
                    </div>
                    
                    {
                        global?.config?.support?.whatsapp && currentSupportPhone?.supportPhone && <div className={classes.AuthorizedSub} onClick={() => handleWhatsappLogin(global.config.support.whatsapp)}>
                            <Iconfont className={classes.WhatsAppIcon}>&#xe785;</Iconfont>
                            <span className={classes.AuTitle}>
                                <FormattedMessage id="sign_in_with" defaultMessage="Sign in With {mode}" values={{ mode: 'Whatsapp' }} />
                            </span>
                        </div> 
                    }
                    
                    <div className={classes.AuthorizedSub} onClick={()=>handlePaypalLogin(global.config)}>
                        <span className={classes.PaypalIcon}></span>
                        <span className={classes.AuTitle}>
                            <FormattedMessage id="sign_in_with" defaultMessage="Sign in With {mode}" values={{ mode: 'Paypal' }} />
                        </span>
                    </div>
                </div>
            </React.Fragment>
        }
        

        {/* {
            currentSupportPhone?.supportPhone && <div className={classes.PolicyMessage}>
                <span dangerouslySetInnerHTML={{__html:privacyPolicyI18n}}></span>
            </div>
        } */}

        <LoginForgetPassworModal 
            showModal={resetPasswodModal || ui?.login?.alertForgetPassword} 
            onClose={() => forgetPasswordClose()} 
            mainEmail={popValue} 
            // onToogle={() =>{
            //     forgetPasswordClose();
            //     setRestPasswordPhoneModal(true);
            // }}
        />
        
        <PhoneLoginForgetPassworModal 
            showModal={resetPasswodPhoneModal}
            onClose={() =>setRestPasswordPhoneModal(false)} 
            codeValue={currentSupportPhone?.currentCode?.value}
            phoneAreaCode={currentSupportPhone?.currentCode?.areaCode}
            setErrorModal={(erros)=>setErrorModal(erros)} 
            phoneNumber={popValue} 
            // onToogle={() =>{
            //     setRestPasswordPhoneModal(false);
            //     setRestPasswordModal(true);
            // }}
        />

        <RequestPrivacy/>
    </div>
}

export default props =>{
    const { isModal } = props;
    const query  = useQuery();
    // query?.token whatsapp set the passwod  register
    // query?.key 自动登录
    const dispatch = useDispatch();
    const global = useSelector(state=>state.global);
    const [fetchResult, setFetchResult] = useState(null);
    const [fetchLoginResult, setLoginFetchResult] = useState(null);
    const [errorModal, setErrorModal] = useState(null);
    const [opLoading, setOpLoading] = useState(false);
    const [popValue, setPopValue] = useState("");

    // ui?.login?.redirectUrl || 这一行是之前为了让google登录可以跳回来才加的
    const redirectUrl = global?.redirectUrl;

    const handleLoginin = useCallback(async () =>{
        dispatch(action_fetch_current_user());
        dispatch(action_fetch_cart_count());
        dispatch(action_fetch_notification_count());
    }, []);
    

    const simplifyNotCheckVerifyEvent = (eventName, type) =>{
        if (window.GeekoSensors) {
            window.GeekoSensors.Track(eventName, {
                register_scenes: eventTypeStr(redirectUrl),
                register_method: type,
            })
        }
    }

    // 点击成功弹窗 登录成功非弹窗的处理逻辑
    const onRedirectHandle = () =>{
        const url = redirectUrl;
        if (url == '_android') {
            window.location.href = `https://play.google.com/store/apps/details?id=${global?.config?.app.android.id}`;
        } else if (url == '_ios') {
            window.location.href = `https://itunes.apple.com/us/app/chic-me-shopping-sharing-saving/id${global?.config?.app.ios.id}?l=zh&ls=1&mt=8`;
        } else {
            window.location.replace(encodeURI(url));
        }
    }

    return <React.Fragment>
        {
            !isModal && (query?.token || query?.key) ? <React.Fragment>
                <WhatsAppLoginPage 
                    isLogin={!!query?.key} 
                    registerScenes={eventTypeStr(redirectUrl)} 
                    handleLoginin={handleLoginin} 
                    notCheckVerifyEvent={simplifyNotCheckVerifyEvent} 
                    setFetchResult={setFetchResult} 
                    setLoginFetchResult={setLoginFetchResult} 
                    setOpLoading={setOpLoading} 
                    {...props} 
                />
            </React.Fragment> : <React.Fragment>
                <NormalLoginPage 
                    redirectUrl={redirectUrl} 
                    setFetchResult={setFetchResult} 
                    setLoginFetchResult={setLoginFetchResult} 
                    setErrorModal={setErrorModal} 
                    setOpLoading={setOpLoading} 
                    handleLoginin={handleLoginin} 
                    onRedirectHandle={onRedirectHandle} 
                    popValue={popValue} 
                    setPopValue={setPopValue} 
                    {...props} 
                />
            </React.Fragment>
        }

        <RegisterSuccessModal
            registerSuccessMsg1={fetchResult?.registerSuccessMsg1}
            registerSuccessMsg2={fetchResult?.registerSuccessMsg2}
            registerSuccessMsg3={fetchResult?.registerSuccessMsg3}
            showModal={!!fetchResult}
            email={fetchResult?.customer?.email}
            onClose={() => setFetchResult(null)} 
            onRedirectHandle={onRedirectHandle} 
            popValue={popValue} 
            {...props}
        />

        {
            fetchLoginResult && 
                <LoginSuccessModal 
                    fetchLoginResult={fetchLoginResult} 
                    onLoginClose={() =>setLoginFetchResult(null)} 
                    onRedirectHandle={onRedirectHandle} 
                    {...props} 
                />
        }

        {
            errorModal && <EmailOrPasswordErrorModal errorModal={errorModal} />
        }

        {
            opLoading && <OPLoading />
        }
    </React.Fragment>
}