import React, { useCallback } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import {IMAGE_GEEKO_LTD} from '../../../../../constants/index'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import deeplink, {getAppLink} from '../../../../../utils/deeplink'
import {useHistory} from 'react-router-dom'
import withSourceScroll from '../../../../hocs/source-scoll'
import { useApp } from '../../../../hooks/common'
import { getSensorsUrl } from '../../../../utils/sensor'

const clickAHandle = (e, sensors) => {
    e.preventDefault()
    const href = e.currentTarget?.href
    window.location.href = getSensorsUrl(href, sensors)
}

export default withSourceScroll(props => {
	useStyles(classes)
	const history = useHistory()
	const {collection, innerRef, position, listType} = props
	const isApp = useApp()
	let href

	if(collection?.deepLink){
		href = isApp ? getAppLink(collection?.deepLink) : deeplink(collection?.deepLink, "msite")
	}else{
		href =  isApp ? `chic-me://chic.me/collection?collectionId=${collection?.id}` : `/collection/${'-'}/${collection?.id}.html`
	}

	const handleClick = useCallback(e => {
		clickAHandle(e, {
			resourcepage_title: 'list',
			resource_type: '1',
			resource_content: collection?.enName || collection?.name,
			resource_position: position+1,
		})
	}, [])

	const imageUrl = listType === '1' ? collection.horizontalBackgroundImage: collection.mainImage

	if(!imageUrl)
		return <></>

	return <div className={listType === '1' ? classes.HCollection :classes.NormalItem}>
		<a href={href} onClick={ handleClick }
			ref={innerRef} 
			data-position={position+1}
			data-type="1"
			data-title="list"
			data-content={collection?.enName || collection?.name}
		>
			<div className={classes.Image}>
				<LazyLoadImage src={`${IMAGE_GEEKO_LTD}${imageUrl}`}/>
			</div>
		</a>
	</div>
})