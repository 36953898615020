import React, { useRef, useState, useEffect } from "react";
import cls from "./style.module.scss";
import useStyles from "isomorphic-style-loader/useStyles";
import { withContainerList } from "../../../../../components/list";
import { FormattedMessage } from "react-intl";
import { fetchProductYouLikes } from "../../../../../../api";
import { Products } from "../../../../../pages/listing/components/list";
import { Iconfont } from "../../../../../components/icon/iconfont";
import { IMAGE_GEEKO_LTD } from "../../../../../../constants";


const YouLikes = withContainerList(props =>{
    const { products, sensors } = props;

    return <React.Fragment>
        <div className={cls.MessageTip}>
            <div className={cls.SpecialIcon}>
                <img src={`${IMAGE_GEEKO_LTD}/chicme/20230506/coat-hanger.svg`} alt="" />
            </div>
           
           <div className={cls.Message}>
                <FormattedMessage id="not_find_anything_similar_search" defaultMessage="We're sorry that we couldn't find anything similar to what you were searching for." />
           </div>
        </div>

        <div className={cls.Hd}>
            <FormattedMessage id="you_may_also_likes" defaultMessage="You May Also Like" />
        </div>
        
        <div>
            {
                products?.length > 0 && <Products 
                    products={products} 
                    sensors={sensors} 
                />
            }
        </div>
    </React.Fragment>
});

export default props =>{
    useStyles(cls);

    const { productId } = props;

    const limitRef = useRef(20);
    const [loading, setLoading] = useState(false)
    const [products,setProducts] = useState([]);
    const [skip,setSkip] = useState(0);
    const [finished,setFinished] = useState(false);

    useEffect(async () =>{
        loadHandle();
    },[]);

    const loadHandle = async () =>{
        setLoading(true)
        const products = await fetchProductYouLikes({skip: skip, limit: limitRef.current, productId }).then(data => data.result).catch(data => {
            console.error(data)
            return []
        })
        
        setProducts(preValue =>[...preValue,...products]);
        setSkip((preValue) =>preValue += limitRef.current);
        setLoading(false)
        setFinished(!!!products || products?.length < 1);
    }

    return <YouLikes loading={loading} products={products} finished={finished} request={loadHandle} {...props} className={`${cls.YouMayAlsoLikes}`} />
}