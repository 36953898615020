import React from "react";
// import "./index.css"
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'

const Loading = (props) =>{
    useStyles(classes);

    return (
        <div className={`${classes.spinner} ${props?.className?props.className:""}`}>
            <div className={`${classes.spinnerContainer} ${classes.container1}`}>
                <div className={classes.circle1}></div>
                <div className={classes.circle2}></div>
                <div className={classes.circle3}></div>
                <div className={classes.circle4}></div>
            </div>
            <div className={`${classes.spinnerContainer} ${classes.container2}`}>
                <div className={classes.circle1}></div>
                <div className={classes.circle2}></div>
                <div className={classes.circle3}></div>
                <div className={classes.circle4}></div>
            </div>
            <div className={`${classes.spinnerContainer} ${classes.container3}`}>
                <div className={classes.circle1}></div>
                <div className={classes.circle2}></div>
                <div className={classes.circle3}></div>
                <div className={classes.circle4}></div>
            </div>
        </div>
    )
}

export default Loading;