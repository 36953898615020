import { useEffect, useState } from "react"

export default id => {
    const [show, setShow] = useState(false)

    const handleHashChange = () => {
        const hash = window.location.hash
        if(hash === `#${id}`){
            setShow(true)
        }else{
            setShow(false)
        }
    }

    useEffect(() => {
        window.addEventListener('hashchange', handleHashChange)
        return () => window.removeEventListener('hashchange', handleHashChange)
    }, [])


    const setShowHandle = s => {
        setShow(s)
        if(s){
            window.history.pushState({}, '', `#${id}`)
        }else{
            window.history.back()
        }
    }



    return {show, setShow: setShowHandle}
}