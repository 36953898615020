import useStyles from "isomorphic-style-loader/useStyles";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Iconfont } from "../../../../../../components/icon/iconfont";
import classes from './style.module.scss';

const SizeChartHead = props => {
    useStyles(classes)
    const { closeSizeChart, history } = props

    const clickCartIcon = () => {
        if(window.location?.pathname === '/cart' || window.location?.pathname?.startsWith('/cart/')){
            history.go(-1)
        } else {
            window.location.href = '/cart'
            return
        }
    }

    return <React.Fragment>
            <div className={classes.SizeChartHead}>
                <Iconfont className={classes.SizeChartClose} onClick={() => closeSizeChart()}>&#xe693;</Iconfont>

                <span className={classes.SizeChartTitle}><FormattedMessage id="size_help"/></span>

                <Iconfont className={classes.CartIcon} onClick={() => clickCartIcon()}>&#xe78e;</Iconfont>
            </div>
    </React.Fragment>
}

export default SizeChartHead