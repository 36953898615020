import React, { useState, useEffect } from "react";
import classes from "./style.module.scss";
import useStyles from "isomorphic-style-loader/useStyles";
import Cache from "../../../../../../utils/cache";
import { getByProductIds } from "../../../../../../api";
import { FormattedMessage } from "react-intl";
import SwiperProducts from "../../../../../pages/moduleable/modules/list/components/swiper-products";
import ReactDOM from "react-dom";
import BottomToTop from "../../../../../components/transitions/bottom-to-top";
import { BlackMask } from "../../../../../components/mask";
import { Iconfont } from "../../../../../components/icon/iconfont";
import { Products } from "../../../../../pages/listing/components/list";


const RecentlyWrapped = props =>{
    const { onClose, products, sensors } = props;

    return <div className={classes.RecentlyWrapped}>
        <div className={classes.RecentlyHd}>
            <span className={classes.Font}><FormattedMessage id="recently_viewed" defaultMessage="Recently Viewed" /></span>

            <Iconfont className={classes.CloseIcon} onClick={onClose}>&#xe69a;</Iconfont>
        </div>

        <div className={classes.RecentlyBd}>
            <Products 
                products={products} 
                ingoreCard 
                sensors={sensors} 
            />
        </div>
    </div>
}

const WrappedEditor = props =>{
    const { show, onClose } = props;

    return <React.Fragment>
        <BottomToTop in={show} className={classes.RecentlyWrappedEditor}>
            <div>
                <RecentlyWrapped {...props} />
            </div>
        </BottomToTop>
        
        {
            show && <BlackMask onClick={onClose} />
        }
    </React.Fragment>
}

const RecentlyViewdModal = props => {
    if (typeof window === 'undefined')
        return null

    return ReactDOM.createPortal(
        <WrappedEditor {...props} />,
        document.getElementById('root')
    )
}


const RecentlyViewedProducts = props =>{
	useStyles(classes)
	const [products, setProducts] = useState([]);
	const [show, setShow] = useState(false);
    const [showModal, setShowModal] = useState(false);

	useEffect(async () =>{
        try {
            let productIds = Cache.get("recently_view_products");
            if(productIds?.length > 0){
                setShow(true);
                let products = await getByProductIds({ productIds: productIds.join(",") }).then(response=>{
                    const productItems = response?.result?.map(p => ({
                        ...p,
                        geekoRequsestId: response.requestId,
                        geekoExperimentId: response.experimentId,
                        layerName: response.layerName,
                        trackingRecords: response.trackingRecords
                    }))
                    return productItems || [];
                }).catch(error=>[]);
                setProducts(preValue => [...preValue, ...products]);
            }
        } catch (error) {
			setShow(false);
            console.log('error', error);
        }
    },[]);

	return <React.Fragment>
		{
			show && <React.Fragment>
                    <div className={classes.RecentlyViewedProducts}>
                        <div className={classes.Hd}>
                            <span onClick={()=>setShowModal(true)}>
                                <FormattedMessage id="recently_viewed" defaultMessage="Recently Viewed" />{' >'}
                            </span>
                        </div>
                        
                        <div className={classes.Bd}>
                            <SwiperProducts 
                                products={products} 
                                sensors={{
                                    resourcepage_title: "search",
                                    resource_position: "1",
                                    resource_content: "recently view",
                                    resource_type: "25",
                                }}
                            />
                        </div>
                    </div>

                <RecentlyViewdModal 
                    products={products} 
                    show={showModal} 
                    onClose={()=>setShowModal(false)} 
                    sensors={{
                        resourcepage_title: "search",
                        resource_position: "1",
                        resource_content: "recently view",
                        resource_type: "25",
                    }}
                />
            </React.Fragment>
            
		}
	</React.Fragment>
}

export default RecentlyViewedProducts;