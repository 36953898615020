import React from 'react'
import classes from './style.module.scss'
import {Iconfont} from "../../../../components/icon/iconfont";
import useStyles from 'isomorphic-style-loader/useStyles'

export default props => {

	useStyles(classes)

	const { data } = props

	return <div className={classes.Select}>
		<Iconfont className={classes.Icon} dangerouslySetInnerHTML={{__html: props.icon}}>

		</Iconfont>
		<span className={classes.Label} >
			{props.label}
		</span>
		<span className={classes.Value}>
			{
				props?.data?.find(v => v?.value === props.value)?.label || props.value
			}
		</span>
		<select onChange={props?.onChange} value={props.value}>
			{
				data?.map(item => <option value={item.value}>{item.label}</option>)
			}
		</select>
	</div>
}