import React, { useState, useCallback, createRef } from 'react'
import useStyles from 'isomorphic-style-loader/useStyles'
import classes from '../style.module.scss'
import PlaceHolder from '../placeholder'
import Label from '../label'


export default props => {
    useStyles(classes)
    const [focused, setFocused] = useState(false)
    const { placeholder, title, required, onChange, value, limit, error } = props

    const handleClick = useCallback(e => {
        e.preventDefault()
        setFocused(true)
    }, [])

    const handleBlur = useCallback(e => {
        e.preventDefault()
        setFocused(false)
    }, [])


    const changeHandler = useCallback(e => {
        e.preventDefault()
        const v = e.target.value
        if(v.length > limit && limit > 0) {
            onChange(v.substr(0, limit))
        }else{
            onChange(v)
        }
    }, [])



    const showInput = value || focused

    return <div className={classes.Input} onClick={handleClick}>
        <div>
            <Label title={title} required={required} />
        </div>

        {
            showInput && <input autoFocus={focused} value={value} onChange={changeHandler} onBlur={handleBlur} />
        }

        {
            !showInput && !error && <div>
                <PlaceHolder title={placeholder} />
            </div>
        }

        {
            error && <div className={classes.ErrorInfo}>{error}</div>
        }

        {
            limit && <div className={classes.Limit}>
                {value?.length}/{limit}
            </div>
        }

    </div>
}