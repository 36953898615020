import React from 'react'
import withStyles from 'isomorphic-style-loader/withStyles'
import useStyles from 'isomorphic-style-loader/useStyles'
import classes from './style.module.scss'
import { fetchGetMessage } from '../../../../../../api'
import { BigButton } from '../../../../../components/button'
import { FormattedMessage } from 'react-intl'


const Empty = props => {
    useStyles(classes)
    return <div className={classes.Empty}>
        <div className={classes.Element}/>
        <div className={classes.Element}/>
        <div className={classes.Element}/>
        <div className={classes.Element}/>
        <div className={classes.Element}/>
        <div className={classes.Element}/>
        <div className={classes.Element}/>
        <div className={classes.Element}/>
        <div className={classes.Element}/>
        <div className={classes.Element}/>
        <div className={classes.Element}/>
        <div className={classes.Element}/>
        <div className={classes.Element}/>
        <div className={classes.Element}/>
        <div className={classes.Element}/>
        <div className={classes.Element}/>
    </div>
}


export default withStyles(classes)(class extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            left: 5,
            html: null
        }
    }

    async componentDidMount() {
        this.timer = setInterval(() => {
            this.setState({
                left: this.state.left - 1
            })
        }, 1000)
        const response = await fetchGetMessage('M1651')
        if (response?.code === 200) {
            this.setState({
                html: response?.result?.message
            })
        }
    }

    componentWillUnmount(){
        clearInterval(this.timer)
    }

    nextStepHandler(e){
        e.preventDefault()
        this.props.onContinue()
    }

    render() {

        const ready = this.state.left < 1

        return <div className={classes.Container}>

            {
                this.state.html ? <div className={classes.Text} dangerouslySetInnerHTML={{ __html: this.state.html }} /> : <Empty/>
            }
           
            <div className={classes.Button}>
                <BigButton disabled={!ready} onClick={this.nextStepHandler.bind(this)}>
                    {
                        !ready ? `${this.state.left}S` : <FormattedMessage id="next_step" defaultMessage="Next Step" />
                    }
                </BigButton>
            </div>
        </div>
    }
})