import React, {useEffect, useState} from 'react'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import { getSensorsUrl } from '../../../../utils/sensor'
import { getAddOnDelPrice, getAddOnLowerPrice, getDelPrice, getGiftPrice, getLowerPrice, getProductUrl, isPromotion } from '../../../../../utils/product'
import { IMAGE_GEEKO_LTD } from '../../../../../constants'
import Money from '../../../shoppingcart/pages/collect-order/components/money'
import { Iconfont } from '../../../../components/icon/iconfont'
import CartIcon from '../cart/cart-icon'
import withItemScroll from '../../../../hocs/item-scroll'
import sourceOnlyClick from '../../../../hocs/source-only-click'
import { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'


export const ProductScore = props =>{
    const {starScore} = props;
    return (
        <React.Fragment>
            <div className={classes.StarScore}>
                {
                    ((starScore && starScore > 0) || starScore == 0) && 
                    <ol className={`${classes.PStars}`}>
                        {
                            [1,2,3,4,5].map((item,index) => {
                                if(starScore > index){
                                    return starScore < index + 1 ? (<li className={classes.Half} key={item}></li>) : (<li className={classes.Active} key={item}></li>);
                                }else{
                                    return (<li key={item}></li>)
                                }
                            })
                        }
                    </ol>
                }
            </div>
        </React.Fragment>
    )
}

export const RowItem = withItemScroll(props => {
    useStyles(classes)
    const {product_type, giftInfo, sensors, similar, column, position, product, innerRef, onSelect, addOnMsg, isAddOn, deleteItemHandle, afterAddToCart, beforeAddToCart} = props
	const [showSelect, setShowSelect] = useState()

	const [lowerPrice, delPrice, isProm, giftPrice] = [
		isAddOn? getAddOnLowerPrice(product): getLowerPrice(product),
		isAddOn? getAddOnDelPrice(product): getDelPrice(product),
		isPromotion(product),
		getGiftPrice(product)
	]

	let off = 0
	if (delPrice) {
		off = Math.round((Number(delPrice?.amount) - Number(lowerPrice?.amount)) * 100 / Number(delPrice?.amount))
	}

	const promotionType = isProm ? product.promotion.type : ''
	const isGift = !!giftInfo && !isAddOn

	const { differentColorStyleVariants } = product
	const [selectedColor, setSelectedColor] = useState(differentColorStyleVariants?.find(v => v.productId === product.id))

	const productUrl = useMemo(() => {
		let url = getProductUrl(product?.id, product?.name)
		if(isGift){
			if(url?.indexOf("?") == -1){
				url += '?product_type=gift'
			} else {
				url += '&product_type=gift'
			}
		} else if(isAddOn){
			if(url?.indexOf("?") == -1){
				url += '?product_type=addOn'
			} else {
				url += '&product_type=addOn'
			}
		}
		return url
	}, [product, isGift, isAddOn])

	useEffect(() => {
		if(product?.selected){
			setShowSelect(true)
		} else {
			setShowSelect(false)
		}
	}, [product])

	const preventClickHandle = e => {
		e.preventDefault()
		e.stopPropagation()
	}

    return <React.Fragment>
        <div className={classes.RowItem} 
			data-column={column}
            ref={innerRef}
			data-product-position={position}
			data-position={sensors?.resource_position}
			data-type={sensors?.resource_type}
			data-content={sensors?.resource_content}
			product-id={product?.id}
			data-filter={sensors?.filter}
			data-price={product?.usdPrice?.amount}
			data-title={sensors?.title}
			data-resource-title={sensors?.resourcepage_title}
			data-price-tag={sensors?.price_tag}
			onClick={(e) => {
				e?.preventDefault()
				window.location.href = getSensorsUrl(
					productUrl,
					{
						resourcepage_title: sensors?.resourcepage_title,
						resource_position: sensors?.resource_position,
						resource_type: sensors?.resource_type,
						resource_content: sensors?.resource_content,
						product_position: position,
						product_id: product?.id,
						price: product?.usdPrice?.amount,
						filter: sensors?.filter,
						column: column,
						title: sensors?.title,
						price_tag: sensors?.price_tag,
					}
				)
			}}
			>
			<div >
				<div className={classes.ImageBox}>
					<a style={{ display: 'block', height: "100%"}}>
						<img className={classes.Image} src={`${IMAGE_GEEKO_LTD}/medium/${product?.pcMainImage}`} />

						{
							off > 0 && !isGift &&
							<span className={classes.OffTip}>
								<span className={classes.Number}>-{off}%</span>
							</span>
						}
					</a>
				</div>

			</div>

			<div className={classes.ProductPrice}>

				<div>
					<p className={classes.TxtEllipsis}>
						{product?.name}
					</p>

					<div className={classes.PriceBox}>
						<span className={classes.MoneyBox}>
							<Money money={isGift? giftPrice: lowerPrice} />
						</span>

						{
							delPrice ?
							<del className={classes.DelBox}>
								<Money money={delPrice} />
							</del>:
							<React.Fragment></React.Fragment>
						}
					</div>

					{
						product?.makeOrderReminder &&
						<div className={classes.AddonMsg}>
							<span dangerouslySetInnerHTML={{__html: product?.makeOrderReminder}}></span>
						</div>
					}
				</div>

				<div className={classes.ReviewBox}>
					<div className={classes.Review}>
						<ProductScore starScore={product.reviewAverageScore || 0}/>
						<span>
							{"(" + (product.reviews || 0) + ")"}
						</span>
					</div>

					<div onClick={preventClickHandle}>
						{
							showSelect ?
							<div className={classes.ProductSelected} onClick={() => deleteItemHandle(product, () => {setShowSelect(false)})}>
								<FormattedMessage id={"selected"} defaultMessage={"selected"}/>
							</div>:
							<CartIcon
								sensors={{
									layerName: product?.layerName,
									promotionType,
									trackingRecords: product?.trackingRecords,
									dataSource: product?.dataSource,
									geekoExperimentId: product?.geekoExperimentId,
									geekoRequsestId: product?.geekoRequsestId,
									aliRequestId: product?.aliRequestId,
									aliExperimentId: product?.aliExperimentId,
									product_position: position + 1,
									...sensors
								}}
								isGift={isGift}
								isAddOn={isAddOn}
								giftInfo={giftInfo}
								product_type={product_type}
								productId={selectedColor ? selectedColor.productId : product.id}
								color={selectedColor ? selectedColor.color : product?.variants?.[0]?.color}
								callBack={() => {
									setShowSelect(true)
									// 把产品放到第一位
									afterAddToCart && afterAddToCart?.(product)
								}}
								beforeAddToCart={beforeAddToCart}
								/>
						}
					</div>
				</div>
			</div>
		</div>

    </React.Fragment>
})

export const RowItemOnlyClick = sourceOnlyClick(props => {
    useStyles(classes)
    const {product_type, giftInfo, sensors, similar, column, position, product, innerRef, onSelect, isAddOn, } = props

	const [lowerPrice, delPrice, isProm, giftPrice] = [
		isAddOn? getAddOnLowerPrice(product): getLowerPrice(product),
		isAddOn? getAddOnDelPrice(product): getDelPrice(product),
		isPromotion(product),
		getGiftPrice(product)
	]

	const promotionType = isProm ? product.promotion.type : ''
	const isGift = !!giftInfo && !isAddOn

	const { differentColorStyleVariants } = product
	const [selectedColor, setSelectedColor] = useState(differentColorStyleVariants?.find(v => v.productId === product.id))

	let off = 0
	if (delPrice) {
		off = Math.round((Number(delPrice?.amount) - Number(lowerPrice?.amount)) * 100 / Number(delPrice?.amount))
	}

	const productUrl = useMemo(() => {
		let url = getProductUrl(product?.id, product?.name)
		if(isGift){
			if(url?.indexOf("?") == -1){
				url += '?product_type=gift'
			} else {
				url += '&product_type=gift'
			}
		} else if(isAddOn){
			if(url?.indexOf("?") == -1){
				url += '?product_type=addOn'
			} else {
				url += '&product_type=addOn'
			}
		}
		return url
	}, [product, isGift, isAddOn])

    return <React.Fragment>
        <div className={classes.RowItem}
			data-column={column}
            ref={innerRef}
			data-product-position={position}
			data-position={sensors?.resource_position}
			data-type={sensors?.resource_type}
			data-content={sensors?.resource_content}
			data-product-id={product?.id}
			data-filter={sensors?.filter}
			data-price={product?.usdPrice?.amount}
			data-title={sensors?.title}
			data-resource-title={sensors?.resourcepage_title}
			data-price-tag={sensors?.price_tag}
			>
			<div >
				<div className={classes.ImageBox}>
					<a style={{ display: 'block' }} 
					   onClick={() => {
							window.location.href = getSensorsUrl(
								productUrl,
								{
									resourcepage_title: sensors?.resourcepage_title || 'cart',
									resource_position: sensors?.resource_position,
									resource_type: sensors?.resource_type,
									resource_content: sensors?.resource_content,
									product_position: position,
									product_id: product?.id,
									price: product?.usdPrice?.amount,
									filter: sensors?.filter,
									column: column,
									title: sensors?.title,
									price_tag: sensors?.price_tag,
								}
							)
						}}>
						<img className={classes.Image} src={`${IMAGE_GEEKO_LTD}/medium/${product?.pcMainImage}`} />
					</a>
				</div>

			</div>

			<div className={classes.ProductPrice}>

				<div>
					<p className={classes.TxtEllipsis}>
						{product?.name}
					</p>

					<div className={classes.PriceBox}>
						<span className={classes.MoneyBox}>
							<Money money={isGift? giftPrice: lowerPrice} />
						</span>

						{
							delPrice ?
							<del className={classes.DelBox}>
								<Money money={delPrice} />
							</del>:
							<React.Fragment></React.Fragment>
						}
					</div>
				</div>

				<div className={classes.ReviewBox}>
					<div className={classes.Review}>
						<ProductScore starScore={product.reviewAverageScore || 0}/>
						<span>
							{"(" + (product.reviews || 0) + ")"}
						</span>
					</div>

					<CartIcon
						sensors={{
							layerName: product?.layerName,
							promotionType,
							trackingRecords: product?.trackingRecords,
							dataSource: product?.dataSource,
							geekoExperimentId: product?.geekoExperimentId,
							geekoRequsestId: product?.geekoRequsestId,
							aliRequestId: product?.aliRequestId,
							aliExperimentId: product?.aliExperimentId,
							product_position: position + 1,
							...sensors
						}}
						isGift={isGift}
						isAddOn={isAddOn}
						giftInfo={giftInfo}
						product_type={product_type}
						productId={selectedColor ? selectedColor.productId : product.id}
						color={selectedColor ? selectedColor.color : product?.variants?.[0]?.color}
						/>
				</div>
			</div>
		</div>

    </React.Fragment>
})

export const RowItemSkelton = props => {
    useStyles(classes)

    return <React.Fragment>
        <div className={`${classes.RowItem} ${classes.RowItemSkelton}`}>
			<div>
				<div className={classes.ImageBox}>
					<a style={{ display: 'block' }}>
						<div style={{background:'#efefef'}}></div>
					</a>
				</div>

			</div>

			<div className={classes.ProductPrice}>
				<div>
					<div style={{background:'#efefef', width: '100%', height: '16px'}}></div>
					<div style={{background:'#efefef', width:'50%', height:'19px',marginTop:'10px'}}></div>
				</div>

				<div className={classes.ReviewBox}>
					<div className={classes.Review}>
						<ProductScore starScore={0}/>
						<span>
							{"(" + 0 + ")"}
						</span>
					</div>

					<div className={classes.CartIcon}>
						<Iconfont>&#xe6a8;</Iconfont>
					</div>
				</div>
			</div>
		</div>

    </React.Fragment>
}