import React, { useRef, useState, useCallback, createRef, useEffect } from "react"
import useStyles from "isomorphic-style-loader/useStyles"
import classes from "./style.module.scss";
import RightToLeft from "../../../../components/transitions/right-to-left";
import { fetchProductBestSellers, fetchCategory } from "../../../../../api";
import { getLowerPrice, getDelPrice, unitPrice, getProductUrl } from "../../../../../utils/product";
import { FormattedMessage } from "react-intl";
import ProductShare from "../../components/product-share/index";
import { useSelector } from "react-redux";
import withItemScroll from '../../../../hocs/item-scroll'
import { getSensorsUrl } from "../../../../utils/sensor";
import { Iconfont } from "../../../../components/icon/iconfont";
import { IMAGE_GEEKO_LTD } from "../../../../../constants";
import ReactDOM from 'react-dom'
import { BuyIcon } from "../../../listing/components/cart/cart-icon";
import Alert from "../../../../../client/components/alert/index";


const getRankIndexImage = (index) =>{
    switch (index){
        case 0:
            return 'https://image.geeko.ltd/chicme/20220720/No.3.svg'
        case 1:
            return 'https://image.geeko.ltd/chicme/20220720/No.2.svg'
        case 2:
            return 'https://image.geeko.ltd/chicme/20220720/No.1.svg'
        default:
            return null
    }
}

const ProductRankItem = withItemScroll(props =>{
    useStyles(classes);
    const { product, position, innerRef, sensors, column, promotionType, isCartIcon } = props;

    const rankImageSrc = getRankIndexImage(position);

    const lower = getLowerPrice(product);
    const higher = getDelPrice(product);

    let off = 0
    if (higher) {
        off = Math.round((Number(higher.amount) - Number(lower.amount)) * 100 / Number(higher.amount))
    }

    const handleProductClick = e => {
		e.preventDefault()
        if(!isCartIcon){
            const href = e.currentTarget?.href
            window.location.href = getSensorsUrl(href, {
                resourcepage_title: sensors?.resourcepage_title,
                resource_type: sensors?.resource_type,
                resource_content: sensors?.resource_content,
                resource_position: sensors?.resource_position,
                product_position: position + 1,
            })
        }
	}

    const handleProductImageClick = (e, href) =>{
        if(isCartIcon){
            e.preventDefault()
            e.stopPropagation()
            window.location.href = getSensorsUrl(href, {
                resourcepage_title: sensors?.resourcepage_title,
                resource_type: sensors?.resource_type,
                resource_content: sensors?.resource_content,
                resource_position: sensors?.resource_position,
                product_position: position + 1,
            });
        }
    }

    return (
        <a 
            className={classes.ProductRankItem} 
            href={getProductUrl(product?.id, product?.name)} 
            ref={innerRef}
			product-id={product?.id}
			data-product-list-source
			data-promotion={promotionType}
			data-layer={product?.layerName}
			data-tracking={product.trackingRecords}
			data-product-source={product.dataSource}
			data-geeko-experiment={product.geekoExperimentId}
			data-geeko-id={product.geekoRequsestId}
			data-request-id={product.aliRequestId}
			data-experiment-id={product.aliExperimentId}
			data-column={column}
			data-product-position={position + 1}
			data-position={sensors?.resource_position}
			data-type={sensors?.resource_type}
			data-content={sensors?.resource_content}
			data-title={sensors?.resourcepage_title}
            onClick={handleProductClick}
        >
            <div className={classes.ProductRankCo}>
                <div>
                    <div className={classes.Image} onClick={(e) =>handleProductImageClick(e, getProductUrl(product?.id, product?.name))}>
                        <img className={classes.NormalImage} src={`${IMAGE_GEEKO_LTD}/medium/${product.pcMainImage}`} alt={`RankProduct${position}`} />
                    
                        {
                            rankImageSrc && <img className={classes.RankImage} src={rankImageSrc} alt={`NO${position}`} />
                        }
                    </div>
                </div>

                <div>
                    <div className={classes.RightBasicInfo}>
                        <div className={classes.Name}>{product.name}</div>
                        
                        <div className={classes.PriceContainer}>
                            <div className={classes.Prices}>
                                <span className={classes.RealPrice}>{unitPrice(lower)}</span>

                                {
                                    higher && <del className={classes.DealPrice}>{unitPrice(higher)}</del>
                                }
                                {
                                    off > 0 && <span className={classes.DiscountPercent}>{`-${off}%`}</span>
                                }
                            </div>

                            <div className={classes.LinkTo}>
                                {
                                    isCartIcon ? <React.Fragment>
                                        <BuyIcon
                                            sensors={sensors}
                                            render={() => <Iconfont className={classes.CartIcon}>&#xe6a8;</Iconfont>}
                                            productId={product.id} 
                                            color={product?.variants?.[0]?.color} 
                                        />
                                    </React.Fragment> : <React.Fragment>
                                        <span className={classes.RightCon}>
                                            <Iconfont className={classes.RightIcon}>&#xe690;</Iconfont>
                                        </span>
                                    </React.Fragment>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </a>
    )
})

const ProductRankModalItem = props =>{
    useStyles(classes);
    const { productCategoryId, onClose, productCategoyName, selectedProduct, sensors, isCartIcon = false } = props;
    const [rankProduct,setRankProducts] = useState(null);
    const [loading,setLoding] = useState(false);
    const [finished, setFinished] = useState(false);
    const [show,setShow] = useState(false);
    const [categoryName, setCategoryName] = useState(productCategoyName);
    const [showModal,setShowModal] = useState(false);
    const rankContainerRef = useRef();
    const rankRef = useRef();
    const global = useSelector(state=>state.global);
    const { config } = global;
    
    useEffect(async () =>{
        if(productCategoryId){
            setLoding(true);
            let products = await fetchProductBestSellers(productCategoryId).then((data => data.result)).catch((e) =>{
                console.log(e.result);
                return [];
            });
            setLoding(false);
            setRankProducts(products);
            setFinished(products?.length <= 0);
        }
    },[productCategoryId]);

    useEffect(async () =>{
        if(!productCategoyName && productCategoryId){
            let response = await fetchCategory(productCategoryId);
            if(response?.code === 200 && response?.result){
                setCategoryName(response.result?.name);
            } else {
                response?.result && Alert(response.result);
            }
        }
    }, []);

    useEffect(()=>{
        rankContainerRef.current.addEventListener('scroll', scrollHandle)
        return () => {
            rankContainerRef.current.removeEventListener('scroll', scrollHandle)
        }
    },[]);

    const scrollHandle = useCallback(evt => {
        const rect = rankRef.current.getBoundingClientRect()
        if (rect.top <= 44) {
            setShow(true);
        } else {
            setShow(false);
        }
    }, [])

    return (
        <div className={classes.ProductRankModalItem}>
            <div className={classes.RankHd} style={{backgroundImage:`url(${show?"https://image.geeko.ltd/chicme/20220720/bg-slide.png":"none"})`}}>
                <span className={classes.Font}><FormattedMessage id="best_sellers" defaultMessage="Best Sellers" /></span>
                <Iconfont className={`${classes.Icon}`} onClick={onClose}>&#xe693;</Iconfont>

                {
                    isCartIcon ? <React.Fragment>
                        <a href={'/cart'} className={classes.HeaderCart}>
                            <Iconfont className={classes.bigger}>&#xe6a4;</Iconfont>
                            {
                                global?.countCart > 0 && <span className={classes.Num}>{global.countCart}</span>
                            }
                        </a>
                    </React.Fragment> : <React.Fragment>
                        <span className={`${classes.ShareIcon}`} onClick={()=>setShowModal(true)}></span>
                    </React.Fragment>
                }
            </div>

            <div className={classes.ProductRankModalContainer} ref={rankContainerRef}>
                <div className={classes.BgContainer}>
                    <div className={classes.BestSellersName}><FormattedMessage id="best_sellers_in" defaultMessage="Best Sellers in" /> {categoryName || "xxxx"}</div>
                </div>

                <div className={classes.RankBd} ref={rankRef}>
                    <div className={classes.Container}>
                        {
                            rankProduct?.map((item,index) =><React.Fragment>
                                <ProductRankItem 
                                    key={index} 
                                    product={item} 
                                    position={index}
                                    sensors={sensors} 
                                    isCartIcon={isCartIcon} 
                                />
                            </React.Fragment>)
                        }
                    </div>

                    {
                        loading && <div className={classes.Loading}>
                            <FormattedMessage id="loading" defaultMessage="Loading" />
                        </div>
                    }

                    {
                        finished && <div className={classes.Finished}><FormattedMessage id="no_more_data" defaultMessage="No more data" />.</div>
                    }
                </div>
            </div>

            {
                !isCartIcon && <React.Fragment>
                    <ProductShare 
                        showShareModal={showModal} 
                        onClose={()=>setShowModal(false)} 
                        fetchParams={{urlCode: "P01D", params: JSON.stringify({ productId: selectedProduct?.id, showBest: 1 })}}
                        sharePrefix={`${config?.name} ${selectedProduct?.name} `}
                        shareImageurl={`${IMAGE_GEEKO_LTD}/large/${selectedProduct?.id}`}
                    />
                </React.Fragment>
            }
        </div>
    )
}

const WrappedEditor = props =>{
    const { showModal } = props;

    return <React.Fragment>
            <RightToLeft in={showModal}>
                <ProductRankModalItem {...props} />
            </RightToLeft>
        </React.Fragment>
}

export default props =>{
    if (typeof window === 'undefined')
		return null

	return ReactDOM.createPortal(
		<WrappedEditor {...props} />,
		document.getElementById('root')
	)
}