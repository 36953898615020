import { useEffect, useState, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setSizeChart } from '../../store/actions/global'

export default () => {

    const global = useSelector(state => state.global)
    const { sizechart } = global
    const dispatch = useDispatch()
    const eventHandle = useRef()


    eventHandle.current = () => {
        const hash = window.location.hash
        if(hash === `#sizechart`){
            dispatch(setSizeChart({...sizechart, show: true}))
        }else{
            dispatch(setSizeChart({show: false}))
        }
    }

    useEffect(() => {
        window.addEventListener('hashchange', eventHandle.current)
        return () => window.removeEventListener('hashchange', eventHandle.current)
    }, [])


    const setSizeChartHandle = payload => {
        dispatch(setSizeChart(payload))
        if(payload?.show){
            window.history.pushState({}, '', `#sizechart`)
        }else{
            window.history.back()
        }
    }

    return {sizeChart: sizechart, setSizeChart: setSizeChartHandle}
}