import React, { useState } from "react";
import classes from "./style.module.scss";
import useStyles from "isomorphic-style-loader/useStyles";
import ReactDOM from 'react-dom'
import { Formik, Form } from "formik";
import { BigButton } from "../../../../../components/button";
import { useIntl } from "react-intl";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { Iconfont } from "../../../../../components/icon/iconfont";
import RightToLeft from "../../../../../components/transitions/right-to-left";
import EmailInputFiled from "../../input/email-input-filed";
import PhonePasswordInputFiled from "../../input/phone-password-input";
import PhoneCodeInputFiled from "../../input/phone-code-input";
import RegisterMessage from "../../../fragments/register-message";

const VerifyPopoverForm = props =>{
    const { 
        handleRegister, 
        codeComponent, 
        refreshCode, 
        loading, 
        handleLogin, 
        setRestPasswordModal,
        onClose, 
        currentLoginType, 
        localEmail, 
        showLoginCode, 
        showRegister, 
        notCheckVerifyEvent
    } = props;

    const Intl = useIntl();
    const [policyActive, setPolicyActive] = useState(false);
    const [policyError, setPolicyError] = useState(false);

    const privacyPolicyI18n = Intl.formatMessage(
        { id: 'register_privacy_policy' },
        {
            term: `<a href="/policy/terms-conditions-notice">${Intl.formatMessage({id:"term_of_services",defaultMessage:"Terms of Service"})}</a>`,
            privacy: `<a href="/policy/privacy-security-policy">${Intl.formatMessage({id:"privacy_policy",defaultMessage:"Privacy Policy"})}</a>`,
        }
    );

    const submitHandle = async (values, formikBag) =>{
        if (!currentLoginType && !policyActive) {
            setPolicyError(true);

            setTimeout(() => {
                setPolicyError(false);
            }, 500);

            notCheckVerifyEvent('Register', 'email');
            return false;
        }

        formikBag.setSubmitting(true);

        const params = { 
            email: values.email, 
            password: values.password, 
            randcode: values.randCode
        }
        let response;
        if(currentLoginType){
            response = await handleLogin(params);
        }else{
            response = await handleRegister(params);
        }
        if(response){
            onClose();
        }
        
        formikBag.setSubmitting(false);
    }

    return <div className={`${classes.PasswordForm}`}>
        <Formik 
            onSubmit={submitHandle}
            initialValues={{
                email: localEmail,
                password:'', 
                randCode: ''
            }} 
            validate={values => {
                const errors = {}
                let errorFlag = false;

                if(((currentLoginType && showLoginCode) || (!currentLoginType && showRegister)) && !values.randCode){
                    errorFlag = true;
                    errors.randCode = Intl.formatMessage({ id: "captcha_not_empty", defaultMessage: "The CAPTCHA cannot be empty" })
                }
    
                errorFlag && notCheckVerifyEvent?.(currentLoginType ? 'Login' : 'Register', 'email');
                return errors
            }}
            enableReinitialize={true} 
        >
            {
                ({errors, touched, isSubmitting}) =>(
                    <Form className={classes.PhoneRegisterFrom}>
                        <EmailInputFiled 
                            InputProps={{
                                type:"text",
                                name:"email"
                            }} 
                            label={Intl.formatMessage({ id: "email_address", defaultMessage: "Email Address" })} 
                            disabled 
                            onEdit={onClose} 
                        />

                        <PhonePasswordInputFiled 
                            className={classes.MarginTop22} 
                            showRules={!currentLoginType} 
                            InputProps={{
                                type:"password",
                                name:"password"
                            }} 
                        />

                        {
                            currentLoginType ? <React.Fragment>
                                <p className={classes.ForgetPassword} onClick={setRestPasswordModal}><FormattedMessage id="forget_password" defaultMessage="Forget password" />?</p>
                            </React.Fragment> : <React.Fragment>
                                <div className={classes.PolicyContainer}>
                                    <div className={classes.Item1}>
                                        <Iconfont className={`${classes.SelectIcon} ${policyActive ? classes.Active : ""}`} onClick={() => setPolicyActive(flag => !flag)}>&#xe65a;</Iconfont>
                                        <span className={policyError ? classes.Error : ""} dangerouslySetInnerHTML={{ __html: privacyPolicyI18n }}></span>
                                    </div>
                                </div>
                            </React.Fragment>
                        }

                        {
                            ((currentLoginType && showLoginCode) || (!currentLoginType && showRegister)) && 
                            <PhoneCodeInputFiled 
                                InputProps={{
                                    name:"randCode"
                                }}
                                codeComponent={codeComponent}
                                refreshCode={refreshCode}
                            />
                        }

                        <BigButton loading={isSubmitting || loading} className={classes.SubmitButton}>{currentLoginType ? <FormattedMessage id="sign_in" defaultMessage="Sign in" /> : <FormattedMessage id="register" defaultMessage="Register" />}</BigButton>
                    </Form>
                )
            }
        </Formik>
    </div>
}

const VerifyPopoverComponent = props =>{
    const { onClose, currentLoginType, registerDiscount } = props;
    const global = useSelector(state=>state.global);
    const { config } = global;

    return <div className={classes.Container}>
        <div className={classes.AlertHeader}>
            <div className={classes.ImageContainer}>
                <img src={config?.logo} alt="logo" />
            </div>

            <Iconfont className={classes.CloseIcon} onClick={()=>onClose()}>&#xe693;</Iconfont>
        </div> 

        <div className={classes.AlertBody}>
            <div className={classes.RegisterMessage}>
                <RegisterMessage registerDiscount={registerDiscount}  />
            </div>

            {
                currentLoginType ? <React.Fragment>
                    <p className={classes.Title1}><FormattedMessage id="welcome_back" defaultMessage="Welcome Back" />!</p>
                    <p className={classes.Title2}><FormattedMessage id="please_sign_in_account" defaultMessage="Please sign in with your account." /></p>
                </React.Fragment> : <React.Fragment>
                    <p className={classes.Title1}><FormattedMessage id="welcome_to" defaultMessage="Welcome to" /> {config?.name}!</p>
                    <p className={classes.Title2}><FormattedMessage id="please_create_your_account" defaultMessage="Please create your account." /> </p>
                </React.Fragment>
            }

            <VerifyPopoverForm {...props} />
        </div>
    </div>
}

const WrappedVerifyPopover = props => {
    useStyles(classes)
	return <React.Fragment>
		<RightToLeft in={props.show} className={classes.VerifyPopover} unmountOnExit>
			<div>
				<VerifyPopoverComponent {...props} />
			</div>
		</RightToLeft>
	</React.Fragment>
}


export default props => {
    if (typeof window === 'undefined')
		return null

	return ReactDOM.createPortal(
		<WrappedVerifyPopover {...props} />,
		document.getElementById('root')
	)
}