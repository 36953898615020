// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2hNFer9F8iV5v2pFS9jlkk{margin-top:.42667rem}._2hNFer9F8iV5v2pFS9jlkk ._3NirDCFesWf-v3OH1rejzx{height:1.28rem;width:100%;background-color:#222222;border-radius:.05333rem;color:#ffffff;font-family:'Roboto-Medium';font-size:.48rem;line-height:1.28rem;outline:none;box-shadow:none;border:none;text-transform:uppercase;margin-top:.53333rem}\n", "",{"version":3,"sources":["webpack://src/client/pages/login2/components/email-way/style.module.scss","webpack://src/client/base.scss"],"names":[],"mappings":"AAEA,yBACI,oBCCoC,CDFxC,kDAIQ,cCFgC,CDGhC,UAAW,CACX,wBAAyB,CACzB,uBCLgC,CDMhC,aAAc,CACd,2BAA4B,CAC5B,gBCRgC,CDShC,mBCTgC,CDUhC,YAAa,CACb,eAAgB,CAChB,WAAY,CACZ,wBAAyB,CACzB,oBCdgC","sourcesContent":["@import \"../../../../base.scss\";\n\n.LoginContainer{\n    margin-top: actual(16);\n\n    .SubmitButton{\n        height: actual(48);\n        width: 100%;\n        background-color: #222222;\n        border-radius: actual(2);\n        color: #ffffff;\n        font-family: 'Roboto-Medium';\n        font-size: actual(18);\n        line-height: actual(48);\n        outline: none;\n        box-shadow: none;\n        border: none;\n        text-transform: uppercase;\n        margin-top: actual(20);\n    }\n}","$baseFontSize: 75; //基于视觉稿横屏尺寸/10得出的基准font-size\n$IMAGE_GEEKO_LTD: 'https://image.geeko.ltd';\n\n@function actual($px) {\n  @return 2 * $px / $baseFontSize * 1rem;\n}\n\n@function actualPc($px){\n  @return $px / 1920 * 100vw;\n}\n\n@function imageGeekoLtd($url){\n\t@return $IMAGE_GEEKO_LTD + $url;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"LoginContainer": "_2hNFer9F8iV5v2pFS9jlkk",
	"SubmitButton": "_3NirDCFesWf-v3OH1rejzx"
};
module.exports = ___CSS_LOADER_EXPORT___;
