import React from 'react'
import { Switch, Route } from 'react-router-dom'
import './theme.scss'
import './app.scss'
import './swiper.bundle.scss'
import BaseLayout from './layout/base-layout'
// import ListingCollection from './pages/listing/collection'
// import ListCollections from './pages/listing/list-collection'
// import ListingCategory from './pages/listing/category'
// import ListingSearch from './pages/listing/search'
// import ListingSimilar from './pages/listing/similar'
// import Landing from './pages/moduleable/landing'
// import SimpleLanding from './pages/moduleable/simple-landing'
// import Home from './pages/moduleable/home'
// import Details from './pages/details'
// import Gifts from './pages/listing/gifts'
// import Menu from './pages/menus'

// import ListSales from './pages/listing/list-sale'

import Loadable from 'react-loadable'



import { IntlProvider } from 'react-intl'
import { getMessage, getLocale } from '../i18n'

import { useSelector } from 'react-redux'
import listCollectionPaths from './pages/listing/list-collection/paths.json'

// import LoginPage from './pages/login'
// import Exclusive from './pages/listing/exclusive'



import SwiperCore, {
	Pagination,
	Autoplay,
	Virtual
} from 'swiper'


SwiperCore.use([Pagination, Autoplay, Virtual])


const Loading = props => <span style={{position: 'fixed', top: 0}}/>

const Home = Loadable({
	loader: () => import('./pages/moduleable/home'),
	loading: Loading,
})

const Menu = Loadable({
	loader: () => import('./pages/menus'),
	loading: Loading,
})

const ListingCollection = Loadable({
	loader: () => import('./pages/listing/collection'),
	loading: Loading,
})

const ListCollections = Loadable({
	loader: () => import('./pages/listing/list-collection'),
	loading: Loading,
})

const ListSales = Loadable({
	loader: () => import('./pages/listing/list-sale'),
	loading: Loading,
})

const ListingCategory = Loadable({
	loader: () => import('./pages/listing/category'),
	loading: Loading,
})

const ListingSearch = Loadable({
	loader: () => import('./pages/listing/search'),
	loading: Loading,
})

const ListingSimilar = Loadable({
	loader: () => import('./pages/listing/similar'),
	loading: Loading,
})

const ListingSimple = Loadable({
	loader: () => import('./pages/listing/simple-list'),
	loading: Loading,
})

const Landing = Loadable({
	loader: () => import('./pages/moduleable/landing'),
	loading: Loading,
})

const SimpleLanding = Loadable({
	loader: () => import('./pages/moduleable/simple-landing'),
	loading: Loading,
})

const Welcome = Loadable({
	loader: () => import('./pages/moduleable/welcome'),
	loading: Loading,
})

const Details = Loadable({
	loader: () => import('./pages/details'),
	loading: Loading,
})

// const Gifts = Loadable({
// 	loader: () => import('./pages/listing/gifts'),
// 	loading: Loading,
// })

const Exclusive = Loadable({
	loader: () => import('./pages/listing/exclusive'),
	loading: Loading,
})

const ListVideoSales = Loadable({
	loader: () => import('./pages/listing/list-video-sale'),
	loading: Loading,
})

const Live = Loadable({
	loader: () => import('./pages/live'),
	loading: Loading,
})

const LoginPage = Loadable({
	loader: () => import('./pages/login2'),
	loading: Loading,
})


const OrderConfirmAlert = Loadable({
	loader: () => import('./pages/order-confirm-alert'),
	loading: Loading,
})

const NewArrivals = Loadable({
	loader: () => import('./pages/listing/new-arrivals'),
	loading: Loading,
})

const Trending = Loadable({
	loader: () => import('./pages/listing/trending'),
	loading: Loading,
})

// const OrderConfirmAlertIframe = Loadable({
// 	loader: () => import('./pages/order-confirm-alert-iframe'),
// 	loading: Loading,
// })


const Index = props => {

	const global = useSelector((state) => state.global)

	return <IntlProvider messages={getMessage(global.language)} locale={getLocale(global.language)} defaultLocale="en">
		<Switch>
			<Route path={'/order-confirm-alert'} exact component={OrderConfirmAlert}/>
			{/* <Route path={'/order-confirm-alert-iframe'} exact component={OrderConfirmAlertIframe}/> */}
			<BaseLayout>
				<Route path={'/'} exact component={Home} />
		
			    {
					['en', 'es', 'de', 'fr', 'pt', 'da', 'sv', 'nb', 'fi', 'is'].map(lang => <Route path={`/${lang}/`} exact component={Home} />)
				}

				
				<Route path={'/*/i/index'} component={Home} />
				<Route path={'/index'} component={Home} />
				<Route path={'/*/index'} component={Home} />


				<Route path={'/*/category/*/:id.html'} component={ListingCategory} />
				<Route path={'/category/*/:id.html'} component={ListingCategory} />
				<Route path={'/**/i/category/products'} component={ListingCategory} />

				<Route path={'/*/collection/*/:id.html'} component={ListingCollection} />
				<Route path={'/collection/*/:id.html'} component={ListingCollection} />
				<Route path={'/**/i/collection/products'} component={ListingCollection} />

				<Route path={'/simple-list/*'} component={ListingSimple}/>
				<Route path={'/*/simple-list/*'} component={ListingSimple}/>

				<Route path={'/*/similar/:id.html'} component={ListingSimilar} />
				<Route path={'/similar/:id.html'} component={ListingSimilar} />



				<Route path={'/*/w-site/anon/search'} component={ListingSearch} />
				<Route path={'/w-site/anon/search'} component={ListingSearch} />
				<Route path={'/*/i/search/result'} component={ListingSearch} />
				<Route path={'/i/search/result'} component={ListingSearch} />

				<Route path={'/i/black-friday'} component={SimpleLanding} />
				<Route path={'/*/i/black-friday'} component={SimpleLanding} />

				<Route path={'/i/sales'} component={SimpleLanding} />
				<Route path={'/*/i/sales'} component={SimpleLanding} />

				<Route path={'/i/beauty-at-your-command'} component={SimpleLanding} />
				<Route path={'/*/i/beauty-at-your-command'} component={SimpleLanding} />



				<Route path={'/product/:name/:id.html'} component={Details}/>
				<Route path={'/*/product/:name/:id.html'} component={Details}/>
				<Route path={'/pc/product_detail'} component={Details}/>
				<Route path={'/*/pc/product_detail'} component={Details}/>
				<Route path={'/i/menu'} component={Menu}/>
				<Route path={'/*/i/menu'} component={Menu}/>

				{
					listCollectionPaths.map(c => <Route key={c.path} path={c.path} component={ListCollections}/>)
				}

				{
					listCollectionPaths.map(c => <Route key={c.path} path={`/*${c.path}`} component={ListCollections}/>)
				}

				<Route path={'/sales/*'} component={ListSales}/>
				<Route path={'/*/sales/*'} component={ListSales}/>

				<Route path={'/landing-page/*'} component={SimpleLanding}/>
				<Route path={'/*/landing-page/*'} component={SimpleLanding}/>

				{
					['/i/login', '/*/i/login', '/i/register', '/*/i/register'].map(c => <Route key={c} path={c} component={LoginPage}/>)
				}

				{
					['/i/exclusive/products', '/*/i/exclusive/products'].map(path => <Route key={path} path={path} component={Exclusive}/>)
				}

				{
					['/i/new-arrivals', '/*/i/new-arrivals'].map(path => <Route key={path} path={path} component={NewArrivals}/>)
				}

				<Route path={'/living/*'} component={SimpleLanding}/>
				<Route path={'/*/living/*'} component={SimpleLanding}/>

				{
					['/i/welcome', '/*/i/welcome'].map(c => <Route key={c} path={c} component={Welcome}/>)
				}

				{
					['/i/live', '/*/i/live'].map(c => <Route key={c} path={c} component={Live}/>)
				}
				{
					['/i/trending-now', '/*/i/trending-now'].map(path => <Route key={path} path={path} component={Trending}/>)
				}
			</BaseLayout>
		</Switch>


		<div id="alert-container"></div>
		<div id="confirm-container"></div>
		<div id="toast-container"></div>


	</IntlProvider>

}


export default Index