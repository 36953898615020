import React, { useCallback, useEffect, useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { IMAGE_GEEKO_LTD } from '../../../../../constants/index'
import classes from './style.module.scss'
import deep, { getAppLink } from '../../../../../utils/deeplink'
import { getLowerPrice, unitPrice, getDelPrice, isFlashSales } from '../../../../../utils/product'
import useStyles from 'isomorphic-style-loader/useStyles'
import withSourceScroll from '../../../../hocs/source-scoll'
import withSourceOnlyScroll from '../../../../hocs/source-only-scoll'
import { useApp } from '../../../../hooks/common'
import { FullSwiper } from '../../../moduleable/components/swiper'
import { getSensorsUrl } from '../../../../utils/sensor'
import { useRef } from 'react'
import _ from 'lodash'
import { FormattedMessage } from 'react-intl'
import { useMemo } from 'react'
import { withCartIcon } from '../cart/cart-icon'
import { Iconfont } from '../../../../components/icon/iconfont'
import RankingList from '../../../details/fragments/ranking-list'
import useOpenWindow from '../../../../hooks/open-window'
import CountDown from '../../../../components/count-down/count-down'

const clickAHandle = (e, sensors) => {
    e.preventDefault()
    const href = e.currentTarget?.href
    window.location.href = getSensorsUrl(href, sensors)
}

// {
//     resourcepage_title: sensors?.title,
//     resource_type: '1',
//     resource_content: data?.refId,
//     resource_position: sensors?.position,
// }

export const SmartType2 = withSourceScroll(props => {
    useStyles(classes)
    const { smartCard, innerRef, position } = props
    const { products, title, titleColor, backgroundImage, deepLink, enTitle } = smartCard

    const isApp = useApp()
    const url = isApp ? getAppLink(deepLink) : deep(deepLink)


    return <div className={`${classes.NormalItem} ${classes.SmartType2}`}>
        <a href={url} ref={innerRef}
            data-position={position + 1}
            data-type="1"
            data-content={enTitle}
            data-title="list"
            onClick={e => clickAHandle(e, {
                resourcepage_title: 'list',
                resource_type: '1',
                resource_content: enTitle,
                resource_position: position + 1,
            })}
        >
            <div className={classes.SmartCard2} style={{
                backgroundImage: `url(${backgroundImage})`
            }}>
                <div className={classes.Bd}>
                    <FullSwiper
                        items={products}
                        options={{
                            pagination: {
                                clickable: true,
                                bulletClass: classes.Bullet,
                                bulletActiveClass: classes.BulletActive
                            },
                            loop: true,
                            autoplay: {
                                disableOnInteraction: false,
                                delay: 2000,
                            }
                        }}
                        render={(product, index) => <div>
                            <div className={classes.Image} key={product.id + index}>
                                <img src={`${IMAGE_GEEKO_LTD}/medium/${product?.pcMainImage}`} />
                            </div>
                        </div>}
                    >
                    </FullSwiper>
                </div>

                <div className={classes.Hd}>
                    <span style={{color: titleColor || '#222'}}  dangerouslySetInnerHTML={{__html:title}}></span>
                </div>
            </div>
        </a>
    </div>
})

export const HSmartType2 = withSourceScroll(props => {
    useStyles(classes)
    const { smartCard, innerRef, position } = props
    const { products, title, titleColor, horizontalBackgroundImage, deepLink, enTitle } = smartCard

    const isApp = useApp()
    const url = isApp ? getAppLink(deepLink) : deep(deepLink)


    return <div className={`${classes.HSmartType2}`}>
        <a href={url} ref={innerRef}
            data-position={position + 1}
            data-type="1"
            data-content={enTitle}
            data-title="list"
            onClick={e => clickAHandle(e, {
                resourcepage_title: 'list',
                resource_type: '1',
                resource_content: enTitle,
                resource_position: position + 1,
            })}
        >
            <div className={classes.HSmartCard2}>
                <div className={classes.Bd}>
                    <FullSwiper
                        items={products}
                        options={{
                            pagination: {
                                clickable: true,
                                bulletClass: classes.Bullet,
                                bulletActiveClass: classes.BulletActive
                            },
                            loop: true,
                            autoplay: {
                                disableOnInteraction: false,
                                delay: 2000,
                            }
                        }}
                        render={(product, index) => <div>
                            <div className={classes.Image} key={product.id + index}>
                                <img src={`${IMAGE_GEEKO_LTD}/medium/${product?.pcMainImage}`} />
                            </div>
                        </div>}
                    >
                    </FullSwiper>
                </div>
                

                <div className={classes.Hd} style={{
                    backgroundImage: `url(${horizontalBackgroundImage})`
                }}>
                    <div className={classes.Title}>
                        <span style={{color: titleColor || '#222'}} dangerouslySetInnerHTML={{__html:title}}></span>
                    </div>
                    <div>
                        <span className={classes.ShopNow}>
                            <FormattedMessage id="shop_now"/>
                        </span>
                    </div>
                </div>
            </div>
        </a>
    </div>
})

export const SmartType1 = withSourceScroll(props => {
    useStyles(classes)
    const { smartCard, innerRef, position } = props
    const { products, title, titleColor, backgroundImage, deepLink, enTitle } = smartCard

    const isApp = useApp()
    const url = isApp ? getAppLink(deepLink) : deep(deepLink)


    return <div className={classes.NormalItem}>
        <a href={url} ref={innerRef}
            data-position={position + 1}
            data-type="1"
            data-content={enTitle}
            data-title="list"
            onClick={e => clickAHandle(e, {
                resourcepage_title: 'list',
                resource_type: '1',
                resource_content: enTitle,
                resource_position: position + 1,
            })}
        >
            <div className={classes.SmartCard} style={{
                backgroundImage: `url(${backgroundImage})`
            }}>
                <div className={classes.Hd}>
                    <span style={{color: titleColor || '#222'}} dangerouslySetInnerHTML={{__html:title}}></span>
                </div>
                <div className={classes.Bd}>
                    {
                        products?.slice(0, 4)?.map(product => <div key={product.id}>
                            <div className={classes.Image}>
                                <LazyLoadImage src={`${IMAGE_GEEKO_LTD}/small/${product?.pcMainImage}`} />
                            </div>
                            <div className={classes.Price}>
                                {unitPrice(getLowerPrice(product))}
                            </div>
                        </div>)
                    }
                </div>
            </div>
        </a>
    </div>
})


const FlashImage = props => {
    useStyles(classes)
    const { products, position } = props
    const [selectedProduct, setSelectedProduct] = useState(products?.[0])
    const opacityIndex = useRef(-2)
    const timing = useRef()
    const timeout = useRef()

    useEffect(() => {

        timeout.current = setTimeout(() => {
            timing.current = setInterval(() => {
                if (opacityIndex.current < products?.length - 2) {
                    opacityIndex.current = opacityIndex.current + 1
                    if (opacityIndex.current >= -1) {
                        setSelectedProduct(products[opacityIndex.current + 1])
                    }
                } else {
                    opacityIndex.current = -2
                }
            }, 1500)
        }, position * 100)

        return () => {
            clearInterval(timing.current)
            clearInterval(timeout.current)
        }
    }, [])

    return <div>
        <div className={classes.Image}>
            {
                products?.map((product, index) => <img className={opacityIndex.current >= index ? classes.Transparent : ''} key={`${product?.id}-${index}`} style={{ zIndex: products?.length - index }} src={`${IMAGE_GEEKO_LTD}/small/${product?.pcMainImage}`} />)
            }
        </div>
        <div className={classes.Price}>
            {unitPrice(getLowerPrice(selectedProduct))}
        </div>
    </div>
}

export const SmartType3 = withSourceScroll(props => {
    useStyles(classes)
    const { smartCard, innerRef, position } = props
    const { products, title, titleColor, backgroundImage, deepLink, enTitle } = smartCard

    const isApp = useApp()
    const url = isApp ? getAppLink(deepLink) : deep(deepLink)

    const groupedProducts = _.reduce(products, function (result, p, index) {
        result[index % 4].push(p)
        return result
    }, [[], [], [], []])


    return <div className={classes.NormalItem}>
        <a href={url} ref={innerRef}
            data-position={position + 1}
            data-type="1"
            data-content={enTitle}
            data-title="list"
            onClick={e => clickAHandle(e, {
                resourcepage_title: 'list',
                resource_type: '1',
                resource_content: enTitle,
                resource_position: position + 1,
            })}
        >
            <div className={classes.SmartCard} style={{
                backgroundImage: `url(${backgroundImage})`
            }}>
                <div className={classes.Hd}>
                    <span style={{color: titleColor || '#222'}} dangerouslySetInnerHTML={{__html:title}}></span>
                </div>
                <div className={classes.Bd}>
                    {
                        groupedProducts?.map((ps, index) => <FlashImage position={index} key={index} products={ps} />)
                    }
                </div>
            </div>
        </a>
    </div>
})

const Type4Link = withSourceScroll(props => {
    const { cardWord, position, innerRef } = props;
    const isApp = useApp();

    const sensors = useMemo(() => {
        return {
            resourcepage_title: 'list',
            resource_type: '24',
            resource_content: cardWord?.word,
            resource_position: position + 1,
        }
    }, [cardWord, position]);

    return <a
        ref={innerRef}
        href={isApp ? getAppLink(cardWord?.deepLink) : deep(cardWord?.deepLink)}
        className={classes.WordSearch}
        data-position={sensors?.resource_position}
        data-type={sensors?.resource_type}
        data-content={sensors?.resource_content}
        data-title={sensors?.resourcepage_title}
        onClick={e => clickAHandle(e, sensors)}
    >
        <span>{cardWord?.word}</span>
    </a>
})

export const SmartType4 = (props => {
    useStyles(classes)
    const { smartCard, position } = props
    const { searchWords } = smartCard

    return <div className={`${classes.NormalItem} ${classes.SmartType4}`}>
        <div className={classes.SmartCard4}>
            <div className={classes.Hd}>
                <span className={`${classes.SearchIcon}`}></span>

                <span className={classes.Font}><FormattedMessage id="hot_search" defaultMessage="Hot Search" /></span>
            </div>

            <div className={classes.Bd}>
                {
                    searchWords?.map((item, index) => (
                        <Type4Link key={item?.word + index} cardWord={item} position={position} />
                    ))
                }
            </div>
        </div>
    </div>
})

const SmartCartIcon = withCartIcon(props => {
    const { onCart } = props;
    const handleClick = useCallback((e) => {
        e.stopPropagation();
        onCart();
    }, []);

    return <Iconfont className={classes.CartIcon} onClick={handleClick}>&#xe6a8;</Iconfont>
});

const Type5Link = props => {
    const { product, rankIndex, sensors, onRanking } = props;

    const lower = getLowerPrice(product);
    const higher = getDelPrice(product);

    const rankIndexImage = useMemo(() => {
        switch (rankIndex) {
            case 0:
                return 'https://image.geeko.ltd/chicme/20220720/No.3.svg'
            case 1:
                return 'https://image.geeko.ltd/chicme/20220720/No.2.svg'
            case 2:
                return 'https://image.geeko.ltd/chicme/20220720/No.1.svg'
            default:
                return null
        }
    }, [rankIndex]);

    return <div className={classes.Type5Product}>
        <div className={classes.Image} onClick={onRanking}>
            <img className={classes.NormalImage} src={`${IMAGE_GEEKO_LTD}/medium/${product.pcMainImage}`} alt={`ProductImage`} />

            {
                rankIndexImage && <img className={classes.RankImage} src={rankIndexImage} alt={`NO${rankIndex}`} />
            }
        </div>

        <div className={classes.Content}>
            <p className={classes.PName}>
                {product.name}
            </p>

            <div className={classes.PriceContainer}>
                <div className={classes.Smart5Prices}>
                    <span className={`${classes.RealPrice}`}>{unitPrice(lower)}</span>

                    {/* {
                        higher && <del className={classes.DealPrice}>{unitPrice(higher)}</del>
                    } */}
                </div>

                <div className={classes.LinkTo}>
                    <SmartCartIcon
                        sensors={sensors}
                        productId={product.id}
                        color={product?.variants?.[0]?.color}
                    />
                </div>
            </div>
        </div>
    </div>
}

/**
 * 
 [
"id", productCategoryId
"title",    productCategoryName
"&type=1&productId=........"
]
 * 
 */
export const SmartType5 = withSourceOnlyScroll(props => {
    useStyles(classes)
    const { smartCard, position, innerRef } = props
    const { products, enTitle, title, titleColor, deepLink } = smartCard;
    const { show: showModal, setShow: setShowModal } = useOpenWindow('ranking-list');

    const onRanking = useCallback((e) => {
        e.preventDefault();
        if (deepLink?.type == "47") {
            setShowModal(true);
        } else {
            window.location.href = getSensorsUrl(deep(deepLink), sensors)
        }

        if (window.GeekoSensors) {
            window.GeekoSensors.Track("PitPositionClick", sensors)
        }
    }, [deepLink, sensors]);

    const sensors = useMemo(() => {
        return {
            resourcepage_title: 'list',
            resource_type: '1',
            resource_content: enTitle || title,
            resource_position: position + 1,
        }
    }, [smartCard, position]);

    return <React.Fragment>
        <div className={`${classes.NormalItem} ${classes.SmartType5}`}>
            <a
                className={classes.SmartCard5}
                ref={innerRef}
                data-position={sensors.resource_position}
                data-type={sensors.resource_type}
                data-content={sensors.resource_content}
                data-title={sensors.resourcepage_title}
            >
                <div className={classes.Hd}>
                    <span className={classes.Font} style={{color: titleColor || '#222'}} onClick={onRanking}  dangerouslySetInnerHTML={{__html:title}}></span>
                </div>

                <div className={classes.Bd}>
                    {
                        products?.slice(0, 3).map((item, index) => (
                            <Type5Link key={item?.id + index} product={item} position={position} sensor={sensors} rankIndex={index} onRanking={onRanking} />
                        ))
                    }
                </div>
            </a>
        </div>

        <RankingList
            showModal={showModal}
            productCategoryId={deepLink?.params?.[0]}
            sensors={sensors}
            isCartIcon
            onClose={() => {
                setShowModal(false);
            }}
        />
    </React.Fragment>
})

const SmartType6Product = props => {
    useStyles(classes)
    const {product} = props
    const [lowerPrice, delPrice, isFlash] = [
        getLowerPrice(product),
        getDelPrice(product),
        isFlashSales(product)
    ]

    let off = 0
    if (delPrice) {
        off = Math.round((Number(delPrice.amount) - Number(lowerPrice.amount)) * 100 / Number(delPrice.amount))
    }

    return <div className={`${classes.Image} ${classes.Type6Image}`}>
        <LazyLoadImage src={`${IMAGE_GEEKO_LTD}/small/${product?.pcMainImage}`} />
        {/* <img src={`${IMAGE_GEEKO_LTD}/small/${product?.pcMainImage}`}/> */}
        {
            off > 0 && <span className={classes.FlashTip}>
                <span className={classes.Number}>-{off}%</span>
            </span>
        }
    </div>
}

export const SmartType6 = withSourceScroll(props => {
    useStyles(classes)
    const { smartCard, innerRef, position } = props
    const { products, title, titleColor, backgroundImage, deepLink, enTitle } = smartCard

    const isApp = useApp()
    const url = isApp ? getAppLink(deepLink) : deep(deepLink)

    const offset = useMemo(() => {
        if(products?.length <= 0)return 0
        let product1 = products?.[0]
        let num = product1?.promotion?.endTime - product1?.promotion?.serverTime
        return num > 0 ? num: 0
    }, [products])

    const productsPages = useMemo(() => {
        let output = []
        const chunkArray = (arr, chunkSize) => {
            for(let i = 0; i < arr.length; i += chunkSize){
                output.push(arr.slice(i, i + chunkSize));
            }
        }
        chunkArray(products, 4)
        return output
    }, [products])


    return <div className={classes.NormalItem}>
        <a href={url} ref={innerRef}
            data-position={position + 1}
            data-type="1"
            data-content={enTitle}
            data-title="list"
            onClick={e => clickAHandle(e, {
                resourcepage_title: 'list',
                resource_type: '1',
                resource_content: enTitle,
                resource_position: position + 1,
            })}
        >
            <div className={`${classes.SmartCard} ${classes.SmartCard6}`} style={{
                backgroundImage: `url(${backgroundImage})`
            }}>
                <div className={classes.Hd}>
                    <span style={{color: titleColor || '#222'}} dangerouslySetInnerHTML={{__html:title}}></span>
                </div>
                <div className={classes.CountDownBox}>
                    <CountDown offset={offset} showHour showLabel/>
                </div>
                <div className={classes.Bd}>
                    <FullSwiper
                        style={{ zIndex: 0, width: '100%'}}
                        items={productsPages}
                        options={{
                            loop: true,
                            autoplay: {
                                disableOnInteraction: false,
                                delay: 5000,
                            },
                            slidesPerView: 1
                        }}
                        slideClass={classes.slideClass}
                        render={(productList, index) => <div className={classes.Type6Content} key={index}>
                            {
                                productList?.map((p) => {
                                    return <SmartType6Product product={p} key={p?.id}/>
                                })
                            }
                        </div>}
                    >
                    </FullSwiper>
                </div>
            </div>
        </a>
    </div>
})

export const HSmartType6 = withSourceScroll(props => {
    useStyles(classes)
    const { smartCard, innerRef, position } = props
    const { products, title, titleColor, horizontalBackgroundImage, deepLink, enTitle } = smartCard

    const isApp = useApp()
    const url = isApp ? getAppLink(deepLink) : deep(deepLink)

    const offset = useMemo(() => {
        if(products?.length <= 0)return 0
        let product1 = products?.[0]
        let num = product1?.promotion?.endTime - product1?.promotion?.serverTime
        return num > 0 ? num: 0
    }, [products])

    return <div className={`${classes.HSmartType2} ${classes.HSmartType6}`}>
        <a href={url} ref={innerRef}
            data-position={position + 1}
            data-type="1"
            data-content={enTitle}
            data-title="list"
            onClick={e => clickAHandle(e, {
                resourcepage_title: 'list',
                resource_type: '1',
                resource_content: enTitle,
                resource_position: position + 1,
            })}
        >
            <div className={classes.HSmartCard2}>
                <div className={classes.Bd}>
                    <FullSwiper
                        items={products}
                        options={{
                            // pagination: {
                            //     clickable: true,
                            //     bulletClass: classes.Bullet,
                            //     bulletActiveClass: classes.BulletActive,
                            //     dynamicBullets: true,
                            //     dynamicMainBullets: 3,
                            // },
                            loop: true,
                            autoplay: {
                                disableOnInteraction: false,
                                delay: 2000,
                            }
                        }}
                        render={(product, index) => <div>
                            <div className={classes.Image} key={product.id + index}>
                                <img src={`${IMAGE_GEEKO_LTD}/medium/${product?.pcMainImage}`} />
                            </div>
                        </div>}
                    >
                    </FullSwiper>
                </div>

                <div className={classes.Hd} style={{
                    backgroundImage: `url(${horizontalBackgroundImage})`
                }}>
                    <div className={classes.Title}>
                        <span style={{color: titleColor || '#222'}} dangerouslySetInnerHTML={{__html:title}}></span>
                    </div>
                    <div className={classes.CountDownBox}>
                        <CountDown offset={offset} showHour showLabel/>
                    </div>
                    <div>
                        <span className={classes.ShopNow}>
                            <FormattedMessage id="shop_now"/>
                        </span>
                    </div>
                </div>
            </div>
        </a>
    </div>
})

export default props => {
    const { smartCard, listType } = props;

    if (listType === '1') {
        return <React.Fragment>
            {
                smartCard?.style === '2' && <HSmartType2 {...props} />
            }
            {
                smartCard?.style === '6' && <HSmartType6 {...props} />
            }
        </React.Fragment>
    }


    return <React.Fragment>
        {
            smartCard?.style === '1' && <SmartType1 {...props} />
        }

        {
            smartCard?.style === '2' && <SmartType2 {...props} />
        }

        {
            smartCard?.style === '3' && <SmartType3 {...props} />
        }

        {
            smartCard?.style === '4' && <SmartType4 {...props} />
        }

        {
            smartCard?.style === '5' && <SmartType5 {...props} />
        }

        {
            smartCard?.style === '6' && <SmartType6 {...props} />
        }
    </React.Fragment>
}