import React from 'react'
import useStyles from 'isomorphic-style-loader/useStyles'
import classes from "./style.module.scss"
import LoginElement from './components/login-page/index'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'

export default props => {
    useStyles(classes)
    const global = useSelector(state =>state.global);

    const onSuccess = data => {}
    const onFailure = data => {
        console.log('data', data);
    }

    useEffect(() =>{
        if(global?.user || global?.user?.id){
            window.location.replace('/');
        }
    },[]);

    return <div>
        <LoginElement {...props} onSuccess={onSuccess} onFailure={onFailure} />
    </div>
}