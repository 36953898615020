import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import StyleContext from 'isomorphic-style-loader/StyleContext'
import Loadable from 'react-loadable'

const insertCss = (...styles) => {
  const removeCss = styles.map(style => style._insertCss())
  return () => removeCss.forEach(dispose => dispose())
}


// const isEnv = process.env.NODE_ENV === 'development' ? true : false

// if(isEnv){
//   ReactDOM.hydrate(
//     <StyleContext.Provider value={{ insertCss }}>
//       <App />
//     </StyleContext.Provider>,
//     document.getElementById('root')
//   )
// }else{
//   window.main = () => {
//     Loadable.preloadReady().then(() => {
//       ReactDOM.hydrate(<StyleContext.Provider value={{ insertCss }}>
//         <App />
//       </StyleContext.Provider>,
//       document.getElementById('root'))
//     })
//   }
// }

window.main = () => {
  Loadable.preloadReady().then(() => {
    ReactDOM.hydrate(<StyleContext.Provider value={{ insertCss }}>
      <App />
    </StyleContext.Provider>,
    document.getElementById('root'))
  })
}