import React from 'react'
import classes from './style.module.scss'
import { IMAGE_GEEKO_LTD } from '../../../../../constants/index'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { unitPrice, getLowerPrice, getDelPrice, isPromotion, isFlashSales } from '../../../../../utils/product'
import { getProductUrl } from '../../../../../utils/product'
import useStyles from 'isomorphic-style-loader/useStyles'
import withItemScroll from '../../../../hocs/item-scroll'
import { useLocation } from 'react-router-dom'
import { useApp, useLanguage, useWebp } from '../../../../hooks/common'

import { FormattedMessage } from 'react-intl'
import { BuyIcon, withCartIcon } from '../cart/cart-icon.jsx'
import { getSensorsUrl } from '../../../../utils/sensor'
import { Iconfont } from '../../../../components/icon/iconfont.jsx'
import { ListingPreOrderComponent } from './pre-order-compnents.jsx'

const getSvg = num => {
    return <img src={`https://image.geeko.ltd/upgrade/20211223/${num + 1 >= 4 ? 4 : num + 1}.svg`} />
}


export const EmptyItem = props => {
    useStyles(classes)
    return <figure className={classes.SimpleItem}>
        <div>
            <div className={classes.Image}>
                <img alt="empty" src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" />
            </div>
        </div>
        <figcaption>
            <div className={classes.Prices}>
                <span className={classes.Price} style={{ opacity: 0 }}>$</span>
            </div>
        </figcaption>
    </figure>
}


const ProductEditorClick = withCartIcon(props => {
    const { onCart } = props
    const clickHandle = e => {
        e.preventDefault()
        onCart(e)
    }
    return <a
        {...props}
        ref={props.innerRef}
        onClick={clickHandle}
    >
        {props.children}
    </a>
})

const ProductNormalClick = props => <a {...props} ref={props.innerRef}>{props.children}</a>

export default withItemScroll(props => {

    useStyles(classes)

    const location = useLocation()
    const isApp = useApp()
    const language = useLanguage()

    const { product, position, column, sensors, top, buyButton, lineFeed, isTypeFlash, mode, festival } = props


    // product.promotion = {
    // 	...(product.promotion || {}),
    // 	type: '9',
    // 	// salesQty: Math.round(Math.random() * 10),
    // 	totalQty: 12,
    // 	salesQty: 12
    // }


    const isWebp = useWebp() && product?.status === '1'

    const [lowerPrice, delPrice, isProm, isFlash] = [
        getLowerPrice(product),
        getDelPrice(product),
        isPromotion(product),
        isFlashSales(product) || isTypeFlash
    ]

    let off = 0
    if (delPrice) {
        off = Math.round((Number(delPrice.amount) - Number(lowerPrice.amount)) * 100 / Number(delPrice.amount))
    }
    const url = isApp ? `chic-me://chic.me/product?id=${product?.id}` : getProductUrl(product?.id, product?.name, null, null, language)

    const promotionType = isProm ? product.promotion.type : ''

    const priceClass = isFlash ? classes.FlashPrice : (off > 0 ? classes.PromotionPrice : classes.Price)

    const getPositionClass = () => {
        switch (position) {
            case 0:
                return classes.N1;
            case 1:
                return classes.N2;
            case 2:
                return classes.N3;
            default:
                return classes.N4;
        }
    }

    const imageUrl = `${IMAGE_GEEKO_LTD}${isWebp ? '/webp' : ''}/medium/${product.pcMainImage}`

    const isFinished = isFlash && product.promotion?.totalQty <= product.promotion?.salesQty

    const handleProductClick = e => {
        e.preventDefault()

        if (mode === 'show_editor') {

        } else {
            const href = e.currentTarget?.href
            window.location.href = getSensorsUrl(href, {
                resourcepage_title: sensors?.resourcepage_title,
                resource_type: sensors?.resource_type,
                resource_content: sensors?.resource_content,
                resource_position: sensors?.resource_position,
                product_position: position + 1,
            })
        }

    }

    const isNew = product.isNew && location.pathname?.indexOf('/landing-page/new') < 0

    const ProductClick = mode === 'show_editor' ? ProductEditorClick : ProductNormalClick

    

    const isPreorder = product?.isPreOrder && product?.promotion?.type === '11' && product?.promotion?.enabled



    return <figure className={`${classes.SimpleItem}`}>
        <ProductClick href={url} innerRef={props.innerRef}
            productId={product?.id}
            product-id={product?.id}
            data-product-list-source
            data-promotion={promotionType}
            data-layer={product?.layerName}
            data-tracking={product.trackingRecords}
            data-product-source={product.dataSource}
            data-geeko-experiment={product.geekoExperimentId}
            data-geeko-id={product.geekoRequsestId}
            data-request-id={product.aliRequestId}
            data-experiment-id={product.aliExperimentId}
            data-column={column}
            data-product-position={position + 1}
            data-position={sensors?.resource_position}
            data-type={sensors?.resource_type}
            data-content={sensors?.resource_content}
            data-title={sensors?.resourcepage_title}
            onClick={handleProductClick}
            sensors={sensors}
        >
            <div className={classes.Image}>
                <LazyLoadImage src={imageUrl} alt={product?.name} />
                {
                    isFinished && <div className={classes.Finished}>
                        <FormattedMessage id="finished" defaultMessage={'Finished'} />
                    </div>
                }
            </div>

            <span className={classes.OffTip}>{`-${off}%`}</span>

        </ProductClick>

        <figcaption>
            <div className={classes.Prices} style={{paddingBottom: 0}}>
                <span className={priceClass}>{unitPrice(lowerPrice)}</span>

                {
                    lineFeed && <br />
                }

                {
                    delPrice && <del className={classes.Del}>{unitPrice(delPrice)}</del>
                }
            </div>
            {
                isPreorder && <ListingPreOrderComponent small ignoreTime preorder={ product.promotion } />
            }
        </figcaption>
    </figure>
})