import React, { useMemo, useState, useEffect } from "react";
import classes from "./style.module.scss";
import useStyles from "isomorphic-style-loader/useStyles";
import RegionPicker from "../../../components/address-form/region-picker";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { action_fect_countries } from "../../../../../store/actions/global";
import { action_fetch_cart } from "../../../../../store/actions/pages/cart"
import { IMAGE_GEEKO_LTD } from "../../../../../constants";
import Cookies from "js-cookie";
import { setCountry } from "../../../../../store/actions/global";
import { FormattedMessage } from "react-intl";
import { setCountries } from "../../../../../store/actions/global";
import { fetchChangeCurrentCountry } from "../../../../../api";
import Alert from "../../../../components/alert";
import { useLocation } from "react-router";

export default props =>{
    useStyles(classes);
    const { onSelect, countrys } = props;
    const dispatch = useDispatch();
    const global = useSelector(state=>state.global);
    const [show, setShow] = useState(false);
    const location = useLocation()

    useEffect(async () => {
        if(countrys?.length > 0){
            dispatch(setCountries(countrys));
        }
    }, [countrys]);

    const currentCounryName = useMemo(() =>{
        if(global?.countries?.length > 0){
            let country = global?.country;
            let countries = global.countries;
            return countries?.find?.(c => c.value === country)?.label
        }
    },[global?.country, global.countries]);

    const selectHandle = async (data) =>{
        try {
            const { country } = data;
            let response = await fetchChangeCurrentCountry(country);
            if(response?.code === 200){
                dispatch(setCountry(country));

                if(location.pathname?.startsWith('/cart')){
                    dispatch(action_fetch_cart())
                }

                // onSelect(data?.country);
                Cookies.set('country',country, {path: '/', expires: 30});
            }else{
                Alert(response?.result);
            }
        } catch (error) {
            console.log('error', error)
        }
    }

    return <div>
        <div className={classes.Location} onClick={() =>setShow(true)}>
            <span className={classes.label}><FormattedMessage id="location" defaultMessage="Location" /></span>

            <span className={classes.Select}>
                <span className={classes.FontBold}>{currentCounryName || <FormattedMessage id="country" defaultMessage={'Country / Region'}/>}</span>
                <span className={classes.Icon} style={{backgroundImage:`url(${IMAGE_GEEKO_LTD}/chicme/20221029/tri.svg)`}}></span>
            </span>
        </div>

        {
            global?.countries?.length > 0 && <React.Fragment>
                <RegionPicker 
                    onlyCountry 
                    show={show} 
                    countries={global?.countries} 
                    country={global?.country} 
                    onSelect={selectHandle} 
                    onClose={() => setShow(false)} 
                />
            </React.Fragment>
        }
    </div>
}