import React from 'react'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import withSource from '../../../../hocs/source-scoll'
import { useApp } from '../../../../hooks/common'
import { useCallback } from 'react'
import { getSensorsUrl } from '../../../../utils/sensor'


const Image = withSource(props => {
	const { image, sensors, innerRef, position, showVideoEvent } = props
	const { percent, width, height, title, href, appHref } = image
	const aspectRatio = width && height ? `${width} / ${height}` : undefined
	const isApp = useApp()

	let ratio
	if(width && height){
		ratio = `${Math.floor(height*100/width)}%`
	}


	const clickHandle = useCallback(e => {
		e.preventDefault()
		if (!!showVideoEvent){
			showVideoEvent(image);
		} else {
			const href = e.currentTarget?.href
			window.location.href = getSensorsUrl(href, {
				resourcepage_title: sensors?.title,
				resource_type: sensors?.type,
				resource_content: image.refId,
				resource_position: `${sensors?.position || 1}-${position + 1}`,
			})
		}
	}, [image])

	return <a
		ref={innerRef}
		data-position={`${sensors?.position || 1}-${position + 1}`}
		data-type={sensors?.type}
		data-content={image.refId || image.title}
		data-title={sensors?.title}
		style={{
			width: isNaN(percent) ? percent : `${Number(percent) * 100}%`
		}}
		onClick={clickHandle}
		href={isApp? appHref: href} title={title}>
			<div className={`${classes.Image} ${!ratio?classes.Static:''}`} style={{
				aspectRatio,
				paddingTop: ratio,
			}}>
				{
					props?.render(image)
				}
			</div>
	</a>
})

const MenuImage = withSource(props => {
	const { image, sensors, innerRef, position } = props
	const { percent, width, height, title, href, appHref } = image
	const aspectRatio = width && height ? `${width} / ${height}` : undefined
	const isApp = useApp()

	let ratio
	if(width && height){
		ratio = `${Math.floor(height*100/width)}%`
	}


	const clickHandle = useCallback(e => {
		e.preventDefault()
		const href = e.currentTarget?.href
		window.location.href = getSensorsUrl(href, {...sensors, resource_position: position})
	}, [])

	return <a
		ref={innerRef}
		data-position={position}
		data-type={sensors?.resource_type}
		data-content={sensors?.resource_content}
		data-title={sensors?.resourcepage_title}
		style={{
			width: isNaN(percent) ? percent : `${Number(percent) * 100}%`
		}}
		onClick={clickHandle}
		href={isApp? appHref: href} title={title}>
			<div className={`${classes.Image} ${!ratio?classes.Static:''}`} style={{
				aspectRatio,
				paddingTop: ratio,
			}}>
				{
					props?.render(image)
				}
			</div>
	</a>
})


export const MenuImages = props => {
	useStyles(classes)
	const { images, sensors, position } = props
	return <div className={classes.Container}>
		<div className={`${classes.Images}`}>
			{
				images?.map((image, index) => {
					return <MenuImage position={`${position}-${index+1}`} render={
						image => props?.render(image, index)
					} sensors={{...sensors, resource_content: `${sensors?.resource_content} - ${image?.enTitle || image?.title }`}} key={index} image={image}/>
				})
			}
		</div>
	</div>
}

export default props => {
	useStyles(classes)
	const { images, title, sensors, special, direction, showVideoEvent } = props

	return <div className={classes.Container}>
		{
			title && <div className={classes.Title}>
				{
					title
				}
			</div>
		}

		<div className={`${classes.Images} ${special? classes.Special:''} ${direction === 'rtl'? classes.Rtl: ''}`}>
			{
				images?.map((image, index) => {
					const { refId, id } = image
					return <Image position={index} render={
						image => props?.render(image, index)
					} sensors={sensors} key={refId || id || index} image={image} showVideoEvent={showVideoEvent}/>
				})
			}
		</div>

	</div>
}