import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import { Swiper, SwiperSlide } from 'swiper/react';
import { FormattedMessage } from 'react-intl';
import React from 'react'

const LuckDrawHistory = props => {
    useStyles(classes);
    const { drawHistory, fontColor = "#fff" } = props;

    return <>
        {
            drawHistory?.length > 0 && <div className={`${classes.LuckDrawHistory} ${props?.className?props?.className:''}`} style={{...props.style}}>
                <Swiper
                    direction='vertical'
                    loop={true}
                    autoplay={{ disableOnInteraction: false, delay: 3000 }}
                    onSlideChange={(swiper) => console.log()}
                    onSwiper={(swiper) => console.log()}
                >
                    {
                        drawHistory?.map(item => <SwiperSlide className={classes.LuckDrawHistoryItem} style={{color:fontColor}}>
                            <div>{item?.email}</div>
                            <div><FormattedMessage id="get" defaultMessage="Get" /> {item?.prize?.name}</div>
                        </SwiperSlide>)
                    }

                </Swiper>
            </div>
        }
    </>
}

export default LuckDrawHistory;