// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._23SI1IKmg9jKspXExCxFMe img{display:block;width:100%}\n", "",{"version":3,"sources":["webpack://src/client/components/swiper/normal/style.module.scss"],"names":[],"mappings":"AAAA,6BAEI,aAAc,CACd,UAAW","sourcesContent":[".SwiperSlide{\n  img{\n    display: block;\n    width: 100%;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SwiperSlide": "_23SI1IKmg9jKspXExCxFMe"
};
module.exports = ___CSS_LOADER_EXPORT___;
