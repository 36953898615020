import React, { useCallback, useState, useRef, useEffect } from "react";
import classes from "./style.module.scss";
import useStyles from "isomorphic-style-loader/useStyles";
import { Iconfont } from "../../../../../components/icon/iconfont";
import { useSelector } from "react-redux";
import RightToLeft from "../../../../../components/transitions/right-to-left";
import { Formik, useField, Form, useFormikContext } from "formik";
import PhonePasswordField from "../../input/phone-password-input"
import { BigButton } from "../../../../../components/button";
import { FormattedMessage, useIntl } from "react-intl";
import { IMAGE_GEEKO_LTD } from "../../../../../../constants";
import { fetchPhoneRandcode } from "../../../../../../api";
import Toast from "../../../../../components/toast";
import ReactDOM from 'react-dom'
import EmailPhoneFiled from "../../input/email-phone-input-filed";
import RegisterMessage from "../../../fragments/register-message";
import PhoneCodeInputFiled from "../../input/phone-code-input";


const SendCodeContainer = props =>{
    const { phoneNumber, currentCode, currentLoginType = false, show, showPassword, setOpLoading } = props;
    const progressRef = useRef();
    const [time, setTime] = useState(60);
    const [showSend, setShowSend] = useState(false);

    useEffect(() => {
        if(time <= 0){
            clearInterval(progressRef.current)
            setTime(60);
            setShowSend(true);
        }
    }, [time])
    

    useEffect(() => {
        if(show){
            progressRef.current = setInterval(() => {
                setTime(preValue =>preValue <= 0 ? 0 : (preValue - 1));
            }, 1000);
    
            setShowSend(false);
            setTime(60);
        }
    
        return () => {
            progressRef.current && clearInterval(progressRef.current)
        }
    }, [show])

    const senHandle = useCallback(async (data) =>{
        let mode;
        if(currentLoginType){
            mode = "2";
        }else{
            mode = "1";
        }
        setOpLoading(true);
        let response = await fetchPhoneRandcode({...data, mode});
        setOpLoading(false);
        if(response?.code !== 200){
            response?.result && Toast(response?.result);
        }
        progressRef.current = setInterval(() => {
            setTime(preValue =>preValue <= 0 ? 0 : (preValue - 1));
        }, 1000);
        setShowSend(false);
    }, [currentLoginType])
    

    return <div className={classes.SendCodeContainer} style={{display:`${showPassword ? "none" : "block"}`}}>
        {
            showSend ? <span onClick={()=>{
                senHandle({phoneAreaCode:currentCode?.areaCode, phoneNumber: phoneNumber})
            }} className={classes.SendCodeBtn}><FormattedMessage id="resend" defaultMessage="Resend" /></span> : <span className={classes.CountDownMessgae}>{time}S</span>
        }
    </div>
}

const VerificationCode = props =>{
    const { InputProps, showPassword, currentLoginType } = props;
    const { values, submitForm } = useFormikContext();
    const Intl = useIntl();

    const validate = (value) => {
        if(showPassword) return null;
        let errorMsg = '';
        if(value?.length < 4){
            errorMsg = Intl.formatMessage({id:"enter_code_number", defaultMessage:"Enter code number"}); 
        }
        return errorMsg;
    };

    const [field, meta, helpers] = useField({...InputProps, validate})
    const [codeList, setCodeList] = useState([
        {
            value:"",
            focus:false
        },
        {
            value:"",
            focus:false
        },
        {
            value:"",
            focus:false
        },
        {
            value:"",
            focus:false
        },
    ]);
    const [isReadOnly, setReadOnly] = useState(true);
    const inputRef = useRef();
    

    useEffect(() =>{
        setTimeout(() =>{
            setReadOnly(false);
            // inputRef.current?.focus();
        },100);
    },[]);

    useEffect(() => {
        if ((currentLoginType && !showPassword) && values.codeNumber.length === 4) {
            submitForm();
        }
    }, [values, submitForm]);

    const changeHandle = useCallback((evt) =>{
        let value = evt.target.value;
        value = value.replace(/[^\d]/g, '');
        field.onChange(evt);
        let length = value?.length >= 4 ? value?.length -1 : value?.length;
        let valueArr = Array.from(value?.split(""));
        setCodeList((state) =>{
            return state.map((item, index) =>{
                return length === index ? {value:valueArr[index] || "", focus:true} : {value:valueArr[index] || "", focus:false}
            })
        });
    }, [])

    const focusHandle = useCallback((evt) =>{
        let value = evt.target.value;
        calcCursorPosition(value, true)
    }, [codeList])

    const blurHandle = useCallback((evt) =>{
        field.onBlur(evt);
        let value = evt.target.value;
        calcCursorPosition(value, false)
    }, [codeList])

    // 计算光标出现位置
    const calcCursorPosition = useCallback((value, flag) =>{
        let length = value?.length >= 4 ? value?.length -1 : value?.length;
        let currentCode = {...codeList[length], focus:flag};
        let newArrs = codeList.map((item, index)=>(index===length ? currentCode : item))
        setCodeList(newArrs);
    }, [codeList])

    return <div className={classes.VerificationCodeCon}>
        <div className={classes.VerificationCode}>
            <div className={classes.FieldList}>
                {
                    codeList?.map((item, index) =>(
                        <div className={`${classes.FieldItem} ${item?.focus ? classes.FocusItem:''}`} key={item?.value+index}>{item?.value}</div>
                    ))
                }
            </div>
            <input 
                ref={inputRef}
                className={classes.FieldInput}
                {...InputProps}
                maxLength="4" 
                onChange={changeHandle}
                onFocus={focusHandle}
                onBlur={blurHandle}
                autoComplete={"off"}
                readOnly={isReadOnly}
            />
        </div>

        <div className={classes.ErrorMsg}>{meta.touched && meta.error ? meta.error : null}</div>
    </div>
    
}

const PasswordForm = props =>{
    const { 
        currentLoginType = false, 
        handleRegister, 
        currentCode, 
        phoneNumber, 
        loading, 
        handleLogin, 
        setRestPasswordPhoneModal,
        showPassword,  
        setShowPassword, 
        onClose, 
        notCheckVerifyEvent,
        codeComponent, 
        refreshCode, 
        showLoginCode
    } = props;
    const Intl = useIntl();

    // 第一次密码框不出现防止输入框自动填充值
    const [initPassword, setInitPassword] = useState(false);
    const [policyActive, setPolicyActive] = useState(false);
    const [policyError, setPolicyError] = useState(false);

    const submitHandle = async (values, formikBag) =>{
        if(currentLoginType){
            let params = {phoneAreaCode: currentCode?.areaCode, phoneNumber:phoneNumber };
            if(showPassword){
                params.password = values.password;

                if(showLoginCode){
                    params.randcode = values.randCode;
                }
            }else{
                params.randcode = values.codeNumber;
            }
            let response = await handleLogin?.(params, {showPassword});
            if(response){
                onClose?.();
            }
        }else{
            if(!policyActive) {
                setPolicyError(true);
    
                setTimeout(() => {
                    setPolicyError(false);
                }, 500);
                
                notCheckVerifyEvent("Register", 'phone');
                return false;
            }
            
            let response = await handleRegister({phoneAreaCode: currentCode?.areaCode, phoneNumber: phoneNumber, smsRandcode: values?.codeNumber}, true);
            if(response){
                onClose?.();
            }
        }
    }

    const privacyPolicyI18n = Intl.formatMessage(
        { id: 'register_privacy_policy' },
        {
            term: `<a href="/policy/terms-conditions-notice">${Intl.formatMessage({id:"term_of_services",defaultMessage:"Terms of Service"})}</a>`,
            privacy: `<a href="/policy/privacy-security-policy">${Intl.formatMessage({id:"privacy_policy",defaultMessage:"Privacy Policy"})}</a>`,
        }
    );

    return <div className={`${classes.PasswordForm} ${showPassword?classes.MarginTop14:''}`}>
        <Formik 
            onSubmit={submitHandle}
            initialValues={{
                codeNumber:'',
                password:'',
                phoneNumber: phoneNumber,
                randCode: ''
            }} 
            validate={values => {
                const errors = {}
                let errorFlag = false;

                if((currentLoginType && showPassword && showLoginCode) && !values.randCode){
                    errorFlag = true;
                    errors.randCode = Intl.formatMessage({ id: "captcha_not_empty", defaultMessage: "The CAPTCHA cannot be empty" })
                }
    
                errorFlag && notCheckVerifyEvent?.(currentLoginType ? 'Login' : 'Register', 'email');
                return errors
            }}
            enableReinitialize={true} 
        >
            {
                ({errors, touched, isSubmitting}) =>(
                    <Form className={classes.PhoneRegisterFrom}>
                        <div style={{display:`${showPassword ? "none" : "block"}`}}>
                            <VerificationCode 
                                InputProps={{
                                    type:"text",
                                    name:"codeNumber"
                                }}
                                phoneNumber={phoneNumber}
                                currentCode={currentCode}
                                showPassword={showPassword} 
                                currentLoginType={currentLoginType} 
                            />
                        </div>
                        
                        <div style={{display:`${!showPassword ? "none" : "block"}`}}>
                            <EmailPhoneFiled 
                                label={Intl.formatMessage({ id: "phone_number", defaultMessage: "Phone Number" })} 
                                InputProps={{
                                    type:"text",
                                    name:"phoneNumber",
                                }}
                                currentCode={currentCode}
                                disabled
                                onEdit={onClose}
                            />
                        </div>
                        
                        
                        <SendCodeContainer 
                            {...props}
                            showPassword={showPassword} 
                        />

                        {
                             currentLoginType ? <React.Fragment>
                                {
                                    initPassword && <div style={{display: `${showPassword?"block":"none"}`}} className={classes.MarginTop24}>
                                        <PhonePasswordField 
                                            InputProps={{
                                                placeholder:Intl.formatMessage({id:"enter_the_password", defaultMessage:"Enter the password"}),
                                                type:"password",
                                                name:"password"
                                            }}
                                            showPassword={showPassword}
                                            autoComplete="new-password"
                                        />
                                    </div>
                                }

                                
                                <div className={`${classes.PhoneToogle} ${showPassword?classes.MarginTop6:''}`}>
                                    <div className={classes.PasswordToogle} onClick={()=>{
                                        setInitPassword(true);
                                        setShowPassword(state=>!state)
                                    }}>
                                        <span style={{backgroundImage: `url(${IMAGE_GEEKO_LTD}/chicme/20221029/toggle-login.svg)`}}></span>
                                        <span>{showPassword?Intl.formatMessage({id:"use_verification_code", defaultMessage:"Use the verification code to sign in"}):Intl.formatMessage({id:"sin_in_via_password", defaultMessage:"Sign in via password"})}</span>
                                    </div>
                                    
                                    {
                                        showPassword && <span className={classes.ForgetPa} onClick={() =>setRestPasswordPhoneModal()}><FormattedMessage id="forget_password" defaultMessage="Forget password" />?</span>
                                    }
                                </div>
                             </React.Fragment> : <React.Fragment>
                                <div className={classes.PolicyContainer}>
                                    <div className={classes.Item1}>
                                        <Iconfont className={`${classes.SelectIcon} ${policyActive ? classes.Active : ""}`} onClick={() => setPolicyActive(flag => !flag)}>&#xe65a;</Iconfont>
                                        <span className={policyError ? classes.Error : ""} dangerouslySetInnerHTML={{ __html: privacyPolicyI18n }}></span>
                                    </div>
                                </div>
                             </React.Fragment>
                             
                        }

                        {
                            showPassword && (showLoginCode) && <PhoneCodeInputFiled 
                                InputProps={{
                                    name: "randCode"
                                }} 
                                codeComponent={codeComponent} 
                                refreshCode={refreshCode} 
                            />
                        }

                        <BigButton loading={isSubmitting || loading} className={classes.SubmitButton}>{currentLoginType ? <FormattedMessage id="sign_in" defaultMessage="Sign in" /> : <FormattedMessage id="register" defaultMessage="Register" />}</BigButton>
                    </Form>
                )
            }
        </Formik>
    </div>
}

const VerifyPopoverForm = props =>{
    const { currentCode, phoneNumber, onClose, currentLoginType, websiteName, registerDiscount } = props;
    const [showPassword, setShowPassword] = useState(false);

    return <div className={classes.VerifyPopoverForm}>
        <div className={classes.RegisterMessage}>
            <RegisterMessage registerDiscount={registerDiscount} />
        </div>

        {
            currentLoginType ? <React.Fragment>
                <p className={classes.Title1}><FormattedMessage id="welcome_back" defaultMessage="Welcome Back" />!</p>
                <p className={classes.Title2}><FormattedMessage id="please_sign_in_account" defaultMessage="Please sign in with your account." /></p>
            </React.Fragment> : <React.Fragment>
                <p className={classes.Title1}><FormattedMessage id="welcome_to" defaultMessage="Welcome to" /> {websiteName}!</p>
                <p className={classes.Title2}><FormattedMessage id="please_create_your_account" defaultMessage="Please create your account." /> </p>
            </React.Fragment>
        }

        <div className={classes.VerificationCode} style={{display:`${showPassword ? "none" : "block"}`}}>
            <span className={classes.ContentItem1}><FormattedMessage id="send_verification_code" defaultMessage="A verification code is sent to" values={{ value: `${currentCode?.areaCode} ${phoneNumber}` }} /></span>
            <span className={classes.ContentItem2} onClick={onClose}><FormattedMessage id="edit" defaultMessage="Edit" /></span> 
        </div>

        <PasswordForm {...props} showPassword={showPassword} setShowPassword={(flag) =>setShowPassword(flag)} />
    </div>
}


const VerifyPopoverComponent = props =>{
    const { 
        onClose
    } = props;
    const global = useSelector(state=>state.global);

    const { config } = global;

    return <div className={classes.Container}>
        <div className={classes.AlertHeader}>
            <div className={classes.ImageContainer}>
                <img src={config?.logo} alt="logo" />
            </div>

            <Iconfont className={classes.CloseIcon} onClick={()=>onClose()}>&#xe693;</Iconfont>
        </div>

        <VerifyPopoverForm websiteName={config?.name} {...props} />
    </div>
}

const WrappedVerifyPopover = props => {
    useStyles(classes)
	return <React.Fragment>
		<RightToLeft in={props.show} className={classes.VerifyPopover} unmountOnExit>
			<div>
				<VerifyPopoverComponent {...props} />
			</div>
		</RightToLeft>
	</React.Fragment>
}


export default props => {

	if (typeof window === 'undefined')
		return null

    return ReactDOM.createPortal(
        <WrappedVerifyPopover {...props} />,
        document.getElementById('root')
    )
}