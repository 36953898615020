import React from 'react'
import Item, { EmptyItem } from './item'
import FlashItem from './flash-item'
import SimpleItem, { EmptyItem as SimpleEmptyItem } from './simple-item'
import CollectionCard from './collection-card'
import classes from './style.module.scss'
import { withList } from '../../../../components/list'
import SmartCard from './smart-card'
import useStyles from 'isomorphic-style-loader/useStyles'
import { RowItem, RowItemOnlyClick } from './row-item'
import PreOrderItem from './preorder-item'

export const Products = props => {
	useStyles(classes)
	const { products, column, similar, sensors, giftInfo, isDrawGift, product_type, ingoreCard, filter, isFlash, isWishList, wishListSelectEvent, wishListSelect, noAddWish, festival, isPreOrder } = props
	//去掉列表切换
	const listType = 0

	return <div className={`${(listType === '1' && !isFlash) ? classes.SpecilList : classes.NormalList}`}>
		{
			products?.length > 0 &&
			products?.filter(p => !ingoreCard || !p.isCollection && !p.isSmartCard)?.map((product, index) => {
				//当时刘刚让这么改的   由于IOS的一个bug
				if (product.isCollection !== undefined) {
					return <CollectionCard listType={listType} sensors={sensors} position={index} collection={product} key={product.id} />
				}
				if (product.isSmartCard) {
					return <SmartCard listType={listType} sensors={sensors} position={index} smartCard={product} key={product.id} />
				}

				// product.isPreOrder = true
				// product.promotion = {
				// 	"promotionPrice": {
				// 	  "amount": "12.59",
				// 	  "unit": "$",
				// 	  "currency": "USD"
				// 	},
				// 	"startTime": 1708480475162,
				// 	"endTime": 1708919675161,
				// 	"type": "11",
				// 	"countdownHidden": false,
				// 	"orderQty": 0,
				// 	"orderQtyThreshold": 15,
				// 	"estimatedShippedTimeMsg": "02 Mar - 07 Mar",
				// 	"enabled": true,
				// 	"serverTime": 1708503929067,
				// 	"isAppOnly": false
				// }

				return product.isPreOrder && isPreOrder ? <PreOrderItem
					filter={filter}
					product_type={product_type}
					giftInfo={giftInfo}
					isDrawGift={isDrawGift}
					sensors={sensors}
					similar={similar}
					column={column}
					position={index}
					product={product}
					key={product.id}
					isWishList={isWishList}
					wishListSelect={wishListSelect}
					wishListSelectEvent={wishListSelectEvent}
					noAddWish={noAddWish}
				/> : (isFlash ?
					<FlashItem
						filter={filter}
						product_type={product_type}
						giftInfo={giftInfo}
						isDrawGift={isDrawGift}
						sensors={sensors}
						similar={similar}
						column={column}
						position={index}
						product={product}
						key={product.id}
						isWishList={isWishList}
						wishListSelect={wishListSelect}
						wishListSelectEvent={wishListSelectEvent}
						noAddWish={noAddWish}
					/> :
					<Item
						festival={festival}
						listType={listType}
						filter={filter}
						product_type={product_type}
						giftInfo={giftInfo}
						isDrawGift={isDrawGift}
						sensors={sensors}
						similar={similar}
						column={column}
						position={index}
						product={product}
						key={product.id}
						isWishList={isWishList}
						wishListSelect={wishListSelect}
						wishListSelectEvent={wishListSelectEvent}
						noAddWish={noAddWish}
					/>)
			})
		}
	</div>
}

export const EmptyProducts = props => {
	useStyles(classes)
	const { count = 6 } = props
	return <div className={classes.NormalList}>
		{
			Array.from(new Array(count)).map((_, index) => <EmptyItem key={index} />)
		}
	</div>
}

export const TrebleProducts = props => {
	useStyles(classes)
	const { products, column, similar, sensors, giftInfo, isDrawGift, product_type, ingoreCard, filter, isFlash, isWishList, wishListSelectEvent, wishListSelect } = props
	return <div className={`${classes.NormalList} ${classes.TrebleNormalList}`}>
		{
			products?.filter(p => !ingoreCard || !p.isCollection && !p.isSmartCard)?.map((product, index) => {
				//当时刘刚让这么改的   由于IOS的一个bug
				if (product.isCollection !== undefined) {
					return <CollectionCard sensors={sensors} position={index} collection={product} key={product.id} />
				}
				if (product.isSmartCard) {
					return <SmartCard sensors={sensors} position={index} smartCard={product} key={product.id} />
				}
				return isFlash ? <FlashItem filter={filter} product_type={product_type} giftInfo={giftInfo} isDrawGift={isDrawGift} sensors={sensors} similar={similar} column={column} position={index} product={product} key={product.id} isWishList={isWishList} wishListSelect={wishListSelect} wishListSelectEvent={wishListSelectEvent} /> : <Item filter={filter} product_type={product_type} giftInfo={giftInfo} isDrawGift={isDrawGift} sensors={sensors} similar={similar} column={column} position={index} product={product} key={product.id} isWishList={isWishList} wishListSelect={wishListSelect} wishListSelectEvent={wishListSelectEvent} />
			})
		}
	</div>
}

export const TrebleEmptyProducts = props => {
	useStyles(classes)
	const { count = 6 } = props
	return <div className={`${classes.NormalList} ${classes.TrebleNormalList}`}>
		{
			Array.from(new Array(count)).map((_, index) => <EmptyItem key={index} />)
		}
	</div>
}


export const SimpleProducts = props => {
	useStyles(classes)
	const { products, column, similar, sensors, giftInfo, product_type, mode, ingoreCard } = props
	return <div className={classes.SimpleList}>
		{
			products?.filter(p => !ingoreCard || !p.isCollection && !p.isSmartCard)?.map((product, index) => {
				//当时刘刚让这么改的   由于IOS的一个bug
				if (product.isCollection !== undefined) {
					return <CollectionCard sensors={sensors} position={index} collection={product} key={product.id} />
				}
				if (product.isSmartCard) {
					return <SmartCard sensors={sensors} position={index} smartCard={product} key={product.id} />
				}
				return <SimpleItem mode={mode} product_type={product_type} giftInfo={giftInfo} sensors={sensors} similar={similar} column={column} position={index} product={product} key={product.id} />
			})
		}
	</div>
}

export const SimpleEmptyProducts = props => {
	useStyles(classes)
	const { count = 6 } = props
	return <div className={classes.SimpleList}>
		{
			Array.from(new Array(count)).map((_, index) => <SimpleEmptyItem key={index} />)
		}
	</div>
}

export const SimpleListProducts = withList(SimpleEmptyProducts)

export const RowProducts = props => {
	useStyles(classes)
	const { products, sensorOnlyClick } = props
	return <div className={classes.RowList}>
		{
			products?.map((product, index) => {
				if (!product.isCollection && !product.isSmartCard) {
					return !sensorOnlyClick ?
						<RowItem position={index + 1} product={product} key={product.id} {...props} /> :
						<RowItemOnlyClick position={index + 1} product={product} key={product.id} {...props} />
				}
			})
		}
	</div>
}

export const RowListProducts = withList(RowProducts)


const ListingProducts = withList(Products)


export default class extends React.Component {
	constructor(props) {
		super(props)
	}
	render() {
		return <ListingProducts {...this.props} />
	}
}