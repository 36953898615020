import React, { useEffect, useState } from "react";
import useStyles from 'isomorphic-style-loader/useStyles'
import classes from "./style.module.scss"
import { BigButton } from "../../../../components/button";
import _ from "lodash"
import { useIntl,FormattedMessage } from "react-intl";
import { Formik, Form, useField } from "formik";
import EmailPhoneFiled from "../input/email-phone-input-filed";
import { fetchPhoneRandcode, fetchUserDistinguish } from "../../../../../api";
import Toast from "../../../../components/toast";
import EmailWayVerifyPopover from '../verify-popover/email-pop/index';
import PhoneWayVerifyPopover from "../verify-popover/phone-pop";
import { useQuery } from "../../../../hooks/common";

const EmailPhoneValidatorFrom = props =>{
    const {
        currentCode, 
        loading, 
        globalPhoneNumber, 
        email,
        setPopValue, 
        setPopType, 
        setCurrentLoginType, 
        country
    } = props;

    // show代表当前是邮箱模式
    const [showPassword, setShowPassword] = useState(false);
    const Intl = useIntl();
    const query = useQuery();

    useEffect(() =>{
        if(globalPhoneNumber){
            setShowPassword(false);
        }else{
            if(email){
                setShowPassword(true);
            }else{
                setShowPassword(false);
            }
        }
    }, [globalPhoneNumber, email]);

    const submitHandle = async (values, formikBag) =>{
        if(showPassword){
            let response = await fetchUserDistinguish({ loginData: values.emailOrPhone, countryCode: '' });
            if(response?.code === 200 && response?.result){
                setPopValue(values.emailOrPhone);
                setCurrentLoginType(response.result?.register == "1");
                setPopType(1);
            }else{
                response?.result && Toast(response.result);
            }
        }else{
            let response = await fetchUserDistinguish({ countryCode: country, loginData: values.emailOrPhone });
            if(response?.code === 200 && response?.result){
                let response2 = await fetchPhoneRandcode({ phoneAreaCode:currentCode?.areaCode, phoneNumber: values.emailOrPhone, mode: response.result?.register == "1" ? 2 : 1 });
                if(response2?.code !== 401){
                    setPopType(2);
                    setPopValue(values.emailOrPhone);
                    setCurrentLoginType(response.result?.register == "1");
                }
                response2?.result && Toast(response2.result);
            }else{
                response?.result && Toast(response.result);
            }
        }
    }

    return <Formik 
        onSubmit={submitHandle}
        initialValues={{
            emailOrPhone: globalPhoneNumber || email,
            password:'',
            randCode:''
        }}
        validate={values => {
            const errors = {}
            let errorFlag = false;
            const emailReg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            let __reg_phone_normal__ = /^\d{5,20}$/;
            if(currentCode?.value === "BR"){
                __reg_phone_normal__ = /^\d{10,11}$/;
            }

            if(!values.emailOrPhone){
                errorFlag = true;
                errors.emailOrPhone = Intl.formatMessage({id:"enter_the_telephone_number_or_email", defaultMessage:"Enter The Telephone number or Email Address"});
            }else{
                if(showPassword){
                    if(!emailReg.test(values.emailOrPhone)){
                        errorFlag = true;
                        errors.emailOrPhone = Intl.formatMessage({ id: "incorrect_email_address", defaultMessage: "Incorrect email address" });
                    } 
                }else{
                   if(!__reg_phone_normal__.test(values.emailOrPhone)){
                        errorFlag = true;
                        errors.emailOrPhone = Intl.formatMessage({id:"incorrect_phone_number", defaultMessage:"Incorrect phone number"});
                    }
                }
            }

            return errors
        }}
        enableReinitialize={true}
    >
        {
            ({ isSubmitting, values }) =>(
                <Form className={classes.EmailPhoneValidatorFrom} >
                    <EmailPhoneFiled 
                        InputProps={{
                            type:"text",
                            name:"emailOrPhone",
                            placeholder: Intl.formatMessage({id:"phone_number_or_email_address", defaultMessage:"Phone number or email address"})
                        }}
                        currentCode={currentCode}
                        showPassword={showPassword}
                        changeType={(flag)=>setShowPassword(flag)} 
                    />

                    <BigButton type="submit" loading={isSubmitting || loading} className={classes.SubmitButton}><FormattedMessage id="continue" defaultMessage="Continue" /></BigButton>
                </Form>
            )
        }
    </Formik>
}

const EmailPhoneWay = props => {
    useStyles(classes);
    const { popValue, setPopValue } = props;
    const [popType, setPopType] = useState(0); // 1为邮箱 2为手机号
    const [currentLoginType, setCurrentLoginType] = useState(false);

    return <React.Fragment>
        <EmailPhoneValidatorFrom 
            {...props} 
            setPopValue={setPopValue} 
            setPopType={setPopType} 
            setCurrentLoginType={setCurrentLoginType} 
        />

        <EmailWayVerifyPopover 
            show={(popType === 1) && !!popValue} 
            onClose={()=>setPopType(0)} 
            localEmail={popValue} 
            currentLoginType={currentLoginType} 
            {...props}  
        />

        <PhoneWayVerifyPopover 
            show={(popType === 2) && !!popValue} 
            onClose={()=>setPopType(0)} 
            phoneNumber={popValue} 
            currentLoginType={currentLoginType} 
            {...props}  
        />

        
    </React.Fragment>
}

export default EmailPhoneWay;