import React from "react";
import useStyles from 'isomorphic-style-loader/useStyles'
import classes from "./style.module.scss"

export const OneStar = props => {
    useStyles(classes);
    const {starScore} = props;

    const formateTxt = (num) => {
        let numStr = num + ''
        if(numStr.indexOf(".") != -1){
            return numStr?.slice(0,3)
        } else {
            return numStr?.slice(0,1)+'.0'
        }
    }

    return (
        <React.Fragment>
            <div className={classes.OneStar}>
                <span className={classes.Star}></span>
                <span className={classes.StarTxt}>{formateTxt(starScore)}</span>
            </div>
        </React.Fragment>
    )
}

export default props =>{
    useStyles(classes);
    const {starScore,isComment} = props;

    return (
        <React.Fragment>
            {
                starScore && starScore > 0 && <ol className={`${classes.PStars} ${!!isComment?classes.CommentPStars:""}`}>
                    {
                        [1,2,3,4,5].map((item,index) => {
                            if(starScore > index){
                                return starScore < index + 1 ? (<li className={classes.Half} key={item}></li>) : (<li className={classes.Active} key={item}></li>);
                            }else{
                                return (<li key={item}></li>)
                            }
                        })
                    }
                </ol>
            }
        </React.Fragment>
    )
}