import React from 'react'
import ReactDOM from 'react-dom'
import BlackMask from './mask'

const withAlert = Wrapped => {
    return props => {
        const onClose = () => {
            ReactDOM.unmountComponentAtNode(document.getElementById('alert-container'))
        }

        return <Wrapped {...props}  onClose={onClose}/>
    }
}


export const NormarAlert = withAlert(props => {

    const {message, options, onClose, uuid} = props

    const handleClick = event => {
        onClose()
        options?.onClose?.()
    }

    return <React.Fragment>
        <BlackMask onClick={handleClick}/>
        <div className="NormalAlert" id={uuid}>
            <div className="Msg">
                {
                    message
                }
            </div>
            <div className="ButtonBox">
                <button className="OkButton" onClick={handleClick}>{options?.buttonText || 'Ok'}</button>
            </div>
        </div>
    </React.Fragment>
})


export default (message, options) => {
    ReactDOM.render(<NormarAlert message={message} options={options}/>, document.getElementById('alert-container'))
}