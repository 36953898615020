import useStyles from "isomorphic-style-loader/useStyles";
import React from "react";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import classes from './style.module.scss';

const HeadSelect = props => {
    useStyles(classes)
    const {showType, setShowType, productType, productTaglia} = props || {}

    return (
        <div className={classes.HeadSelect}>
            <div className={`${classes.HeadSelectItem}`}>
                <span className={`${showType == 'product' ? classes.Selected: ''}`} onClick={() => setShowType('product')}>
                    <FormattedMessage id="product_measurements"/>
                </span>
            </div>
            {/*  productType == 'clothing' && */}
            {
                productTaglia != 'shoes' &&
                <div className={`${classes.HeadSelectItem}`}>
                    <span className={`${showType == 'body' ? classes.Selected: ''}`} onClick={() => setShowType('body')}>
                        <FormattedMessage id="body_measurements"/>
                    </span>
                </div>
            }
        </div>
    )
}

const SCTable = props => {
    useStyles(classes)
    const {tableHead, tableBody, unit, productType} = props || {}
    // console.log(tableHead, tableBody)

    const [greyX, setGreyX] = useState(null)
    const [greyY, setGreyY] = useState(null)

    const tableHeadSelect = (i, tH) => {
        return productType == 'shoes' ?
                (unit == tH?.title):
                (greyX == i) || (greyY == 0 && greyX >= i )
    }

    const tableBodySelect = (i, tH, ind) => {
        return productType == 'shoes' ?
                (unit == tH?.title):
                (greyX == i && greyY >= (ind+1)) || (greyY == (ind+1) && greyX >= i )
    }

    const tableItemValue = (tb, tH) => {
        return tb?.find((tb1 => tb1?.title == tH?.title))?.size || tb?.find((tb1 => tb1?.title == tH?.title))?.value ||'-'
    }

    return (
        <div className={classes.SCTableBox} onMouseleave={()=>{setGreyX(null);setGreyY(null)}}>
            <table>
                <tr>
                    {
                        tableHead?.map(((tH, i) => (
                            <td onClick={()=>{setGreyX(i);setGreyY(0)}}
                                key={i}
                                className={`${tableHeadSelect(i, tH) ? classes.colSelect : ''} ${classes.colSelectHead} ${classes.colSelect}`}>
                                {tH?.title || '-'}
                            </td>
                        )))
                    }
                </tr>
                {
                    tableBody?.map((tb, ind) => (
                        <tr >
                            {
                                tableHead?.map((tH, i) => {
                                    return <td onClick={()=>{setGreyX(i);setGreyY((ind+1))}}
                                            key={i+"-"+ind}
                                            className={`${tableBodySelect(i,tH,ind) || i == 0 ? classes.colSelect : ''} ${(i==0 || i==1) && classes.colNoWarp}`}
                                            >
                                           {tableItemValue(tb, tH)}
                                        </td>
                                    })
                            }
                        </tr>
                    ))
                }
            </table>
        </div>
    )
}


const SizeChartTableSelect = props => {
    useStyles(classes)
    const {
        unit,
        productType,
        productTaglia,
        showTableType,
        setShowTableType,
        tableHead,
        tableBody
    } = props

    return <React.Fragment>
        {
            // tableHead?.length > 0 && tableBody?.length >0 &&
            <div className={classes.TableBox}>
                <HeadSelect showType={showTableType} setShowType={setShowTableType} productType={productType} productTaglia={productTaglia}/>

                <SCTable tableHead={tableHead} tableBody={tableBody} unit={unit} productType={productType}/>

                {
                    productType != 'bra' &&
                    <div className={classes.Message}>
                        <FormattedMessage id="product_measurements_message"/>
                    </div>
                }

            </div>
        }


    </React.Fragment>
}


export default SizeChartTableSelect