import React from "react";
import classes from "./style.module.scss";
import useStyles from "isomorphic-style-loader/useStyles";
import { Iconfont } from "../../../../components/icon/iconfont";

import BottomToTop from "../../../../components/transitions/bottom-to-top";
import { BlackMask } from "../../../../components/mask";
import { useState } from "react";
import { useEffect } from "react";
import { action_add_modal_gift_list, action_fetch_gifts_info_message } from "../../../../../store/actions/pages/collection";
import { useDispatch } from "react-redux";
import { withList } from "../../../details/hoc/withList";
import { Products } from "../../components/list";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { unitPrice } from "../../../../../utils/product";
import { IMAGE_GEEKO_LTD } from "../../../../../constants";
import { useQuery } from "../../../../hooks/common";
import { BuyIcon } from "../../components/cart/cart-icon";
import qs from 'query-string'

const GiftToolModalList = withList(props =>{
    const { innerRef, isDrawGift } = props;
    const pageCollection = useSelector(state =>state.pageCollection);
    const { modalGifts, giftInfo } = pageCollection;

    return <div className={classes.Bd} >
        <div className={classes.ProductContainer} ref={innerRef}>
            {
                modalGifts?.products?.length > 0 && <Products
                    products={modalGifts?.products}
                    giftInfo={giftInfo}
                    column="gift"
                    product_type="gift" 
                    isDrawGift={isDrawGift} 
                    ingoreCard
                />
            }

            {
                modalGifts?.loading && !modalGifts?.finished && <div className={classes.Loading}>
                    <FormattedMessage id="loading" defaultMessage="Loading" />
                </div>
            }

            {
                modalGifts?.finished && <div className={classes.Finished}>
                    <FormattedMessage id="finished" defaultMessage="Finished" />
                </div>
            }
        </div>
    </div>
})

const GiftsToolsModalItem = props =>{
    const { onClose, isApp, giftData = {}, isDrawGift } = props;

    const dispatch = useDispatch();
    const pageCollection = useSelector(state =>state.pageCollection);
    const { modalGifts, giftInfo } = pageCollection;
    const query = useQuery();
    useStyles(classes)
    let { giftCollectionId } = giftData || qs.parse(giftInfo?.deepLink?.params?.[2])
    let collectionId = query?.giftCollectionId ? query.giftCollectionId : giftCollectionId

    const onScroll = async () =>{
        await dispatch(action_add_modal_gift_list(collectionId,{skip:modalGifts?.skip}));
    }

    return <React.Fragment>
        <div className={classes.Hd}>
            {
                !isApp &&
                <Iconfont className={classes.CloseIcon} onClick={onClose}>&#xe69a;</Iconfont>
            }
            <p className={classes.Title1}><FormattedMessage id="free_gift" defaultMessage="Free gifts" /></p>

            {
                !giftData?.showMsg && <p className={classes.Title2}>{giftInfo?.giftWarnMsg}</p>
            }
            
        </div>

        <GiftToolModalList request={onScroll} loading={modalGifts?.loading} finished={modalGifts?.finished} isDrawGift={isDrawGift} />
    </React.Fragment>
}


const GiftsToolsModal = props =>{
    const { showModal, onClose, zIndex } = props;
    useStyles(classes)

    return <React.Fragment>
        <BottomToTop in={showModal} className={classes.GiftsToolsModal} style={{zIndex: zIndex?zIndex+1:9}}>
            <div>
                <GiftsToolsModalItem {...props} />
            </div>
        </BottomToTop>

        {
            showModal && !zIndex && <BlackMask onClick={onClose} style={{zIndex:8}} />
        }
    </React.Fragment>
}

const GiftsTools = props =>{
    useStyles(classes);
    const [show,setShow] = useState(false);
    const dispatch = useDispatch();
    const pageCollection = useSelector(state =>state.pageCollection);
    const { modalGifts, giftInfo } = pageCollection;
    const query = useQuery();

    useEffect(async ()=>{
        if(query?.giftCollectionId){
            if(!(modalGifts?.skip > 0)){
                await dispatch(action_add_modal_gift_list(query?.giftCollectionId,{skip:modalGifts?.skip}));
            }
            
            await dispatch(action_fetch_gifts_info_message());
        }
    },[]);

    const modalShow = (flag) => {
        setShow(flag);
        document.body.style.overflow = 'hidden';

        if(window.GeekoSensors){
            window.GeekoSensors.Track('FreeGiftClick', {})
        }
    }

    const modalHide = (flag) => {
        setShow(flag);
        document.body.style.overflow = 'auto';
    }

    let gifts = giftInfo?.gifts?.[0];

    return <React.Fragment>
        {
            !!giftInfo && <div className={classes.GiftsTools}>
            {
                !!gifts ? (
                    <div className={classes.GiftsContainer}>
                        <div className={classes.GHd} onClick={()=>modalShow(true)}>
                            <div className={classes.Left}>
                                <Iconfont className={classes.GiftsIcon}>&#xe6da;</Iconfont>
                                <span className={classes.GiftsMessage}>{giftInfo?.giftWarnMsg}</span>
                            </div>
    
                            <div className={classes.Right}>
                                <Iconfont>&#xe690;</Iconfont>
                            </div>
                        </div>
    
                        <div className={classes.GBd}>
                            <div className={classes.LeftImage}>
                                <img src={gifts?.imageUrl} alt="" />
                            </div>
    
                            <div className={classes.RightMessage}>
                                <p className={classes.GiftName}>{gifts?.productName}</p>
    
                                <p className={classes.GiftPrice}>
                                    <span className={classes.High}>{unitPrice(gifts?.promotionPrice || gifts?.price)}</span>
                                    
                                    {
                                        gifts?.promotionPrice && <del className={classes.Lower}>{unitPrice(gifts?.price)}</del>
                                    }
                                    
                                </p>
    
                                <div className={classes.SizeMessage}>
                                    <BuyIcon 
                                        productId={gifts?.productId} 
                                        color={gifts?.color} 
                                        variantId={gifts?.variantId}
                                        isGift={!!giftInfo}
                                        giftInfo={giftInfo}
                                        isCollectionGift
                                        render={() =>{
                                            return <span className={classes.SizeColor}>{gifts?.color}/{gifts?.size}</span>
                                        }}
                                    />
                                    <Iconfont className={classes.Icon}>&#xe690;</Iconfont>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    modalGifts?.products?.length > 0 && <div className={classes.ToolsContainer} onClick={()=>modalShow(true)}>
                        <div className={classes.Hd}>
                            <Iconfont className={classes.GiftsIcon}>&#xe6da;</Iconfont>
                            <span className={classes.GiftsMessage}>{giftInfo?.giftWarnMsg}</span>
                        </div>

                        <div className={classes.Bd}>
                            <div className={classes.Images}>
                                {
                                    modalGifts?.products.slice(0,12)?.map((item,index)=>{
                                        return <div className={classes.ImageContainer}>
                                            <img src={`${IMAGE_GEEKO_LTD}/medium/${item?.pcMainImage}`} alt="" key={index} />
                                        </div>
                                    })
                                }
                            </div>

                            <div className={classes.More}>
                                <Iconfont>&#xe690;</Iconfont>
                            </div>
                        </div>
                    </div>
                )
            }
    
            <GiftsToolsModal showModal={show} onClose={()=>modalHide(false)} />
        </div>
        }
    </React.Fragment>
}

export {
    GiftsTools,
    GiftsToolsModal
};