import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'

import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'

export default props => {
	useStyles(classes)
	const {items} = props
	return <Swiper
		onSlideChange={() => {}}
		onSwiper={(swiper) => {}}
	>
		{
			items?.map((item, index) => <SwiperSlide className={classes.SwiperSlide} key={index}>
				{
					props.render(item, index)
				}
			</SwiperSlide>)
		}
	</Swiper>
}