import React, { useEffect, useMemo, useState } from "react";
import useStyles from 'isomorphic-style-loader/useStyles'
import classes from "./style.module.scss"
import withOutClick from "./out-side-lick";
import { useRef } from "react";
import { useField } from "formik";
import { useIntl } from "react-intl";
import { Iconfont } from "../../../../components/icon/iconfont";


export default withOutClick(props => {
    useStyles(classes);
    const { InputProps, disabled = false, label, onEdit } = props;
    const [field, meta, helpers] = useField(InputProps)
    const { setValue } = helpers;
    const { value } = meta;
    const [showEmail, setShowEmail] = useState(false)
    const itemEmail = useRef("");
    const Intl = useIntl();

    useEffect(()=> {
        setShowEmail(false)
    }, [props.open])


    let emailList = useMemo(() =>{
        let arr = ["gmail.com","hotmail.com","yahoo.com","outlook.com","icloud.com","aol.com","mail.com","live.com","wp.pl","gmx.net"];
        let newArr;
        const email = value;
        if(email?.indexOf("@") >= 0){
            let emailArr = email?.split("@");
            let value = emailArr[1];
            itemEmail.current = emailArr[0];
            if(!!value){
                newArr = arr.filter(item =>{
                    return item.indexOf(value) >= 0 && value !== item;
                });
            }else{
                newArr = arr;
            }
        }else{
            newArr = arr;
            itemEmail.current = email;
        }

        return newArr;
    },[value]);
    

    return <div className={`${classes.InputFiledContainer} ${props?.className ? props.className : ""}`}>
        {
            label && <label className={classes.InputLabel}>{label}</label>
        }
        <div className={`${classes.InputFiled} ${meta.touched && meta.error?classes.ErrorCon:""} ${disabled?classes.Disabled:""}`}>
            <input
                className={`${classes.InputFiledInput}`} 
                {...field} 
                {...InputProps} 
                autocomplete="off" 
                placeholder={Intl.formatMessage({ id: "email_address", defaultMessage: "Email Address" })}
                disabled={disabled} 
                onFocus={() => {
                    setShowEmail(true)
                }}
            />

            <div className={classes.InputIconCon}>
                {
                    disabled ? <React.Fragment>
                        <span className={classes.InputIconSub} onClick={onEdit}>
                            <Iconfont className={classes.EditIcon}>&#xe778;</Iconfont>
                        </span>
                    </React.Fragment> : <React.Fragment>
                        {
                            value && <span
                                className={classes.ClearIcon}
                                onClick={() => {
                                    setValue('')
                                }}>
                            </span>
                        }
                    </React.Fragment>
                }
            </div>
        </div>

        <div className={classes.ErrorMsg}>{meta.touched && meta.error ? meta.error : null}</div>

        {
            showEmail && value && emailList?.length > 0 && <ul className={classes.EmailList}>
                {
                    emailList.map((item,index) =><li key={item+index} onClick={() =>{
                        setValue(`${itemEmail.current}@${item}`);
                        setShowEmail(false)
                    }}>{`${itemEmail.current}@${item}`}</li>)
                }
            </ul>
        }
       
    </div>
})