import React, { useState, useCallback } from 'react'
import useStyles from 'isomorphic-style-loader/useStyles'
import classes from './style.module.scss'
import CountrySelect from '../../components/country-select'
import Input from '../../components/input'
import { fetchValidCode, addRequestPrivacy } from '../../../../../../api'
import Toast from '../../../../../components/toast'
import { BigButton } from '../../../../../components/button'
import { Iconfont } from '../../../../../components/icon/iconfont'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'


const emailReg = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
const FormELements = props => {
    useStyles(classes)
    return <div className={`${classes.Elements} 11`}>
        {props.children}
    </div>
}

const FormElement = props => {
    useStyles(classes)
    return <div className={`${classes.FormElement} ${props.className}`}>
        {props.children}
    </div>
}


const SendCode = props => {
    useStyles(classes)
    const intl = useIntl()
    const [loading, setLoading] = useState(false)

    const { email, validateEmail } = props

    const clickHandler = async e => {
        e.preventDefault()
        const isValid = validateEmail()
        if (isValid) {
            setLoading(true)
            const response = await fetchValidCode(email)
            setLoading(false)
            if (response?.code === 200) {
                Toast(intl.formatMessage({ id: 'valid_code_sent', defaultMessage: 'The verification code has been sent, please go to your email to check the verification code.' }))
            } else {
                Toast(response.result)
            }
        }
    }

    return <div className={classes.ValidCode}>
        <div className={classes.InputArea}>
            <Input {...props} />
        </div>

        {
            loading ? <button disabled className={classes.Send}>
                <FormattedMessage id="sending" defaultMessage="Sending"/>
            </button>: <button onClick={clickHandler} className={classes.Send}>
                <FormattedMessage id="send" defaultMessage="Send"/>
            </button>
        }

    </div>
}

const Success = props => {
    useStyles(classes)
    return <div className={classes.ThankQuest}>
        <div className={classes.Icon}>
            <Iconfont className={classes.IFont}>&#xe6b7;</Iconfont>
        </div>
        <div className={classes.Title}>
            <FormattedMessage id="submit_successfully" defaultMessage="Submitted successfully"/>
        </div>
        <div className={classes.Description}>
            <FormattedMessage id="thanck_request" defaultMessage="Thank you for your request. A representative will review your request and contact you at the email address you provided."/>
        </div>
        <div className={classes.Button}>
            <BigButton onClick={props.onClose}>
                <FormattedMessage id="confirm" defaultMessage="CONFIRM"/>
            </BigButton>
        </div>
    </div>
}

export default props => {
    useStyles(classes)

    const intl = useIntl()
    const global = useSelector(state => state.global)


    const [checked, setChecked] = useState(false)
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const [country, setCountry] = useState('')
    const [countryError, setCountryError] = useState('')
    const [name, setName] = useState('')
    const [nameError, setNameError] = useState('')
    const [account, setAccount] = useState('')
    const [accountError, setAccountError] = useState('')
    const [code, setCode] = useState('')
    const [codeError, setCodeError] = useState('')
    const [description, setDescription] = useState('')
    const [descriptionError, setDescriptionError] = useState('')


    const validateAll = () => {
        let isValid = true
        if (!country) {
            setCountryError( intl.formatMessage({ id: 'country_is_required', defaultMessage: 'Please select your country/region' }))
            isValid = false
        }
        if (!name) {
            setNameError(intl.formatMessage({ id: 'name_is_required', defaultMessage: 'Please enter your full name' }))
            isValid = false
        }else{
            if(name?.length < 2 || name?.length > 100){
                setNameError(intl.formatMessage({ id: 'place_holder_fullname', defaultMessage: 'Full name should contain 2-100 characters' }))
                isValid = false
            }
        }

        
        if (!account) {
            setAccountError(intl.formatMessage({ id: 'account_is_required', defaultMessage: 'Please enter your email address' }))
            isValid = false
        }else{
            if(!emailReg.test(account)){
                setAccountError(intl.formatMessage({ id: 'account_is_invalid', defaultMessage: 'The email you entered is invalid.' }))
                isValid = false
            }
        }

        if (!code) {
            setCodeError(intl.formatMessage({ id: 'code_is_required', defaultMessage: 'Please enter the verification code' }))
            isValid = false
        }
        if (!description) {
            setDescriptionError(intl.formatMessage({ id: 'description_is_required', defaultMessage: 'Please describe your problem in detail' }))
            isValid = false
        }
        return isValid
    }

    const validateEmail = () => {
        let isValid = true
        if (!account) {
            setAccountError(intl.formatMessage({ id: 'account_is_required', defaultMessage: 'Please enter your email address' }))
            isValid = false
        }else{
            if(!emailReg.test(account)){
                setAccountError(intl.formatMessage({ id: 'account_is_invalid', defaultMessage: 'The email you entered is invalid.' }))
                isValid = false
            }
        }
        return isValid
    }

    const handleSubmit = async e => {
        setLoading(true)
        const isValid = validateAll()
        if (isValid) {
            const response = await addRequestPrivacy({
                country: country,
                detailedDescription: description,
                email: account,
                fullName: name,
                verificationCode: code
            })
    
            if (response?.code === 200) {
                setSuccess(true)
                setLoading(false)
            } else {
                Toast(response?.result || 'error')
                setLoading(false)
            }
        }else{
            setLoading(false)
        }
    }

    const onClose = () => {
        props?.onClose()
        setSuccess(false)
    }

    const changeHandler = (v, name) => {
        switch(name) {
            case 'country':
                setCountry(v)
                setCountryError('')
                break
            case 'name':
                setName(v)
                setNameError('')
                break
            case 'account':
                setAccount(v)
                setAccountError('')
                break
            case 'code':
                setCode(v)
                setCodeError('')
                break
            case 'description':
                setDescription(v)
                setDescriptionError('')
                break
            default:
                break                    
        }
    }


    

    return <div className={classes.Container}>
        {
            success ? <Success onClose={onClose}/> : <React.Fragment>
                <div className={classes.Boxes}>
                    <FormELements>
                        <FormElement className={countryError ? classes.Error: ''}>
                            <CountrySelect
                                title={<FormattedMessage id="country" defaultMessage="Country/Region"/>}
                                required
                                value={country}
                                error={countryError}
                                placeholder={<FormattedMessage id="place_holder_country" defaultMessage="Please choose your Country/Region."/>}
                                onChange={v => { changeHandler(v, 'country') }} />
                        </FormElement>
                    </FormELements>

                    <FormELements>
                        <FormElement className={nameError ? classes.Error: ''}>
                            <Input
                                error={nameError}
                                title={<FormattedMessage id="full_name" defaultMessage="Full Name"/>}
                                required
                                value={name}
                                placeholder={<FormattedMessage id="place_holder_fullname" defaultMessage="Full name should contain 2-100 characters."/>}
                                onChange={v => { changeHandler(v, 'name') }} />

                        </FormElement>

                        <FormElement className={accountError ? classes.Error: ''}>
                            <Input
                                title={<FormattedMessage id="your_account" values={{webname: global?.config?.name}} defaultMessage="Your {webname} account"/>}
                                required
                                error={accountError}
                                value={account}
                                placeholder={<FormattedMessage id="place_holder_account" defaultMessage="We will need you to provide the email address associated with your account in order to find the personal data we have stored in our system."/>}
                                onChange={v => { changeHandler(v, 'account') }} />
                        </FormElement>

                        <FormElement className={codeError ? classes.Error: ''}>
                            <SendCode
                                title={<FormattedMessage id="verification_code" defaultMessage="Verification code"/>}
                                validateEmail={validateEmail}
                                required
                                value={code}
                                email={account}
                                error={codeError}
                                placeholder={<FormattedMessage id="code_is_required" defaultMessage="Please enter verification code."/>}
                                onChange={v => { changeHandler(v, 'code') }} />
                        </FormElement>
                    </FormELements>


                    <FormELements>
                        <FormElement className={descriptionError ? classes.Error: ''}>
                            <Input
                                title={<FormattedMessage id="detailed_description" defaultMessage="Detailed Description"/>}
                                required
                                limit={1000}
                                value={description}
                                error={descriptionError}
                                placeholder={<FormattedMessage id="place_holder_description" defaultMessage="Please enter a detailed description."/>}
                                onChange={v => { changeHandler(v, 'description') }} />
                        </FormElement>
                    </FormELements>
                </div>

                <div className={classes.Policy} onClick={e => { setChecked(!checked) }}>
                    <input type="checkbox" checked={checked} />
                    <div className={classes.Text}>
                        <FormattedMessage 
                        id="i_agree_to_the_privacy_policy" 
                        values={{
                            policy: <a href={`/policy/privacy-security-policy`} target="_blank" style={{
                                color: '#0d58a3',
                                textDecoration: 'none'
                            }}>
                                <FormattedMessage id="privacy_policy" defaultMessage="Privacy & Cookie Policy"/>
                            </a>,
                            webname: global?.config?.name
                        }}
                        defaultMessage="I agree that {webname} collets and use the data I submit above according to {policy} to Process my request."/>
                    </div>
                </div>

                <div className={classes.Submit}>
                    <BigButton onClick={handleSubmit} disabled={!checked} loading={loading}>
                        <FormattedMessage id="submit" defaultMessage="Submit"/>
                    </BigButton>
                </div>
            </React.Fragment>
        }








    </div>
}