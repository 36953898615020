import React, { useEffect, useState } from "react";
import BottomToTop from "../../../../components/transitions/bottom-to-top";
import useStyles from 'isomorphic-style-loader/useStyles'
import classes from "./style.module.scss"
import { BlackMask } from "../../../../components/mask";
import { fetchShareUrl } from "../../../../../api";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { Iconfont } from "../../../../components/icon/iconfont";
// import { Toast } from "../../../../components/toast";

import ShareToFace from "../../tools/share-to-face";
import ShareToPinterest from "../../tools/share-to-pinterest";
import ShareToWhatsapp from "../../tools/share-to-whatsapp";
import ShareToMessage from "../../tools/share-to-message";
import CopyPageLink from "../../tools/copy-page-link";

const Social = props => {
    const { onClose, fetchParams, sharePrefix, shareImageurl, isShare } = props;
    useStyles(classes);
    const [shareUrl, setShareUrl] = useState(null);
    const [earnPoints,setEarnPoints] = useState(false)

    useEffect(async () => {
        let response = await fetchShareUrl(fetchParams);
        if (response.code === 200) {
            setShareUrl(response?.result);
        } else {
            alert(response?.result);
        }
    }, []);

    const earnPointsEvent = () => {
        setEarnPoints(true);

        setTimeout(() => {
            setEarnPoints(false);
        }, 3000);
        // Toast("Copy successful");
    }

    const shareClickSensor = (platform) => {
        if(isShare) {
            if(window.GeekoSensors){
                window.GeekoSensors.Track("GiftCardShare",{
                    button: `share_${platform}`,
                })
            }
        }
    }

    return <div className={classes.SocialBox}>
        <Iconfont className={classes.closeIcon} onClick={onClose}>&#xe69a;</Iconfont>

        <div className={classes.Hd}>
            <span><FormattedMessage id="share_to" defaultMessage="Share To" /></span>
        </div>

        {props?.children}

        <div className={classes.Bd}>
            <div className={classes.Item}>
                <div className={`${classes.PluginContainer} ${classes.FacebookShare}`}>
                    <ShareToFace shareUrl={shareUrl?.facebook} onClick={() => shareClickSensor('facebook')}>
                        <span className={classes.FaceTool}>
                            <Iconfont className={classes.FaceIcon}>&#xe65e;</Iconfont>
                        </span>
                    </ShareToFace>
                    {/* <FacebookShareButton
                        url={shareUrl?.facebook}
                    >
                        <FacebookIcon size={50} round />
                    </FacebookShareButton> */}
                </div>

                <p>Facebook</p>
            </div>

            {
                shareImageurl &&
                <div className={classes.Item}>
                    <div className={`${classes.PluginContainer} ${classes.PinterestShare}`}>
                        <ShareToPinterest
                            shareUrl={shareUrl?.pinterest}
                            imageUrl={shareImageurl}
                            onClick={() => shareClickSensor('pinterest')}
                        >
                            <span className={classes.PinterTool}>
                                <Iconfont className={classes.PinterIcon}>&#xe65f;</Iconfont>
                            </span>
                        </ShareToPinterest>

                        {/* <PinterestShareButton
                        >
                            <PinterestIcon size={50} round />
                        </PinterestShareButton> */}
                    </div>

                    <p>Pinterest</p>
                </div>
            }

            <div className={classes.Item}>
                <ShareToMessage
                    shareValue={`${sharePrefix?sharePrefix:""}${shareUrl?.message}`}
                    className={`${classes.MessageShare} ${classes.PluginContainer}`}
                    onClick={() => shareClickSensor('message')}
                >
                    <Iconfont>&#xe784;</Iconfont>
                </ShareToMessage>

                <p>Message</p>
            </div>
            

            <div className={classes.Item}>
                <ShareToWhatsapp 
                    shareValue={`${sharePrefix?sharePrefix:""}${shareUrl?.whats_app}`}
                    className={`${classes.WhatsAppShare} ${classes.PluginContainer}`}
                    onClick={() => shareClickSensor('whatsapp')}
                >
                    <Iconfont>&#xe785;</Iconfont>
                </ShareToWhatsapp>

                <p>WhatsApp</p>
            </div>

            <div className={classes.Item}>
                <div className={classes.PluginContainer}>
                    <CopyPageLink
                        className={`${classes.PluginContainer} ${classes.CopyLink}`} 
                        successCallback={()=>earnPointsEvent()}
                        copyValue={shareUrl?.unknown}
                        onClick={() => shareClickSensor('copylink')}
                    >
                        <Iconfont>&#xe776;</Iconfont>
                    </CopyPageLink>
                </div>

                <p><FormattedMessage id="copy_link" defaultMessage="Copy Link" /></p>
            </div>
        </div>

        <div className={classes.Fd} onClick={onClose}>
            <div className={classes.Container}>
                <span><FormattedMessage id="cancel" defaultMessage="Cancel" /></span>
            </div>
        </div>
        
        {
            earnPoints && <div className={classes.EarnPointsToast}>
                <Iconfont>&#xe6b7;</Iconfont>
                <span><FormattedMessage id="copy_success" defaultMessage="Copy successful" />!</span>
            </div>
        }
    </div>
}

const ProductShare = (props) => {
    useStyles(classes);
    const { showShareModal, onClose } = props;
    return (
        <React.Fragment>
            <BottomToTop in={showShareModal} className={classes.ProductShareContainer}>
                <div>
                    <Social {...props}/>
                </div>
            </BottomToTop>

            {
                showShareModal && <BlackMask onClick={onClose} />
            }
        </React.Fragment>
    )
}

export default ProductShare;