import React, { useState, useCallback, useRef } from "react";
import classes from "./style.module.scss";
import useStyles from "isomorphic-style-loader/useStyles";
import { v4 } from "uuid";
import { useMemo } from "react";
import { useField } from "formik";
import { Iconfont } from "../../../../components/icon/iconfont";

export default props =>{
    useStyles(classes);
    const { InputProps, currentCode, disabled, changeType, showPassword, onEdit, label } = props;
    const [field, meta, helpers] = useField(InputProps)
    const { setValue } = helpers;
    const { value } = meta;
    const phoneNumberReg = /^[0-9]+$/;

    const phoneId = useMemo(() =>{
        return v4();
    },[])


    const changeHandle = useCallback((e) =>{
        field.onChange(e)
        let value = e.target.value;
        let flag = phoneNumberReg.test(value);
        changeType(!flag);
    },[])


    return <div className={`${classes.InputFiledContainer} ${classes.PhoneNumber}`}>
        {
            label && <label htmlFor={phoneId} className={classes.InputLabel}>{label}</label>
        }

        <div id={phoneId} className={`${classes.InputFiled} ${classes.PhoneCon} ${meta.touched && meta.error?classes.ErrorCon:""} ${disabled?classes.Disabled:""}`}>
            <div style={{display:`${!!value && !showPassword?'block':'none'}`}} className={classes.AreaCodeVessel}>
                <span className={classes.FontBold}>{currentCode?.value} {currentCode?.areaCode}</span>
            </div>
            
            <input 
                className={classes.InputFiledInput} 
                {...field} 
                {...InputProps} 
                autoComplete={"off"}
                onChange={(e)=>changeHandle(e)}
                disabled={disabled} 
            />

            <div className={classes.InputIconCon}>
                {
                    disabled ? <React.Fragment>
                        <span className={classes.InputIconSub} onClick={onEdit}>
                            <Iconfont className={classes.EditIcon}>&#xe778;</Iconfont>
                        </span>
                    </React.Fragment> : <React.Fragment>
                        {
                            value?.length > 0 && <span
                                className={classes.clearIcon}
                                onClick={() => {
                                    setValue('');
                                    changeType(false);
                                }}>
                            </span>
                        }
                    </React.Fragment>
                }
                
            </div>
            
        </div>

        <div className={classes.ErrorMsg}>{meta.touched && meta.error ? meta.error : null}</div>
    </div>
}