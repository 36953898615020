import React, { useCallback, useEffect, useState } from 'react'
import ReactDOM from "react-dom";
import { BlackMask } from "../../../../components/mask";
import BottomToTop from '../../../../components/transitions/bottom-to-top'
import classes from './style.module.scss'
import { IMAGE_GEEKO_LTD } from '../../../../../constants'

import { Swiper, SwiperSlide } from 'swiper/react'
import { fetchProductDetails, addToCart, doLuckDrawTask, selectGift, updateAddOnItem, saveWishListProduct, removeWishListProduct } from '../../../../../api'
import { unitPrice, getLowerPrice, getDelPrice, isFlashSales, getLowerPriceWithVariant, getDelPriceWithVariant, getProductUrl, getAddOnLowerPrice, getAddOnDelPrice } from "../../../../../utils/product";
import { useDispatch, useSelector } from "react-redux";
import { Iconfont } from "../../../../components/icon/iconfont";
import { setWishList, action_fetch_cart_count, setRaffle, setShowRaffle, SET_SIZE_CHART } from "../../../../../store/actions/global";
import { action_fetch_cart } from '../../../../../store/actions/pages/cart';

import { BigButton } from '../../../../components/button/index'
import FlyToCart from './fly-to-cart'
import { FormattedMessage, useIntl } from 'react-intl'
import GeekoEvents from '../../../../ads/events'
import { getColor } from '../../../../../utils/product'
import { useHistory, withRouter } from 'react-router-dom'
import qs from 'query-string'
import Cache from '../../../../../utils/cache'


import useStyles from 'isomorphic-style-loader/useStyles'
import NormalConfirm from '../../../../components/alert/confirm'
import { useApp, useHeader, useWebp } from '../../../../hooks/common';

import { TOGGLE_UI_LOGIN } from '../../../../../store/actions/ui';

import { action_fetch_activity_promotional } from '../../../../../store/actions/global';
import { action_fetch_gifts_info_message } from '../../../../../store/actions/pages/collection';
import Toast from '../../../../components/toast';
import { useRef } from 'react';
import SizeCountrySelector from '../../../components/size-country-selector'
import { action_fetch_gift_info, action_fetch_order_prompt } from '../../../../../store/actions/pages/cart';
import { fbAddToCart } from '../../../../../api/facebook-ctm-ads';
import { action_fetch_customer, action_fetch_wishlist_count, initWishList, unshiftWishList, action_init_wishlist } from '../../../../../store/actions/pages/customer';
import { TOOGLE_UI_POPUP_SLIDE_IMAGES } from '../../../../../store/actions/ui';
import useOpenSizeChart from '../../../../hooks/open-sizechart'
import { useMemo } from 'react';
import { getOriginalSize } from '../../../../utils/sensor';
import { PreOrderComponent } from '../list/pre-order-compnents';


const ImageSwiper = props => {
	useStyles(classes)
	const { product } = props

	const isWebp = useWebp()
	const dispatch = useDispatch();

	let images = product?.pcExtraImageUrls || []
	images = [product?.pcMainImage, ...images]


    const imagePrefix = `${IMAGE_GEEKO_LTD}${isWebp ? '/webp':''}`

	const imageHandle = (index) =>{
		dispatch({
			type: TOOGLE_UI_POPUP_SLIDE_IMAGES, 
			payload: {
				show: true,
				options: {
					images: images,
					selctedIndex: index
				}
			}
		});
	}


	return product ? <Swiper
		onSlideChange={() => {
		}}
		onSwiper={(swiper) => {
		}}
		spaceBetween={10}
		slidesPerView='auto'
	>
		{
			images?.map((image, index) => <SwiperSlide className={classes.EditorDisplayImage} key={index} onClick={() =>imageHandle(index)}>
				<img src={`${imagePrefix}/medium/${image}`} />
			</SwiperSlide>)
		}

	</Swiper> : <Swiper
		onSlideChange={() => {
		}}
		onSwiper={(swiper) => {
		}}
		spaceBetween={10}
		slidesPerView='auto'
	>
		{
			[{}, {}, {}].map((image, index) => <SwiperSlide className={classes.EditorDisplayImage} key={index}>

			</SwiperSlide>)
		}

	</Swiper>
}

const ColorSwiper = props => {
	const { products, selectedProduct, style } = props

	const isWebp = useWebp()

	const imagePrefix = `${IMAGE_GEEKO_LTD}${isWebp ? '/webp':''}`

	const isSameProduct = useCallback((p1, p2) => {
		return p1?.id === p2?.id && p1?.variants?.[0]?.color === p2?.variants?.[0]?.color
	}, [])

	return <div className={classes.ColorContainer}>
		{
			products?.map((product, index) => style === 1 ? <a style={{
				backgroundColor: `${getColor(product?.variants?.[0]?.color)}`,
				backgroundImage: product?.variants?.[0]?.colorImage ? `url(${product?.variants?.[0]?.colorImage})`: undefined
			}} onClick={() => { props?.onSelect(product) }} className={`${classes.EditorColorPlus} ${isSameProduct(product, selectedProduct) ? classes.Selected : ''}`} key={index}>
				{
					!!product?.hotColor && <span className={classes.HotColor}>
						<span>Hot</span>
					</span>
				}
			</a> : <a onClick={() => { props?.onSelect(product) }} className={`${classes.EditorColor} ${isSameProduct(product, selectedProduct) ? classes.Selected : ''}`} key={index}>
				<span 
				className={classes.NormalColor}
				style={{
					backgroundColor: `${getColor(product?.variants?.[0]?.color)}`,
					backgroundImage: `url(${imagePrefix}/small/${product?.variants?.[0]?.image || product.pcMainImage})`
				}}></span>

				{
					!!product?.hotColor && <span className={classes.HotColor}>
						<span>Hot</span>
					</span>
				}
			</a>)
		}
	</div>
}

const SizeSwiper = props => {
	const { selectedProduct, variants, selectedVariant } = props
	const global = useSelector(state => state.global)

	const getSizeLabel = size => {
		const countries = selectedProduct?.sizeChart2?.countryAndSizesList?.[0]?.map(c => c.country)
        const sizeDefaultCountry = countries?.find(c => c === global?.sizeDefaultCountry) || selectedProduct?.sizeChart2?.defaultCountry
        const sizes = selectedProduct?.sizeChart2?.countryAndSizesList?.find?.(s => s[0]?.size === size)
        if(sizes?.length > 0){
            return sizes?.find(s => s.country === sizeDefaultCountry)?.size || size
        }
        return size
    }

	const isEnabled = v => {
		if (!v) return true
		return v.status === '1' && v.inventory > 0 || (v.domesticDelivery && v.domesticDelivery.enabled && v.domesticDelivery.inventory > 0)
	}


	return variants ? <Swiper
		spaceBetween={10}
		slidesPerView='auto'
	>
		{
			variants?.map((variant, index) => <SwiperSlide onClick={() => { props?.onSelect(variant, selectedProduct) }} className={`${classes.EditorSize} ${!isEnabled(variant)?classes.SoldOut: ''} ${variant.id === selectedVariant?.id ? classes.Selected : ''}`} key={variant.id}>
				{getSizeLabel(variant.size)}
			</SwiperSlide>)
		}

	</Swiper> : <Swiper
		spaceBetween={10}
		slidesPerView='auto'
	>
		{
			[{}, {}, {}].map((variant, index) => <SwiperSlide className={`${classes.EditorSize}`} key={index}>
			</SwiperSlide>)
		}
	</Swiper>


}


const ProductEditor = withRouter(props => {

	const { productId, color, sensors, location, isGift, isAddOn, product_type, giftInfo, variantId, isCollectionGift, addToCartApi, noAddWish, callBack } = props
	const {search} = location
	const intl = useIntl()
	const isWebp = useWebp()
	const history = useHistory()
	
	const query = qs.parse(location?.search)

	const dispatch = useDispatch()
	const global = useSelector((state) => state.global)
	const { wishlist, raffle, cart } = global
	const { wishListFilterResult } = useSelector(state => state.pageCustomer)
	const { sizeChart, setSizeChart } = useOpenSizeChart()

	const [productVo, setProductVo] = useState(undefined)
	const [selectedProduct, setSelectedProduct] = useState(undefined)
	const [selectedVariant, setSelectedVariant] = useState(undefined)
	const [cartImage, setCartImage] = useState(null)

	const [loading, setLoading] = useState(false)

	const isApp = useApp()

	const imagePrefix = `${IMAGE_GEEKO_LTD}${isWebp ? '/webp':''}`

	const descriptionRef = useRef();

	useEffect(async () => {
		const params = isAddOn ? {type: 2}: isGift ? {type: 1}: null
		const response = await fetchProductDetails(productId, params)
		if (response.code === 200) {
			setProductVo(response.result)
			const selected = response.result?.products?.find(p => p.id === productId && p.variants?.[0]?.color === color) || response.result?.products?.[0]
			setSelectedProduct(selected)
			if(selected?.saved){
				dispatch(setWishList([...(wishlist || []), productId]))
			}

			if(!!variantId){
				let variant = selected?.variants?.find(item =>item?.id === variantId);
				setSelectedVariant(variant)
			}else if(selected?.variants?.length === 1 || response?.result?.style === 1){
				setSelectedVariant(selected?.variants?.[0])
			}

		} else {
			alert('Error')
		}
	}, [])

	useEffect(async () => {
		if (productVo) {
			const p = productVo?.products?.find(p => p.id === productId && p.variants?.[0]?.color === color) || productVo?.products?.[0]
			setSelectedProduct(p)
			if(p?.saved){
                dispatch(setWishList([...(wishlist || []), productId]))
            }
		}
	}, [productId, color])

	const isEstimated = selectedProduct?.estimatedPrice && !isAddOn && !isGift

	const estimatedPercent = useMemo(() => {
        let highPrice = getAddOnDelPrice(selectedProduct)
        let estimatedPrice = selectedProduct?.estimatedPrice
        if (estimatedPrice) {
            return Math.round((Math.abs(estimatedPrice?.amount - highPrice?.amount) / highPrice?.amount) * 100)
        } else {
            return null
        }
	}, [selectedProduct])

	const lower = selectedVariant && !isAddOn ?
        getLowerPriceWithVariant(selectedVariant) :
        isAddOn ?
        getAddOnLowerPrice(selectedProduct):
        getLowerPrice(selectedProduct)

	const higher = selectedVariant && !isAddOn ?
            getDelPriceWithVariant(selectedVariant) :
        isAddOn ?
            getAddOnDelPrice(selectedProduct) :
            getDelPrice(selectedProduct)

	let off = 0
	if (higher) {
		off = Math.round((Number(higher.amount) - Number(lower.amount)) * 100 / Number(higher.amount))
	}

	const selectHandle = (product) => {
		setSelectedProduct(product)
		if(product?.saved){
            dispatch(setWishList([...(wishlist || []), product?.id]))
        }
		setSelectedVariant(null)
		// product?.variants?.length === 1 && !!!product?.variants?.[0]?.size || productVo?.style === 1
		if(product?.variants?.length === 1 || productVo?.style === 1){
			setSelectedVariant(product?.variants?.[0])
		}
	}


	const sizeHandle = useCallback((v, p) => {
		setSelectedVariant(v)

		if(descriptionRef?.current){
			descriptionRef.current?.scrollIntoView?.({
                behavior: 'smooth'
            })
		}
	}, [])

	const getDescription = useCallback(desc => {
		return desc?.replace(/:[\w|\d|\-|(|)|\.]+;/g, function (value) { return '<span style="color:#999;margin-right: 5px;">' + value + ' </span>' })
	})


	const toggleWishHandle = (productId, product) => {
		const hasProduct = wishlist?.some(pId => pId === productId)
		// const hasWished = product?.saved
		let newWish, newWishListResult
		if (hasProduct) {
			newWish = wishlist?.filter(pId => pId !== productId)
			newWishListResult = [...(wishListFilterResult || [])]?.filter(w => w?.id != productId)
			removeWishListProduct(productId).then(res => {
				dispatch(initWishList(newWishListResult))
				dispatch(action_fetch_wishlist_count())
				dispatch(setWishList(newWish))
			})
		} else {
			newWish = [...(wishlist || []), productId]
			newWishListResult = [...(wishListFilterResult || []), product]
			saveWishListProduct(productId).then(data => {
				if (data.code === 300 || data.code === 600) {
					if(isApp){
						window.location.href = "chic-me://chic.me/loginRoot"
					}else{
						// window.location.href = `/i/login?redirectUrl=${window.location.href}`
						dispatch({
							type: TOGGLE_UI_LOGIN,
							payload: {
								showLogin:true,
								eventType:"收藏页",
								callBack: async () => {
									saveWishListProduct(productId)
									dispatch(setWishList(newWish))
									if(location.pathname?.startsWith('/cart')){
										dispatch(action_fetch_cart())
									}
									if(location.pathname?.indexOf('/user') >= 0){
										if(location.pathname?.startsWith('/user/wishlist')){
											// 在心愿单页面收藏的话，手动把产品加到第一个
											dispatch(unshiftWishList(product))
											dispatch(action_fetch_wishlist_count())
										} else {
											// 兼容 /user页面时候 wishlist与you also like双列表时候 弹窗出来未请求当前customer数据
											await dispatch(action_fetch_customer());
											dispatch(action_init_wishlist());
										}
									}
								}
							}
						});
					}
				} else if(data.code === 400){
					alert(data?.result)
				}else{
					if(location.pathname?.indexOf('/user') >= 0){
						if(location.pathname?.startsWith('/user/wishlist')){
							// 在心愿单页面收藏的话，手动把产品加到第一个
							dispatch(unshiftWishList(product))
							dispatch(action_fetch_wishlist_count())
						} else {
							dispatch(action_init_wishlist());
						}
					}
					dispatch(setWishList(newWish))
				}
			}).catch(err => {
				if(err?.result){
					alert(err?.result)
				} else {
					console.error(err)
				}
			})
		}

		GeekoEvents.addToWishList({
			productId,
			sensors
		})
	}


	const addToCartHandle = async () => {

		if (selectedVariant) {
			setLoading(true)
 
			let response

			if(isGift){

				response = await selectGift({
					variantId: selectedVariant.id
				})

				window.parent?.refreshCart?.()
			} else if(isAddOn){
				let params = {
					variantId: selectedVariant.id,
					oldProductId: productId
				}
				response = await updateAddOnItem(params)
				window.parent?.refreshCart?.()
			} else if (addToCartApi){
				response = await addToCartApi({
					variantId: selectedVariant.id,
					quantity: 1,
					unifiedId: selectedVariant.domesticDelivery?.enabled ? selectedVariant.unifiedId: undefined,
					warehouseId: selectedVariant.domesticDelivery?.enabled ? selectedVariant.domesticDelivery?.warehouseId: undefined,
					shippedCountryCode: selectedVariant.domesticDelivery?.enabled ? selectedVariant.domesticDelivery?.countryCode : undefined
				})
			}else{
				response = await addToCart({
					variantId: selectedVariant.id,
					quantity: 1,
					unifiedId: selectedVariant.domesticDelivery?.enabled ? selectedVariant.unifiedId: undefined,
					warehouseId: selectedVariant.domesticDelivery?.enabled ? selectedVariant.domesticDelivery?.warehouseId: undefined,
					shippedCountryCode: selectedVariant.domesticDelivery?.enabled ? selectedVariant.domesticDelivery?.countryCode : undefined
				})
			}
			



			//isGift
			setLoading(false)

			if (response.code === 200) {
				props?.onClose()
				setCartImage(`${imagePrefix}/small/${selectedProduct?.variants?.[0]?.image || selectedProduct?.pcMainImage}`)
				setTimeout(() => {
					setCartImage(null)
				}, 400)
				dispatch(action_fetch_cart_count())

				if(location?.pathname === '/gifts' || location?.pathname === '/cart' || location?.pathname?.startsWith('/cart/')){
					if(!addToCartApi){
						dispatch(action_fetch_cart())
					}
				}

				if(location?.pathname === '/cart/collect-products' || location?.pathname === '/cart/free-gifts'){
					dispatch(action_fetch_gift_info())
					dispatch(action_fetch_order_prompt(query?.type))
				}

				if(isGift){
					if(query?.ref === 'gifts' && giftInfo?.difference){
						Toast(giftInfo?.giftWarnMsg);
					}else{
						Toast(intl.formatMessage({ id: 'add_successful', defaultMessage: 'Add Successful' }));
					}
				}
			} else {
				Toast(response.result)
			}


			GeekoEvents.addToCart({
				productId: selectedProduct?.id,
				sku: selectedProduct.parentSku,
				variantId: selectedVariant.id,
				variantSku: selectedVariant.sku,
				quantity: 1,
				amount: lower?.amount,
				currency: lower?.currency,
				product_type: product_type,
				is_success: response?.code === 200,
				variantSize: getOriginalSize(selectedVariant?.size),
				usdPrice: selectedVariant?.salesPriceWithUSD?.amount,
				isGift:!!isGift,
				name: selectedProduct.name,
				size: getOriginalSize(selectedVariant?.size),
				sensors
			})
			if(location?.pathname !== '/cart'){
				fbAddToCart(selectedProduct?.id, lower?.amount, lower?.currency)
			}

			try{
				if(query?.promotionType && raffle){
					


					const storedRaffle = Cache.get(query?.promotionType)

					if(storedRaffle && storedRaffle.task){
						const { task } = storedRaffle
						const addToCartTimes = task?.rule?.addToCartTimes


						if(storedRaffle?.currentCount + 1 >= addToCartTimes){
							Cache.set(query?.promotionType, {
								...storedRaffle,
								currentCount: 0,
								currentCompletedTimes: storedRaffle?.currentCompletedTimes + 1
							})
							
							dispatch(setRaffle({
								...raffle,
								currentCount: 0,
								currentCompletedTimes: storedRaffle?.currentCompletedTimes + 1,
							}))


							doLuckDrawTask('02').then(() => {
								dispatch(setShowRaffle(true))
							})

						}else{
							Cache.set(query?.promotionType, {
								...storedRaffle,
								currentCount: storedRaffle?.currentCount + 1
							})
							
							dispatch(setRaffle({
								...raffle,
								currentCount: storedRaffle?.currentCount + 1,
							}))
						}

					}

				}

				if((query?.ref === 'promotion' || query?.ref === 'gifts') && query?.productId && query?.activityId){
					dispatch(action_fetch_activity_promotional(query?.productId, query?.activityId));
				}

				if(query?.ref === 'gifts'){
					dispatch(action_fetch_gifts_info_message());
				}

				if(location?.pathname === '/cart/free-gifts'){
					window.history.go(-1)
				}

				// 换购接口
				if((isAddOn && location?.pathname === '/cart/collect-products') && response.code === 200){
					callBack && callBack?.()
				}
			}catch(e){
				console.error(e)
			}

		} else {

			if(window.GeekoSensors){
                window.GeekoSensors.Track('Addtocart', {
                    is_success: false,
                    product_id: selectedProduct?.id,
                    product_qty: 1,
                })
            }


			Toast(intl.formatMessage({id:'please_select_size', defaultMessage:'Please Select a Size'}));
		}

	}


	const isLiked = wishlist?.some(pId => pId === selectedProduct?.id)


	const isEnabled = v => {
		if (!v) return true
		return v.status === '1' && v.inventory > 0 || (v.domesticDelivery && v.domesticDelivery.enabled && v.domesticDelivery.inventory > 0)
	}

	const isFlash = isFlashSales(selectedProduct)

	const isDisabled = !isEnabled(selectedVariant) || (isFlash && selectedProduct?.promotion?.salesQty >= selectedProduct?.promotion?.totalQty)

	const isNew = selectedProduct?.isNew

	const percent = (per) => "-" + (per || 0) + '%'

	const sizeChartHandle = () => {
		setSizeChart({
            show: true,
            data: {
				selectedProduct: selectedProduct,
				products: productVo?.products,
				taglia: productVo?.taglia,
				productSizeData: productVo?.productSizeData
			}
        })
	}

	const productUrl = useMemo(() => {
		if(isApp){
			return `chic-me://chic.me/product?id=${selectedProduct?.id}`
		}else{
			let url = getProductUrl(selectedProduct?.id, selectedProduct?.name)
			if(isGift){
				if(url?.indexOf("?") == -1){
					url += '?product_type=gift'
				} else {
					url += '&product_type=gift'
				}
			} else if(isAddOn){
				if(url?.indexOf("?") == -1){
					url += '?product_type=addOn'
				} else {
					url += '&product_type=addOn'
				}
			}
			return url
		}
	}, [selectedProduct, isGift, isAddOn, isApp])


	const isPreOrder = selectedProduct?.isPreOrder && selectedProduct?.promotion?.type === '11' && selectedProduct?.promotion?.enabled
	

	return <div className={classes.Editor}>
		<Iconfont className={classes.Close} onClick={props.onClose}>&#xe7c9;</Iconfont>
		<div className={classes.EditorCon}>
			<div className={classes.ImageContainer}>
				<ImageSwiper product={selectedProduct} />
			</div>
			<div className={classes.Bd}>
				<div className={classes.EditorName}>
					{
						selectedProduct?.flag && <span className={`${classes.New} ${classes.PreFall}`}>{selectedProduct.flag}</span>
					}

					{
						!selectedProduct?.flag && isNew && !isPreOrder && <span className={classes.New}>New</span>
					}

					{
						isPreOrder && <span className={classes.PreOrderFlag}>Pre order</span>
					}
					<span>{selectedProduct?.name || '-'}</span>
				</div>

				<div className={`${classes.Prices} ${isEstimated && classes.PricesFlex}`}>

					{
						isGift ?
						<span className={ classes.PromotionLower }>
							{selectedProduct ? unitPrice(selectedProduct?.giftPrice || {...selectedProduct?.price, amount: '0.00'}) : '-'}
						</span> :

						<span className={off > 0 ? classes.PromotionLower : classes.Lower}>
							{
								(unitPrice(lower) || '-')
							}
						</span>
					}

					{
						isEstimated && <span className={classes.PriceDiscountBox}>
							<span className={classes.PriceTxt}><FormattedMessage id="estimated" defaultMessage={'Estimated'} /></span>
							<span className={classes.PriceDiscount}>{unitPrice(selectedProduct?.estimatedPrice)}</span>
						</span>
					}

					{
						higher && <del className={classes.Del}>{unitPrice(higher)}</del>
					}
					{
						off > 0 && !isGift && !isAddOn&& <span className={classes.Off}>{`-${off}%`}</span>
					}

					<a className={classes.Details} onClick={e => {
						e.preventDefault()
						window.location.href = productUrl
					}} href={productUrl}><FormattedMessage id="details" defaultMessage="Details"/>{' >'}</a>
				</div>


				{
					isPreOrder && <div className={`${classes.PreOrder}`}>
						<PreOrderComponent preorder={selectedProduct?.promotion} />
					</div>
				}



				{
					productVo?.products ? <div className={classes.Boxes}>
						{
							productVo?.products?.length > 1 && <div className={classes.Box}>
								<div className={classes.Hd}>
									<span className={classes.Title}>
										<FormattedMessage id="color" defaultMessage="Color" />:<span>{selectedProduct?.theme || selectedProduct?.variants[0]?.color}</span>
									</span>
								</div>
								<div className={classes.Bd}>
									<ColorSwiper style={productVo?.style} onSelect={selectHandle} products={productVo?.products} selectedProduct={selectedProduct} />
								</div>
							</div>
						}
					</div> : <div className={classes.ColorContainer}>
						{
							[{}, {}, {}, {}, {}].map((product, index) => <a className={`${classes.EditorColor}`} key={index}>
								<span className={classes.NormalColor} style={{backgroundColor: '#efefef'}}></span>
							</a>)
						}
					</div>
				}

				{
					productVo?.style !== 1 && !(selectedProduct?.variants?.length === 1 && !!!selectedProduct?.variants?.[0]?.size) && <div className={classes.Boxes}>
						<div className={classes.Box}>
							<div className={`${classes.Hd} ${classes.SelectedHd}`}>
								
								{/* <SizeCountrySelector sizeChart2={selectedProduct?.sizeChart2}/> */}
								{
									selectedProduct?.sizeChart2 ? <>
										<SizeCountrySelector sizeChart2={selectedProduct?.sizeChart2}/>
										{selectedProduct?.sizeChart2 && productVo?.taglia && productVo?.productSizeData && <span className={classes.SizeGuide} onClick={sizeChartHandle}><FormattedMessage id="size_guide" defaultMessage={'Size Guide'}/></span>}
									</>:
									<span><FormattedMessage id="size" defaultMessage={'Size'}/>:</span>
								}
							</div>
							<div className={classes.Bd}>
								<SizeSwiper onSelect={sizeHandle} selectedVariant={selectedVariant} variants={selectedProduct?.variants} selectedProduct={selectedProduct} />
								<div ref={descriptionRef} className={classes.Description} dangerouslySetInnerHTML={{ __html: getDescription(selectedVariant?.description) }} />
							</div>
						</div>
					</div>
				}

				{
					isFlash && selectedProduct.promotion.maxPurchasePerTimeQty > 0 && <div className={classes.PurchaseLimit}>
						<FormattedMessage id="purchase_limit" defaultMessage="Purchase limit: {qty} qty" values={{qty: selectedProduct.promotion.maxPurchasePerTimeQty}}/>
					</div>
				}
			</div>
		</div>

		<div className={classes.BottomCart}>
			{
				!noAddWish &&
				<Iconfont onClick={() => {
					toggleWishHandle(selectedProduct?.id, selectedProduct)
				}} className={`${classes.CartLike} ${isLiked ? classes.Liked : ''}`}>
					{
						isLiked ? <React.Fragment>&#xe775;</React.Fragment> : <React.Fragment>&#xe6a2;</React.Fragment>
					}
				</Iconfont>
			}

			{
				isDisabled ? <BigButton disabled>
					<FormattedMessage id="sold_out" defaultMessage="Sold Out" />
				</BigButton> : <BigButton onClick={() => {
					if(!isCollectionGift && isGift && giftInfo?.giftCount && !Cache.get('__depulite_pick_gift')){
						Cache.set('__depulite_pick_gift', true)
						NormalConfirm(intl.formatMessage({id:'free_gift', defaultMessage:'Free Gifts'}), intl.formatMessage({id:'multi_add', defaultMessage:'Sorry, you can only choose one item as a free gift, if you want add another item, it will replace the free gift you have already added.'}), {
							onConfirm: addToCartHandle,
							onClose: () => {props?.onClose()},
							cancelText: intl.formatMessage({id:'cancel', defaultMessage: 'Cancel'}),
							confirmText: intl.formatMessage({id:'confirm', defaultMessage: 'Confirm'})
						})
					}else{
						addToCartHandle()
					}

				}} loading={loading} disabled={!isEnabled()}>
					<FormattedMessage id="add_to_bag" defaultMessage="Add to Bag" />
				</BigButton>
			}

		</div>
		{
			cartImage && (typeof window !== 'undefined') && ReactDOM.createPortal(<FlyToCart image={cartImage} />, document.getElementById('root'))
		}
	</div>
})


const WrappedEditor = props => {
	return <React.Fragment>
		<BottomToTop in={props.showEditor} className={classes.EditorContainer}>
			<div>
				<ProductEditor {...props} />
			</div>
		</BottomToTop>
		{props.showEditor && <BlackMask onClick={props.onClose} />}
	</React.Fragment>
}


export default props => {

	if (typeof window === 'undefined')
		return null

	return ReactDOM.createPortal(
		<WrappedEditor {...props} />,
		document.getElementById('root')
	)
}