import React, { useCallback, useEffect, useState } from 'react'
import { Iconfont } from '../icon/iconfont'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import _ from 'lodash'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

export default props => {


    const [show, setShow] = useState(false)
    const location = useLocation()
    const ui = useSelector(state => state.ui)


    useEffect(() => {

        window.addEventListener('scroll', scrollHandle)

        return () => {
            window.removeEventListener('scroll', scrollHandle)
        }
    }, [])

    const scrollHandle = useCallback(event => {
        const scrollTop = window.scrollY
        if(scrollTop > 1200){
            setShow(true)
        }else{
            setShow(false)
        }
    }, [])


    const scrollToTopHandle = useCallback(evt => {
        window.scrollTo({
            top: 0, 
            behavior: 'smooth'
         })
    }, [])


    useStyles(classes)

    const isHomePage = location.pathname === '/' || location.pathname === '/index' || location.pathname === '/i/index'


    return show && (ui.direction === 'down' || !ui.direction) && <Iconfont onClick={scrollToTopHandle} className={`${classes.BackToTop} ${isHomePage?classes.Style1:''}`} >&#xe75c;</Iconfont>
}