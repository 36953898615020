import React, { useCallback, useEffect, useRef, useState } from 'react'
import classes from './style.module.scss'
import { IMAGE_GEEKO_LTD } from '../../../../../constants/index'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { unitPrice, getLowerPrice, getDelPrice, isFlashSales } from '../../../../../utils/product'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Iconfont } from "../../../../components/icon/iconfont";
import CartIcon from '../cart/cart-icon'
import { getProductUrl } from '../../../../../utils/product'
import useStyles from 'isomorphic-style-loader/useStyles'
import { withRouter } from 'react-router-dom'
import qs from 'query-string'

import withItemScroll from '../../../../hocs/item-scroll'
import { useApp, useLanguage, useWebp } from '../../../../hooks/common'
import Toast from '../../../../components/toast'
import { FormattedMessage } from 'react-intl'
import { getSensorsUrl } from '../../../../utils/sensor'


export const EmptyItem = props => {
    return <figure className={classes.NormalItem}>
        <div>
            <div className={classes.Image}>
                <img alt="empty" src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" />
            </div>
        </div>
        <figcaption>
            <div className={classes.Prices}>
                <div>

                </div>

                <div>
                    <Iconfont>&#xe6a8;</Iconfont>
                </div>
            </div>
        </figcaption>
    </figure>
}


const Processes = props => {
    useStyles(classes)
    const { rate, isFinished } = props
    const percent = Math.min(Math.round(rate * 100), 100)
    const isAmost = percent >= 90

    return <div className={classes.Processes}>
        <div className={classes.Actived} style={{ width: `${percent}%` }} />
        <span className={classes.Text}>
            {
              isFinished ? <FormattedMessage id="sold_out" defaultMessage="Sold Out"/>: (isAmost ? <FormattedMessage id="almost_sold_out" defaultMessage="Almost Sold Out"/> : <FormattedMessage id="sold_percent" values={{
                percent
              }}/>)
            }
        </span>
    </div>
}


export default withRouter(withItemScroll(props => {

    useStyles(classes)

    const { location } = props

    const query = qs.parse(location?.search)
    const { product, position, column, similar, sensors, giftInfo, product_type, filter, isDrawGift, noAddWish } = props

    // product.promotion = {
    //     ...(product.promotion || {}),
    //     type: '9',
    //     // salesQty: Math.round(Math.random() * 10),
    //     totalQty: 12,
    //     salesQty: 12
    // }

    const isGift = isDrawGift || !!giftInfo

    const language = useLanguage()


    const { differentColorStyleVariants } = product
    const [selectedColor, setSelectedColor] = useState(differentColorStyleVariants?.find(v => v.productId === product.id))

    const isApp = useApp()
    const isWebp = useWebp() && selectedColor?.status === '1'

    const [lowerPrice, delPrice, isFlash] = [
        getLowerPrice(product),
        getDelPrice(product),
        isFlashSales(product)
    ]


    let off = 0
    if (delPrice) {
        off = Math.round((Number(delPrice.amount) - Number(lowerPrice.amount)) * 100 / Number(delPrice.amount))
    }

    // const url = similar ? `/similar/${selectedColor?.id || product?.id}.html` : getProductUrl( selectedColor?.id || product?.id, product?.name, selectedColor?.color, query?.promotionType? `promotionType=${query?.promotionType}`:'' )

    let suffix = ''

    if (query?.promotionType) {
        suffix += `promotionType=${query?.promotionType}`
    }

    if (product_type) {
        suffix += `${suffix ? '&' : ''}product_type=${product_type}`
    }

    const url = isApp ? `chic-me://chic.me/product?id=${selectedColor?.productId || product?.id}${isGift ? '&isGiftProduct=1' : ''}` : getProductUrl(selectedColor?.productId || product?.id, product?.name, selectedColor?.color, suffix, language)

    const promotionType = product?.promotion?.type || ''

    // const priceClass = isFlash ? classes.FlashPrice : classes.Price
    const priceClass = classes.FlashPrice


    const handleProductClick = e => {
        e.preventDefault()
        const href = e.currentTarget?.href
        window.location.href = getSensorsUrl(href, {
            resourcepage_title: sensors?.resourcepage_title,
            resource_type: sensors?.resource_type,
            resource_content: sensors?.resource_content,
            resource_position: sensors?.resource_position,
            product_position: position + 1,
            filter
        })
    }

    const imageUrl = `${IMAGE_GEEKO_LTD}${isWebp ? '/webp' : ''}/medium/${selectedColor?.mainImage || product?.pcMainImage}`

    const isFinished = isFlash && product.promotion?.totalQty <= product.promotion?.salesQty

    return <figure className={`${classes.NormalItem} ${position === 0 ? classes.FirstImage : ''}`}>
        <a href={url} ref={props.innerRef}
            product-id={product?.id}
            data-product-list-source
            data-promotion={promotionType}
            data-layer={product?.layerName}
            data-tracking={product.trackingRecords}
            data-product-source={product.dataSource}
            data-geeko-experiment={product.geekoExperimentId}
            data-geeko-id={product.geekoRequsestId}
            data-request-id={product.aliRequestId}
            data-experiment-id={product.aliExperimentId}
            data-column={column}
            data-product-position={position + 1}
            data-position={sensors?.resource_position}
            data-type={sensors?.resource_type}
            data-content={sensors?.resource_content}
            data-title={sensors?.resourcepage_title}
            data-filter={filter}
            data-product-type={product_type}
            data-price={product?.usdPrice?.amount}
            onClick={handleProductClick}
        >
            <div className={classes.Image}>




                {
                    position === 0 ? <img src={imageUrl} alt={product?.name} /> : <LazyLoadImage src={imageUrl} alt={product?.name} />
                }



                {
                    product.combinatorialPromotion && product.combinatorialPromotion.enabled && <div className={classes.CombinatorialPromotion}>
                        {product?.combinatorialPromotion?.title}
                    </div>
                }


                {
                    product?.listingVideoLink && <span className={classes.PlayIcon} />
                }

                {
                    isFinished && <div className={classes.Finished}>
                        <FormattedMessage id="finished" defaultMessage={'Finished'} />
                    </div>
                }


            </div>

            {
                off > 0 && <span className={classes.FlashTip}>
                    <span className={classes.Number}>-{off}%</span>
                </span>
            }

        </a>

        <figcaption>
            <div className={classes.Prices}>
                <div>
                    {
                        isGift ? <span className={priceClass}>{product?.giftPrice ? unitPrice(product?.giftPrice) : unitPrice({ ...lowerPrice, amount: '0.00' })}</span> : <span className={priceClass}>{unitPrice(lowerPrice)}</span>
                    }

                    {' '}
                    <span className={classes.PriceElement}>
                        {
                            delPrice && <React.Fragment>
                                <del className={classes.Del}>{unitPrice(delPrice)}</del>
                            </React.Fragment>
                        }

                    </span>
                </div>

                {
                    isFlash ? <span /> : <CartIcon sensors={
                        {
                            layerName: product?.layerName,
                            promotionType,
                            trackingRecords: product?.trackingRecords,
                            dataSource: product?.dataSource,
                            geekoExperimentId: product?.geekoExperimentId,
                            geekoRequsestId: product?.geekoRequsestId,
                            aliRequestId: product?.aliRequestId,
                            aliExperimentId: product?.aliExperimentId,
                            product_position: position + 1,
                            ...sensors
                        }
                    } product_type={product_type} noAddWish={noAddWish} productId={selectedColor ? selectedColor.productId : product.id} color={selectedColor ? selectedColor.color : product?.variants?.[0]?.color} />
                }

            </div>

            {
                isFlash && <div className={classes.FlashProcess}>
                    <Processes isFinished={isFinished} rate={product.promotion.salesQty / product.promotion.totalQty} />
                    <CartIcon sensors={
                        {
                            layerName: product?.layerName,
                            promotionType,
                            trackingRecords: product?.trackingRecords,
                            dataSource: product?.dataSource,
                            geekoExperimentId: product?.geekoExperimentId,
                            geekoRequsestId: product?.geekoRequsestId,
                            aliRequestId: product?.aliRequestId,
                            aliExperimentId: product?.aliExperimentId,
                            product_position: position + 1,
                            price: product?.usdPrice?.amount,
                            ...sensors
                        }
                    } product_type={product_type} productId={selectedColor ? selectedColor.productId : product.id} color={selectedColor ? selectedColor.color : product?.variants?.[0]?.color} />
                </div>
            }



        </figcaption>
    </figure>
}))