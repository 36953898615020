import React, { useCallback, useMemo, useState } from "react";
import classes from "./style.module.scss";
import useStyles from "isomorphic-style-loader/useStyles";
import PhonePasswordField from '../../input/phone-password-input';
import { useIntl, FormattedMessage } from "react-intl";
import { Formik, Form } from "formik";
import { Iconfont } from "../../../../../components/icon/iconfont";
import { BigButton } from "../../../../../components/button";
import { fetchAutoLoginByKey, fetchRegister2 } from "../../../../../../api";
import Cache from "../../../../../../utils/cache";
import { useQuery } from "../../../../../hooks/common";
import Alert from "../../../../../components/alert";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFirstOrder } from "../../../../../../store/actions/global";
import qs from 'query-string'
import { useLocation } from "react-router-dom";
import FacebookEvents from '../../../../../ads/events/facebook'


const WhatsAppPhoneRegister = props =>{
    const {
        notCheckVerifyEvent, 
        handleRegister, 
        decodeLocation, 
        loading 
    } = props;

    const Intl = useIntl();

    const [policyActive, setPolicyActive] = useState(false);
    const [policyActive2, setPolicyActive2] = useState(false);
    const [policyError, setPolicyError] = useState(false);


    const submitHandle = async (values, formikBag) =>{
        console.log('values', values)
        if (!policyActive) {
            setPolicyError(true);

            setTimeout(() => {
                setPolicyError(false);
            }, 500);
            
            notCheckVerifyEvent?.("Register", 'whatsapp');
        }else{
            handleRegister({ phoneAreaCode: decodeLocation?.phoneArea, phoneNumber: values.phoneNumber, password: values.confirmPassword, registerType: 1 }, true);
        }
        formikBag.setSubmitting(false);
    }

    const privacyPolicyI18n = Intl.formatMessage(
        { id: 'register_privacy_policy' },
        {
            term: `<a href="/policy/terms-conditions-notice">${Intl.formatMessage({id:"term_of_services",defaultMessage:"Terms of Service"})}</a>`,
            privacy: `<a href="/policy/privacy-security-policy">${Intl.formatMessage({id:"privacy_policy",defaultMessage:"Privacy Policy"})}</a>`,
        }
    );

    return <Formik 
        onSubmit={submitHandle}
        initialValues={{
            phoneNumber: decodeLocation?.phoneNumber,
            password:'',
            confirmPassword:''
        }}
        validate={values => {
            const errors = {}
            let errorFlag = false;

            if(values.password !== values.confirmPassword){
                errors.confirmPassword = Intl.formatMessage({ id: "inconststent_password", defaultMessage: "Inconsistent passwords" });

                errorFlag = true;
            }
            
            errorFlag && notCheckVerifyEvent?.("Register", 'whatsapp');
            return errors
        }}
        enableReinitialize={true} 
    >
        {
            ({ isSubmitting, values }) =>(
                <Form className={classes.PhoneRegisterFrom}>
                    <div className={classes.WhatsappNumber}>
                        <p><FormattedMessage id="whatsapp_number" defaultMessage="WhatsApp Number" /> </p>
                        <input value={`${decodeLocation?.phoneArea || "+xx"} ${values.phoneNumber || "xxxxxxxxx"}`} readOnly />
                    </div>

                    <PhonePasswordField 
                        InputProps={{
                            placeholder:Intl.formatMessage({id:"password", defaultMessage:"Password"}),
                            type:"password",
                            name:"password"
                        }}
                        showRules
                    />

                    <PhonePasswordField 
                        InputProps={{
                            placeholder:Intl.formatMessage({id:"confirm_password", defaultMessage:"Confirm Password"}),
                            type:"password",
                            name:"confirmPassword"
                        }} 
                        label={Intl.formatMessage({id:"confirm_password", defaultMessage:"Confirm Password"})}
                        showPassword={false} 
                    />

                    <div className={classes.PolicyContainer}>
                        <div className={classes.Item1}>
                            <Iconfont className={`${classes.SelectIcon} ${policyActive ? classes.Active : ""}`} onClick={() => setPolicyActive(flag => !flag)}>&#xe65a;</Iconfont>
                            <span className={policyError ? classes.Error : ""} dangerouslySetInnerHTML={{ __html: privacyPolicyI18n }}></span>
                        </div>

                        <div className={classes.Item1}>
                            <Iconfont className={`${classes.SelectIcon} ${policyActive2 ? classes.Active : ""}`} onClick={() => setPolicyActive2(flag => !flag)}>&#xe65a;</Iconfont>
                            <span><FormattedMessage id="receive_offers_message" defaultMessage="I' d like to receive exclusive offers and news via SMS." /></span>
                        </div>
                    </div>


                    <BigButton type="submit" loading={isSubmitting || loading} className={classes.SubmitButton}>
                        <FormattedMessage id="create_account" defaultMessage="Create Account" />
                    </BigButton>
                </Form>
            )
        }
    </Formik>
}

const WhatsAppRegister = props =>{
    const { notCheckVerifyEvent, handleLoginin, setFetchResult, onFailure, registerScenes, token } = props;
    const [loading, setLoading] = useState(false);
    const Intl = useIntl();

    const handleRegister = async (data, phoneRegister = false) => {
        // 让调用这个函数的返回值可以知道是成功还是失败
        let callbackFlag = true;
        try {
            setLoading(true);
            const response = await fetchRegister2(data);

            if (response?.code === 200) {
                await handleLoginin();
                setFetchResult(response?.result);
                let customerId = response?.result?.customer?.id;

                if (window?.fbq) {
                    window.fbq('track', "CompleteRegistration");
                }

                if (window.mobileAnalyticsClient) {
                    window.mobileAnalyticsClient.recordEvent('COMPLETED_REGISTRATION', {
                        'info1': window?.utm_source,
                        'info2': window?.utm_campaign,
                        'info3': window?.utm_medium,
                        'type': '1',
                        'customerId': customerId,
                        'currentPage': window?.currentPage ? window.currentPage : ""
                    });
                }
                
                if (window.GeekoSensors) {
                    window.GeekoSensors.Track('Register', {
                        register_scenes: registerScenes,
                        register_method: 'whatsapp',
                        is_success: true
                    })
                    window.sensors.login(customerId)
                }

                FacebookEvents.register({ registration_method: 'whatsapp', status: true });
                

                if (window.gtag) {
                    window.gtag("event", "sign_up", {
                        method: "email"
                    })
                }

                if(phoneRegister){
                    Cache.set("customerPhone", data?.phoneNumber, (365 * 24 * 60 * 60) * 1000);
                }

                if(response?.result?.couponShareErrorCode){
                    Cache.set("couponShareErrorCode", response?.result?.couponShareErrorCode, (24 * 60 * 60) * 1000);
                }
            } else {
                callbackFlag = false;
                Alert(
                    response?.result || Intl.formatMessage({ id: "network_error", defaultMessage: "Network Error" }),
                    {
                        buttonText:Intl.formatMessage({ id: "ok", defaultMessage: "Ok" }),
                        // whatsapp注册如果失败也直接跳转到正常登录注册 会存在自身注册过二次点击链接的情况
                        onClose: () =>{
                            window.location.replace('/i/login');
                        }
                    }
                );

                if (window.GeekoSensors) {
                    window.GeekoSensors.Track('Register', {
                        register_scenes: registerScenes,
                        register_method: 'whatsapp',
                        is_success: false,
                        fail_reason: response?.result
                    })
                }
            }
            
            setLoading(false);

            if (window.fbq) {
                window.fbq('track', 'Lead');
            }

            if (window.pintrk) {
                window.pintrk('track', 'signup', {
                    lead_type: window?.utm_source ? window.utm_source : ""
                });
            }

            if (window.mobileAnalyticsClient) {
                window.mobileAnalyticsClient.recordEvent('INIT_REGISTRATION', {
                    'info1': window?.utm_source,
                    'info2': window?.utm_campaign,
                    'info3': window?.utm_medium,
                    'type': '1',
                    'currentPage': window?.currentPage ? window.currentPage : ""
                });
            }
        } catch (error) {
            onFailure(error)
            setLoading(false);
            callbackFlag = false;
        }

        return new Promise((reslove, rej) =>{
            reslove(callbackFlag);
        });
    }

    const decodeLocation = useMemo(() =>{
        if(token){
            try {
                let decodeStr = window?.atob(token)
                let decodeObj = JSON.parse(decodeStr);
                return { ...decodeObj, phoneArea: `+${decodeObj?.phoneArea}` };
            } catch (error) {
                return null;
            }
        }

        return null;
    }, [token]);

    return <div className={classes.WhatsAppLoginPage}>
        <p className={classes.Title}><FormattedMessage id="set_the_password" defaultMessage="Set the Password" /></p>
        
        <WhatsAppPhoneRegister 
            loading={loading} 
            decodeLocation={decodeLocation} 
            handleRegister={handleRegister} 
            notCheckVerifyEvent={notCheckVerifyEvent} 
            {...props} 
        />
    </div>
}

const WhatsAppAutoLogin = props =>{
    const { handleLoginin, setLoginFetchResult, onRedirectHandle, setOpLoading, registerScenes, onFailure } = props;
    
    const dispatch = useDispatch();
    const Intl = useIntl();
    const location = useLocation();

    useEffect(async () =>{
        // 因为 query-string 在7之后会把加号转译成空格 因此单独转译处理
        let queryString = location.search.replace(/\+/g, '%2B');
        let params = qs.parse(queryString);
        let loginKey = params?.key;
        setOpLoading(true);
        let response = await fetchAutoLoginByKey(loginKey);
        setOpLoading(false);
        handleLogin(response);
    }, []);

    const LoginModal = response => {
        let _me = response?.result;
        if (_me && _me.customer && !_me.customer.subscribeToFacebookMessage) {
            setLoginFetchResult(_me);
        } else {
            onRedirectHandle();
        }
    }

    const handleLogin = async (response) => {
        // 让调用这个函数的返回值可以知道是成功还是失败
        let callbackFlag = true;
        try {
            if (response?.code === 200) {
                await handleLoginin();
                LoginModal(response);
                let customerId = response?.result?.customer?.id;

                if(response?.result){
                    let isFirstOrder = response.result.isFirstOrder;
                    dispatch(setFirstOrder(isFirstOrder));
                    window.is_first_order = isFirstOrder;

                    if(!isFirstOrder){
                        window.localStorage && window.localStorage.setItem('c_has_order', 1);
                    }
                }
                
                
                if (window.GeekoSensors) {
                    window.GeekoSensors.Track('Login', {
                        register_scenes: registerScenes,
                        register_method: 'whatsapp',
                        is_success: true
                    })

                    customerId && window?.sensors.login(customerId)
                }

                if (window.gtag) {
                    window?.gtag("event", "login", {
                        method: "email"
                    })
                }

                if (window?.mobileAnalyticsClient) {
                    window?.mobileAnalyticsClient.recordEvent('LOGIN_SUCCESS', {
                        'info1': window?.utm_source,
                        'info2': window?.utm_campaign,
                        'info3': window?.utm_medium,
                        'type': '1',
                        'customerId': customerId,
                        'currentPage': window?.currentPage ? window.currentPage : ""
                    });
                }
                
                if(response?.result?.customer?.phoneNumber){
                    Cache.set("customerPhone", response.result.customer.phoneNumber, (365 * 24 * 60 * 60) * 1000);
                }

                if(response?.result?.couponShareErrorCode){
                    Cache.set("couponShareErrorCode", response?.result?.couponShareErrorCode, (24 * 60 * 60) * 1000);
                }
            } else {
                callbackFlag = false;
                Alert(
                    response?.result || Intl.formatMessage({ id: "network_error", defaultMessage: "Network Error" }),
                    {
                        buttonText:Intl.formatMessage({ id: "ok", defaultMessage: "Ok" }),
                        // 自动登录如果失败直接跳转到正常的登录页面
                        onClose: () =>{
                            window.location.replace('/i/login');
                        }
                    }
                );
                
                if (window.GeekoSensors) {
                    window.GeekoSensors.Track('Login', {
                        register_scenes: registerScenes,
                        register_method: 'whatsapp',
                        is_success: false,
                        fail_reason: response.result
                    })
                }
            }

            if (window?.mobileAnalyticsClient) {
                window.mobileAnalyticsClient?.recordEvent('LOGIN', {
                    'info1': window?.utm_source,
                    'info2': window?.utm_campaign,
                    'info3': window?.utm_medium,
                    'type': '1',
                    'currentPage': window?.currentPage ? window.currentPage : ""
                });
            }
        } catch (error) {
            callbackFlag = false;
            onFailure(error)
        }

        return new Promise((reslove, rej) =>{
            reslove(callbackFlag);
        });
    }

    return <div></div>
}

export default props =>{
    useStyles(classes);
    const query = useQuery();
    const global = useSelector(state=>state.global);

    const initFacebook = useCallback((data) =>{
        const facebookSdk = document.getElementById('facebook-jssdk');
        if(!facebookSdk){
            window.fbAsyncInit = function () {
                window.FB.init({
                    appId: `${data.facebookAppId}`,
                    autoLogAppEvents: true,
                    xfbml: true,
                    version: 'v3.1'
                })
            }

            const head = document.getElementsByTagName('head')[0]
            const script = document.createElement('script')
            script.type = 'text/javascript'
            script.id = 'facebook-sdk'
            script.text = `
                (function (d, s, id) {
                    var js, fjs = d.getElementsByTagName(s)[0];
                    if (d.getElementById(id)) { return; }
                    js = d.createElement(s); js.id = id;
                    js.src = "https://connect.facebook.net/en_US/sdk.js";
                    fjs.parentNode.insertBefore(js, fjs);
                }(document, 'script', 'facebook-jssdk'));
            `
            head.appendChild(script)
        }else if(window.FB){
            window.FB.init({
                appId: `${data.facebookAppId}`,
                autoLogAppEvents: true,
                xfbml: true,
                version: 'v3.1'
            })
        }
    }, []);

    useEffect(() =>{
        initFacebook({facebookAppId: global?.config?.facebook?.appId});
    }, []);

    return <React.Fragment>
        {
            query?.key  ? <WhatsAppAutoLogin loginKey={query.key} {...props} /> : <WhatsAppRegister token={query.token} {...props} />
        }
    </React.Fragment>
}