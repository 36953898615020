import { useEffect, useState, useRef, useCallback } from "react";

export function useTouch(ref, offset = 0, preventDefault = false) {
    const [direction, setDirection] = useState(null)
    const [changing, setChanging] = useState(0)
    const [changed, setChanged] = useState(0)

    const startY = useRef(null)
    const endY = useRef(null)
    const moveY = useRef(null)

    const onTouchStart = useCallback(e => {
        startY.current = e.touches[0].clientY
    }, [])

    const onTouchMove = useCallback(e => {
        if(preventDefault){
            e.preventDefault()
        }
        moveY.current = e.changedTouches[0].clientY
        setChanging(moveY.current - startY.current)
    }, [])

    const onTouchEnd = useCallback(e => {
        endY.current = e.changedTouches[0].clientY
        let d
        if (endY.current - startY.current > offset) {
            d = 'down'
        } else if (endY.current - startY.current < -offset) {
            d = 'up'
        }
        setDirection(d)
        setChanged(endY.current - startY.current)
    }, [])


    useEffect(() => {

        if (ref.current) {
            ref.current.addEventListener('touchstart', onTouchStart)

            ref.current.addEventListener('touchmove', onTouchMove)

            ref.current.addEventListener('touchend', onTouchEnd)
        }

        return () => {
            if (ref.current) {
                ref.current.removeEventListener('touchstart', onTouchStart)
                ref.current.removeEventListener('touchmove', onTouchMove)
                ref.current.removeEventListener('touchend', onTouchEnd)
            }
        }
    }, [])


    return {
        direction,
        changing,
        changed
    }
}