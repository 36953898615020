import React, { useEffect, useMemo, useState } from "react";
import useStyles from 'isomorphic-style-loader/useStyles'
import classes from "./style.module.scss"
import { FormattedMessage } from "react-intl";

import withOutClick from "./out-side-lick";
import { useRef } from "react";

export default withOutClick(props => {
    useStyles(classes);
    const { changeEmail, email, selectPage, name } = props;
    const inputRef = React.createRef();
    const [labelActive, setLabelActive] = useState(true);
    const [showEmail, setShowEmail] = useState(false)
    const itemEmail = useRef("");


    useEffect(()=> {
        setShowEmail(false)
    }, [props.open])

    const inputBlurEvent = () => {
        if (email?.length <= 0) {
            setLabelActive(false);
        }
    }

    useEffect(() => {
        if(email?.length > 0){
            setLabelActive(true);
        }
    }, [selectPage]);

    let emailList = useMemo(() =>{
        let arr = ["gmail.com","hotmail.com","yahoo.com","outlook.com","icloud.com","aol.com","mail.com","live.com","wp.pl","gmx.net"];
        let newArr;
        if(email?.indexOf("@") >= 0){
            let emailArr = email?.split("@");
            let value = emailArr[1];
            itemEmail.current = emailArr[0];
            if(!!value){
                newArr = arr.filter(item =>{
                    return item.indexOf(value) >= 0 && value !== item;
                });
            }else{
                newArr = arr;
            }
        }else{
            newArr = arr;
            itemEmail.current = email;
        }

        return newArr;
    },[email]);

    return <div className={`${classes.InputContainerModule} ${props?.className ? props?.className : ""}`}>
        <label onClick={() => {
            inputRef.current.focus()
        }} className={labelActive ? classes.foucsScale : ""}><FormattedMessage id="email_address" defaultMessage="Email Address" /></label>
        <input
            ref={inputRef}
            type="email"
            name={name}
            required
            value={email}
            onChange={(e) => changeEmail(e.target.value)}
            onFocus={() => {
                setLabelActive(true);
                setShowEmail(true)
            }}
            onBlur={() => inputBlurEvent()}
        />
        {
            labelActive && <span
                className={classes.clearIcon}
                onClick={() => {
                    changeEmail('')
                    setLabelActive(false);
                }}>
            </span>
        }

        {
            email && showEmail && emailList?.length > 0 && <ul className={classes.EmailList}>
                {
                    emailList.map((item,index) =><li key={item+index} onClick={() =>{
                        changeEmail(`${itemEmail.current}@${item}`);
                        setShowEmail(false)
                    }}>{`${itemEmail.current}@${item}`}</li>)
                }
            </ul>
        }
       
    </div>
})