import React from 'react'
import ReactDOM from 'react-dom'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import DisplayTransition from '../../desktop/components/transitions/display-transition'
import { BlackMask } from '../mask'
import { BigButton } from '../button'
import { FormattedMessage } from 'react-intl'

const Confirm = props => {
    useStyles(classes)
    const { options } = props
    const {okText, cancelText, content, onCancel, onConfirm} = options || {}

    return <div className={classes.Container}>
        <div className={classes.Content}>
            {content}
        </div>
        <div className={classes.Buttons}>
            <BigButton onClick={onConfirm}>{okText || <FormattedMessage id="confirm" defaultMessage="Confirm" />}</BigButton>
            <BigButton outlined onClick={onCancel}>{cancelText || <FormattedMessage id="cancel" defaultMessage="Cancel" />}</BigButton>
        </div>
    </div>
}

export default props => {
    useStyles(classes)
    if (typeof window === 'undefined')
        return null

    return ReactDOM.createPortal(
        <React.Fragment>
            <DisplayTransition in={props.show} className={classes.AlertContainer}>
                <div>
                    <Confirm {...props} />
                </div>
            </DisplayTransition>
            {props.show && <BlackMask style={{zIndex: 50}} onClick={props.onClose} />}
        </React.Fragment>,
        document.getElementById('root')
    )
}