import useStyles from "isomorphic-style-loader/useStyles";
import React from "react";
import { FormattedMessage } from "react-intl";
import Dropdown from "../../drop-down";
import RadioSelect from "../../radio-select";
import classes from './style.module.scss'

const SizeChartSelect = props => {
    useStyles(classes)

    const { intl, unitData, unitSelect, unitChange, countryData, countrySelect, countryChange, hideUnitSelect } = props || {}

    return <div className={classes.SizeChartSelectBox}>
        <div className={classes.SizeChartCountry}>
            {
                countrySelect && <React.Fragment>
                    <span><FormattedMessage id={"switchTo"} defaultMessage={"Swtich to"}/></span>
                    <Dropdown intl={intl} data={countryData} value={countrySelect || ''} onChange={(e) => countryChange(e)}></Dropdown>
                </React.Fragment>
            }
        </div>
        <div className={classes.SizeChartSelectUnit}>
            {
                !hideUnitSelect ?
                <RadioSelect intl={intl} data={unitData} value={unitSelect} onChange={(e) => unitChange(e)}></RadioSelect>:
                <React.Fragment>
                    <div className={`${classes.radioSelectBox}`}>
                        <span className={classes.selectItemM}>
                            <span className={classes.selected}>{ intl.formatMessage({id:"inches"}) }</span>
                        </span>
                    </div>
                </React.Fragment>
            }
        </div>
    </div>
}

export default SizeChartSelect