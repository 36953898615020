import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import BottomToTop from "../../../../components/transitions/bottom-to-top";
import LoginPage from "../../index"
import useStyles from 'isomorphic-style-loader/useStyles'
import classes from "./style.module.scss"
import { Iconfont } from "../../../../components/icon/iconfont";
import ReactDOM from 'react-dom'
import { TOGGLE_UI_LOGIN } from "../../../../../store/actions/ui";
// import { setRedirectUrl } from '../../../../../store/actions/global'

const LoginPageItem = props =>{
    useStyles(classes);
    const global = useSelector(state =>state.global);
    const ui = useSelector(state => state.ui)
    const {config} = global;
    const dispatch = useDispatch();

    // useEffect(() =>{
    //     if(ui?.login?.showLogin){
    //         dispatch(setRedirectUrl(window.location.href));
    //     }
    // }, [ui?.login]);

    useEffect(() =>{
        document.body.style.overflow = 'hidden';
    },[]);

    const closeEvent = async (isSuccess) =>{
        if(isSuccess){
            await ui?.login?.callBack?.()
        }

        dispatch({
            type:TOGGLE_UI_LOGIN,
            payload: {
                showLogin:false,
                callBack:null,
                redirectUrl:null
            }
        });

        // dispatch({
        //     type:SET_REDIRECT_URL,
        //     payload: null
        // })
        
        document.body.style.overflow = 'auto'
    }


    const onSuccess = () => {
        // if(global?.redirectUrl){
        //     window.location.href = global?.redirectUrl
        // }
        //todo
        closeEvent(true)
    }

    return <>
        <div className={classes.SlideContainer}>
            <div className={classes.AlertHeader}>
                <div className={classes.ImageContainer}>
                    <img src={config?.logo} alt="logo" />
                </div>

                <Iconfont className={classes.CloseIcon} onClick={()=>closeEvent(false)}>&#xe7c9;</Iconfont>
            </div>
            <LoginPage {...props} alertLoginEvent={onSuccess} isModal />
        </div>
    </>
}

const WrappedEditor = props => {
    useStyles(classes);
    const ui = useSelector(state => state.ui);

	return <React.Fragment>
		<BottomToTop in={ui?.login?.showLogin} className={classes.LoginPageContainer}>
			<div>
                <LoginPageItem />
			</div>
		</BottomToTop>
	</React.Fragment>
}

export default props =>{
    if (typeof window === 'undefined')
		return null

	return ReactDOM.createPortal(
		<WrappedEditor {...props} />,
		document.getElementById('root')
	)
}