import React from 'react'
import { useCallback } from 'react'
import ReactDOM from 'react-dom'

import BlackMask from './mask'
import { Iconfont } from '../icon/iconfont'

/* 
{
    boxStyle:{
    },
    close:{
        style:{},
        clickHandle:() =>{

		}
    },
	title:{
		text:"",
		style:{}
	},
	message:{
		text:"",
		style:{},
        html:true
	},
	btn1:{
		text:"",
		style:{},
		clickHandle:() =>{

		}
	},
	btn2:{
		text:"",
		style:{},
		clickHandle:() =>{
		
		}
	}
}
*/

const withAlert = Wrapped => {
    return props => {
        const onClose = () => {
            ReactDOM.unmountComponentAtNode(document.getElementById('alert-container'))
        }

        return <Wrapped {...props}  onClose={onClose}/>
    }
}

export const NormalConfigConfirm = withAlert(props => {

    const { options, uuid, onClose} = props
    const { 
        title, 
        message, 
        btn1, 
        btn2, 
        boxStyle, 
        close 
    } = options;

    const nullTOBoject = useCallback((value) =>{
        return value || {}
    }, [])

    const handleClick = event => {
        onClose()
        btn2?.clickHandle?.()
    }

    const handleConfirm = event => {
        onClose()
        btn1?.clickHandle?.()
    }

    const closeHandleClick = () =>{
        onClose();
        close?.clickHandle ? close?.clickHandle() : btn2?.clickHandle?.();
    }

    return <React.Fragment>
        <div className={`NormalAlert`} id={uuid} style={{padding:'20px', borderRadius:'4px', ...nullTOBoject(boxStyle)}}>
            {
                close && <Iconfont className="MeClose" style={{...nullTOBoject(close?.style)}} onClick={closeHandleClick}>&#xe69a;</Iconfont>
            }

            {
                title?.text && <div className="Title" style={{fontSize: 16, textAlign: 'center', fontFamily:'Roboto-Medium', marginBottom: 10, ...nullTOBoject(title?.style)}}>
                    {
                        title.text
                    }
                </div>
            }

            <div className="Msg" style={{fontFamily: 'Roboto-Medium', ...nullTOBoject(message?.style)}}>
                {
                    message?.html ? <span dangerouslySetInnerHTML={{__html:message.text}}></span> : <span>{message.text}</span>
                }
            </div>

            {/* <div className="ButtonBox" style={{flexWrap: 'wrap'}}> */}
            <div style={{marginTop:12}}>
                <button style={{width: '100%', textTransform: 'uppercase', marginLeft: 0, ...nullTOBoject(btn1?.style)}} className="OkButton" onClick={handleConfirm}>{btn1?.text || 'Confirm'}</button>
                {
                    btn2 && <button style={{width: '100%', textTransform: 'uppercase', marginTop: 10, ...nullTOBoject(btn2?.style)}} className="CancelButton" onClick={handleClick}>{btn2?.text || 'Cancel'}</button>
                }
            </div>
        </div>

        <BlackMask onClick={handleClick} style={{zIndex:options?.isDesktop?"52":"20"}} />
    </React.Fragment>
})


export default (options) => {
    ReactDOM.render(<NormalConfigConfirm options={options}/>, document.getElementById('alert-container'))
}