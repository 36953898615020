import React, { useEffect, useState } from 'react'
import './style.scss'
import {CSSTransition} from 'react-transition-group'

export default props => {

	const [inited, setInited] = useState(false)


	useEffect(() => {
		if(inited){
			document.body.style.overflow = (props.in ? 'hidden': 'auto')
		}
		setInited(true)
		return () => {
			document.body.style.overflow = 'auto'
		}
	}, [props.in])


	return <CSSTransition
		mountOnEnter={true}
		in={props.in}
		timeout={500}
		className={props.className || "LeftToRight"} 
		classNames="LeftToRight">
		{
			props.children
		}
	</CSSTransition>
}