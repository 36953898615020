import React, {useState} from 'react'
import {Iconfont} from "../../../../components/icon/iconfont"
import ProductEditor from './product-editor'


export const BuyIcon = props => {
	const { productId, color, sensors, isGift, giftInfo, variantId, isCollectionGift, noAddWish } = props
	const [showEditor, setShowEditor] = useState(false)

	const clickHandle = () => {
		setShowEditor(true)
	}
	return <React.Fragment>
		<span onClick={clickHandle}>
			{
				props.render()
			}
		</span>
		<ProductEditor isGift={isGift} giftInfo={giftInfo} noAddWish={noAddWish} sensors={sensors} showEditor={showEditor} isCollectionGift={isCollectionGift} onClose={() =>{setShowEditor(false)}} variantId={variantId}  productId={productId} color={color}/>
	</React.Fragment>
}

export const withCartIcon = Wrapped => {
	return props => {
		const [showEditor, setShowEditor] = useState(false)
		const clickHandle = () => {
			setShowEditor(true)
		}
		return <React.Fragment>
			<Wrapped {...props} onCart={clickHandle}/>
			<ProductEditor {...props} showEditor={showEditor} onClose={() => { setShowEditor(false) }} />
		</React.Fragment>
	}
}


export default props => {

	const {productId, color, sensors, isGift, isAddOn, isWishList, giftInfo, noAddWish, callBack, beforeAddToCart} = props
	const [showEditor, setShowEditor] = useState(false)

	const clickHandle = () => {
		if(typeof(beforeAddToCart) == 'function'){
			beforeAddToCart(() => {
				setShowEditor(true)
			})
		} else {
			setShowEditor(true)
		}
	}


	return <React.Fragment>
		<Iconfont onClick={clickHandle}>
			&#xe6a8;
		</Iconfont>
		<ProductEditor isGift={isGift} isAddOn={isAddOn} noAddWish={noAddWish} isWishList={isWishList} giftInfo={giftInfo} sensors={sensors} showEditor={showEditor} onClose={() =>{setShowEditor(false)}}  productId={productId} color={color} callBack={callBack}/>
	</React.Fragment>
}