import React, { useState, useEffect } from "react";
import classes from "./style.module.scss";
import useStyles from "isomorphic-style-loader/useStyles";
import { useQuery } from "../../../../hooks/common";
import { getCountryMessage } from "../../../../../api";
import { BlackMask } from "../../../../components/mask";
import { Iconfont } from "../../../../components/icon/iconfont";
import { FormattedMessage } from "react-intl";

const RegisterDiscount = props =>{
    const { registerDiscount } = props;
    // const { width, height } = registerDiscount;
    // const aspectRatio = width && height ? `${width} / ${height}` : undefined

    return <div className={classes.RegisterDiscount}>
        <div className={classes.Image}>
            <img src={registerDiscount.url} alt="RegisterDiscount" />
        </div>
    </div>
}

export default props =>{
    useStyles(classes);
    const { style, registerDiscount } = props;
    const query = useQuery(); 

    return <div className={classes.RegisterMessage} style={style || {}}>
        {
            !!registerDiscount && <RegisterDiscount registerDiscount={registerDiscount} />
        }
        
        {
            query?.share == 1 && <div className={classes.ShareRegister}>
                <div className={classes.Image}>
                    <img src="https://image.geeko.ltd/chicme/2021111101/register.png" alt="share register" />
                </div>

                <p className={classes.Title1}><FormattedMessage id="belong_get_points_500" defaultMessage="500 points will belong to you immediately" />!</p>
                <p className={classes.Title2}><FormattedMessage id="finshed_register_shop" defaultMessage="Complete the registration and get Points Reward to shop" />!</p>
            </div>
        }
    </div>
}