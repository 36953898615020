import React, { useEffect, useState, useCallback } from "react";
import classes from "./style.module.scss";
import useStyles from "isomorphic-style-loader/useStyles";
import { v4 } from "uuid";
import { useMemo } from "react";
import { useField } from "formik";
import { FormattedMessage, useIntl } from "react-intl";
import { isEmojiCharacter } from "../../../../../utils/common";

export default props =>{
    useStyles(classes);
    const { showRules = false, InputProps, label, showPassword = true, autoComplete = "off" } = props;
    const Intl = useIntl();

    const validate = (value) =>{
        if(!showPassword) return null;
        let error;
        if(value?.length < 6){
            error = Intl.formatMessage({id:"six_characters_min", defaultMessage:"6 characters minimum"});
        }else if(value?.length > 20){
            error = Intl.formatMessage({id:"twenty_characters_max", defaultMessage:"20 characters maximum"})
        } else if(isEmojiCharacter(value)){
            error = Intl.formatMessage({id:"no_emojis", defaultMessage:"No emojis"});
        }

        // 刷新三个条件的具体显示
        unifyErrors(value);
    
        return error;
    }

    const [field, meta, helpers] = useField({...InputProps, validate })
    const { error, value, touched } = meta;
    const [ruleErrors, setRuleErrors] = useState({
        minLength: true,
        maxLength: true,
        noEmojis: true
    });
    const [showIn, setShowIn] = useState(true);

    useEffect(() =>{
        setTimeout(()=>{
            setShowIn(false);
        },100);
    },[]);

    const phonePasswordId = useMemo(() =>{
        return v4();
    },[])

    const validateRules = (password) =>{
        const errors = {};
        if (password?.length < 6) {
            errors.minLength = true;
        } 

        if (password?.length > 20) {
            errors.maxLength = true;
        }

        if (isEmojiCharacter(password)) {
            errors.noEmojis = true;
        }
        return errors;
    }

    const unifyErrors = (value) =>{
        if(showPassword){
            const errors = validateRules(value);
            setRuleErrors(errors);
        }
    }


    return <div className={`${classes.InputFiledContainer} ${classes.PhonePassword} ${props?.className?props.className:''}`}>
        <label htmlFor={phonePasswordId}>{label || Intl.formatMessage({id:"password", defaultMessage:"Password"})}</label>
        <div id={phonePasswordId} className={`${classes.InputFiled} ${meta.touched && meta.error?classes.ErrorCon:""}`}>
            <input 
                className={classes.InputFiledInput}
                {...field} 
                {...InputProps} 
                type={showIn ? "text" : "password"}
                autoComplete={autoComplete}
            />

            <div className={classes.InputIconCon}>
                <span
                    className={`${classes.ShowInIcon} ${showIn ? classes.Active : ""}`}
                    onClick={() => {
                        setShowIn(flag => !flag);
                    }}>
                </span>
            </div>
        </div>

        {
            !showRules && <div className={classes.ErrorMsg}>{meta.touched && meta.error ? meta.error : null}</div>
        }

        {
            showRules && meta.touched && <div className={classes.PasswordRules}>
                <p className={!ruleErrors?.minLength ? classes.Correct : classes.Error}><FormattedMessage id="six_characters_min" defaultMessage="6 characters minimum" /></p>
                <p className={!ruleErrors?.maxLength ? classes.Correct : classes.Error}><FormattedMessage id="twenty_characters_max" defaultMessage="20 characters maximum" /></p>
                <p className={!ruleErrors?.noEmojis ? classes.Correct : classes.Error}><FormattedMessage id="no_emojis" defaultMessage="No emojis" /></p>
            </div>
        }
        
    </div>
}
