import React, {useState} from 'react'
import useStyles from 'isomorphic-style-loader/useStyles'
import classes from './style.module.scss'
import RequestForm from './request-form'
import Policy from './policy'
import { Iconfont } from '../../../../components/icon/iconfont'
import { FormattedMessage } from 'react-intl'
import { useApp, useQuery } from '../../../../hooks/common'

export default props => {
    useStyles(classes)
    const [step, setStep] = useState(1)
    const isApp = useApp();

    const onClose = () => {
        if(step == 1){
            props.onClose()
        }
        setStep(1)
    }

    const closeEvent = () =>{
        if(!!isApp){
            window.location.href = '/close';
            return;
        }
        props.onClose()
        setStep(1)
    }

    return <div className={classes.Container}>
        {
            !isApp && <div className={classes.Hd}>
                <Iconfont className={classes.Close} onClick={onClose}>&#xe7c7;</Iconfont>
                <span className={classes.Title}>
                    <FormattedMessage id="privacy_request_center" defaultMessage="Privacy Request Center"/>
                </span>
            </div>
        }
        
        <div className={classes.Bd}>
            {
                step === 1 && <Policy onContinue={() => {
                    setStep(2)
                }}/>
            }

            {
                step === 2 && <RequestForm onClose={closeEvent}/>
            }
        </div>
    </div>
}